<template>
  <div class="early-detail popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】证件信息' : (todo == 'edit' ? '【修改】证件信息' : '【查看】证件信息')"
      width="60%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="130px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="证件对象" prop="cardObject">
              <el-select class="item-choose" v-model="dataForm.cardObject"
                         :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择" @change="selectObject">
                <el-option
                  v-for="item in dictTypeMap.card_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车号名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="车号名称" v-if="showInputType === 1" :disabled="isView"></el-input>
              <el-cascader
                :disabled="isView"
                v-if="showInputType === 2"
                v-model="dataForm.name"
                :options="vehicleList"
                ref="cascaderTake"
                style="width: 100%"
                clearable
                filterable
                change-on-select
                :props="{ checkStrictly: false, value: 'vehiclePlate', label: 'vehiclePlate' }"
                placeholder="请选择"></el-cascader>
              <el-cascader
                :disabled="isView"
                v-if="showInputType === 3"
                v-model="dataForm.name"
                :options="driverList"
                ref="cascaderTake"
                style="width: 100%"
                clearable
                filterable
                change-on-select
                :props="{ checkStrictly: false, value: 'name', label: 'name' }"
                placeholder="请选择"></el-cascader>
            </el-form-item>
          </el-col>
         <el-col :span="12">
            <el-form-item label="证件类型" prop="cardType">
              <el-select class="item-choose" v-model="dataForm.cardType" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in cardTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="证件编码" prop="cardNo">
              <el-input v-model="dataForm.cardNo" placeholder="证件编码" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="档案编号" prop="archiveNo">-->
<!--              <el-input v-model="dataForm.archiveNo" placeholder="档案编号" :disabled="isView"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="年审供应商" prop="annualReview">
              <el-select class="item-choose"
                         @change="queryNameSearch"
                         v-model="dataForm.annualReview"
                         :disabled="todo === 'view'"
                         style="width: 100%;">
                <el-option
                  v-for="item in dictTypeMap.annual_review_factory"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="办证日期" prop="createCardTime">
              <el-date-picker v-model="dataForm.createCardTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isView">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="办证费用（元）" prop="createCardCost">
              <el-input v-model="dataForm.createCardCost" placeholder="办证费用（元）" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="证件开始日期" prop="cardBeginTime">
              <el-date-picker v-model="dataForm.cardBeginTime" type="date" value-format="yyyy-MM-dd" placeholder="开始日期" :disabled="isView">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件失效日期" prop="cardEndTime">
              <el-date-picker v-model="dataForm.cardEndTime" type="date" value-format="yyyy-MM-dd" placeholder="失效日期" :disabled="isView">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="附件">
              <al-upload :fileData="cardFileList" :fileUrl="uploadUrl" :isView="isView" :format="'img'" :type="'IdCard'" :limit="2" :businessType="8000" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input :rows="1" :disabled="isView" v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      cardTypeList: [],
      dataForm: {
        id: null,
        name: null,
        cardObject: null,
        cardType: null,
        cardNo: null,
        archiveNo: null,
        createCardTime: null,
        createCardCost: 0,
        cardBeginTime: null,
        cardEndTime: null,
        remark: null,
        enclosureInfoList: [],
        annualReview: null
      },
      showInputType: 1,
      vehicleList: [],
      driverList: [],
      uploadUrl: '',
      accessToken: '',
      cardFileList: [],
      proofFileList: [],
      todo: '',
      dataRule: {
        cardObject: this._Valid.init(['null']),
        name: this._Valid.init(['null']),
        cardType: this._Valid.init(['null']),
        cardBeginTime: this._Valid.init(['null']),
        cardEndTime: this._Valid.init(['null']),
        createCardCost: this._Valid.init(['null'])
      },
      annualReviewFactory: 'annual_review_factory'
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 证件类型根据证件对象带出
    selectObject () {
      this.dataForm.name = null
      this.showInputTypeInfo()
      this.getCardType()
    },
    showInputTypeInfo () {
      let cardType = this.dictTypeMap.card_type
      this.showInputType = 1
      for (let i = 0; i < cardType.length; i++) {
        if (this.dataForm.cardObject === cardType[i].id) {
          if (cardType[i].code === 'vehicle') {
            this.showInputType = 2
          }
          if (cardType[i].code === 'driver') {
            this.showInputType = 3
          }
          break
        }
      }
    },
    queryNameSearch () {
      let repairFactoryList = this.dictTypeMap.annual_review_factory
      for (let i = 0; i < repairFactoryList.length; i++) {
        let element = repairFactoryList[i]
        if (this.dataForm.repairItem === element.name) {
          break
        }
      }
    },
    getCardType () {
      this.$http({
        url: this.$http.adornUrl('/cardType/findAllByObject'),
        method: 'get',
        params: this.$http.adornParams({
          'cardObject': this.dataForm.cardObject
        })
      }).then(({ data }) => {
        this.cardTypeList = data
      }).then(() => {
      })
    },
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.cardFileList = []
      this.proofFileList = []
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        this.cardFileList = data.enclosureInfoList
      }
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    getDriver () {
      this.$http({
        url: this.$http.adornUrl('/driver/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.driverList = data
      })
    },
    init (todo, row) {
      if (row === undefined) {
        this.dataForm.id = null
      } else {
        this.dataForm.id = row.id
      }
      if (todo === 'edit') {
        this.dataForm.writeName = row.writeName
        this.dataForm.writeDate = row.writeDate
      }
      this.todo = todo
      this.clearFileList()
      this.getVehicle()
      this.getDriver()
      if (todo !== 'add') {
        this.$http({
          url: this.$http.adornUrl(`/cardWarn/detail/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.id = data.id
            this.dataForm.cardObject = data.cardObject
            this.dataForm.cardType = data.cardType
            this.dataForm.cardNo = data.cardNo
            this.dataForm.archiveNo = data.archiveNo
            this.dataForm.createCardTime = data.createCardTime
            this.dataForm.createCardCost = data.createCardCost
            this.dataForm.cardBeginTime = data.cardBeginTime
            this.dataForm.cardEndTime = data.cardEndTime
            this.dataForm.remark = data.remark
            this.dataForm.annualReview = data.annualReview
            this.selectObject()
            if (data.name.indexOf('/') > -1) {
              this.dataForm.name = data.name.split('/')
            } else {
              this.dataForm.name = data.name
            }
            this.initFileList(data)
            this.visible = true
          }
        })
      } else {
        this.dataForm.name = null
        this.dataForm.cardObject = null
        this.dataForm.cardType = null
        this.dataForm.cardNo = null
        this.dataForm.archiveNo = null
        this.dataForm.createCardTime = null
        this.dataForm.createCardCost = null
        this.dataForm.cardBeginTime = null
        this.dataForm.cardEndTime = null
        this.dataForm.remark = null
        this.visible = true
        this.dataForm.annualReview = null
        this.dataForm.writeName = null
        this.dataForm.writeDate = null
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          if (this.todo === 'add') {
            this.dataForm.status = 1
          }
          let formData = JSON.parse(JSON.stringify(this.dataForm))
          if (Array.isArray(formData.name)) {
            formData.name = formData.name[0] + '/' + formData.name[1]
          }
          this.$http({
            url: this.$http.adornUrl(`/cardWarn/` + methodName),
            method: method,
            data: this.$http.adornData(formData)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    getFileData (data) {
      this.cardFileList = data.fileList
      this.dataForm.enclosureInfoList = data.fileList
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>
<style lang="less">
.early-detail {
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%
  }
}
</style>
