<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
<!--        <el-form-item label="订单类型" prop="orderType">-->
<!--          <el-select class="item-choose" v-model="dataForm.orderType" @change="getDataList" style="width: 100%;" filterable clearable placeholder="请选择">-->
<!--            <el-option label="请选择" value="" ></el-option>-->
<!--            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            @change="getDataList"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
<!--        <el-form-item label="包装类型">-->
<!--          <el-select class="item-choose" @change="getDataList" v-model="dataForm.packType" size="mini" style="width: 100%" filterable clearable placeholder="请选择">-->
<!--            <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="settingMode(0)" type="success" :disabled="!dispatchSelectList.length">取消抢单</el-button>
          <el-button @click="settingMode(1)" type="success" :disabled="!dispatchSelectList.length">设置指派</el-button>
          <el-button @click="settingMode(2)" type="success" :disabled="!dispatchSelectList.length">设置报价</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"  height="64vh" border size="small" ref="tableRef"
                :span-method="arraySpanMethod" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="30" align="center" fixed="left"></el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" prop="orderInfo.takeGoodsDate">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="分拨时间" prop="orderInfo.allocatedTime" width="105">
        </el-table-column>
        <el-table-column label="抢单截止时间" prop="orderInfo.deadlineTime" width="105">
        </el-table-column>
        <el-table-column label="抢单状态" prop="orderInfo.modeStatus" width="70">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.modeStatus == 1" size="warning">抢单中</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.modeStatus == 2" size="danger">结束未成交</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.modeStatus == 3" size="small">已成交</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="平台运费价" prop="orderInfo.newPrice" width="60">
        </el-table-column>
        <el-table-column label="设置价格" prop="orderInfo.settingPrice" width="60">
        </el-table-column>
        <el-table-column label="订单车型" :formatter="dictConvert" prop="orderInfo.vehicleType" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货方" prop="factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系人" prop="contactName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系电话" prop="contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物尺寸(长/宽/高)" prop="orderGoods.length" width="110" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
          </template>
        </el-table-column>
        <el-table-column label="包装类型" width="65" :show-overflow-tooltip="true" :formatter="manyDictConvert"
                         prop="orderGoods.packType">
        </el-table-column>
        <el-table-column label="收货联系人" prop="giveAddressInfo.contactName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货电话" prop="giveAddressInfo.contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center"  width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button type="text" size="small" @click="detail(scope.row, 'view')">详情</el-button>
              <el-button type="text" size="small" @click="detail(scope.row, 'edit')">修改</el-button>
              <el-button type="text" size="small" @click="wayBillReturn(scope.row, 'edit')">订单退回</el-button>
              <el-button type="text" size="small" @click="updateStatus(scope.row, '取消', 12)">取消订单</el-button>
<!--              <el-button v-if="isAuth('sys:user:update') && !scope.row.orderInfo.planNo" type="text" size="small" @click="addCarNum(scope.row, 'edit')">追加到车次</el-button>-->
<!--              <el-button type="text" v-if="scope.row.vehiclePlan && scope.row.vehiclePlan.allWinVehicle === 1" size="small" @click="inputPrepayment(scope.row.orderInfo)">补录代垫</el-button>-->
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-dialog title="设置指派"
               :visible.sync="dispatchLoading"
               width="500px">
      <div>
        已选择 <label style="font-weight: bold;font-size: 20px;">{{ dispatchSelectList.length }}</label> 单，确认将订单设置为 【指派模式】吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dispatchLoading = false">取 消</el-button>
        <el-button type="primary" @click="updateOrderMode">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="取消抢单"
               :visible.sync="grabLoading"
               width="500px">
      <div>
        已选择 <label style="font-weight: bold;font-size: 20px;">{{ dispatchSelectList.length }}</label> 单，确认订单 【取消抢单】吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="grabLoading = false">取 消</el-button>
        <el-button type="primary" @click="updateOrderMode">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置报价"
               :visible.sync="offerLoading"
               width="500px">
      <div>
        已选择 <label style="font-weight: bold;font-size: 20px;">{{ dispatchSelectList.length }}</label> 单，确认将订单设置为 【报价模式】吗？
      </div>
      <div style="margin-top: 20px">
        <el-form :model="offerDataForm" ref="offerDataForm" size="small" label-width="130px">
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="报价截止时间" prop="deadlineTime"
                            :rules="[{ required: true, message: '请选择报价截止时间', trigger: 'change' }]">
                <el-date-picker
                  v-model="offerDataForm.deadlineTime"
                  type="datetime"
                  style="width: 100%"
                  default-time="12:00:00"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
                <el-form-item label="理想成交价格(元)" prop="settingPrice">
                  <el-input v-model="offerDataForm.settingPrice"  placeholder="参考价格"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerLoading = false">取 消</el-button>
        <el-button type="primary" @click="updateOrderMode">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 弹窗 -->
    <intel-scheDetail ref="dispatch" @refreshDataList="getDataList"></intel-scheDetail>
    <report ref="report"></report>
    <add-car-number ref="addCarNum"></add-car-number>
    <input-prepayment-pop ref="inputPrepaymentPop"></input-prepayment-pop>
  </div>
</template>

<script>
import intelScheDetail from '@/views/commonPop/list-dispatch'
import addCarNumber from './detail/addCarNumber'
import report from './detail/abnormalReport'
import inputPrepaymentPop from './detail/inputPrepaymentPop'
import { newAuth } from '@/utils'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        orderType: null,
        takeGoodsTime: null,
        packType: null,
        inWarehouseNo: null,
        customerName: null,
        customerIdList: []
      },
      offerDataForm: {
        deadlineTime: null,
        settingPrice: null,
        operateMode: null
      },
      returnStatus: 11,
      dispatchSelectList: [], // 调度多选
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectListArr: [],
      status: 2,
      getIndex: -1,
      spanArr: [],
      dispatchLoading: false,
      offerLoading: false,
      grabLoading: false

    }
  },
  components: {
    intelScheDetail,
    addCarNumber,
    report,
    inputPrepaymentPop
  },
  created () {
    // this.setDefaultDate()
    // this.getDataList()
  },
  activated () {
    if (!newAuth('order:manager:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    // 异常上报
    abnormalReporting (row) {
      this.$refs.report.init(row)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    addCarNum (row, todo) {
      this.$refs.addCarNum.init(row)
    },
    inputPrepayment (orderInfo) {
      this.$refs.inputPrepaymentPop.init(orderInfo)
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 修改状态
    updateStatus (row, title, status) {
      this.$confirm('确定对【' + row.orderInfo.orderNo + '】进行【' + title + '】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/updateStatusById'),
          method: 'post',
          data: {
            id: row.orderInfo.id,
            status: status
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 订单退回
    wayBillReturn (row) {
      this.$confirm('你正在执行【订单退回】操作，请确认是否继续执行', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let formData = {
          id: row.orderInfo.id,
          status: this.returnStatus
        }
        this.$http({
          url: this.$http.adornUrl(`/order/updateStatusById`),
          method: 'POST',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {

      })
    },
    // 多选
    handleSelectionChange (rows) {
      this.dispatchSelectList = rows
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'status': this.status,
        'operateMode': 3,
        'orderType': this.dataForm.orderType,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'packType': this.dataForm.packType,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerName': this.dataForm.customerName,
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findOrderList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.$emit('getTotal')
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    settingMode (type) {
      this.offerDataForm = {
        deadlineTime: null,
        settingPrice: null,
        operateMode: type
      }
      if (type === 1) {
        this.dispatchLoading = true
      }
      if (type === 2) {
        this.offerLoading = true
      }
      if (type === 0) {
        this.grabLoading = true
      }
    },
    updateOrderMode () {
      if (this.offerLoading) {
        if (!this.offerDataForm.deadlineTime) {
          this.$message.error('截止时间不能为空')
          return
        }
      }
      let orderIdList = []
      this.dispatchSelectList.forEach(item => {
        orderIdList.push(item.orderInfo.id)
      })
      this.$http({
        url: this.$http.adornUrl('/order/updateOrderMode'),
        method: 'post',
        data: this.$http.adornData({
          'orderIdList': orderIdList,
          'deadlineTime': this.offerDataForm.deadlineTime,
          'settingPrice': this.offerDataForm.settingPrice,
          'operateMode': this.offerDataForm.operateMode
        })
      }).then(({ data }) => {
        this.dispatchLoading = false
        this.grabLoading = false
        this.offerLoading = false
        this.getDataList()
      })
    },
    // 详情
    detail (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo
        }
      })
    }
  }
}
</script>
