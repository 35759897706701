<template>
  <div class="cardType-detail  popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】司机提现信息' : (todo == 'edit' ? '【修改】司机提现信息' : '【查看】司机提现信息')"
      width="400px"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="100px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="证件对象" prop="cardObject">
              <el-select class="item-choose" v-model="dataForm.cardObject" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.card_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="证件类型" prop="name">
              <el-input v-model="dataForm.name" placeholder="对象名称" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        cardObject: null,
        name: null,
        status: 1
      },
      todo: '',
      dataRule: {
        name: this._Valid.init(['null']),
        cardObject: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo == 'view'
    }
  },
  methods: {
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      if(id) {
        this.$http({
          url: this.$http.adornUrl(`/cardType/detail/${id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          this.dataForm.name = data.name
          this.dataForm.cardObject = data.cardObject
          this.dataForm.status = data.status
        })
      } else {
        this.dataForm.name = null
        this.dataForm.cardObject = null
        this.dataForm.status = 1
      }
      this.visible = true
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/cardType/` + methodName),
            method: method,
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
