<template>
  <div class="supplier-cost-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】费用信息' : '【编辑】费用信息'"
      :close-on-click-modal="false"
      width="60%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="small">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderInfo.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型" prop="orderInfo.orderType">
                <el-select class="item-choose" v-model="dataForm.orderInfo.orderType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="实际车型" prop="orderInfo.vehicleType">
                <el-select class="item-choose" v-model="dataForm.orderInfo.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in vehicleTypeList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="应付车型">
                <el-input v-model="dataForm.vehiclePlan.vehicleTypeName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.orderInfo.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.orderInfo.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-for="(item) in dataForm.orderInfo.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="供应商名称" prop="customerId">
                <el-input v-model="dataForm.vehiclePlan.motorcadeName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="件数">
                <el-input v-model="dataForm.orderInfo.orderGoods.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                <el-input v-model="dataForm.orderInfo.orderGoods.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                <el-input v-model="dataForm.orderInfo.orderGoods.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="6">
                <el-form-item label="客户单号">
                  <el-input v-model="dataForm.orderInfo.customerSelfNo" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="车次创建时间">
                <el-input v-model="dataForm.vehiclePlan.createTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次创建人">
                <el-input v-model="dataForm.vehiclePlan.createId" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.orderInfo.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>供应商费用</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="dataForm" ref="dataForm" :rules="rules" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="dataForm.freightFee" placeholder="运费"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费" prop="otherFee">
                <el-input v-model="dataForm.otherFee" placeholder="压夜超时费"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款人" prop="payeeUser">
                <el-input v-model="dataForm.payeeUser" placeholder="收款人"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款人电话" prop="payeePhone">
                <el-input v-model="dataForm.payeePhone"  placeholder="收款人电话" :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input v-model="dataForm.remark" type="textarea" :disabled="isViews" placeholder="备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {
          orderGoods: {}
        },
        vehiclePlan: {}
      },
      costInfoData: {},
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      todo: 'view',
      vehicleTypeList: [],
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view' || this.dataForm.status === 2
    }
  },
  methods: {
    init (type, todo, row) {
      this.visible = true
      this.todo = todo || 'view'
      this.dataForm = row
      if (!this.dataForm.orderInfo.orderGoods) {
        this.dataForm.orderInfo.orderGoods = {}
      }
      this.costInfoData = JSON.parse(JSON.stringify(row))
      this.$http({
        url: this.$http.adornUrl(`/supplierCost/orderCost/${row.supplierCost.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.dataForm.orderInfo.takeGoodsDate = data.orderInfo.takeGoodsDate + ' ' + data.orderInfo.takeGoodsTime
      })

      // 获取车型信息
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/supplierCost/updateCost`),
            method: 'POST',
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'freightFee': this.dataForm.freightFee,
              'otherFee': this.dataForm.otherFee,
              'payeeUser': this.dataForm.payeeUser,
              'payeePhone': this.dataForm.payeePhone,
              'remark': this.dataForm.remark
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="less">
  .supplier-cost-pop {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-dialog {
      // margin-top: 20px !important;
      .el-input__inner {
        height: 26px !important;
      }
      .el-dialog__header {
        padding: 10px 20px 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 650px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 10px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
    .modularBox {
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      margin-left: 20px;
      margin-right: 20px;
      .el-col {
        padding-right: 0 !important;
      }
    }
    .modularBox1 {
      padding-right: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .modularBox2 {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 40px;
      margin-bottom: 2px;
      .addressBtn {
        width: 100%;
        padding: 9px 0px;
        text-align: center
      }
      .deleteBtn {
        width: 100%;
        height: 40px;
        padding-top: 5px;
        text-align: right;
      }
    }
    .modularBox3 {
      padding: 25px;
      .carBox {
        width: 320px;
        margin-right: 20px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        text-align: center;
        cursor: pointer;
      }
    }
    .modularBox4 {
      padding: 25px;
    }
    .modularLabel {
      margin-bottom: 0px;
      //padding-left: 20px;
      margin-top: 15px;
      span.separate {
        display: inline-block;
        width: 5px;
        margin-right: 10px;
        background: #17B3A3;
        color: transparent;
        height: 20px;
        line-height: 20px;
      }
      label {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .modularLabel2 {
      margin-top: 0px;
      line-height: 30px;
      height: 30px;
      label {
        min-width: 120px;
        float: left;
      }
    }
    .footer {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      text-align: right;
      padding: 25px;
      .explain {
        color: #17B3A3 !important
      }
    }
    .common-pop {
      .el-table__header-wrapper {
        font-size: 8px;
      }
      .el-table__body-wrapper {
        font-size: 8px;
      }
      .cell {
        padding: 0 !important;
      }
      .el-dialog {
        margin-top: 0px !important;
        margin: 0 auto;
        top: 50%;
        transform: translate(-0, -50%);
        .el-dialog__header {
          padding: 10px;
        }
        .el-dialog__body {
          padding: 10px;
        }
        .el-dialog__footer {
          padding: 10px;
          .el-button {
            height: 32px;
            line-height: 0;
          }
        }
      }
    }
    .el-step__title {
      font-size: 14px; /* 调整为你希望的字体大小 */
      line-height: 18px;
    }
  }
  </style>
