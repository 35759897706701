<template>
  <div class="amap-page-container">
    <div id="container"></div>
    <span class="showDetail" @click="showDetail" :style="{'right': (offsetWid + 350) + 'px'}">
      <img src="@/../static/img/show.png" alt="" v-if="offsetWid == 0">
      <img src="@/../static/img/show.png" alt="" v-else class="rotateDeg">
    </span>
    <div class="map-search">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="提货日期" prop="takeGoodsDate">
          <el-date-picker v-model="dataForm.takeGoodsDate" style="width: 140px" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;"
                    filterable clearable placeholder="请选择">
            <el-option label="请选择" value="" ></el-option>
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="包装类型" prop="packType">
          <el-select class="item-choose" v-model="dataForm.packType" size="mini" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" prop="customerName">
          <el-input v-model="dataForm.customerName" style="width: 200px" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showGroupOrder" style="height:28px;line-height:0;" @change="getDataList">显示已组订单</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div id="outer-box">
      <div id="panel" :style="{'right': offsetWid + 'px'}">
        <div class="panelTitle">
          运单池（单位：单）
        </div>
        <div class="my-list">
          <el-collapse  v-model="activeNames" @change="handleChange">
            <el-collapse-item :name="index + 1" v-for="(item, index) in orderList" :key="item.id">
              <template slot="title">
                <span class="title">
                  <el-checkbox v-model="item.checked" @change="openWindow(item)">
                    <span class="startAddress address-area" :title="item.addressList[0].fullAddress">{{ item.addressList[0].fullAddress }}</span>
                    <span class="symbol address-area">~</span>
                    <span class="endAddress address-area" :title="item.giveAddressInfo.factoryName">{{ item.giveAddressInfo.factoryName }}</span>
                  </el-checkbox>
                </span>
                <span class="status" style="border: 1px solid red;color: red;" v-if="item.orderType === 2">
                  拼
                </span>
                <span class="status" style="border: 1px solid #45c2b5;color: #45c2b5;" v-else>
                  专
                </span>
              </template>
              <div class="transportPool">
                <p class="rows">
                  <span class="label">提货时间：</span>
                  <span class="value">{{ item.takeGoodsDate + ' ' + item.takeGoodsTime }}</span>
                </p>
                <p class="rows" v-for="address in item.addressList" :key="address.id">
                  <span class="label" v-if="address.type === 1">提货地址：</span>
                  <span class="label" v-else-if="address.type === 2">卸货地址：</span>
                  <span class="label" v-else>报关地址：</span>
                  <span class="value">{{ address.fullAddress }}</span>
                </p>
                <p class="rows">
                  <span class="label">包装类型：</span>
                  <span class="value">{{ manyDictConvert('', '', item.orderGoods.packType) }}</span>
                </p>
                <p class="rows">
                  <span class="label">货物名称：</span>
                  <span class="value">{{ item.orderGoods.goodsName }}</span>
                </p>
                <p class="rows">
                  <span class="label">长/宽/高(cm)：</span>
                  <span class="value">{{ getDefaultValue(item.orderGoods.length) }} / {{ getDefaultValue(item.orderGoods.width) }} / {{ getDefaultValue(item.orderGoods.height) }}</span>
                </p>
                <p class="rows">
                  <span class="label">总件/重/体：</span>
                  <span class="value">{{ getDefaultValue(item.orderGoods.pieceNum) }}件/{{ getDefaultValue(item.orderGoods.weight) }}kg/{{ getDefaultValue(item.orderGoods.volume) }}方</span>
                </p>
                <p class="rows">
                  <span class="label">货物备注：</span>
                  <span class="value">{{ item.orderGoods.remark }}</span>
                </p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="waybill-statistics">
          <div class="panelTitle">
            0 运单统计
          </div>
          <div class="statistics">
              <p class="rows">
                <span class="label">已选运单数</span>
                <span class="value">{{waybillStat.orderCount}}单</span>
              </p>
              <p class="rows">
                <span class="label">包装类型</span>
                <span class="value">{{waybillStat.packingType}}</span>
              </p>
              <p class="rows">
                <span class="label">长/宽/高(cm)：</span>
                <span class="value">{{waybillStat.size}}</span>
              </p>
              <p class="rows">
                <span class="label">总件/重/体：</span>
                <span class="value">{{waybillStat.weight}}</span>
              </p>
              <button class="submit" @click="createCarNum()">下一步，确认创建车次</button>
          </div>
        </div>
      </div>
    </div>
    <div id="group-box">
      <div class="title">
        已组车次
      </div>
      <div class="group-body">
        <span class="item">
          <el-input v-model="searchCar" placeholder="支持模糊查询"></el-input>
        </span>
        <span class="item" v-for="(item, index) in groupInfoList" :key="index">
          <span class="groupcar">
            <span class="count">{{item.orderNum}}</span>
            <span class="car-info">{{item.vehicleTypeName}}-{{item.vehiclePlate}}</span>
            <span class="surplus">{{getPlanStatusName(item.status)}}</span>
          </span>
        </span>
      </div>
    </div>
    <!-- 调度 -->
    <intel-scheDetail ref="dispatch" @refreshDataList="refreshData"></intel-scheDetail>
  </div>
</template>

<script>
import intelScheDetail from '@/views/commonPop/list-dispatch'
export default {
  components: {
    intelScheDetail
  },
  data () {
    return {
      mapInfo: null,
      MarkerList: null,
      searchCar: null,
      selectedInfo: {
        dataList: [],
        position: []
      },
      orderList: [],
      activeNames: [1, 2, 3, 4, 5],
      checked: true,
      showGroupOrder: false,
      offsetWid: '0', // 隐藏地图详情面板时位移值
      groupInfoList: [], // 已组车次信息
      markers: [],
      pathSimplifier: null,
      waybillStat: {
        orderCount: 0,
        warehouse: '',
        packingType: '',
        size: '',
        weight: ''
      },
      dispatchSelectList: [],
      style: [
        {
          url: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          anchor: new AMap.Pixel(15, 15),  // 位移
          size: new AMap.Size(25, 25), // 图片大小
          imageSize: new AMap.Size(25, 25), // 根据所设置的大小拉伸或压缩图片
          imageOffset: new AMap.Pixel(-95, -3) // 图像相对展示区域的偏移量，适于雪碧图等
        },
        {
          url: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          anchor: new AMap.Pixel(15, 15),
          size: new AMap.Size(25, 25),
          imageSize: new AMap.Size(25, 25) // 根据所设置的大小拉伸或压缩图片
        }
      ], // 地图上点样式，第一个为起点，第二个为终点
      dataForm: {
        takeGoodsDate: null,
        status: 1,
        orderType: null,
        packType: null,
        customerName: null
      },
      colors: [
        '#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00',
        '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707',
        '#651067', '#329262', '#5574a6', '#3b3eac'
      ],
      totalPage: 0
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  created () {
    this.setDefaultDate()
  },
  methods: {
    setDefaultDate () {
      let start = new Date()
      start.setTime(start.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsDate = this.dateFormatter(start, 'yyyy-MM-dd')
    },
    showDetail () {
      if (this.offsetWid == 0) {
        this.offsetWid = -350
        document.getElementById('group-box').style.width = '100%'
      } else {
        this.offsetWid = 0
        document.getElementById('group-box').style.width = 'calc(100% - 350px)'
      }
    },
    getPlanStatusName (status) {
      let val = ''
      this.dictTypeMap.planStatus.forEach(item => {
        if (item.id === status) {
          val = item.name
        }
      })
      return val
    },
    getDefaultValue (value) {
      if (value) {
        return value
      }
      return '--'
    },
    refreshData (data) {
      this.getDataList()
    },
    createCarNum () {
      this.$nextTick(() => {
        this.$refs.dispatch.init(this.dispatchSelectList)
      })
    },
    // 获取数据
    getDataList () {
      let self = this
      this.mapInfo.clearMap()
      this.clearMap()
      this.dataForm.status = this.showGroupOrder ? 2 : 1
      this.$http({
        url: this.$http.adornUrl('/order/findMapOrderList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'takeGoodsDate': this.dataForm.takeGoodsDate,
          'orderType': this.dataForm.orderType,
          'packType': this.dataForm.packType,
          'customerName': this.dataForm.customerName
        })
      }).then(({ data }) => {
        if (data) {
          data.list.forEach(item => {
            item.checked = false
          })
          this.orderList = data.list
          this.groupInfoList = data.mapPlanList
          // this.totalPage = data.total
          // 如果有数据，先清除数据
          // if(this.markers.length) {
          //   this.mapInfo.remove(this.markers)
          // }
          // for (let i = 0; i < self.pathSimplifierIns.length; i++) {
          //   self.pathSimplifierIns[i].clearPathNavigators()
          //   self.pathSimplifierIns[i].setData([])
          // }
          self.canvasMap()
        } else {
          self.dataList = []
          self.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    handleChange (val) {
    },
    setCenter () {
      this.mapInfo.setZoomAndCenter(11, [114.0295, 22.609875]) // 同时设置地图层级与中心点
    },
    initMap () {
      // 初始化地图
      this.mapInfo = new AMap.Map('container', {
        center: [114.0295, 22.609875],
        resizeEnable: true,
        zoom: 11,
        features: ['bg', 'building', 'point']
      })
    },
    infoWindow (type) {
      let self = this
      let orderInfo = self.selectedInfo.dataList
      if(type !== 'list') {
        self.orderList.forEach(item => {
          this.$set(item, 'checked', false)
        })
        this.$set(orderInfo, 'checked', true)
      }
      var infoWindowContent = `
                    <div class="infoTitle"><strong>详情</strong></div>
                    <div class="infoTip">
                      <p class="rows">
                        <span class="label">提货时间：</span>
                        <span class="value">${orderInfo.takeGoodsDate}  ${orderInfo.takeGoodsTime}</span>
                      </p>
                      ${orderInfo.addressList.map(item =>
                        `<p class="rows">
                          <span class="label">${item.type == 1 ? '提货地址：' : item.type == 2 ? '卸货地址：' : '报关地址：'}</span>
                          <span class="value">${item.fullAddress}</span>
                        </p>`
                      ).join('')}
                      <p class="rows">
                        <span class="labelLeft">
                          <span class="label">客户联系人：</span>
                          <span class="value">${orderInfo.customerContactName}</span>
                        </span>
                        <span class="labelRight">
                          <span class="label">客户联系电话：</span>
                          <span class="value">${orderInfo.customerContactPhone || ''}</span>
                        </span>
                      </p>
                      <p class="rows">
                        <span class="labelLeft">
                          <span class="label">包装类型：</span>
                          <span class="value">${self.manyDictConvert('', '', orderInfo.orderGoods.packType)}</span>
                        </span>
                        <span class="labelRight">
                          <span class="label">货物名称：</span>
                          <span class="value">${orderInfo.orderGoods.goodsName}</span>
                        </span>
                      </p>
                      <p class="rows">
                        <span class="label">单件长/宽/高：</span>
                        <span class="value">${self.getDefaultValue(orderInfo.orderGoods.length)} / ${self.getDefaultValue(orderInfo.orderGoods.width)} / ${self.getDefaultValue(orderInfo.orderGoods.height)}</span>
                      </p>
                      <p class="rows">
                        <span class="label">总件/重/体：</span>
                        <span class="value">${self.getDefaultValue(orderInfo.orderGoods.pieceNum)} / ${self.getDefaultValue(orderInfo.orderGoods.weight)} / ${self.getDefaultValue(orderInfo.orderGoods.volume)}</span>
                      </p>
                  </div>`
      var infoWindow = new AMap.InfoWindow({
        position: self.selectedInfo.position,
        offset: new AMap.Pixel(0, -35),
        closeWhenClickMap: true,
        content: infoWindowContent
      }).open(self.mapInfo)
    },
    canvasLine (lineDataArr) {
      let self = this
      AMapUI.load(['ui/misc/PathSimplifier'], function (PathSimplifier) {
        if (!PathSimplifier.supportCanvas) {
          alert('当前环境不支持 Canvas！')
          return
        }// 绘制线条
        let pathSimplifier = new PathSimplifier({
          zIndex: 100,
          map: self.mapInfo, // 所属的地图实例
          getPath: function (pathData, pathIndex) {
            return pathData.path
          },
          // 鼠标经过线路时，显示的tip
          getHoverTitle: function (pathData, pathIndex, pointIndex) {
            if (pointIndex >= 0) {
              return pathData.name + '，点：' + pointIndex + '/' + pathData.path.length
            }
            return pathData.name + '，点数量' + pathData.path.length
          },
          renderOptions: {
            // 轨迹线的样式
            pathLineStyle: {
              // strokeStyle: self.colors[Math.floor(Math.random() * 4) + 0],
              strokeStyle: self.colors[1],
              lineWidth: 2,
              dirArrowStyle: true
            },
            pathLineSelectedStyle: {
              lineWidth: 3,
              strokeStyle: self.colors[1]
            },
            pathNavigatorStyle: {
              fillStyle: self.colors[1]
            },
            getPathStyle: function (pathItem, zoom) {
              return {
                pathLineSelectedStyle: {
                  strokeStyle: self.colors[1],
                  lineWidth: 6
                },
                pathNavigatorStyle: {
                  fillStyle: self.colors[1]
                }
              }
            }
          }
        })
        pathSimplifier.setData(lineDataArr)
        // 每条线一个巡航器
        lineDataArr.forEach((item, index) => {
          pathSimplifier.createPathNavigator(index, {
            loop: true, // 循环播放
            speed: 800, // 巡航速度，单位千米/小时
            pathNavigatorStyle: {
              width: 15, // 箭头大小
              height: 15,
              strokeStyle: null,
              lineWidth: 2,
              fillStyle: self.colors[1],
              // 经过路径的样式
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: self.colors[1],
                dirArrowStyle: {
                  stepSpace: 15,
                  strokeStyle: self.colors[1]
                }
              }
            }
          }).start()
          self.pathSimplifier = pathSimplifier
        })
      })
    },
    clearMap () {
      if (this.markers.length > 0) {
        this.mapInfo.remove(this.markers)
      }
      if (this.pathSimplifier) {
        this.pathSimplifier.clearPathNavigators()
        this.pathSimplifier.setData([])
      }
    },
    canvasMap () {
      // 多个订单
      if (this.orderList.length < 1) {
        this.clearMap()
        return
      }
      let self = this
      let lineDataArr = []
      for (let i = 0; i < this.orderList.length; i++) {
        // 多个地址连线
        let orderInfo = this.orderList[i]
        for (let j = 0; j < orderInfo.addressList.length; j++) {
          let address = orderInfo.addressList[j]
          let pix1 = 0
          let pix2 = 0
          if (address.type === 1) { // 提货
            pix1 = -9
            pix2 = -3
          } else if (address.type === 3) { // 报关
            pix1 = -52
            pix2 = -3
          } else {
            // 卸货
            pix1 = -95
            pix2 = -3
          }
          // 起点
          let market = new AMap.Marker({
            map: this.mapInfo,
            position: [address.longitude, address.latitude],
            extData: orderInfo,
            offset: new AMap.Pixel(-13, -30),
            icon: new AMap.Icon({
              image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png', // 添加 Icon 图标 URL
              size: new AMap.Size(25, 34),
              imageOffset: new AMap.Pixel(pix1, pix2),  // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(135, 40)   // 根据所设置的大小拉伸或压缩图片
            })
          })
          // 将所有的标点放到markers中
          self.markers.push(market)
          // 点击标点
          AMap.event.addListener(market, 'click', function (e) {
            // self.selectedInfo.dataList = e.target.getExtData().orderInfo
            self.selectedInfo.dataList = e.target.getExtData()
            self.selectedInfo.dataList.packTypeName = self.manyDictConvert(null, null, self.selectedInfo.dataList.packType)
            self.selectedInfo.position = market.getPosition()
            self.infoWindow()
          })
          if (j > 0) {
            // 上一个地址与当前地址连线
            let per = orderInfo.addressList[j - 1]
            lineDataArr.push({
              name: '线路',
              path: [[per.longitude, per.latitude], [address.longitude, address.latitude]]
            })
          }
        }
      }
      self.canvasLine(lineDataArr)
    },
    openWindow (row) {
      // for(let i=0; i<this.orderList.length; i++) {
      //   if(this.orderList[i].checked) {
      //     this.orderCount++
      //   }
      // }
      // this.selectedInfo.dataList = row
      // this.selectedInfo.dataList.packTypeName = this.manyDictConvert(null, null, row.packType)
      // this.selectedInfo.position = [row.addressList[0].longitude, row.addressList[0].latitude]
      // this.infoWindow('list')
    }
  },
  watch: {
    orderList: {
      handler (newDatas) {
        this.waybillStat.orderCount = 0
        this.waybillStat.packingType = ''
        this.waybillStat.size = ''
        this.waybillStat.weight = ''
        this.dispatchSelectList = []
        for(let i=0; i<newDatas.length; i++) {
          if(newDatas[i].checked) {
            this.waybillStat.orderCount++
            this.waybillStat.packingType += (this.manyDictConvert('', '', newDatas[i].orderGoods.packType) + '；')
            this.waybillStat.size += `${this.getDefaultValue(newDatas[i].orderGoods.length)} / ${this.getDefaultValue(newDatas[i].orderGoods.width)} / ${this.getDefaultValue(newDatas[i].orderGoods.height)}；`
            this.waybillStat.weight += `${this.getDefaultValue(newDatas[i].orderGoods.pieceNum)} / ${this.getDefaultValue(newDatas[i].orderGoods.weight)} / ${this.getDefaultValue(newDatas[i].orderGoods.volume)}方；`
          }
          this.dispatchSelectList.push({
            orderInfo: newDatas[i]
          })
        }
      },
      deep: true
    }
  },
  mounted () {
    this.initMap()
    this.getDataList()
  }
}
</script>
  <style lang="less">
  .amap-page-container {
    margin: -20px -10px;
    position: relative;
    .map-search {
      position: absolute;
      top: 10px;
      left: 40%;
      transform: translate(-50%);
      padding: 5px 20px;
      border: 1px solid #ccc;
      background: #fff;
      width: 66%;
      .el-input {
        width: 113px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
    }

    div#group-box {
      background: #fff;
      position: absolute;
      bottom: 0;
      width: calc(100% - 350px);
      transition: width 2s;
      -moz-transition: width 2s; /* Firefox 4 */
      -webkit-transition: width 2s; /* Safari and Chrome */
      -o-transition: width 2s; /* Opera */
      min-height: 100px;
      display: flex;
      z-index: 161;
      .title {
        writing-mode: vertical-lr;
        text-align: center;
        background: #45c2b5;
        width: 35px;
        line-height: 35px;
        font-size: 18px;
        color: #fff;
        letter-spacing: 5px;
      }
      .group-body {
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-right: 10px;
          width: 220px;
          height: 32px;
          margin: 5px;
          .groupcar {
            display: block;
            font-size: 12px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            span.count {
              display: inline-block;
              background: #c2c2c2;
              width: 20px;
              text-align: center;
              color: #fff;
              height: 30px;
              line-height: 30px;
              float: left;
            }
            span.car-info {
              height: 30px;
              display: inline-block;
              line-height: 30px;
              width: 150px;
              text-align: center;
              font-size: 12px;
            }
            span.surplus {
              background: #c2c2c2;
              width: 40px;
              height: 30px;
              text-align: center;
              color: #fff;
              float: right;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          border-radius: 0px;
        }
      }
    }
    #container {
      width: 100%;
      height: 87vh;
    }
    #panel {
      width: 350px;
      background: #fff;
      position: absolute;
      top: 10px;
      right: 0;
      height: 99%;
      overflow: auto;
      transition: right 2s;
      -moz-transition: right 2s; /* Firefox 4 */
      -webkit-transition: right 2s; /* Safari and Chrome */
      -o-transition: right 2s; /* Opera */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      .panelTitle {
        height: 32px;
        background: #45c2b5;
        color: #fff;
        line-height: 32px;
        font-weight: 700;
        padding-left: 10px;
      }
      .my-list {
        padding: 0 6px;
        height: calc(80vh - 88px - 32px);
        overflow-y: auto;
        overflow-x: hidden;
        .title {
          .el-checkbox__label {
            display: inline-block;
            width: 95%;
            text-align: left;
            padding-left: 6px;
            float: right
          }
        }
        .address-area {
          width: 132px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          margin-top: 6px;
        }
        .symbol {
          display: inline-block;
          width: 10px;
        }
        .endAddress {
          // float: right
        }
        .el-collapse-item__header {
          height: 30px;
          line-height: 30px;
          position: relative;
          .el-checkbox__label {
            font-size: 12px
          }
          .status {
              display: inline-block;
              height: 22px;
              width: 22px;
              line-height: 22px;
              position: absolute;
              right: 20px;
              text-align: center;
              border-radius: 50%;
              border: 1px solid red;
              color: red;
              background: #fff;
              font-size: 12px;
          }
        }
        .el-collapse-item {
          margin: 5px 0;
          box-shadow: 0 2px 8px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
          border: 1px solid #45c2b5;
          padding: 0 3px;
        }
        .el-collapse-item__wrap {
          padding: 5px;
          // border: 1px solid #45c2b5;
          box-shadow: 0 2px 8px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
          .el-collapse-item__content {
            padding-bottom: 0;
          }
        }
        .transportPool {
          .rows {
            margin: 0;
            font-size: 12px;
            .label {
              width: 80px !important;
              display: inline-block;
              color: #666;
            }
          }
        }
      }
      .waybill-statistics {
        position: absolute;
        width: 100%;
        bottom: 10px;
        z-index: 1;
        .statistics {
          padding-left: 7px;
          background: #fff;
          .rows {
            padding: 3px 0;
            margin: 0;
            font-size: 12px;
            .label {
              width: 86px;
              display: inline-block;
              color: #666;
            }
          }
          button.submit {
            width: 100%;
            background: #45c2b5;
            height: 26px;
            border: 0;
            color: #fff;
            font-weight: 800;
          }
        }
      }
    }
  }
  .infoTip {
    width: 400px;
    font-size: 12px;
    .rows {
      margin: 0;
      padding: 5px;
      span.label {
        display: inline-block;
        width: 80px;
        text-align: right;
        box-sizing: border-box;
        float: left;
        color: #666;
      }
      span.value {
        display: inline-block;
        word-break: break-all;
        width: 300px;
        color: #000 ;
      }
      .labelLeft, .labelRight {
        width: 190px;
        display: inline-block;
        .value {
          width: 110px
        }
      }
      .labelRight {
        .label {
          width: 85px;
        }
        .value {
          width: 105px;
        }
      }
    }
  }
  </style>
  <style lang="less" scoped>
  #pane-map-map-dispatch {
    // position: relative;
    .showDetail {
      position: absolute;
      top: 40%;
      right: 350px;
      z-index: 0;
      background: #45c2b5;
      display: block;
      width: 20px;
      height: 100px;
      line-height: 100px;
      text-align: left;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      transition: right 2s;
      -moz-transition: right 2s; /* Firefox 4 */
      -webkit-transition: right 2s; /* Safari and Chrome */
      -o-transition: right 2s; /* Opera */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      img {
        width: 100%;
        height: 30px
      }
    }
  }
  .rotateDeg {
     -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
    -webkit-transition:-webkit-transform 2s linear;
    transition:transform 2s linear;
  }
  </style>
