<template>
  <div class="order-examine-popup">
    <el-dialog title="查看退回原因" :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="退回原因" prop="reason">
              <el-input v-model="dataForm.reason" type="textarea" rows="5" :disabled="true" placeholder="退回原因说明限200字" maxlength="200"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        reason: '',
        ids: ''
      },
      row: {},
      dataRule: {
      }
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
      this.dataForm.reason = row.reason
      this.dataForm.ids = null
    }
  }
}
</script>
