<template>
  <div class="mod-log">
    <data class="searchBox">
      <el-form :inline="true" size="mini" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="操作说明" clearable></el-input>
        </el-form-item>
        <el-form-item label="操作方式">
          <el-select class="item-choose" v-model="dataForm.type" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option v-for="(item, index) in requestType" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作模块">
          <el-select class="item-choose" v-model="dataForm.module" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option v-for="(item, index) in dictTypeMap.module_type"
                       :key="index"
                       :label="item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList(true)" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </data>
    <div class="tableBox">
      <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%" size="mini">
        <el-table-column prop="createName" header-align="center" align="center" label="用户名" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.createName | filterEmpty }} <span v-if="scope.row.createId">(ID: {{ scope.row.createId
              }})</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" header-align="center" align="center" label="操作说明" min-width="100" :show-overflow-tooltip="true" />
        <el-table-column prop="type" header-align="center" align="center" label="操作类型" width="70"/>
        <el-table-column prop="module" header-align="center" align="center" label="操作模块" min-width="150"/>
        <el-table-column prop="requestUri" header-align="center" align="center" min-width="250"
          :show-overflow-tooltip="true" label="请求方法">
        </el-table-column>
        <el-table-column prop="requestBody" header-align="center" align="center" min-width="300"
          :show-overflow-tooltip="true" label="请求参数">
        </el-table-column>
        <el-table-column prop="requestTime" header-align="center" align="center" width="100" label="执行时长(毫秒)">
        </el-table-column>
        <el-table-column prop="requestIp" header-align="center" align="center" width="120" label="IP地址">
        </el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" width="130" label="创建时间">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="querry.page"
        :page-sizes="[25, 50, 100]" :page-size="querry.limit" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { requestType } from '@/utils/constants'
export default {
  data () {
    return {
      requestType: requestType,
      dataForm: {},
      dataList: [],
      querry: {
        page: 1,
        limit: 25
      },
      totalPage: 0,
      dataListLoading: false,
      selectionDataList: []
    }
  },
  created () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 获取数据列表
    getDataList (reset = false) {
      if (reset) this.querry.page = 1
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/system/log/findList'),
        method: 'get',
        params: this.$http.adornParams({ ...this.querry, ...this.dataForm })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.querry.limit = val
      this.querry.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.querry.page = val
      this.getDataList()
    }
  }
}
</script>
