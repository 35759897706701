<template>
  <div class="vehicle-pop popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】车辆信息' : (todo == 'edit' ? '【修改】车辆信息' : '【查看】车辆信息')"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="50%"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="mini" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车牌号" prop="vehiclePlate">
              <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属车队" prop="motorcadeId">
              <el-select class="item-choose" v-model="dataForm.motorcadeId" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in motorcadeList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="能源类型" prop="energyType">
              <el-select class="item-choose" v-model="dataForm.energyType" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.energy_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆类型" prop="vehicleType">
              <el-select class="item-choose" :disabled="isView" v-model="dataForm.vehicleType" style="width: 100%;"
                         filterable clearable placeholder="请选择" @change="changeVehicleType(dataForm.vehicleType)">
                <el-option
                  v-for="item in vehicleTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="最大载重(kg)" prop="maxLoad">
              <el-input v-model="dataForm.maxLoad" placeholder="最多两位小数" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车厢内高(cm)" prop="carriageInsideHeight">
              <el-input v-model="dataForm.carriageInsideHeight" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车型内长(cm)" prop="carriageInsideLength">
              <el-input v-model="dataForm.carriageInsideLength" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车厢内宽(cm)" prop="carriageInsideWidth">
              <el-input v-model="dataForm.carriageInsideWidth" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="进门高(cm)" prop="enterDoorHeight">
              <el-input v-model="dataForm.enterDoorHeight" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进门宽(cm)" prop="enterDoorWidth">
              <el-input v-model="dataForm.enterDoorWidth" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="是否有尾板" prop="isTailstock">
              <el-select class="item-choose" v-model="dataForm.isTailstock" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆空重(KG)" prop="vehicleEmptyWeight">
              <el-input v-model="dataForm.vehicleEmptyWeight" placeholder="车辆空重(KG)" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车辆常驻地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="车辆常驻地址" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" :rows="1" v-model="dataForm.remark" placeholder="备注"
                        :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="标准油耗(L)" prop="consumeOil">
              <el-input v-model="dataForm.consumeOil" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标准车用泵素(KG)" prop="pumpElement">
              <el-input v-model="dataForm.pumpElement" placeholder="请输入" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="行驶证">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleDrivingType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleDrivingType)" :src="getPhotoType(vehicleDrivingType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆45度角">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleType)" :src="getPhotoType(vehicleType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="交强险">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleForceType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleForceType)" :src="getPhotoType(vehicleForceType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商业险">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleBusinessType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleBusinessType)" :src="getPhotoType(vehicleBusinessType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车辆营运证">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleTransportType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleTransportType)" :src="getPhotoType(vehicleTransportType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="过磅单">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: vehicleWeighType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(vehicleWeighType)" :src="getPhotoType(vehicleWeighType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-top: -10px;">
          <h3 align="center">个性化设置</h3>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="是否有GPS" prop="isGps">
                <el-select class="item-choose" v-model="dataForm.isGps" :disabled="isView" style="width: 100%;"
                           filterable clearable placeholder="请选择">
                  <el-option
                    v-for="item in dictTypeMap.yes_no"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="GPS设备号" prop="gpsNo">
                <el-input v-model="dataForm.gpsNo" placeholder="GPS设备号" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50" v-if="dataForm.isGps == 1">
            <el-col :span="12">
              <el-form-item label="第三方GPS平台" prop="platformGpsConfigIds">
                <el-select v-model="platformGpsConfigIdsArrayValueIds" multiple placeholder="请选择" value-key="id" clearable :disabled="isView">
                  <el-option
                      v-for="item in platformGpsConfigIdsArray"
                      :disabled="item.status == 1"
                      :label="item.configName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="是否共赢车辆" prop="allWinVehicle">
                <el-select class="item-choose" v-model="dataForm.allWinVehicle" :disabled="isView" style="width: 100%;"
                           filterable clearable placeholder="请选择">
                  <el-option
                    v-for="item in dictTypeMap.yes_no"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--            <el-form-item label="共赢运费应付(%)" prop="allWinFee">-->
              <!--              <el-input v-model="dataForm.allWinFee" placeholder="共赢运费应付(%)" :disabled="isView"></el-input>-->
              <!--            </el-form-item>-->
              <el-form-item label="平台分成比例(%)" prop="platformShare">
                <el-input v-model="dataForm.platformShare" placeholder="共赢车平台分成比例" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="月卡停车费" prop="monthParkingFee">
                <el-input v-model="dataForm.monthParkingFee" placeholder="月卡停车费,仅保留两位小数" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车辆挂靠费" prop="affiliationFee">
                <el-input v-model="dataForm.affiliationFee" placeholder="车辆挂靠费,仅保留两位小数" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="目标产值" prop="targetProfit">
                <el-input v-model="dataForm.targetProfit" placeholder="车辆目标产值" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车辆停车费" prop="parkingFee">
                <el-input v-model="dataForm.parkingFee" placeholder="车辆停车费" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="车辆社保" prop="socialFee">
                <el-input v-model="dataForm.socialFee" placeholder="车辆社保" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="尿素费用" prop="ureaFee">
                <el-input v-model="dataForm.ureaFee" placeholder="尿素费用" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="支出费用1" prop="other1Fee">
                <el-input v-model="dataForm.other1Fee" placeholder="支出费用1" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支出费用2" prop="other2Fee">
                <el-input v-model="dataForm.other2Fee" placeholder="支出费用2" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="支出费用3" prop="other3Fee">
                <el-input v-model="dataForm.other3Fee" placeholder="支出费用3" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      platformGpsConfigIdsArray: [],
      platformGpsConfigIdsArrayValueIds: [],
      dataForm: {
        id: null,
        vehiclePlate: null,
        motorcadeId: null,
        vehicleType: null,
        energyType: null,
        drivingCode: null,
        isTailstock: null,
        isGps: 2,
        gpsNo: null,
        maxLoad: null,
        carriageInsideLength: null,
        carriageInsideWidth: null,
        carriageInsideHeight: null,
        enterDoorHeight: null,
        enterDoorWidth: null,
        address: null,
        remark: null,
        targetProfit: null,
        vehicleEmptyWeight: null,
        allWinVehicle: null,
        affiliationFee: null,
        allWinFee: null,
        monthParkingFee: null,
        parkingFee: null,
        platformShare: null,
        other1Fee: null,
        other2Fee: null,
        other3Fee: null,
        socialFee: null,
        ureaFee: null,
        enclosureInfoList: [],
        consumeOil: null,
        pumpElement: null,
        platformGpsConfigIds: null
      },
      uploadUrl: '',
      accessToken: '',
      vehicleDrivingType: 3000,
      vehicleType: 3001,
      vehicleTransportType: 3002,
      vehicleWeighType: 3004,
      vehicleForceType: 9040,
      vehicleBusinessType: 9041,
      motorcadeList: [],
      todo: '', // 当前弹框所属状态， add:新增 ； edit: 编辑 ; view: 查看
      dataRule: {
        vehiclePlate: this._Valid.init(['null']),
        motorcadeId: this._Valid.init(['null']),
        energyType: this._Valid.init(['null']),
        vehicleType: this._Valid.init(['null'])
      },
      vehicleTypeList: [],
      domain: ''
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  created() {
    let that = this
    that.$http({
      url: that.$http.adornUrl('/sys/appConfig/get'),
      method: 'get',
      params: that.$http.adornParams({ type: 2 })
    }).then(({ data }) => {
      this.domain = JSON.parse(data.configValue).spaceDomain
    })
  },
  methods: {
    // 选择车型时自动带出车载重\长\宽\高
    changeVehicleType (id) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataForm.maxLoad = data.maxLoad
          this.dataForm.carriageInsideLength = data.carriageLength
          this.dataForm.carriageInsideWidth = data.carriageWidth
          this.dataForm.carriageInsideHeight = data.carriageHeight
        }
      })
    },
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.dataForm.enclosureInfoList = []
      this.platformGpsConfigIdsArray = []
      this.platformGpsConfigIdsArrayValueIds = []
    },
    initFileList (data) {
      if (data.enclosureInfoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
      }
    },
    init (todo, id,vehicleNo,platformGpsConfigIdsArrayValueId) {
      this.clearFileList()
      this.dataForm.id = id
      this.todo = todo
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.motorcadeList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/vehicleInfo/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              this.dataForm.vehiclePlate = data.vehiclePlate
              this.dataForm.motorcadeId = data.motorcadeId
              this.dataForm.vehicleType = data.vehicleType
              this.dataForm.energyType = data.energyType
              this.dataForm.drivingCode = data.drivingCode
              this.dataForm.isTailstock = data.isTailstock
              this.dataForm.isGps = data.isGps
              this.dataForm.targetProfit = data.targetProfit
              this.dataForm.gpsNo = data.gpsNo
              this.dataForm.maxLoad = data.maxLoad
              this.dataForm.carriageInsideLength = data.carriageInsideLength
              this.dataForm.carriageInsideWidth = data.carriageInsideWidth
              this.dataForm.carriageInsideHeight = data.carriageInsideHeight
              this.dataForm.enterDoorHeight = data.enterDoorHeight
              this.dataForm.enterDoorWidth = data.enterDoorWidth
              this.dataForm.address = data.address
              this.dataForm.remark = data.remark
              this.dataForm.vehicleEmptyWeight = data.vehicleEmptyWeight
              this.dataForm.allWinVehicle = data.allWinVehicle
              this.dataForm.affiliationFee = data.affiliationFee
              this.dataForm.allWinFee = data.allWinFee
              this.dataForm.monthParkingFee = data.monthParkingFee
              this.dataForm.parkingFee = data.parkingFee
              this.dataForm.platformShare = data.platformShare
              this.dataForm.other1Fee = data.other1Fee
              this.dataForm.other2Fee = data.other2Fee
              this.dataForm.other3Fee = data.other3Fee
              this.dataForm.socialFee = data.socialFee
              this.dataForm.ureaFee = data.ureaFee
              this.dataForm.consumeOil = data.consumeOil
              this.dataForm.pumpElement = data.pumpElement
              this.platformGpsConfigIdsArrayValueIds = data.platformGpsConfigIds != undefined ? data.platformGpsConfigIds.split(',') : []
              this.initFileList(data)
            }
          })
        } else {
          if (vehicleNo != null) {
            this.dataForm.vehiclePlate = vehicleNo
            this.dataForm.isGps = 1
            this.platformGpsConfigIdsArrayValueIds.push(platformGpsConfigIdsArrayValueId)
          }
        }
      })

      // 获取第三方平台GPS信息
      this.$http({
        url: this.$http.adornUrl('/platform/gps/all'),
        method: 'get'
      }).then(({ data }) => {
        if (data) {
          this.platformGpsConfigIdsArray = data
        }
      }).finally(() => {})

      // 获取车型信息
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/vehicleInfo/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'vehiclePlate': this.dataForm.vehiclePlate,
              'motorcadeId': this.dataForm.motorcadeId,
              'vehicleType': this.dataForm.vehicleType,
              'energyType': this.dataForm.energyType,
              'drivingCode': this.dataForm.drivingCode,
              'isTailstock': this.dataForm.isTailstock,
              'isGps': this.dataForm.isGps,
              'gpsNo': this.dataForm.gpsNo,
              'targetProfit': this.dataForm.targetProfit,
              'maxLoad': this.dataForm.maxLoad,
              'carriageInsideLength': this.dataForm.carriageInsideLength,
              'carriageInsideWidth': this.dataForm.carriageInsideWidth,
              'carriageInsideHeight': this.dataForm.carriageInsideHeight,
              'enterDoorHeight': this.dataForm.enterDoorHeight,
              'enterDoorWidth': this.dataForm.enterDoorWidth,
              'address': this.dataForm.address,
              'vehicleEmptyWeight': this.dataForm.vehicleEmptyWeight,
              'enclosureInfoList': this.dataForm.enclosureInfoList,
              'remark': this.dataForm.remark,
              'allWinVehicle': this.dataForm.allWinVehicle,
              'affiliationFee': this.dataForm.affiliationFee,
              'allWinFee': this.dataForm.allWinFee,
              'monthParkingFee': this.dataForm.monthParkingFee,
              'parkingFee': this.dataForm.parkingFee,
              'platformShare': this.dataForm.platformShare,
              'socialFee': this.dataForm.socialFee,
              'ureaFee': this.dataForm.ureaFee,
              'other1Fee': this.dataForm.other1Fee,
              'other2Fee': this.dataForm.other2Fee,
              'other3Fee': this.dataForm.other3Fee,
              'consumeOil': this.dataForm.consumeOil,
              'pumpElement': this.dataForm.pumpElement,
              'platformGpsConfigIds': this.platformGpsConfigIdsArrayValueIds != null && this.platformGpsConfigIdsArrayValueIds.length > 0 ? this.platformGpsConfigIdsArrayValueIds.join(',') : null,
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    getPhotoType (type) {
      let enclosureInfoList = this.dataForm.enclosureInfoList
      if (!enclosureInfoList) {
        return ''
      }
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === type) {
          return this.domain + enclosureInfoList[i].url
        }
      }
      return ''
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      let enclosureInfoList = this.dataForm.enclosureInfoList
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === resData.businessType) {
          enclosureInfoList.splice(i, 1)
          break
        }
      }
      enclosureInfoList.push({ id: resData.id, businessType: resData.businessType, url: resData.url })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>

<style lang="less">
.vehicle-pop {
  .el-dialog__body {
    padding: 10px 35px;
    max-height: 750px;
    overflow: auto;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
