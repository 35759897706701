<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="邮箱账号">
          <el-input v-model="dataForm.account" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="发件人名称">
          <el-input v-model="dataForm.sendName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose"
                     v-model="dataForm.status"
                     size="small"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button type="success"
                     v-if="newAuth('offer:mail:add')"
                     @click="addOrUpdateHandle('add')">新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" @row-click="rowClick"
                :row-style="selectedHighlight" height="72vh" border size="small" v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" width="60" align="center"/>
        <el-table-column prop="account" label="邮箱账号" min-width="140"/>
        <el-table-column prop="sendName" label="发件人名称" min-width="140" :show-overflow-tooltip="true"/>
        <el-table-column prop="telePhone" label="联系电话" min-width="100" :show-overflow-tooltip="true"/>
        <el-table-column prop="host" label="SMTP地址" width="100" :show-overflow-tooltip="true"/>
        <el-table-column prop="port" label="端口" width="60"/>
        <el-table-column prop="createName" label="创建人" width="100"/>
        <el-table-column prop="createTime" label="创建时间" width="150"/>
        <el-table-column prop="defaultOrNot" fixed="right" label="默认">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.defaultOrNot"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="setDefaultOrNot(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="status" fixed="right" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="newAuth('offer:mail:list')" type="text" size="small"
                       @click="addOrUpdateHandle('view', scope.row)">详情
            </el-button>
            <el-button v-if="newAuth('offer:mail:update')" type="text" size="small"
                       @click="addOrUpdateHandle('edit', scope.row)">修改
            </el-button>
            <el-button v-if="newAuth('offer:mail:delete')" type="text" size="small"
                       @click="disableOrEnable(scope.row)">{{ scope.row.status === 0 ? '启用' : '禁用' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!--表单组件-->
    <el-dialog :close-on-click-modal="false"
               :visible.sync="visible"
               v-loading="saveLoading"
               title="邮箱配置">
      <el-form ref="detailForm" :model="detailForm" :rules="rules" size="small" label-width="200px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="邮箱账号" prop="account">
              <el-input v-model="detailForm.account" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱密码" prop="password">
              <el-input v-model="detailForm.password" :disabled="detailDisable" placeholder="该密码为授权密码"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="发件人名称" prop="sendName">
              <el-input v-model="detailForm.sendName" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="telePhone">
              <el-input v-model="detailForm.telePhone" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="SMTP地址" prop="host">
              <el-input v-model="detailForm.host" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱端口" prop="port">
              <el-input v-model="detailForm.port" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="邮件主题">
          <el-input v-model="detailForm.mailSubject" :disabled="detailDisable" type="textarea"/>
        </el-form-item>
        <el-form-item label="模板内容">
          <el-input v-model="detailForm.templateText" :disabled="detailDisable" type="textarea" rows="3"/>
        </el-form-item>
        <el-form-item label="邮件签名">
          <el-input v-model="detailForm.mailSign" :disabled="detailDisable" type="textarea" rows="10"/>
        </el-form-item>
        <el-form-item label="发送账单-头部信息文案">
          <el-input v-model="detailForm.billHeader" :disabled="detailDisable" type="textarea" rows="10"/>
        </el-form-item>
        <el-form-item label="发送账单-底部信息文案">
          <el-input v-model="detailForm.billBottom" :disabled="detailDisable" type="textarea" rows="10"/>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="发送报价-报价方" prop="sendQuoteOffered">
              <el-input v-model="detailForm.sendQuoteOffered" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发送报价-联系人" prop="sendQuoteContactPerson">
              <el-input v-model="detailForm.sendQuoteContactPerson" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="发送报价-联系邮箱" prop="sendQuoteContactEmail">
              <el-input v-model="detailForm.sendQuoteContactEmail" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发送报价-联系电话" prop="sendQuoteContactPhone">
              <el-input v-model="detailForm.sendQuoteContactPhone" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="发送报价-公司名称" prop="sendQuoteCompanyName">
              <el-input v-model="detailForm.sendQuoteCompanyName" :disabled="detailDisable"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
        <el-form-item label="备注信息">
          <el-input v-model="detailForm.remark" :disabled="detailDisable" type="textarea" rows="2" maxlength="300" show-word-limit/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" v-if="!detailDisable" @click="submitDetailForm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        sendName: null,
        account: null,
        status: null
      },
      detailForm: {
        id: null,
        account: null,
        password: null,
        sendName: null,
        telePhone: null,
        host: null,
        port: null,
        mailSubject: null,
        templateText: null,
        mailSign: null,
        remark: null,
        billHeader: '公司信息：***********\n' +
            '公司电话：*********\n' +
            '公司地址：***********',
        billBottom: '温馨提示:\n' +
            ' 1、请仔细核对，如对以上账单有疑问，请联系我司财务，QQ:*****\n' +
            ' 2、确认无误后，请签字盖章回传我司，收到账单3天内无反馈，我司将视作此费用默认无误！\n' +
            ' 3、银行信息：                                                            私户信息（不开票）：\n' +
            '      户名：*****                                              户名：*****\n' +
            '      账号：*****                                                          账号：*****\n' +
            '      开户行：*****                                            开户行：*****'
      },
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      visible: false,
      saveLoading: false,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      detailDisable: false,
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        sendName: [{ required: true, message: '发送人名称不能为空', trigger: 'blur' }],
        telePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
        host: [{ required: true, message: 'SMTP地址不能为空', trigger: 'blur' }],
        port: [{ required: true, message: '端口不能为空', trigger: 'blur' }]
      }
    }
  },
  components: {},
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/sysMailbox/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'account': this.dataForm.account,
          'sendName': this.dataForm.sendName
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.visible = true
      this.detailDisable = todo === 'view'
      if (todo === 'add') {
        this.detailForm = this.$options.data().detailForm
      } else {
        this.detailForm = row
      }
    },
    submitDetailForm () {
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          this.$http({
            url: this.$http.adornUrl(`/sysMailbox/save`),
            method: 'POST',
            data: this.$http.adornData(this.detailForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }

          }).finally(() => {
             this.saveLoading = false
          })
        }
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 0 ? '启用' : '禁用'
      this.$confirm('确定对【' + row.account + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sysMailbox/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 0 ? 1 : 0,
            defaultOrNot: row.defaultOrNot
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 设置默认值
    setDefaultOrNot (row) {
      let title = row.defaultOrNot === 0 ? '设置' : '否'
      this.$confirm('确定对【' + row.account + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sysMailbox/setDefaultOrNot/' + row.id),
          method: 'PUT',
          params: {
            defaultOrNot: row.defaultOrNot === 0 ? 0 : 1
          }
        }).then(({ data }) => {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
          })
        })
      }).catch(() => {})
          .finally(() => {
            setTimeout(() => {
              this.getDataList()
            },1000)

      })
    }
  }
}
</script>
