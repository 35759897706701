// 企业应付未付统计表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="月份" prop="takeGoodsDate">
          <el-date-picker
            v-model="dataForm.takeGoodsDate"
            type="monthrange"
            align="right"
            unlink-panels
            value-format="yyyy-MM"
            :clearable="false"
            range-separator="-"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                @row-click="rowClick"
                show-summary
                :row-style="selectedHighlight"
                :height="this.tableHeight"
                border size="small"
                :fit = "true"
                :cell-class-name = "tableCellClassName"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" fixed="left" align="center" width="40">
        </el-table-column>
        <el-table-column prop="yearMonth" label="年月" :show-overflow-tooltip="true" fixed="left" align="center">
        </el-table-column>
        <el-table-column label="总应收情况" align="center">
          <el-table-column prop="totalIncome" label="应收" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="totalIncomePaid" label="已收" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="totalIncomeNotPay" label="未收" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="总应付情况" align="center">
          <el-table-column prop="totalPay" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="totalPaid" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="totalNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="应付费用池" align="center">
          <el-table-column prop="payFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="paidAmount" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="notPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="driverBorrowing" label="借支" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="业务部提成" align="center">
          <el-table-column prop="saleFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="salePaidAmount" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="saleNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="客服部提成" align="center">
          <el-table-column prop="careFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="carePaidAmount" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="careNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="运输部提成" align="center">
          <el-table-column prop="driverFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="driverPaidAmount" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="driverNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="供应商费用" align="center">
          <el-table-column prop="supplierCostFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="supplierCostPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="supplierCostNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="客户维护费" align="center">
          <el-table-column prop="maintainFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="maintainPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="maintainNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="客户税费" align="center">
          <el-table-column prop="taxesFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="taxesPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="taxesNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="证件办证费" align="center">
          <el-table-column prop="createCardFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="createCardPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="createCardNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="车辆保险费" align="center">
          <el-table-column prop="insurePriceFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="insurePricePaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="insurePriceNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="车辆维修费" align="center">
          <el-table-column prop="repairMoneyFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="repairMoneyPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="repairMoneyNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="事故损失费" align="center">
          <el-table-column prop="lossMoney" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="lossMoney" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="lossMoneyNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="车用泵素费" align="center">
          <el-table-column prop="pumpElementFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="pumpElementPaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="pumpElementNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="其他费用" align="center">
          <el-table-column prop="expenditureFreightFee" label="应付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="expenditurePaidAmounts" label="已付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
          <el-table-column prop="expenditureNotPay" label="未付" :formatter="getNumValue" :show-overflow-tooltip="true" align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick (picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick (picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dataForm: {
        takeGoodsDate: null
      },
      showTotal: false,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      tableHeight: '65vh',
      totalPage: 0,
      dataListLoading: false
    }
  },
  created () {
    let now = new Date()
    let start = new Date(now.getFullYear(), 0, 1)
    this.dataForm.takeGoodsDate = [this.dateFormatter(start, 'yyyy-MM'), this.dateFormatter(now, 'yyyy-MM')]
    this.getDataList()
  },
  methods: {
    tableCellClassName ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 2) {
        return ''
      }
      if (column.property === 'totalIncome' || column.property === 'totalIncomePaid' || column.property === 'totalIncomeNotPay' || column.property === 'payFreightFee' ||
        column.property === 'paidAmount' || column.property === 'notPay' || column.property === 'careFreightFee' || column.property === 'carePaidAmount' ||
        column.property === 'careNotPay' || column.property === 'supplierCostFreightFee' || column.property === 'supplierCostPaidAmounts' || column.property === 'supplierCostNotPay' ||
        column.property === 'taxesFreightFee' || column.property === 'taxesPaidAmounts' || column.property === 'taxesNotPay' || column.property === 'insurePriceFreightFee' ||
        column.property === 'insurePricePaidAmounts' || column.property === 'insurePriceNotPay' || column.property === 'lossMoney' || column.property === 'lossMoneyNotPay' ||
        column.property === 'driverBorrowing' || column.property === 'expenditureFreightFee' || column.property === 'expenditurePaidAmounts' ||
        column.property === 'expenditureNotPay') {
        return 'warning-row'
      } else if (column.property === 'totalPay' || column.property === 'totalPaid' || column.property === 'totalNotPay' || column.property === 'saleFreightFee' ||
        column.property === 'salePaidAmount' || column.property === 'saleNotPay' || column.property === 'driverFreightFee' || column.property === 'driverPaidAmount' ||
        column.property === 'driverNotPay' || column.property === 'maintainFreightFee' || column.property === 'maintainPaidAmounts' || column.property === 'maintainNotPay' ||
        column.property === 'createCardFreightFee' || column.property === 'createCardPaidAmounts' || column.property === 'createCardNotPay' || column.property === 'repairMoneyFreightFee' ||
        column.property === 'repairMoneyPaidAmounts' || column.property === 'repairMoneyNotPay' || column.property === 'pumpElementFee' || column.property === 'pumpElementPaidAmounts' ||
        column.property === 'pumpElementNotPay') {
        return 'success-row'
      }
      return ''
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costSummary/findYearEnterpriseStatistics'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        if (!this.showTotal) {
          // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
          this.tableHeight = '67vh'
          this.showTotal = true
        }
        this.dataListLoading = false
      })
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/costSummary/export/enterpriseStatistics`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '企业应付未付统计表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
  display: inline-block;
  margin-left: 10px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
