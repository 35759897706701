<template>
    <el-input v-model="inputValue" type="password" placeholder="请输入内容" show-password/>
</template>
<script>
export default {
  data () {
    return {
      inputValue: ''
    }
  },
  methods: {
  }
}
</script>
