<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select class="item-choose"
                     v-model="dataForm.auditStatus"
                     size="small"
                     filterable clearable placeholder="请选择">
            <el-option label="待审核" value="0"></el-option>
            <el-option label="审批通过" value="1"></el-option>
            <el-option label="审批不通过" value="2"></el-option>
            <el-option label="已撤回" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList" border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column prop="driverName" label="申请人姓名" align="center">
        </el-table-column>
        <el-table-column prop="driverPhone" label="联系电话" width="90" align="center">
        </el-table-column>
        <el-table-column prop="entryDate" label="入职日期" width="90" align="center">
        </el-table-column>
        <el-table-column prop="expectQuitDate" label="期望离职日期" width="90" align="center">
        </el-table-column>
        <el-table-column prop="quitType" :formatter="dictConvert" width="70"  label="离职原因" align="center">
        </el-table-column>
        <el-table-column prop="quitDesc" label="离职描述" min-width="150" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="附件" width="50" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, driverQuitTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="requestTime" label="申请时间" width="130" align="center">
        </el-table-column>
        <el-table-column prop="auditStatus" label="审核状态" width="80" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.auditStatus === 0" size="small" type="danger">待审核</el-tag>
            <el-tag v-if="scope.row.auditStatus === 1" size="small" type="success">审批通过</el-tag>
            <el-tag v-if="scope.row.auditStatus === 2" size="small" type="warning">审批不通过</el-tag>
            <el-tag v-if="scope.row.auditStatus === 3" size="small" type="info">已撤回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="auditName" label="审核人" width="70" align="center">
        </el-table-column>
        <el-table-column prop="auditTime" label="审核时间" width="120" align="center">
        </el-table-column>
        <el-table-column width="80" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
            <el-button type="text" size="small"
                       v-if="scope.row.auditStatus === 0"
                       @click="addOrUpdateHandle('audit', scope.row.id)">审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <quit-request ref="quitRequest" @refreshDataList="getDataList"></quit-request>

    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import QuitRequest from './details/quit-request'
import viewImage from '@/views/commonPop/viewImage'

export default {
  data () {
    return {
      dataForm: {
        driverName: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      driverQuitTypeList: [5006],
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      bindWxOpenIdVisible: false,
      detailVisible: false,
      idCardTypeList: [5000, 5010],
      proofTypeList: [5001],
      itemRow: {}
    }
  },
  components: {
    QuitRequest,
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driverQuit/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'driverName': this.dataForm.driverName,
          'auditStatus': this.dataForm.auditStatus
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.quitRequest.init(todo, id)
      })
    }
  }
}
</script>
