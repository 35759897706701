<template>
  <div class="report-popup popup-detail">
    <el-dialog title="异常上报" :close-on-click-modal="false" :visible.sync="visible" width="60%" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <div style="margin-top:-40px;margin-bottom:8px;">
          <el-button round type="primary" size="mini" @click="addTemplateRow()">添加</el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="list" border size="mini" :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="50">
            </el-table-column>
            <el-table-column prop="createName" label="上报人" align="center" width="80" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="exceptionTime" align="center" label="上报时间" width="210">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="list[scope.$index].exceptionTime"
                  :disabled="!scope.row.flag"
                  type="datetime"
                  default-time="12:00:00"
                  format="yyyy-MM-dd HH:mm"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column prop="typeId" label="异常类型" align="center" width="140">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="list[scope.$index].typeId" size="mini" style="width: 100%" filterable placeholder="请选择">
                  <el-option v-for="item in abnormalList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="explainDesc" label="异常备注" align="center">
              <template slot-scope="scope">
                <el-input size="mini" v-model="list[scope.$index].explainDesc"></el-input>
              </template>
            </el-table-column>
            <el-table-column type="fileSize" label="附件" align="center" width="60">
              <template slot-scope="scope">
                <el-button slot="append" type="text" @click="preview(fileType, scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column type="fileSize" label="操作" align="center" width="50">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.$index, scope.row.id)" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">保 存</el-button>
        <el-button type="danger" @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="8"></view-image>
  </div>
</template>
<script>
import viewImage from '@/views/commonPop/viewImage-fy'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        explainDesc: '',
        status: 2
      },
      row: {},
      abnormalList: [],
      deleteIds: [],
      list: [],
      fileType: [6020],
      dataRule: {
        explainDesc: [
          { required: true, message: '请填写异常说明', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    viewImage
  },
  computed: {
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 图片预览
    preview (typeList, id) {
      this.$refs.viewPhoto.init(id, typeList)
    },
    addTemplateRow () {
      this.list.push({
        id: getUUID(),
        flag: true,
        exceptionTime: this.dateFormatter(new Date(), 'yyyy-MM-dd hh:mm'),
        createName: this.currentUser.realName
      })
    },
    deleteRow (index, id) {
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.list.splice(index, 1)
        this.deleteIds.push(id)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    init (row) {
      this.dataForm.exceptionTime = this.dateFormatter(new Date(), 'yyyy-MM-dd hh:mm')
      this.deleteIds = []
      this.row = row
      this.$http({
        url: this.$http.adornUrl('/orderException/findExceptionListByOrderId/' + row.orderInfo.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.list = data.list || []
        this.abnormalList = data.typeList || []
        this.visible = true
      })
    },
    confirm () {
      let commitList = []
      for (let i = 0; i < this.list.length; i++) {
        let element = this.list[i]
        commitList.push({
          id: element.id,
          flag: element.flag,
          exceptionTime: element.exceptionTime,
          typeId: element.typeId,
          explainDesc: element.explainDesc,
          orderId: this.row.orderInfo.id,
          orderStatus: this.row.orderInfo.status,
          planId: this.row.vehiclePlan.id,
          driverId: this.row.vehiclePlan.driverId
        })
      }
      this.$http({
        url: this.$http.adornUrl(`/orderException/saveException`),
        method: 'POST',
        data: this.$http.adornData({
          deleteIds: this.deleteIds,
          commitList: commitList
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })

    }
  }
}
</script>
<style lang="less">
.report-popup {
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
