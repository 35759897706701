<template>
  <div class="order-create-body">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>基本信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="5">
            <el-form-item label="报价单编号" prop="templateCode">
              <el-input v-model="dataForm.templateCode"
                        placeholder="报价单编号"
                        :disabled="routeParam.todo !== 'add'"
                        maxlength="40"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="报价单名称" prop="templateName">
              <el-input v-model="dataForm.templateName"
                        :disabled="routeParam.todo === 'view'"
                        maxlength="40"
                        show-word-limit
                        placeholder="报价单名称">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="版本" prop="versionType">
              <el-select v-model="dataForm.versionType" placeholder="请选择" :disabled="routeParam.todo !== 'add'">
                <el-option
                  v-for="(item, index) in activeNameArray"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="适用订单类型" prop="orderType">
              <el-select class="item-choose"
                         v-model="dataForm.orderType"
                         :disabled="routeParam.todo === 'view'"
                         style="width: 100%;"
                         placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.orderType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="是否为公用模板" prop="publicType">
              <el-select class="item-choose"
                         v-model="dataForm.publicType"
                         :disabled="routeParam.todo === 'view'"
                         style="width: 100%;"
                         placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark"
                        :disabled="routeParam.todo === 'view'"
                        type="textarea"
                        maxlength="1000"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="绑定客户" prop="customerSimpleName">
              <el-input v-model="dataForm.customerSimpleName"
                        :disabled="true"
                        type="textarea"
                        maxlength="1000"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel2" style="margin-top: 30px;">
        <label><span class="separate">|</span>报价单明细</label>
        <el-button type="success"
                   v-if="routeParam.todo !== 'view'"
                   @click="addPriceRow"
                   size="mini">添加
        </el-button>
        <el-upload
          ref="upload"
          v-if="routeParam.todo !== 'view'"
          style="display: inline;margin-left: 10px;height: 28px;"
          class="upload-demo"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :action="dataForm.versionType === 'old' ? uploadUrl : uploadUrlV2"
          :show-file-list="false"
          :headers="{'access_token': accessToken}"
          :data="{templateId: dataForm.id}"
          :on-success="successUpload"
          :before-upload="importExcel"
          :limit="1">
          <el-button size="small" type="primary" style="padding: 7px 15px;">导入</el-button>
        </el-upload>
        <el-button type="danger"
                   style="margin-left: 10px;"
                   @click="showSendMail"
                   :disabled="sendDisabled"
                   size="mini">发送
        </el-button>
        <el-button size="small" type="success" @click="downLoad('0')" style="padding: 7px 15px;" :disabled="sendDisabled">导出</el-button>
        <el-button v-if="dataForm.versionType === 'new'" size="small" type="info" @click="downLoadSendExcel()" style="padding: 7px 15px;" :disabled="sendDisabled">导出发送模板</el-button>
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="1">
            <label style="height: 20px;line-height: 24px;padding-left: 7px;">提货</label>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.takeProvince" placeholder="省" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.takeCity" placeholder="市" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.takeArea" placeholder="区/县" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.takeStreet" placeholder="点" clearable></el-input>
          </el-col>
          <el-col :span="1">
            <label style="height: 20px;line-height: 24px;padding-left: 7px;">报关</label>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.entranceName" placeholder="报关点" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px;">
          <el-col :span="1">
            <label style="height: 20px;line-height: 24px;padding-left: 7px;">卸货</label>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.giveProvince" placeholder="省" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.giveCity" placeholder="市" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.giveArea" placeholder="区/县" clearable></el-input>
          </el-col>
          <el-col :span="3">
            <el-input size="mini" v-model="searchForm.giveStreet" placeholder="点" clearable></el-input>
          </el-col>
          <el-col :span="1">
            <label style="height: 20px;line-height: 24px;padding-left: 7px;"></label>
          </el-col>
          <el-col :span="3">
            <el-button type="info"
                       style="padding: 5px 9px;margin-top: 2px;"
                       @click="getBaseCostList"
                       size="mini">查询
            </el-button>
          </el-col>
        </el-row>
      </div>
      <p />
      <div class="modularBox">
        <div class="costInfoClass">
          <el-table  v-if="dataForm.versionType == 'old'" :data="dataForm.templateBaseList" size="mini" border v-loading="dataListLoading" header-cell-class-name="borderClass" cell-class-name="borderClass" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" />
            <el-table-column type="index" label="序号" width="50" fixed="left" align="center" header-align="center">
            </el-table-column>
            <!--     提卸货点       -->
            <el-table-column
              header-align="center"
              prop="startPoint"
              label="起运点"
              fixed="left"
              min-width="220">
              <template slot-scope="scope">
                <el-cascader
                  size="mini"
                  v-model="scope.row.takeCityList"
                  :options="$store.state.areaList"
                  v-if="scope.row.show"
                  clearable
                  filterable
                  style="width: 100%"
                  :props="{ checkStrictly: true, value : 'name', label : 'name' }"
                  placeholder="选择省-市-区"></el-cascader>
                <span v-else>{{ getCitySimpleName(scope.row.takeCityList) }}</span>
              </template>
            </el-table-column>
            <!--     报关地       -->
            <el-table-column
              header-align="center"
              prop="startPoint"
              label="报关地"
              fixed="left"
              min-width="100">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="scope.row.entranceName"
                           filterable
                           clearable
                           v-if="scope.row.show"
                           placeholder="请选择">
                  <el-option v-for="item in dictTypeMap.entrance_type"
                             :key="item.name"
                             :label="item.name"
                             :value="item.name">
                  </el-option>
                </el-select>
                <span v-else>{{ scope.row.entranceName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              prop="endPoint"
              fixed="left"
              label="目的地"
              min-width="220">
              <template slot-scope="scope">
                <el-cascader
                  size="mini"
                  v-model="scope.row.giveCityList"
                  :options="$store.state.areaList"
                  v-if="scope.row.show"
                  clearable
                  filterable
                  style="width: 100%"
                  :props="{ checkStrictly: true, value : 'name', label : 'name' }"
                  placeholder="选择省-市-区"></el-cascader>
                <span v-else>{{ getCitySimpleName(scope.row.giveCityList) }}</span>
              </template>
            </el-table-column>
            <!--     运费列生成       -->
            <el-table-column header-align="center"
                             v-for="(item, index) in costList"
                             :label="item.title"
                             :key="index">
              <el-table-column label="4.2米/3吨车"
                               header-align="center"
                               width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model4"/>
                  <span v-else>{{ scope.row[item.key].model4 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="5.2M/5吨车" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model5"/>
                  <span v-else>{{ scope.row[item.key].model5 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="6.8M/8吨车" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model6"/>
                  <span v-else>{{ scope.row[item.key].model6 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="7.6M/10吨车" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model7"/>
                  <span v-else>{{ scope.row[item.key].model7 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="9.6M/12吨车" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model9"/>
                  <span v-else>{{ scope.row[item.key].model9 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="40尺自备柜" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model40"/>
                  <span v-else>{{ scope.row[item.key].model40 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="45尺自备柜" header-align="center" width="70">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].model45"/>
                  <span v-else>{{ scope.row[item.key].model45 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" header-align="center" width="120" v-if="item.type === 1">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].remark"/>
                  <span v-else>{{ scope.row[item.key].remark }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="80"
                             v-if="routeParam.todo !== 'view'">
              <template slot-scope="scope">
                <el-button size="mini"
                           type="success"
                           style="padding: 4px;"
                           v-if="scope.row.show"
                           @click="savePriceRow(scope.row)">保存
                </el-button>
                <el-button size="mini"
                           type="info"
                           style="padding: 4px;"
                           v-else
                           @click="scope.row.show=true">修改
                </el-button>
                <el-button size="mini"
                           type="danger"
                           v-if="!scope.row.show"
                           style="padding: 4px;margin-left: 5px;"
                           @click="deletePriceRow(scope.row, scope.$index)">删除
                </el-button>
                <el-button size="mini"
                           v-else
                           style="padding: 4px;margin-left: 5px;"
                           @click="cancelSave(scope.row)">取消
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-table v-if="dataForm.versionType == 'new'" :data="dataForm.templateBaseList" size="mini" border v-loading="dataListLoading" header-cell-class-name="borderClass" cell-class-name="borderClass" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" />
            <el-table-column type="index" label="序号" width="50" fixed="left" align="center" header-align="center" />
            <!--     提卸货点       -->
            <el-table-column
              header-align="center"
              prop="startPoint"
              label="起运点"
              fixed="left"
              min-width="220">
              <template slot-scope="scope">
                <el-cascader
                  size="mini"
                  v-model="scope.row.takeCityList"
                  :options="$store.state.areaList"
                  v-if="scope.row.show"
                  clearable
                  filterable
                  style="width: 100%"
                  :props="{ checkStrictly: true, value : 'name', label : 'name' }"
                  placeholder="选择省-市-区"></el-cascader>
                <span v-else>{{ getCitySimpleName(scope.row.takeCityList) }}</span>
              </template>
            </el-table-column>
            <!--     报关地       -->
            <el-table-column
              header-align="center"
              prop="startPoint"
              label="报关地"
              fixed="left"
              min-width="100">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="scope.row.entranceName"
                           filterable
                           clearable
                           v-if="scope.row.show"
                           placeholder="请选择">
                  <el-option v-for="item in dictTypeMap.entrance_type"
                             :key="item.name"
                             :label="item.name"
                             :value="item.name">
                  </el-option>
                </el-select>
                <span v-else>{{ scope.row.entranceName }}</span>
              </template>
            </el-table-column>
            <!-- 目的地 -->
            <el-table-column
              header-align="center"
              prop="endPoint"
              fixed="left"
              label="目的地"
              min-width="220">
              <template slot-scope="scope">
                <el-cascader
                  size="mini"
                  v-model="scope.row.giveCityList"
                  :options="$store.state.areaList"
                  v-if="scope.row.show"
                  clearable
                  filterable
                  style="width: 100%"
                  :props="{ checkStrictly: true, value : 'name', label : 'name' }"
                  placeholder="选择省-市-区"></el-cascader>
                <span v-else>{{ getCitySimpleName(scope.row.giveCityList) }}</span>
              </template>
            </el-table-column>
            <!-- 车型 -->
            <el-table-column label="车型" header-align="center" width="120">
              <template slot-scope="scope">
                <el-select v-model="scope.row.vehicleModelId" placeholder="请选择" :disabled="!scope.row.show">
                  <el-option
                    v-for="item in dictTypeMap.vehicle_model"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <!--     运费列生成       -->
            <el-table-column header-align="center"
                             v-for="(item, index) in costList"
                             :label="item.title"
                             :key="index">
              <el-table-column header-align="center"
                               v-for="(itemV2, indexV2) in vehicleTypeArray"
                               :label="itemV2.name"
                               :key="indexV2">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key][itemV2.name]"/>
                  <span v-else>{{ scope.row[item.key][itemV2.name] }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" header-align="center" width="120" v-if="item.type === 1">
                <template slot-scope="scope">
                  <el-input size="mini" v-if="scope.row.show" v-model="scope.row[item.key].remark"/>
                  <span v-else>{{ scope.row[item.key].remark }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="80" v-if="routeParam.todo !== 'view'">
              <template slot-scope="scope">
                <el-button size="mini"
                           type="success"
                           style="padding: 4px;"
                           v-if="scope.row.show"
                           @click="savePriceRow(scope.row)">保存
                </el-button>
                <el-button size="mini"
                           type="info"
                           style="padding: 4px;"
                           v-else
                           @click="scope.row.show=true">修改
                </el-button>
                <el-button size="mini"
                           type="danger"
                           v-if="!scope.row.show"
                           style="padding: 4px;margin-left: 5px;"
                           @click="deletePriceRow(scope.row, scope.$index)">删除
                </el-button>
                <el-button size="mini"
                           v-else
                           style="padding: 4px;margin-left: 5px;"
                           @click="cancelSave(scope.row)">取消
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
      <div class="footer">
        <div>
          <el-button type="primary" style="font-size: 20px;"
                     v-if="routeParam.todo !== 'view'"
                     @click="submitForm(1)">保存
          </el-button>
          <el-button type="info" style="font-size: 20px;" @click="closePage">关闭</el-button>
        </div>
      </div>
    </el-form>

    <el-dialog title="发送报价单"
               class="popup-detail"
               :close-on-click-modal="false"
               :visible.sync="showSendMailLoading"
               v-loading="sendLoading"
               width="60%">
      <div>
        <el-form :model="sendForm" ref="sendForm" :rules="sendFormRule" size="small" label-width="140px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="报价单名称" prop="templateName">
                <el-input v-model="sendForm.templateName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择发件箱" prop="mailboxId">
                <el-select class="item-choose"
                           v-model="sendForm.mailboxId"
                           size="small"
                           @change="changeMailBox"
                           filterable
                           clearable
                           placeholder="请选择">
                  <el-option
                    v-for="item in sendInfo.mailboxList"
                    :key="item.id"
                    :label="item.sendName + '-' + item.account"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择客户联系人" prop="contactList">
                <el-select class="item-choose"
                           v-model="sendForm.contactList"
                           size="small"
                           multiple
                           filterable
                           clearable
                           placeholder="请选择">
                  <el-option
                    v-for="item in sendInfo.contactList"
                    :key="item.id"
                    :label="item.contactName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="附件">
                <al-upload :fileData="fileList"
                           :fileUrl="fileUploadUrl"
                           :format="'file'"
                           :type="'offer'"
                           :limit="8"
                           :businessType="9000"
                           @getFileData="getFileData"></al-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="邮件主题" prop="mailSubject">
                <el-input type="textarea" clearable rows="3" v-model="sendForm.mailSubject"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="邮件内容" prop="mailContent">
                <el-input type="textarea" clearable rows="7" v-model="sendForm.mailContent"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="邮件签名" prop="mailSign">
                <el-input type="textarea" clearable rows="10" v-model="sendForm.mailSign"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dataForm.versionType == 'new'">
            <el-col :span="24">
              <el-form-item label="发送报价单备注" prop="mailSign">
                <el-input type="textarea" clearable rows="10" v-model="sendForm.quotationRemarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSendMailLoading = false">取 消</el-button>
        <el-button type="primary" @click="sendOfferSubmit()">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导出发送模板"
               class="popup-detail"
               :close-on-click-modal="false"
               :visible.sync="downLoadSendExcelForm.downLoadSendExcelFormLoading"
               v-loading="downLoadSendExcelForm.downLoadSendExcelFormSendLoading"
               width="60%">
      <div>
        <el-form :model="downLoadSendExcelForm.form" ref="sendFormV2" size="small" label-width="140px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择发件箱" prop="mailboxId">
                <el-select class="item-choose"
                           v-model="downLoadSendExcelForm.form.priceSheetMailboxId"
                           size="small"
                           filterable
                           clearable
                           placeholder="请选择">
                  <el-option
                    v-for="item in sendInfo.mailboxList"
                    :key="item.id"
                    :label="item.sendName + '-' + item.account"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择客户联系人" prop="priceSheetContactId">
                <el-select class="item-choose"
                           v-model="downLoadSendExcelForm.form.priceSheetContactId"
                           size="small"
                           filterable
                           clearable
                           placeholder="请选择">
                  <el-option
                    v-for="item in sendInfo.contactList"
                    :key="item.id"
                    :label="item.contactName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dataForm.versionType == 'new'">
            <el-col :span="24">
              <el-form-item label="发送报价单备注" prop="priceSheetQuotationRemarks">
                <el-input type="textarea" clearable rows="10" v-model="downLoadSendExcelForm.form.priceSheetQuotationRemarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downLoadSendExcelForm.downLoadSendExcelFormLoading = false">取 消</el-button>
        <el-button type="primary" @click="sendOfferSubmitV2()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import qs from 'qs'

export default {
  data () {
    return {
      dataForm: {
        id: null,
        templateCode: null,
        templateName: null,
        orderType: 1,
        publicType: 2, // 1 公共模板，2 私有模板只对客户有效(默认)
        remark: null,
        templateBaseList: [],
        customerSimpleName: null,
        templateBaseListSelect: [], // 选中的数据集合
        versionType: 'new'
      },
      sendForm: {
        id: null,
        templateName: null,
        mailboxId: null,
        contactList: [],
        mailSubject: null,
        mailContent: null,
        mailSign: null,
        fileIdList: [],
        selectIdList: [], // 所选中的数据ID合计
        quotationRemarks: "备注：周一至周五24小时接收订单次日上门提货，当天入仓卸货 \n" +
          "1、以上报价为含税9个点运输报价（如不开票减少3%税金）；如有客户要求走高速的高速费、入仓费、海关检验、报关费、查车费、检疫费等，则实报实销； \n" +
          "2、如因客户原因导致压车，则收取压夜费为3-8吨车400元/日，10-12吨车500元/日，柜车700元/日；返空费（已到装货点）为单程运费的80%； \n" +
          "3、货物毛重超过18吨，则加收200元超重费；毛重超过25吨或报价中未提及的区域，双方另行商议； \n" +
          "4、如途中需要加点装卸货，20公里内则收取150元/点（超出20公里双方另行商议；）； \n" +
          "5、运输费用为月结，甲方收到确认对账后的账单和发票后60天内安排付款。 \n" +
          "6、此报价双方签署后即时生效；到期后如双方对报价无异议，此报价自动顺延，直至一方提出异议，经双方协商同意后可以修改报价， \n" +
          "7. 客户收货拒绝安排卸货时需要司机代为卸货，需要额外收取卸货费：每卡板货10-15元/板，如拆卡板卸货收取25元/板 \n" +
          "8. 装货/卸货免费等待各3小时，超时收取等待费：3-5吨车每小时50元，8-12吨车每小时80元，柜车免费等待各4小时/超时收每小时100元/ \n" +
          "9.货物保险请贵自行办理，以上价格以单次事故赔偿限额为200万元人民币的物流责任保险作为报价基础，我司承担的任何赔偿责任均不超过前述保险限额。 \n" +
          "执行新报价后，旧报价自动失效。 \n" +
          "/----------------------------------------------------------------------------------------------------------------------------------/"
      },
      downLoadSendExcelForm: {
        downLoadSendExcelFormLoading: false,
        downLoadSendExcelFormSendLoading: false,
        form: {
          priceSheetContactId: null,
          priceSheetMailboxId: null,
          priceSheetQuotationRemarks: "备注：周一至周五24小时接收订单次日上门提货，当天入仓卸货 \n" +
            "1、以上报价为含税9个点运输报价（如不开票减少3%税金）；如有客户要求走高速的高速费、入仓费、海关检验、报关费、查车费、检疫费等，则实报实销； \n" +
            "2、如因客户原因导致压车，则收取压夜费为3-8吨车400元/日，10-12吨车500元/日，柜车700元/日；返空费（已到装货点）为单程运费的80%； \n" +
            "3、货物毛重超过18吨，则加收200元超重费；毛重超过25吨或报价中未提及的区域，双方另行商议； \n" +
            "4、如途中需要加点装卸货，20公里内则收取150元/点（超出20公里双方另行商议；）； \n" +
            "5、运输费用为月结，甲方收到确认对账后的账单和发票后60天内安排付款。 \n" +
            "6、此报价双方签署后即时生效；到期后如双方对报价无异议，此报价自动顺延，直至一方提出异议，经双方协商同意后可以修改报价， \n" +
            "7. 客户收货拒绝安排卸货时需要司机代为卸货，需要额外收取卸货费：每卡板货10-15元/板，如拆卡板卸货收取25元/板 \n" +
            "8. 装货/卸货免费等待各3小时，超时收取等待费：3-5吨车每小时50元，8-12吨车每小时80元，柜车免费等待各4小时/超时收每小时100元/ \n" +
            "9.货物保险请贵自行办理，以上价格以单次事故赔偿限额为200万元人民币的物流责任保险作为报价基础，我司承担的任何赔偿责任均不超过前述保险限额。 \n" +
            "执行新报价后，旧报价自动失效。 \n" +
            "/----------------------------------------------------------------------------------------------------------------------------------/"
        },
        formRule: {
          priceSheetMailboxId: [{ required: true, message: '请选择发件箱', trigger: 'change' }],
          priceSheetContactId: [{ required: true, message: '请选择联系人', trigger: 'change' }]
        }
      },
      fileList: [],
      searchForm: {},
      costList: [
        {
          type: 1,
          key: 'receiptCost',
          title: '【应收】运费'
        },
        {
          type: 2,
          key: 'payCost',
          title: '【应付】运费'
        }
      ],
      sendInfo: {
        contactList: [],
        mailboxList: []
      },
      showSendMailLoading: false,
      sendLoading: false,
      routeParam: {},
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      uploadUrl: this.$http.adornUrl('/priceNewTemplate/importCost'),
      uploadUrlV2: this.$http.adornUrl('/priceNewTemplate/v2/importCost'),
      fileUploadUrl: this.$http.adornUrl('/upload/local/file'),
      accessToken: this.$cookie.get('TmsToken'),
      dataRule: {
        templateCode: [{ required: true, message: '编号不能为空', trigger: 'blur' }],
        templateName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        versionType: [{ required: true, message: '请选择版本', trigger: 'blur' }]
      },
      sendFormRule: {
        mailboxId: [{ required: true, message: '请选择发件箱', trigger: 'change' }],
        templateName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        mailSubject: [{ required: true, message: '邮件主题不能为空', trigger: 'blur' }],
        mailContent: [{ required: true, message: '邮件内容不能为空', trigger: 'blur' }],
        contactList: [
          { type: 'array', required: true, message: '请至少选择一个客户联系人', trigger: 'change' }
        ]
      },
      vehicleTypeArray: [],
      activeNameArray: [{ label: '旧版', value: 'old' }, { label: '新版', value: 'new' }],
      sendDisabled: true
    }
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    menuActiveName: {
      get () {
        return this.$store.state.common.menuActiveName
      },
      set (val) {
        this.$store.commit('common/updateMenuActiveName', val)
      }
    },
    mainTabs: {
      get () {
        return this.$store.state.common.mainTabs
      },
      set (val) {
        this.$store.commit('common/updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get () {
        return this.$store.state.common.mainTabsActiveName
      },
      set (val) {
        this.$store.commit('common/updateMainTabsActiveName', val)
      }
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$refs['dataForm'].resetFields()
    })
    this.dataForm.templateBaseList = []
    this.routeParam = this.$route.params
    if (this.routeParam.id) {
      this.dataForm.id = this.routeParam.id
      // 获取详情
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/' + this.routeParam.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataForm = data
        }
      })
      this.getBaseCostList()
    } else {
    }

    // 获取所有启用的车型信息
    this.$http({
      url: this.$http.adornUrl('/vehicleType/findAll'),
      method: 'get',
      params: this.$http.adornParams({
        'status': 1
      })
    }).then(({ data }) => {
      if (data) {
        this.vehicleTypeArray = data
      } else {
        this.vehicleTypeArray = []
      }
    })
  },
  methods: {
    /**
     * 省市区转换成文本
     * @param cityList
     * @returns {string}
     */
    getCitySimpleName (cityList) {
      if (!cityList || cityList.length === 0) {
        return ''
      }
      let str = ''
      for (let i = 0; i < cityList.length; i++) {
        str += cityList[i] + '/'
      }
      return str.substring(0, str.length - 1)
    },
    importExcel () {
      if (!this.dataForm.id) {
        this.$message.error('请先保存基本信息')
        return false
      }
    },
    // 获取附件信息
    getFileData (data) {
      this.fileList = data.fileList
      this.sendForm.fileIdList = []
      for (let i = 0; i < this.fileList.length; i++) {
        this.sendForm.fileIdList.push(this.fileList[i].id)
      }
    },
    changeMailBox () {
      let mailboxList = this.sendInfo.mailboxList
      for (let i = 0; i < mailboxList.length; i++) {
        let element = mailboxList[i]
        if (this.sendForm.mailboxId === element.id) {
          this.sendForm.mailSign = element.mailSign
          this.sendForm.mailSubject = element.mailSubject
          this.sendForm.mailContent = element.templateText
          break
        }
      }
    },
    showSendMail () {
      // 重置
      const initialData = this.$options.data.call(this)
      this.sendInfo = initialData.sendInfo

      if (!this.dataForm.id) {
        this.$message.error('请先保存基本信息')
        return
      }
      // 判断所有是否保存
      let isAllTrue = this.dataForm.templateBaseList.every(
        item => item.show === false
      )
      if (!isAllTrue) {
        this.$message.error('当前有数据未保存，保存后再发送')
        return
      }
      this.fileList = []
      this.showSendMailLoading = true
      this.$nextTick(() => {
        this.$refs['sendForm'].resetFields()
      })
      // this.dataListLoading = true
      let param = {
        'templateId': this.dataForm.id,
        'publicType': this.dataForm.publicType,
        'customerIdList': this.currentUser.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      // 查询客户联系人和发送邮箱
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/findSendInfo?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.sendInfo.contactList = data.contactList
        this.sendInfo.mailboxList = data.mailboxList
      })
      this.sendForm.fileIdList = []
      this.sendForm.templateId = this.dataForm.id
      this.sendForm.templateName = this.dataForm.templateName
    },
    sendOfferSubmit () {
      this.$refs['sendForm'].validate((valid) => {
        if (valid) {
          this.sendLoading = true
          this.sendForm.templateVersionType = this.dataForm.versionType
          this.$http({
            url: this.$http.adornUrl(`/priceNewTemplate/sendMail`),
            method: 'POST',
            data: this.$http.adornData(this.sendForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.showSendMailLoading = false
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          }).finally(() => {
            this.sendLoading = false
          })
        }
      })
    },
    /**
     * 添加行
     */
    addPriceRow () {
      if (!this.dataForm.id) {
        this.$message.error('请先保存基本信息')
        return
      }

      // 判断所有是否保存
      let isAllTrue = this.dataForm.templateBaseList.every(
        item => item.show === false
      )
      if (!isAllTrue) {
        this.$message.error('当前有数据未保存，保存后再添加')
        return
      }
      this.dataForm.templateBaseList.unshift(
        {
          id: null,
          show: true,
          templateId: this.dataForm.id,
          entranceName: null,
          takeCityList: [],
          giveCityList: [],
          receiptCost: {},
          payCost: {},
          baseWage: null,
          meritsWage: null,
          useAppReward: null,
          other1Reward: null,
          other2Reward: null
        }
      )
    },
    /**
     * 删除行
     * @param row
     * @param index
     */
    deletePriceRow (row, index) {
      this.$confirm('删除后无法恢复，确定删除此行数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.id) {
          this.$http({
            url: this.$http.adornUrl('/priceNewTemplate/deleteTemplatePrice'),
            method: 'delete',
            data: {
              id: row.id
            }
          }).then(({ data }) => {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1000
            })
            this.dataForm.templateBaseList.splice(index, 1)
          })
        } else {
          this.dataForm.templateBaseList.splice(index, 1)
        }
      }).catch(() => {
        // 不提交
      })
    },
    cancelSave (row) {
      if (row.id) {
        row.show = false
        return
      }
      this.$confirm('数据没有保存，可能会丢失，确定取消吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.show = false
      }).catch(() => {
        // 不提交
      })
    },
    handleSelectionChange (val) {
      this.sendDisabled = val.length === 0
      this.sendForm.selectIdList = []
      this.dataForm.templateBaseListSelect = val
      for (let i = 0; i < this.dataForm.templateBaseListSelect.length; i++) {
        if (this.dataForm.templateBaseListSelect[i].id != null) {
          this.sendForm.selectIdList.push(this.dataForm.templateBaseListSelect[i].id)
        }
      }
    },
    /**
     * 保存行
     * @param row
     */
    savePriceRow (row) {
      let cloneRow = JSON.parse(JSON.stringify(row))
      // 如果是新版则把对象生成为map
      if (this.dataForm.versionType === 'new') {
        cloneRow.receiptCostMap = Object.fromEntries(new Map(Object.entries(cloneRow.receiptCost)))
        cloneRow.payCostMap = Object.fromEntries(new Map(Object.entries(cloneRow.payCost)))
      }
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/saveTemplatePrice'),
        method: 'post',
        data: cloneRow
      }).then(({ data }) => {
        this.$message({
          message: '保存成功',
          type: 'success',
          duration: 1000
        })
        row.id = data
        row.show = false
        this.getBaseCostList()
      })
    },
    /**
     * 保存基本信息
     */
    submitForm () {
      let url = '/priceNewTemplate/add'
      if (this.dataForm.id) {
        url = '/priceNewTemplate/update'
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        data: {
          id: this.dataForm.id,
          templateCode: this.dataForm.templateCode,
          templateName: this.dataForm.templateName,
          orderType: this.dataForm.orderType,
          publicType: this.dataForm.publicType,
          remark: this.dataForm.remark,
          versionType: this.dataForm.versionType
        }
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1000
          })
          this.dataForm.id = data
          this.getBaseCostList()

          // 回到当前报价页面
          this.closePage()
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // tabs, 删除tab
    removeTabHandle (tabName) {
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      if (this.mainTabs.length >= 1) {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName) {
          let tab = this.mainTabs[this.mainTabs.length - 1]
          this.$router.push({ name: tab.name, query: tab.query, params: tab.params }, () => {
            this.mainTabsActiveName = this.$route.name
          })
        }
      } else {
        this.menuActiveName = ''
        this.$router.push({ name: 'home' })
      }
    },
    /**
     * 关闭页面
     */
    closePage () {
      this.removeTabHandle(this.mainTabsActiveName)
      // 回到报价模板列表
      this.$router.push({
        name: 'offer-receipt-price-template',
        params: {}
      })
    },
    // 下载
    downLoad (downLoadType) {
      let cloneSearchForm = JSON.parse(JSON.stringify(this.searchForm))
      cloneSearchForm.templateId = this.dataForm.id
      cloneSearchForm.ids = this.sendForm.selectIdList.length > 0 ? this.sendForm.selectIdList.join(',') : null
      cloneSearchForm.downLoadType = downLoadType
      this.$http({
        url: this.$http.adornUrl(this.dataForm.versionType === 'old' ? '/export/export/PriceNewTemplateList' : '/export/export/v2/PriceNewTemplateList'),
        method: 'GET',
        responseType: 'blob',
        params: this.$http.adornParams(cloneSearchForm)
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = this.dataForm.versionType === 'old' ? '报价模板-旧版.xls' : downLoadType === '0' ? '报价模板-新版.xlsx' : '报价模板-新版.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getBaseCostList () {
      if (!this.dataForm.id) {
        return
      }
      this.dataListLoading = true
      this.searchForm.page = this.pageIndex
      this.searchForm.limit = this.pageSize
      this.searchForm.templateId = this.dataForm.id
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/findTemplateBaseList'),
        method: 'get',
        params: this.$http.adornParams(this.searchForm)
      }).then(({ data }) => {
        if (data && data.list.length > 0) {
          if (this.dataForm.versionType === 'new') {
            data.list.forEach(x => {
              // 应收运费 receiptCost
              if (x.receiptCost && x.receiptCost.modelMapJson && this.isJsonString(x.receiptCost.modelMapJson)) {
                let baseIdV = x.receiptCost.baseId
                let costTypeV = x.receiptCost.costType
                let idV = x.receiptCost.id
                let modelMapJsonV = x.receiptCost.modelMapJson
                let remark = x.receiptCost.remark
                let parseModelMapJsonV = JSON.parse(modelMapJsonV)
                x.receiptCost = parseModelMapJsonV
                x.receiptCost.baseId = baseIdV
                x.receiptCost.costType = costTypeV
                x.receiptCost.id = idV
                x.receiptCost.remark = remark
              }

              // 应付运费 payCost
              if (x.payCost && x.payCost.modelMapJson && this.isJsonString(x.payCost.modelMapJson)) {
                let baseIdV = x.payCost.baseId
                let costTypeV = x.payCost.costType
                let idV = x.payCost.id
                let modelMapJsonV = x.payCost.modelMapJson
                let parseModelMapJsonV = JSON.parse(modelMapJsonV)
                x.payCost = parseModelMapJsonV
                x.payCost.baseId = baseIdV
                x.payCost.costType = costTypeV
                x.payCost.id = idV
              }
            })
          }

          this.dataForm.templateBaseList = data.list
          this.totalPage = data.total
        } else {
          this.dataForm.templateBaseList = []
          this.dataList = []
          this.totalPage = 0
        }
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 是否JSON字符串
    isJsonString (str) {
      try {
        JSON.parse(str)
        return true
      } catch (e) {
        return false
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getBaseCostList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getBaseCostList()
    },
    successUpload (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      this.$refs.upload.clearFiles()
      this.getBaseCostList()
    },
    // 导出发送模板
    downLoadSendExcel () {
      // 重置
      const initialData = this.$options.data.call(this)
      this.downLoadSendExcelForm = initialData.downLoadSendExcelForm
      this.sendInfo = initialData.sendInfo
      //this.$nextTick(() => {
      //  this.$refs['sendFormV2'].resetFields()
      //})
      if (!this.dataForm.id) {
        this.$message.error('请先保存基本信息')
        return
      }
      // 判断所有是否保存
      let isAllTrue = this.dataForm.templateBaseList.every(
        item => item.show === false
      )
      if (!isAllTrue) {
        this.$message.error('当前有数据未保存，保存后再发送')
        return
      }
      let param = {
        'templateId': this.dataForm.id,
        'publicType': this.dataForm.publicType,
        'customerIdList': this.currentUser.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      // 查询客户联系人和发送邮箱
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/findSendInfo?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.sendInfo.contactList = data.contactList
        this.sendInfo.mailboxList = data.mailboxList
      }).finally(() => {
        this.downLoadSendExcelForm.downLoadSendExcelFormLoading = true
      })
    },
    sendOfferSubmitV2 () {
      this.$refs['sendFormV2'].validate((valid) => {
        if (valid) {
          let cloneSearchForm = JSON.parse(JSON.stringify(this.downLoadSendExcelForm.form))
          this.downLoadSendExcelForm.downLoadSendExcelFormSendLoading = true
          cloneSearchForm.templateId = this.dataForm.id
          cloneSearchForm.ids = this.sendForm.selectIdList.length > 0 ? this.sendForm.selectIdList.join(',') : null
          cloneSearchForm.downLoadType = '1'
          this.$http({
            url: this.$http.adornUrl('/export/export/v2/PriceNewTemplateList'),
            method: 'GET',
            responseType: 'blob',
            params: this.$http.adornParams(cloneSearchForm)
          }).then(({ data }) => {
            const blob = data
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = (e) => {
              const a = document.createElement('a')
              a.download = '报价模板-新版.xls'
              a.href = e.target.result
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            }
            this.downLoadSendExcelForm.downLoadSendExcelFormLoading = false
          }).finally(() => {
            this.downLoadSendExcelForm.downLoadSendExcelFormSendLoading = false
          })
        }
      })
    }
  }
}
</script>
<style>
.costInfoClass .el-table th.el-table__cell > .cell {
  height: 20px;
  line-height: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.costInfoClass .el-table td.el-table__cell {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

.costInfoClass .el-table td.el-table__cell > .cell {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.borderClass {
  border: 1px solid #dcdfe6;
}

.costInfoClass .el-input__inner {
  padding: 0;
}
</style>
