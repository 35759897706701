<template>
  <div class="order-page">
    <div class="division">
      <span class="line">
      </span>
      <!-- 专车 1； 拼车 2 -->
      <span class="circle-box" :class="type == 1 ? 'active-type' : ''" @click="selectedType(1)">专车</span>
      <span class="circle-box" :class="type == 2 ? 'active-type' : ''" @click="selectedType(2)">拼车</span>
      <span class="line">
      </span>
    </div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="100px">
      <div class="top">
        <div class="area-box area-box-1">
          <span class="title">
            第一步：填写订单表头
          </span>
          <div class="content">
            <div class="form-box">
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item label="客户名称" prop="customerId">
                    <el-select class="item-choose" v-model="dataForm.customerId" filterable clearable
                              :disabled="isCustomer"
                              placeholder="请选择"
                              @change="changeCustomer(false)">
                      <el-option v-for="item in customerList" :key="item.id"
                                :label="item.simpleName" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提货时间" prop="takeGoodsTime">
                    <!--<el-time-picker v-model="dataForm.takeGoodsTime" format="HH:mm" value-format="HH:mm" placeholder="选择时间">-->
                    <!--</el-time-picker>-->
                    <el-date-picker
                      v-model="dataForm.takeGoodsTime"
                      type="datetime"
                      default-time="08:00:00"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      placeholder="选择提货日期时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="进仓单号">
                    <el-input v-model="dataForm.inWarehouseNo" placeholder="进仓单号" maxlength="40" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="8">
                    <el-form-item label="客户联系人">
                      <!-- <el-input v-model="dataForm.customerContactName" placeholder="客户联系人" maxlength="40" ></el-input> -->
                      <el-autocomplete class="inline-input" v-model="dataForm.customerContactName"
                                      :fetch-suggestions="queryNameSearch"
                                       value-key="contactName"
                                      style="width:100%"
                                      clearable
                                      placeholder="客户联系人"
                                      @select="nameSelect"></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话">
                    <el-input v-model="dataForm.customerContactPhone" placeholder="客户联系人电话"  ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户单号" prop="customerSelfNo">
                    <el-input v-model="dataForm.customerSelfNo" placeholder="客户单号" maxlength="40" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item label="报关方式" prop="entranceType">
                    <el-select class="item-choose" v-model="dataForm.entranceType"
                            filterable clearable placeholder="请选择"
                            @clear="changeType"
                            @change="changeType">
                      <el-option v-for="item in dictTypeMap.entrance_type" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="是否带尾板" prop="isTailstock">
                    <el-select class="item-choose" v-model="dataForm.isTailstock" filterable clearable placeholder="请选择">
                      <el-option v-for="item in dictTypeMap.yes_no" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单备注" prop="remark">
                    <el-input v-model="dataForm.remark" placeholder="备注" maxlength="1000"
                              ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="16">
                  <el-form-item label="附件" prop="remark">
                      <al-upload :fileData="dataForm.enclosureInfoList" :fileUrl="enclosureDto.uploadUrl" :businessType="enclosureDto.type" :limit="8" @getFileData="getFileData" class="order-upload"></al-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            <!-- </el-form> -->
            </div>
          </div>
        </div>
        <div class="area-box area-box-2">
          <span class="title">
            第二步：路线/车库信息
          </span>
          <div class="content content2">
            <div class="form-box">
              <el-row :gutter="50">
                <div v-for="(item, index) in addressInfoList" :key="item.id">
                  <el-col :span="24">
                    <el-form-item :rules="[{ required: true }]">
                      <el-col :span="1">
                        <el-button type="primary" @click="selectAddress(1, index)" round>提货方</el-button>
                      </el-col>
                      <el-col :span="4">
<!--                        <el-input v-model="item.factoryName" placeholder="提货工厂名" maxlength="40"-->
<!--                                  ></el-input>-->
                        <el-autocomplete class="inline-input" v-model="item.factoryName"
                                         :fetch-suggestions="filterTakeAddress"
                                         value-key="simpleName"
                                         style="width:100%"
                                         clearable
                                         popper-class="order-address-select"
                                         placeholder="提货方"
                                         @select="getAddress($event, index, 1)">
                                       <template slot-scope="{ item }">
                                          <div class="name">{{ item.simpleName }} - {{ item.contactName }} - {{ item.contactPhone }}  - {{ item.fullAddress }}</div>
                                        </template>
                        </el-autocomplete>
                      </el-col>
                      <el-col :span="6">
                        <el-cascader
                          v-model="item.cityList"
                          :options="$store.state.areaList"
                          ref="cascaderTake"
                          style="width: 100%"
                          clearable
                          filterable
                          change-on-select
                          :props="takeProps"
                          @change="calculation()"
                          placeholder="选择省-市-区"></el-cascader>
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="item.address" placeholder="详细地址" maxlength="200" ></el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-input v-model="item.contactName" placeholder="联系人姓名" maxlength="40"
                        ></el-input>
                      </el-col>
                      <el-col :span="4">
                        <el-input v-model="item.contactPhone" placeholder="联系电话"
                        ></el-input>
                      </el-col>
                      <!-- 拼车不能新增提货放信息 -->
                      <div class="add-take-btn" v-if="type == 1">
                        <i class="el-icon-delete address-delete"
                          v-if="addressInfoList.length > 1 && dataForm.orderType !=2"
                          @click="deleteAddressInfoRow(index)">
                        </i>
                        <span class="addAddressRow" v-if="dataForm.orderType !=2 && addressInfoList.length == (index + 1)"
                              @click="insertAddressInfoRow">
                          <i class="el-icon-circle-plus-outline"></i>
                        </span>
                      </div>
                    </el-form-item>
                  </el-col>
                  </div>
                </el-row>
                <el-row :gutter="50"  v-if="showEntranceAddress">
                  <el-col :span="24">
                    <el-row :gutter="50">
                      <el-col :span="24">
                        <el-row>
                          <el-form-item :rules="[{ required: true }]">
                            <el-col :span="1">
                              <el-button type="info">报关方</el-button>
                            </el-col>
                            <el-col :span="4">
                              <el-autocomplete class="inline-input" v-model="entranceAddressInfo.contactName"
                                              :fetch-suggestions="entranceContactSearch"
                                               value-key="contactName"
                                              style="width:100%"
                                              clearable
                                              placeholder="联系人"
                                              @select="entranceContactSelect"></el-autocomplete>
                            </el-col>
                            <el-col :span="5">
                              <el-input v-model="entranceAddressInfo.contactPhone" placeholder="联系电话"
                                        ></el-input>
                            </el-col>
                            <el-col :span="14">
                              <el-input v-model="entranceAddressInfo.fullAddress" placeholder="详细地址" maxlength="200"
                                        ></el-input>
                            </el-col>
                          </el-form-item>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-row :gutter="50">
                      <el-col :span="24">
                        <el-form-item :rules="[{ required: true }]">
                          <el-col :span="1">
                            <el-button type="danger" @click="selectAddress(2, 0)">卸货方</el-button>
                          </el-col>
                          <el-col :span="4">
                            <el-select class="item-choose" v-model="giveAddressInfo.factoryName"
                                       filterable
                                       popper-class="order-address-select"
                                       @change="selectGiveFactory"
                                       clearable :disabled="false" placeholder="选择卸货仓">
                              <el-option v-for="(item) in giveAreaList" :key="item.id" :label="item.simpleName + ' - ' + item.contactName + ' - ' + item.contactPhone + ' - ' + item.fullAddress" :value="item.id">
                              </el-option>
                            </el-select>
<!--                            <el-input v-model="giveAddressInfo.factoryName" placeholder="点击卸货方选择" :disabled="true"></el-input>-->
                          </el-col>
                          <el-col :span="6">
                            <el-cascader
                              v-model="giveAddressInfo.cityList"
                              :options="$store.state.areaList"
                              :disabled="inputGive"
                              ref="cascaderGive"
                              style="width: 100%"
                              clearable
                              filterable
                              change-on-select
                              :props="{ checkStrictly: true, value : 'name', label : 'name' }"
                              @change="calculation()"
                              placeholder="选择省-市-区"></el-cascader>
                          </el-col>
                          <el-col :span="6">
                            <el-input v-model="giveAddressInfo.address" placeholder="详细地址" maxlength="200"
                                      :disabled="inputGive"
                                      ></el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="giveAddressInfo.contactName" placeholder="联系人姓名"
                                      :disabled="inputGive"
                            ></el-input>
                          </el-col>
                          <el-col :span="4">
                            <el-input v-model="giveAddressInfo.contactPhone" placeholder="联系电话"
                                      :disabled="inputGive"
                            ></el-input>
                          </el-col>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="50" v-if="type == 1">
                  <div v-if="dataForm.orderType !=2">
                    <div class="order-vehicle-layout">
                      <div v-for="(item, idx) in vehicleTypeList" :key="item.id" @click="showTips(item, idx)"
                          :class="isactive == idx ? 'carBox activeBox' : 'carBox'">
                        <div class="car-item">
                          <icon-svg name="che" class="car-type"></icon-svg>
                          <span class="car-name">{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="car-info" v-if="vehicleRulesTips">
                      <div class="vehicle-rule">
                        <label>车辆规则说明：</label>
                        <p>{{ vehicleRulesTips }}</p>
                      </div>
                      <div class="vehicle-tip" v-if="vehicleTips">
                        <label>温馨提示：</label>
                        <p>{{ vehicleTips }}</p>
                      </div>
                    </div>
                  </div>
                </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="area-box area-box-3">
          <span class="title">
            第三步：填写货物信息
          </span>
          <div class="content content3">
            <div class="add-goods">
              <span class="tip">当前货物条数 <strong style="color: #17B3A3"> {{this.goodsInfoList.length}} </strong> 条</span>
              <el-button type="primary" @click="insertGoodsInfoRow()">新增货物信息</el-button>
            </div>
            <div class="goods-content">
              <div :class="activeGoods == index ? 'goods-info active-goods' : 'goods-info'" v-for="(goodsItem, index) in goodsInfoList" :key="index" @click="selectGoods(index)">
                <div class="form-box">
                  <el-row :gutter="50">
                    <el-col :span="8">
                      <el-form-item label="货物名称" :rules="{ required: true, message: '货物名称不能为空', trigger: 'blur' }">
                         <!--<el-select class="item-choose" v-model="goodsItem.goodsName" filterable clearable placeholder="请选择">-->
                          <!--<el-option v-for="item in dictTypeMap.goods_name" :key="item.id" :label="item.name" :value="item.id">-->
                          <!--</el-option>-->
                        <!--</el-select>-->
                        <el-autocomplete class="inline-input" v-model="goodsInfoList[index].goodsName"
                                         :fetch-suggestions="goodsQueryNameSearch"
                                         style="width:100%"
                                         clearable
                                         size="mini"></el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="唛头" prop="shippingMark">
                        <el-input size="mini" v-model="goodsItem.shippingMark"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="货物类型" prop="goodsType">
                        <el-select class="item-choose" v-model="goodsItem.goodsType" size="mini" filterable
                                   clearable placeholder="请选择">
                          <el-option v-for="item in dictTypeMap.goods_type" :key="item.id" :label="item.name"
                                     :value="item.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="24">
                      <el-form-item label="长宽高件重体" prop="length">
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.length" @input="calcVolume(index)" placeholder="单件长(cm)"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.width" @input="calcVolume(index)" placeholder="单件宽(cm)"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.height" @input="calcVolume(index)" placeholder="单件高(cm)"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.pieceNum" @input="calcVolume(index)" placeholder="件数"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.weight" @input="calcVolume(index)" placeholder="重量(kg)"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-input size="mini" v-model="goodsItem.volume" @input="calcVolume(index)" placeholder="体积(m³)"></el-input>
                        </el-col>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!--<el-row :gutter="50">-->
                    <!--<el-col :span="24">-->
                      <!--<el-form-item label="总件重体" prop="pieceNum" class="no-padding">-->
                        <!--<el-col :span="8">-->
                          <!--<el-input size="mini" v-model="goodsItem.pieceNum" @input="calcVolume(index)" placeholder="件数"></el-input>-->
                        <!--</el-col>-->
                        <!--<el-col :span="8">-->
                          <!--<el-input size="mini" v-model="goodsItem.weight" @input="calcVolume(index)" placeholder="重量(kg)"></el-input>-->
                        <!--</el-col>-->
                        <!--<el-col :span="8">-->
                          <!--<el-input size="mini" v-model="goodsItem.volume" @input="calcVolume(index)" placeholder="体积(m³)"></el-input>-->
                        <!--</el-col>-->
                      <!--</el-form-item>-->
                    <!--</el-col>-->
                  <!--</el-row>-->
                  <el-row :gutter="50">
                    <el-col :span="8">
                      <el-form-item label="包装类型" :rules="{ required: true, message: '包装类型不能为空', trigger: 'blur' }">
                        <el-select class="item-choose" v-model="goodsItem.packType" size="mini" filterable
                                   clearable placeholder="请选择">
                          <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name"
                                     :value="item.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item label="备注" prop="remark">
                        <el-input v-model="goodsItem.remark" placeholder="备注" maxlength="1000"
                                ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2" class="delete-goods">
                      <i class="el-icon-delete opt-delete" @click="deleteGoodsRow(index)"></i>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="area-box area-box-4">
          <span class="title">
            第四步：自动在线报价
          </span>
          <div class="content">
            <!-- <div class="title"><i class="idx">3</i>费用信息</div> -->
                <div class="fee-info" v-if="this.newAuth('show:price')">
                  <p><span>运费：</span><span><strong>{{totalPrice}}</strong>元</span></p>
                  <p><span>加点费：</span><span><strong>{{addFee}}</strong>元</span></p>
                  <hr style="border-color: #17B3A3;" />
                  <p style="font-size: 18px"><span>总费用：</span><span><strong style="color: #17B3A3">{{totalPrice + addFee}}</strong>元</span></p>
                </div>
                <div class="remark-explain">
                  <span>
                    <p class="title-word">备注说明：</p>
                    <p>货物信息与车型信息无变化，则当前报价为最终运费；</p>
                    <p>如订单信息有变动，导致价格变动，客服人员将再派前与您确认！</p>
                  </span>
                  <span>
                    <p class="title-word">报价说明：</p>
                    <p>1，专车车型地址不变的情况下，订单按此价格计费。</p>
                    <p>2，拼车体积地址不变的情况下，订单按次价格计费。</p>
                    <p>3，4.2米以上车型都可免费提供尾板。</p>
                    <p>4，不含入仓费等代垫费，代垫费用实报实销。</p>
                    <p>5，拼车价格，超重货物按照1:500计算。</p>
                    <p>6，机场收获和送货，保税区提货各车型加收100元/车次。</p>
                  </span>
                  <span>
                    <p class="title-word">货物运输特别说明</p>
                    <p>禁止入仓货物：《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品；</p>
                    <p>目前，我司仓库不接收的货物品名如下（包括但不限于以下品名）：</p>
                    <p>1.  爆炸品：炸药、雷管、火药、子弹、烟花、鞭炮等</p>
                    <p>2.  易燃液体：打火机、打火机油、空气清新剂、摩丝(发胶、头发定型剂)、液化气，灭火器</p>
                    <p>3.  易燃液体：油漆、酒精、白酒、胶黏剂、粘合剂、丙酮、汽油、柴油、天那水、胶水、 粘合剂、油墨、定型水、指甲油、香水</p>
                    <p>4.  易燃固体：火柴、硫磺、明胶、硝基、磷、镁、锂电池、乒乓球</p>
                    <p>5.  毒性物质：砷、尼古丁、氰化物、氰化钾、杀虫剂、农药</p>
                    <p>6.  腐蚀品：硫酸、盐酸、氢氧化钠、氢氧化钾、水银、双氧水、电镀用溶液、蓄电池、清洗液、清洁水</p>
                    <p>7.  杂类：安全气囊、充电宝（移动电源）</p>
                    <p></p>
                    <p class="title-word">非危险品声明</p>
                    <p>现申明本进仓单所对应货物，非易燃易爆易腐蚀、非有毒有害物质、非氧化剂、非麻醉品、精神性药品、无放射性、不可用于制造化学武器、不属于《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品，申报属实，且货物在仓储及装卸过程中，如发生因货物本身原因导致的安全隐患或事故，我方承担全部责任。</p>
                  </span>
                </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="opt-area">
      <!-- <el-link type="info" style="margin-right: 10px" class="explain" @click="explain('price')">价格说明</el-link>
      <el-link type="info" style="margin-right: 15px" class="explain" @click="explain('transport')">货物运输特别声明
      </el-link>
      <el-checkbox v-model="dataForm.readNadConsent" style="margin-right: 15px">我已阅读并同意</el-checkbox> -->
      <el-button type="primary" style="font-size: 24px;" :loading="submitLoading"
                  @click="dataFormSubmit(1)">提交
      </el-button>
      <el-button type="info" style="font-size: 24px;" @click="dataFormSubmit(0)">临时保存</el-button>
    </div>
    <explain-pop ref="explainPop"></explain-pop>
    <address-book-pop ref="addressBook" @getAddress="getAddress"></address-book-pop>
    <!-- 放大图片 -->
    <enlarge-img ref="enlarge"></enlarge-img>
  </div>
</template>

<script>
import explainPop from '@/views/commonPop/explain-popup.vue'
import addressBookPop from '@/views/commonPop/addressBook-popup.vue'
import enlargeImg from '@/views/commonPop/enlargeImg.vue'
export default {
  components: {
    explainPop,
    addressBookPop,
    enlargeImg
  },
  data () {
    return {
      takeProps: {
        checkStrictly: true,
        value: 'name',
        label: 'name'
      },
      visible: false,
      totalPrice: 0, // 总价
      addFee: 0,
      isCustomer: false,
      type: 1,
      dataForm: {
        id: null,
        readNadConsent: false,
        vehicleType: null,
        orderType: 1,
        inWarehouseNo: null,
        isTailstock: 2,
        takeGoodsDate: null,
        takeGoodsTime: null,
        customerId: null,
        customerSelfNo: null,
        customerContactName: null,
        customerContactPhone: null,
        abortWarehouseTime: null,
        enclosureInfoList: [],
        entranceType: '6f2c06df588311eaac3200163e05bd41',
        remark: null
      },
      isactive: -1,
      activeGoods: -1,
      isShowSubmit: true,
      customerList: [],
      submitLoading: false, // 提交loadding
      takeType: 1,
      giveType: 2,
      takeCityFourList: [[]],
      giveCityFourList: [],
      addressInfoList: [], // 提货地址信息
      goodsInfoList: [], // 货物信息
      inputGive: false,
      giveAddressInfo: { // 卸货地址信息
        factoryName: '',
        contactName: '',
        contactPhone: '',
        cityList: '',
        street: '',
        enCity: '',
        address: ''
      },
      entranceAddressInfo: { // 报关地址信息
        factoryName: '',
        contactName: '',
        contactPhone: '',
        fullAddress: ''
      },
      giveFactoryNameList: [],
      takeAreaList: [],
      giveAreaList: [],
      vehicleTypeList: [],
      vehicleRulesTips: null,
      vehicleTips: null,
      showEntranceAddress: false,
      enclosureDto: { // 附件
        uploadUrl: '',
        accessToken: '',
        show: false,
        download: false,
        delete: true,
        type: 6001,
        jpegType: 'image/jpeg',
        pngType: 'image/png',
        excel13Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        excel07Type: 'application/vnd.ms-excel',
        pdfType: 'application/pdf'
      },
      dataRule: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        entranceType: [{ required: true, message: '报关方式必填', trigger: 'change' }],
        inWarehouseNo: [{ required: true, message: '进仓单号不能为空', trigger: 'blur' }],
        // takeGoodsDate: [{ required: true, message: '请选择提货日期', trigger: 'change' }],
        takeGoodsTime: [{ required: true, message: '请选择提货时间', trigger: 'change' }],
        customerContactName: [{ required: true, message: '客户联系人不能为空', trigger: 'blur' }],
        customerContactPhone: [{ required: true, message: '客户联系人电话不能为空', trigger: 'blur' }]
        // goodsName: [{ required: true, message: '货物名称不能为空', trigger: 'blur' }],
        // packType: [{ required: true, message: '包装类型不能为空', trigger: 'blur' }]
      },
      contactNameOption: [],
      entranceContactNameOption: []
    }
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  watch: {
    'dataForm.readNadConsent': {
      handler (newVal, oldVal) {
        this.isShowSubmit = !newVal
      }
    }
  },
  created () {
    this.addressInfoList = []
    this.insertAddressInfoRow()
    this.initGoodsInfoList()
    this.getCustomerList()
    this.getVehicleType()
    this.dataForm.customerId = this.currentUser.customerId
  },
  activated () {
    let params = this.$route.query
    // this.dataForm.customerId = this.currentUser.customerId
    if (params) {
      this.copyGetInfo(params.id)
    }
    this.init()
    if (!this.newAuth('create:special:addAll')) {
      this.isCustomer = true
    }
  },
  methods: {
    // 顶部选择类型， 专车或者拼车, 1 专车; 2拼车
    selectedType (val) {
      this.type = val
      // this.price = 0
      // 去掉选择的所有信息
      // this.resetForm('dataForm')
      // 清除路线/车库信息
      this.resetAddress()
      // 去掉选择的车型信息
      this.clearTip()
      // 清除填写订单表头数据
      this.clearOrder()
      this.clearGoodsInfo()
      this.clearUploadList()
    },
    // 选择卸货仓
    selectGiveFactory () {
      this.giveAreaList.forEach(item => {
        if (item.id === this.giveAddressInfo.factoryName) {
          this.giveAddressInfo.cityList = this.getCityList(item)
          this.giveAddressInfo.factoryName = item.simpleName
          this.giveAddressInfo.contactName = item.contactName
          this.giveAddressInfo.contactPhone = item.contactPhone
          this.giveAddressInfo.address = item.address
        }
      })
      this.calculation()
    },
    findByParentId (parentId) {
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findByParentId/` + parentId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.fasterArea = data
      })
    },
    // 获取附件信息
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    changeType (init) {
      let entranceType = this.dataForm.entranceType
      this.entranceContactNameOption = []
      if (entranceType) {
        let list = this.dictTypeMap.entrance_type
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === entranceType) {
            this.showEntranceAddress = list[i].show === 1
            this.entranceAddressInfo.fullAddress = list[i].address
            this.entranceAddressInfo.factoryName = list[i].name
            break
          }
        }
        if (this.showEntranceAddress) {
          this.$http({
            url: this.$http.adornUrl(`/entranceType/findContactList/${entranceType}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            // 将值存入联系人、联系人电话下拉搜索框中
            data.forEach(item => {
              this.entranceContactNameOption.push({
                contactName: item.contactName,
                contactPhone: item.contactPhone
              })
            })
            // 选择客户名称时有联系人信息则将第一个联系人信息赋值
            if (!init && this.entranceContactNameOption[0]) {
              this.entranceAddressInfo.contactName = this.entranceContactNameOption[0].contactName
              this.entranceAddressInfo.contactPhone = this.entranceContactNameOption[0].contactPhone
            }
          })
        }
      } else {
        this.showEntranceAddress = false
      }
      this.calculation()
    },
    // 客户名称改变时，客户要带出联系人、联系电话、带出省市区下拉、运费清0
    changeCustomer (init) {
      this.contactNameOption = []
      if (!init) {
        this.dataForm.customerContactName = null
        this.dataForm.customerContactPhone = null
      }
      // 总计清0
      this.totalPrice = 0
      this.addFee = 0
      this.calculation()
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/customer/findContactList/${this.dataForm.customerId}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 将值存入联系人、联系人电话下拉搜索框中
        data.forEach(item => {
          this.contactNameOption.push({
            contactName: item.contactName,
            contactPhone: item.contactPhone
          })
        })
        // 选择客户名称时有联系人信息则将第一个联系人信息赋值
        if (!init && this.contactNameOption[0]) {
          this.dataForm.customerContactName = this.contactNameOption[0].contactName
          this.dataForm.customerContactPhone = this.contactNameOption[0].contactPhone
        }
      })
      this.getTakeGiveAreaList()
      // this.findGiveFactoryName(this.dataForm.customerId)
    },
    goodsQueryNameSearch (queryString, cb) {
      let goodsName = []
      let goodsNameMap = this.dictTypeMap.goods_name
      for (let goodsNameKey in goodsNameMap) {
        goodsName.push({
          value: goodsNameMap[goodsNameKey].name
        })
      }
      let results = queryString ? goodsName.filter(this.createFilter(queryString, 'value')) : goodsName
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    queryNameSearch (queryString, cb) {
      var results = queryString ? this.contactNameOption.filter(this.createFilter(queryString, 'contactName')) : this.contactNameOption;
      if(!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString, name) {
      return (restaurant) => {
        if (restaurant[name]) {
          return (restaurant[name].indexOf(queryString) === 0)
        }
        return false
      }
    },
    nameSelect (data) {
      this.dataForm.customerContactPhone = data.contactPhone
    },
    // 报关联系人建议信息
    entranceContactSearch (queryString, cb) {
      let results = queryString ? this.entranceContactNameOption.filter(this.createFilter(queryString, 'contactName')) : this.entranceContactNameOption
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    entranceContactSelect (data) {
      this.entranceAddressInfo.contactPhone = data.contactPhone
    },
    phoneSelect (data) {
      // this.dataForm.customerContactName = data.customerContactName
    },
    // *********************************提卸货地址省市区改变**************
    // 卸货地址
    blurGiveAddress () {
      // console.log(this.$refs['cascaderAddr'].currentLabels)
      // console.log(this.$refs['cascaderAddr'].currentLabels)
    },
    changeGiveAddress (addressType, index, flag) {
      // customerId  客户id
      // addressType 地址类型，1 提货， 2 卸货
      // takeCityList
      let formData = {}
      if (addressType === 1) {
        formData = {
          orderType: this.dataForm.orderType,
          customerId: this.dataForm.customerId,
          addressType: addressType,
          takeCityList: this.addressInfoList[index].cityList
        }
        if (!flag) {
          this.addressInfoList[index].street = ''
        }
      } else {
        formData = {
          orderType: this.dataForm.orderType,
          customerId: this.dataForm.customerId,
          addressType: addressType,
          takeCityList: this.giveAddressInfo.cityList
        }
        if (!flag) {
          this.giveAddressInfo.street = ''
        }
      }

      this.$http({
        url: this.$http.adornUrl(`/priceBase/findCustomerPriceList`),
        method: 'post',
        data: this.$http.adornData(formData)
      }).then(({ data }) => {
        if (addressType === 1) {
          this.takeCityFourList[index].length = 0
          if(data && data.length) {
            for (let i = 0; i < data.length; i++) {
              this.takeCityFourList[index].push(data[i])
            }
          } else {
            this.takeCityFourList[index].push('')
          }
        } else {
          this.giveCityFourList = data
        }
      })
    },
    calculation () {
      // this.getTakeGiveAreaList(this.dataForm.customerId)
      // 当这些值都有时调用计算方法
      if (!!this.dataForm.customerId &&
        !!this.addressInfoList[0].cityList &&
        this.dataForm.vehicleType) {
        let addressList = []
        for (let i = 0; i < this.addressInfoList.length; i++) {
          // let enCityArr = this.addressInfoList[i].enCity ? this.addressInfoList[i].enCity.split('/') : []          addressList.push({
          addressList.push({
            'province': this.addressInfoList[i].cityList[0],
            'city': this.addressInfoList[i].cityList[1],
            'area': this.addressInfoList[i].cityList[2],
            'street': this.addressInfoList[i].cityList[3]
          })
        }
        // let giveEnCityArr = this.giveAddressInfo.enCity ? this.giveAddressInfo.enCity.split('/') : []
        let formData = {
          'orderType': this.dataForm.orderType,
          'vehicleType': this.dataForm.vehicleType,
          'giveAddressInfo': {
            'province': this.giveAddressInfo.cityList[0],
            'city': this.giveAddressInfo.cityList[1],
            'area': this.giveAddressInfo.cityList[2],
            'street': this.giveAddressInfo.cityList[3]
          },
          'addressList': addressList,
          'entranceTypeName': this.entranceAddressInfo.factoryName,
          'factoryName': this.giveAddressInfo.factoryName,
          'customerId': this.dataForm.customerId
        }
        this.$http({
          url: this.$http.adornUrl(`/priceTemplateBaseCost/calculationOrderFreightFee`),
          method: 'post',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          if (data && data.price) {
            this.totalPrice = data.price
            this.addFee = (this.addressInfoList.length - 1) * data.addFee || 0
          } else {
            this.totalPrice = 0
            this.addFee = 0
          }
        })
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.$refs.enlarge.init(file)
    },
    handleDownload (file) {
      this.$http({
        url: this.$http.adornUrl(`/upload/download/stream/${file.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        window.open(data.url)
      })
    },
    getDefaultValue (value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    // 计算体积
    calcVolume (index) {
      let volume = this.getDefaultValue(this.goodsInfoList[index].length, 1) *
        this.getDefaultValue(this.goodsInfoList[index].width, 1) *
        this.getDefaultValue(this.goodsInfoList[index].height, 1)
      let pieceNum = this.getDefaultValue(this.goodsInfoList[index].pieceNum, 1)
      let tmp = (volume / 1000000) * pieceNum
      if (tmp < 0.1) {
        tmp = 0.1
      }
      this.goodsInfoList[index].volume = tmp.toFixed(1)
    },
    resetForm (formName) {
      this.$refs['dataForm'].resetFields()
    },
    clearOrder () {
      this.dataForm = {
        id: null,
        readNadConsent: false,
        vehicleType: null,
        orderType: 1,
        inWarehouseNo: null,
        isTailstock: 2,
        takeGoodsDate: null,
        takeGoodsTime: null,
        customerId: null,
        customerSelfNo: null,
        customerContactName: null,
        customerContactPhone: null,
        abortWarehouseTime: null,
        enclosureInfoList: [],
        entranceType: '6f2c06df588311eaac3200163e05bd41',
        remark: null
      }
    },
    clearGoodsInfo () {
      this.goodsInfoList = []
      this.initGoodsInfoList()
    },
    resetAddress () {
      this.addressInfoList = []
      this.entranceAddressInfo = {}
      this.giveAddressInfo =  { // 卸货地址信息
        factoryName: '',
        contactName: '',
        contactPhone: '',
        cityList: '',
        street: '',
        enCity: '',
        address: ''
      }
      this.insertAddressInfoRow()
    },
    clearTip () {
      this.dataForm.vehicleType = null
      this.vehicleRulesTips = null
      this.vehicleTips = null
      this.isactive = -1
    },
    clearUploadList () {
      this.dataForm.enclosureInfoList = []
    },
    copyGetInfo (id) {
      if (!id) {
        return
      }
      // 获取详情
      this.$http({
        url: this.$http.adornUrl('/order/detail/' + id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 数据结构转换
        if (this.vehicleTypeList.length) {
          this.vehicleTypeList.forEach((item, index) => {
            if (item.id === data.vehicleType) {
              this.showTips(item, index)
            }
          })
        }
        this.dataForm = data
        this.changeCustomer(true)
        this.changeType(true)
        let addressList = data.addressList
        this.addressInfoList = addressList
        this.giveAddressInfo = data.giveAddressInfo
        if (this.showEntranceAddress) {
          this.entranceAddressInfo = {
            factoryName: data.entranceAddressInfo.factoryName,
            fullAddress: data.entranceAddressInfo.fullAddress,
            contactName: data.entranceAddressInfo.contactName,
            contactPhone: data.entranceAddressInfo.contactPhone,
            addressId: data.entranceAddressInfo.addressId
          }
        }
        this.inputGive = data.giveAddressInfo.inputGive
        this.goodsInfoList = data.goodsList
        this.totalPrice = data.freightFee
        this.addFee = data.addFee
      })
    },
    // 地址薄
    selectAddress (flag, index) {
      // flag 1为提货； 2为卸货
      if (!this.dataForm.customerId) {
        this.$message.error('请先选择客户')
      } else {
        this.$refs.addressBook.init(flag, this.dataForm.customerId, index)
      }
    },
    getCityList (data) {
      let cityList = []
      if (data.province) {
        cityList.push(data.province)
      }
      if (data.city) {
        cityList.push(data.city)
      }
      if (data.area) {
        cityList.push(data.area)
      }
      if (data.street) {
        cityList.push(data.street)
      }
      return cityList
    },
    // 从地址薄中得到地址
    getAddress (data, index, flag) {
      let cityList = this.getCityList(data)
      if (flag === 1) {
        // if (data.street) {
        //   cityList.push(data.street)
        // }
        this.addressInfoList[index].factoryName = data.simpleName
        this.addressInfoList[index].contactName = data.contactName
        this.addressInfoList[index].contactPhone = data.contactPhone
        this.addressInfoList[index].cityList = cityList
        this.addressInfoList[index].address = data.address
        // this.addressInfoList[index].street = data.street
        // this.addressInfoList[index].enCity = data.enCity
      } else if (flag === 2) {
        this.giveAddressInfo.factoryName = data.simpleName
        this.giveAddressInfo.contactName = data.contactName
        this.giveAddressInfo.contactPhone = data.contactPhone
        this.giveAddressInfo.cityList = cityList
        this.giveAddressInfo.address = data.address
        this.giveAddressInfo.addressId = data.id
        this.inputGive = data.type === 0
        // this.giveAddressInfo.street = data.street
        // this.giveAddressInfo.enCity = data.enCity
      }
      // this.changeGiveAddress(flag, index, true)
      // let self = this
      // setTimeout(function () {
      // })
      this.calculation()
    },
    initGoodsInfoList () {
      this.goodsInfoList = []
      this.insertGoodsInfoRow()
    },
    // 说明弹框
    explain (flag) {
      this.$refs.explainPop.init(flag)
    },
    // 添加地址信息
    insertAddressInfoRow () {
      this.addressInfoList.push({
        type: this.takeType,
        factoryName: null,
        contactName: null,
        contactPhone: null,
        cityList: null,
        street: null,
        enCity: null,
        address: null
      })
      this.takeCityFourList.push([])
    },
    // 添加货物信息
    insertGoodsInfoRow () {
      this.goodsInfoList.push({
        goodsName: null,
        shippingMark: null,
        length: null,
        width: null,
        height: null,
        pieceNum: null,
        weight: null,
        volume: null,
        goodsType: null,
        remark: null
      })
    },
    // 删除货物信息
    deleteGoodsRow (index) {
      let goodsInfoList = this.goodsInfoList
      if (goodsInfoList.length <= 1) {
        this.$message.error('至少要有一条货物信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        goodsInfoList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    // 删除地址信息
    deleteAddressInfoRow (index) {
      if (this.addressInfoList.length === 1) {
        this.$message.error('至少要有一条提卸货地址信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.addressInfoList.splice(index, 1)
        this.takeCityFourList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    // 初始化客户下拉
    getCustomerList () {
      this.$http({
        url: this.$http.adornUrl('/customer/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.customerList = data
      })
    },
    findGiveFactoryName (customerId) {
      this.$http({
        url: this.$http.adornUrl(`/priceBase/findGiveFactoryName`),
        method: 'get',
        params: this.$http.adornParams({
          customerId: customerId,
          orderType: this.takeType
        })
      }).then(({ data }) => {
        if (data) {
          this.giveFactoryNameList = data
        } else {
          this.giveFactoryNameList = []
        }
      })
    },
    // 获取提货方，卸货方
    getTakeGiveAreaList () {
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/customerAddress/findAll`),
        method: 'get',
        params: this.$http.adornParams({
          customerId: this.dataForm.customerId,
          status: 1
        })
      }).then(({ data }) => {
        this.takeAreaList = []
        this.giveAreaList = []
        if (data) {
          data.forEach(item => {
            if (item.simpleName && item.simpleName != '') {
              if (item.addressType === 1) {
                this.takeAreaList.push(item)
              } else {
                this.giveAreaList.push(item)
              }
            }
          })
        }
      })
    },
    filterTakeAddress (queryString, cb) {
      let results = queryString ? this.takeAreaList.filter(function (item) {
        if (item.simpleName && item.simpleName.indexOf(queryString) > -1) {
          return true
        }
        if (item.contactName && item.contactName.indexOf(queryString) > -1) {
          return true
        }
        if (item.contactPhone && item.contactPhone.indexOf(queryString) > -1) {
          return true
        }
        if (item.fullAddress && item.fullAddress.indexOf(queryString) > -1) {
          return true
        }
        return false
      }) : this.takeAreaList
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 获取车型信息
    getVehicleType () {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
        if(!!this.dataForm.vehicleType) {
          data.forEach((item,index) => {
            if(item.id == this.dataForm.vehicleType) {
              this.showTips(item,index)
            }
          })
        }
        // this.showTips(data, index)
      })
    },
    // 点击车型显示提示信息
    showTips (item, index) {
      this.dataForm.vehicleType = item.id
      this.vehicleRulesTips = item.rule
      this.vehicleTips = item.tips
      this.isactive = index
      this.calculation()
    },
    selectGoods (index) {
      this.activeGoods = index
    },
    checkAddressInfoList () {
      let infoList = this.addressInfoList
      for (let i = 0; i < infoList.length; i++) {
        let address = infoList[i]
        // if (!address.factoryName) {
        //   this.$message.error('请输入提货方名称')
        //   return false
        // }
        // if (!address.contactName) {
        //   this.$message.error('请输入提货联系人姓名')
        //   return false
        // }
        // if (!address.contactPhone) {
        //   this.$message.error('请输入提货联系人电话')
        //   return false
        // }
        if (!address.cityList) {
          this.$message.error('请选择提货省市区')
          return false
        }
        if (!address.address) {
          this.$message.error('请输入提货地址')
          return false
        }
        // if (!address.street) {
        //   this.$message.error('请输入提货点')
        //   return false
        // }
      }
      // if (!this.giveAddressInfo.factoryName) {
      //   this.$message.error('请输入卸货方名称')
      //   return false
      // }
      // if (!this.giveAddressInfo.contactName) {
      //   this.$message.error('请输入卸货联系人姓名')
      //   return false
      // }
      // if (!this.giveAddressInfo.contactPhone) {
      //   this.$message.error('请输入卸货联系人电话')
      //   return false
      // }
      if (!this.giveAddressInfo.cityList) {
        this.$message.error('请选择卸货省市区')
        return false
      }
      if (!this.giveAddressInfo.address) {
        this.$message.error('请输入卸货地址')
        return false
      }
      // if (!this.giveAddressInfo.factoryName) {
      //   this.$message.error('请选择卸货工厂')
      //   return false
      // }

      return true
    },
    checkGoodsInfoList () {
      let goodsInfoList = this.goodsInfoList
      for (let j = 0; j < goodsInfoList.length; j++) {
        let goods = goodsInfoList[j]
        if (!goods.goodsName) {
          this.$message.error('请输入货物名称')
          return false
        }
        if (!goods.volume) {
          this.$message.error('请输入体积')
          return false
        }
        if (!goods.packType) {
          this.$message.error('请选择包装类型')
          return false
        }
      }
      return true
    },
    // 初始化
    init () {
      this.enclosureDto.uploadUrl = this.$http.adornUrl('/upload/file')
      this.enclosureDto.accessToken = this.$cookie.get('TmsToken')
    },
    // 表单提交否
    dataFormSubmit (status) {
      // if (!this.dataForm.enclosureInfoList.length) {
      //   this.$message.error('请上传附件')
      //   return false
      // }
      if (this.newAuth('show:price') && this.getDefaultValue(this.totalPrice, 0) <= 0) {
        this.$confirm('当前订单运费为0，请确认是否继续创建订单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitApi(status)
        }).catch(() => {
          // 不提交
        })
      } else {
        this.submitApi(status)
      }
    },
    submitApi (status) {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid || !this.checkAddressInfoList() || !this.checkGoodsInfoList()) {
          return false
        }
        this.submitLoading = true
        let formData = {
          'id': this.dataForm.id || undefined,
          'readNadConsent': this.dataForm.readNadConsent,
          'vehicleType': this.dataForm.vehicleType,
          'orderType': this.dataForm.orderType,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'takeGoodsDate': this.dateFormatter(this.dataForm.takeGoodsTime, 'yyyy-MM-dd'),
          'takeGoodsTime': this.dateFormatter(this.dataForm.takeGoodsTime, 'hh:mm'),
          'customerId': this.dataForm.customerId,
          'customerSelfNo': this.dataForm.customerSelfNo,
          'customerContactName': this.dataForm.customerContactName,
          'isTailstock': this.dataForm.isTailstock,
          'customerContactPhone': this.dataForm.customerContactPhone,
          'abortWarehouseTime': this.dataForm.abortWarehouseTime,
          'enclosureInfoList': this.dataForm.enclosureInfoList,
          'addressList': this.addressInfoList,
          'giveAddressInfo': this.giveAddressInfo,
          'goodsList': this.goodsInfoList,
          'addFee': this.addFee,
          'remark': this.dataForm.remark,
          'entranceType': this.dataForm.entranceType,
          'status': status,
          'freightFee': this.totalPrice // 运费
        }
        if (this.showEntranceAddress) {
          formData.entranceAddressInfo = this.entranceAddressInfo
        }
        let self = this
        if (formData.orderType === 2) {
          this.$http({
            url: this.$http.adornUrl(`/order/createCarpoolingOrder`),
            method: 'PUT',
            data: this.$http.adornData(formData)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500
              })
              setTimeout(function () {
                self.$router.go(-1)
              }, 100)
              this.submitLoading = false
            } else {
              this.$message.error('操作失败')
              this.submitLoading = false
            }
          })
        } else {
          this.$http({
            url: this.$http.adornUrl(`/order/createSpecialOrder`),
            method: 'PUT',
            data: this.$http.adornData(formData)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500
              })
              // setTimeout(function () {
              //   window.history.go(0)
              // }, 100)
              let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
              this.$store.commit('common/updateMainTabs', val)
              this.$router.push({ name: 'list-customer' })
              this.submitLoading = false
            } else {
              this.$message.error('操作失败')
              this.submitLoading = false
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        file.url = resData.url
      }
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    // 文件上传之前过滤,仅支持上传jpg、png、Excel、pdf格式文件
    beforeAvatarUpload (file) {
      if (file.type === this.enclosureDto.jpegType ||
        file.type === this.enclosureDto.pngType ||
        file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        return true
      }
      this.$message.error('仅支持上传jpg、png、Excel、pdf格式文件')
      return false
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
