<template>
  <div class="common-pop">
    <el-dialog
      :title="todo == 'add' ? '【新增】省市区维护' : (todo == 'edit' ? '【修改】省市区维护' : '【查看】省市区维护')"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="所属上级编码" prop="parentId">
              <el-input v-model="dataForm.parentId" placeholder="所属上级编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编码" prop="id">
              <el-input v-model="dataForm.id" placeholder="编码" :disabled="todo != 'add'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序号" prop="sort">
              <el-input-number v-model="dataForm.sort" controls-position="right" :min="1" label="排序号"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        name: null,
        parentId: null,
        sort: 0
      },
      dataRule: {
        name: [
          { required: true, message: '请输入名称', trigger: 'change' }
        ],
        parentId: [
          { required: true, message: '请输入上级编码', trigger: 'blur' }
        ],
        id: [
          { required: true, message: '编码必填', trigger: 'blur' }
        ]
      },
      todo: 'view'
    }
  },
  methods: {
    init (todo, row) {
      this.visible = true
      if (todo !== 'add') {
        this.dataForm.id = row.id
        this.dataForm.name = row.name
        this.dataForm.parentId = row.parentId
        this.dataForm.sort = row.sort
      } else {
        this.dataForm.id = null
        this.dataForm.name = null
        this.dataForm.parentId = null
        this.dataForm.sort = 0
      }
      this.todo = todo
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let url = '/areaManager/update'
          if (this.todo === 'add') {
            url = '/areaManager/insert'
          }
          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'name': this.dataForm.name,
              'sort': this.dataForm.sort,
              'parentId': this.dataForm.parentId
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList', this.dataForm.parentId)
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
