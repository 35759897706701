export default {
  namespaced: true,
  state: {
    id: 0,
    name: '',
    currentUser: {}
  },
  mutations: {
    updateId (state, id) {
      state.id = id
    },
    updateName (state, name) {
      state.name = name
    },
    updateCurrentUser (state, user) {
      state.currentUser = user
      state.userId = user.id
      state.userName = user.account
    }
  }
}
