<!-- 自营司机提成工资表 -->
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="月份" prop="takeGoodsDate">
          <el-date-picker
            v-model="dataForm.takeGoodsDate"
            type="monthrange"
            align="right"
            unlink-panels
            value-format="yyyy-MM"
            :clearable="false"
            range-separator="-"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                show-summary
                :height="this.tableHeight"
                border size="small"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" fixed="left" align="center" width="40">
        </el-table-column>
        <el-table-column prop="driverName" label="司机姓名" fixed="left" align="center" width="100">
        </el-table-column>
        <el-table-column prop="yearMonth" label="年月" fixed="left" align="center" width="70">
        </el-table-column>
        <el-table-column prop="orderNum" label="订单数" :formatter="getNumValue" fixed="left" align="center" width="50">
        </el-table-column>
        <el-table-column label="基本工资" align="center">
          <el-table-column prop="baseFee" label="基本工资" :formatter="getNumValue" align="center" width="70" class-name="yellow-cell">
          </el-table-column>
          <el-table-column prop="meritsFee" label="绩效奖罚金" :formatter="getNumValue" align="center" width="70" class-name="yellow-cell">
          </el-table-column>
          <el-table-column prop="appFee" label="APP奖罚金" :formatter="getNumValue" align="center" width="70" class-name="yellow-cell">
          </el-table-column>
          <el-table-column prop="safetyRewards" label="安全奖罚金" :formatter="getNumValue" align="center" width="70" class-name="yellow-cell">
          </el-table-column>
          <el-table-column prop="extraFee" label="加班费" :formatter="getNumValue" align="center" width="60" class-name="yellow-cell">
          </el-table-column>
        </el-table-column>
        <el-table-column label="福利补助" align="center">
          <el-table-column prop="other1Fee" label="租房费" :formatter="getNumValue" align="center" width="70" class-name="green-cell">
          </el-table-column>
          <el-table-column prop="other2Fee" label="油耗奖励" :formatter="getNumValue" align="center" width="70" class-name="green-cell">
          </el-table-column>
          <el-table-column prop="royaltyFee" label="司机提成" :formatter="getNumValue" align="center" width="80" class-name="green-cell">
          </el-table-column>
          <el-table-column prop="loadFee" label="装卸费" :formatter="getNumValue" align="center" width="60" class-name="green-cell">
          </el-table-column>
          <el-table-column prop="stayFee" label="住宿费" :formatter="getNumValue" align="center" width="60" class-name="green-cell">
          </el-table-column>
        </el-table-column>
        <el-table-column label="应扣工资" align="center">
          <el-table-column prop="deductionsFee" label="社保/公积金" :formatter="getNumValue" align="center" width="75" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="accumulationFund" label="请假扣款" :formatter="getNumValue" align="center" width="60" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="safetyAward" label="扣减安全奖" :formatter="getNumValue" align="center" width="70" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="oilConsumption" label="扣减违章/油耗" :formatter="getNumValue" align="center" width="90" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="hydropower" label="分摊水电费" :formatter="getNumValue" align="center" width="60" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="personalIncomeTax" label="个税" :formatter="getNumValue" align="center" width="60" class-name="blue-cell">
          </el-table-column>
          <el-table-column prop="otherExpenses" label="其他费用" :formatter="getNumValue" align="center" width="80" class-name="blue-cell">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="shouldTotal" label="应发合计" :formatter="getNumValue" align="center" width="80">
        </el-table-column>
        <el-table-column label="借支" align="center">
          <el-table-column prop="driverBorrowing" label="司机借支" :formatter="getNumValue" align="center" width="60">
          </el-table-column>
          <el-table-column prop="borrowingRemark" label="借支备注" :show-overflow-tooltip="true" align="left" min-width="100">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="actualTotal" label="实发合计" :formatter="getNumValue" align="center" width="80">
        </el-table-column>
        <el-table-column prop="leaveDays" label="请假天数" :show-overflow-tooltip="true"  align="center" width="60">
        </el-table-column>
        <el-table-column prop="accidentNumber" label="事故次数"  align="center" width="60">
        </el-table-column>
        <el-table-column prop="violationNumber" label="违章次数"  align="center" width="60">
        </el-table-column>
        <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true" align="left" min-width="100">
        </el-table-column>
        <el-table-column prop="paidAmount" label="已付款" :formatter="getNumValue" align="center" width="80">
        </el-table-column>
        <el-table-column prop="payableAmount" label="未付款" :formatter="getNumValue" align="center" width="80">
        </el-table-column>
        <el-table-column prop="bankType" label="开户行" :formatter="dictConvert" align="center" width="100">
        </el-table-column>
        <el-table-column prop="bankCard" label="银行卡号" :formatter="getNumValue" align="center" width="150">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick (picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick (picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dataForm: {
        driverName: null,
        takeGoodsDate: null
      },
      showTotal: false,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      tableHeight: '65vh',
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  created () {
    let now = new Date()
    let start = new Date(now.getFullYear(), 0, 1)
    this.dataForm.takeGoodsDate = [this.dateFormatter(start, 'yyyy-MM'), this.dateFormatter(now, 'yyyy-MM')]
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costSummary/driverRoyaltyWageList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'driverName': this.dataForm.driverName,
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          // this.getAllSummaries(data.profitCount || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        if (!this.showTotal) {
          // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
          this.tableHeight = '67vh'
          this.showTotal = true
        }
        this.dataListLoading = false
      })
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/costSummary/export/driverRoyaltyWageList`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'driverName': this.dataForm.driverName,
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '自有司机工资表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === undefined || value === null) {
        return ''
      }
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (profitCount) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '',
        this.getValue(profitCount.vehicleProfitMonth),
        this.getValue(profitCount.freightFee),
        this.getValue(profitCount.payFreightFee),
        this.getValue(profitCount.refuelFee),
        this.getValue(profitCount.passingFee),
        this.getValue(profitCount.stayFee),
        this.getValue(profitCount.extraFee),
        this.getValue(profitCount.royaltyFee),
        this.getValue(profitCount.salesmanFee),
        this.getValue(profitCount.salesmanManagerFee),
        this.getValue(profitCount.serviceFee),
        this.getValue(profitCount.maintainFee),
        this.getValue(profitCount.taxesFee),
        this.getValue(profitCount.createCardFee),
        this.getValue(profitCount.insureMoney),
        this.getValue(profitCount.repairMoney),
        this.getValue(profitCount.lossMoney)
      ]
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
  display: inline-block;
  margin-left: 10px;
}
</style>
