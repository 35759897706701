<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
         <el-form-item label="客户名称" v-if="newAuth('pay:week:findAll')">
          <el-input v-model="dataForm.customerName" clearable></el-input>
        </el-form-item>
         <el-form-item label="账单名称">
          <el-input v-model="dataForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="newAuth('pay:week:delete')" type="danger" size="small" @click="deleteHandle()">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small" height="72vh"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" fixed="left"></el-table-column>
        <el-table-column label="客户名称" prop="customerName"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="账单名称" prop="name" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span @click="viewBill(scope.row)" style="cursor:pointer; color: blue">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="账单编号"  prop="billNo" :show-overflow-tooltip="true" width="190">
        </el-table-column>
        <el-table-column label="账单订单数" width="80" prop="orderNum">
        </el-table-column>
        <el-table-column label="账单总金额" width="80" prop="totalMoney">
        </el-table-column>
        <!-- <el-table-column label="发送状态" prop="sendStatus" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sendStatus == 2" size="small" type="warning">已发送</el-tag>
            <el-tag v-else size="small" type="danger">未发送</el-tag>
          </template>
        </el-table-column> -->
<!--        <el-table-column label="发送时间" prop="sendTime" width="130">-->
<!--        </el-table-column>-->
        <el-table-column label="账单生成日期" prop="createTime" width="130">
        </el-table-column>
        <el-table-column label="账单开始日期" prop="beginTime" width="90">
        </el-table-column>
        <el-table-column label="账单结束日期" prop="endTime" width="90">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="50" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('pay:week:download')" type="text" size="small">
                <a-link :id="scope.row.id" :url="'/costInfo/downloadExcel/'" :name="'下载Excel'"></a-link>
              </el-button>
              <el-button v-if="newAuth('pay:week:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <view-bill-detail ref="viewBill"></view-bill-detail>
  </div>
</template>

<script>
import viewBillDetail from './detail/viewBillDetail-yf'
export default {
  components:{
    viewBillDetail
  },
  data () {
    return {
      dataForm: {
        name: null,
        customerName: null,
        customerId: null,
        type: 2,
        billType: 2
      },
      baseUrl: window.SITE_CONFIG.baseUrl,
      token: this.$cookie.get('TmsToken'),
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      selectFeeData: []
    }
  },
  activated () {
    this.getDataList()
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    viewBill (row) {
      this.$refs.viewBill.init(row, '周')
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      if (!this.newAuth('pay:week:findAll')) {
        this.dataForm.customerId = this.currentUser.motorcadeId
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/getOrderBillList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'customerName': this.dataForm.customerName,
          'customerId': this.dataForm.customerId,
          'type': this.dataForm.type,
          'billType': this.dataForm.billType
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 删除
    deleteHandle (row) {
      let title = ''
      if(!!row) {
        title = `【${row.name}】`
      } else {
        title = ''
      }
      this.$confirm('确定删除账单' + title + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        if(!!row) {
          ids.push(row.id)
        } else {
          this.selectFeeData.forEach((item, index) => {
            ids.push(item.id)
          })
        }
        this.$http({
          url: this.$http.adornUrl(`/costInfo/deleteCostBill`),
          method: 'delete',
          data: this.$http.adornParams({
            ids: ids
          })
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('删除失败')
          }
        })
      }).catch(() => {})
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-supplierFee {
}
</style>
