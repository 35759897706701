<!-- 添加应付费用池数据弹窗 -->
<template>
  <div>
    <el-dialog title="报价详情"
               :close-on-click-modal="false"
               :show-close="false"
               top="20px"
               width="70%"
               :visible.sync="visible">
      <div style="margin-top: -25px; font-size: 18px;line-height: 35px;margin-bottom: 15px">
        <div>
          <label>报价截止时间</label>
          <label style="font-size: 16px;margin-left: 15px;">{{ orderInfo.deadlineTime }}</label>
          <label style="margin-left: 20px; color: #09e62a" v-if="orderInfo.modeStatus === 1">有效中</label>
          <label style="margin-left: 20px; color: #09e62a" v-if="orderInfo.modeStatus === 3">已成交</label>
        </div>
        <div>
          <label>当前报价</label>
          <label style="font-size: 20px;margin-left: 5px; color: red">{{ totalPage }}</label>
          <label style="margin-left: 5px;">轮</label>
          <div v-if="orderInfo.modeStatus === 1" style="display: initial; margin-left: 100px;">
            <label style="">报价剩余时间</label>
            <label style="margin-left: 10px;color: red">{{ surplusTimeText }}</label>
          </div>
          <div v-if="orderInfo.modeStatus === 3" style="display: initial; margin-left: 100px;">
            <label>成交时间</label>
            <label style="margin-left: 10px;color: red">{{ orderInfo.dealTime }}</label>
          </div>
          <div style="display: initial; float: right;font-size: 14px">
            按报价价格升序排序
          </div>
        </div>
      </div>
      <div class="tableBox">
        <el-table :data="dataList"
                  border
                  size="mini"
                  height="57vh"
                  @row-click="rowClick"
                  :row-style="selectedHighlight"
                  v-loading="dataListLoading"
                  style="width: 100%;">
          <el-table-column label="报价时间" prop="createTime" min-width="130" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="运费报价" prop="reportPrice" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="理想成交价" prop="settingPrice" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="供应商名称" prop="motorcadeName" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="供应商属性" prop="motorcadeTypeName" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="车牌号" prop="vehiclePlate" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="联系人" prop="driverName" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="联系电话" prop="driverPhone" min-width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" width="90" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status === 1"
                         size="mini"
                         type="primary"
                         style="padding: 5px 5px;"
                         @click="dispatchOrderDeal(scope.row)">指派成交
              </el-button>
              <el-button v-if="scope.row.status === 2"
                         style="padding: 5px 5px;" size="mini">已取消
              </el-button>
              <el-button v-if="scope.row.status === 3"
                         style="padding: 5px 5px;" size="mini" type="success">已成交
              </el-button>
              <el-button v-if="scope.row.status === 4"
                         style="padding: 5px 5px;" size="mini" type="info">未成交
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="sizeChangeHandle"
                       @current-change="currentChangeHandle"
                       :current-page="pageIndex"
                       :page-sizes="[20, 50, 100]"
                       :page-size="dataForm.limit"
                       :total="totalPage"
                       layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWindow">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { padStart } from 'lodash'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        page: 1,
        limit: 20,
        id: null,
        vehiclePlate: null,
        motorcadeName: null,
        driverName: null
      },
      orderInfo: {
        surplusTime: 0
      },
      surplusTimeText: '',
      timer: null,
      pageIndex: 1, // 当前页
      totalPage: 0, // 总数
      dataListLoading: false,
      dataList: [],
      getIndex: 1
    }
  },
  methods: {
    formatSeconds (value) {
      // 秒
      let theTime = parseInt(value)
      // 分
      let middle = 0
      // 小时
      let hour = 0
      if (theTime > 60) {
        middle = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (middle > 60) {
          hour = parseInt(middle / 60)
          middle = parseInt(middle % 60)
        }
      }
      let result = '' + padStart(theTime, 2, '0') + '秒'
      if (middle > 0) {
        result = '' + padStart(middle, 2, '0') + '分' + result
      }
      if (hour > 0) {
        result = '' + padStart(hour, 2, '0') + '时' + result
      }
      return result
    },
    startInterval () {
      if (this.timer != null) {
        return
      }
      this.timer = setInterval(() => {
        this.orderInfo.surplusTime--
        if (this.orderInfo.surplusTime <= 0) {
          this.closeTimer()
        }
        this.surplusTimeText = this.formatSeconds(this.orderInfo.surplusTime)
      }, 1000)
    },
    closeWindow () {
      this.closeTimer()
      this.visible = false
    },
    closeTimer () {
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    dispatchOrderDeal (row) {
      this.$confirm('确定指派给【' + row.motorcadeName + '】的【' + row.driverName + '】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/dispatchOrderDeal'),
          method: 'post',
          data: {
            id: row.id
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    init (id) {
      this.visible = true
      this.dataForm.id = id
      this.surplusTimeText = null
      this.getDataList()
    },
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/order/findOfferOrderRecord'),
        method: 'get',
        params: this.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.orderInfo = data.orderInfo
          this.startInterval()
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.dataForm.limit = val
      this.dataForm.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.dataForm.page = val
      this.getDataList()
    }
  }
}
</script>
