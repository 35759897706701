<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="驾驶员姓名">
          <el-input v-model="dataForm.name" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="dataForm.contactPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号码">
          <el-input v-model="dataForm.cardNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table
        :data="dataList"
        border
        size="small"
        height="72vh"
        :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
        <el-table-column prop="name" header-align="center" align="center" label="驾驶员姓名" min-width="80">
        </el-table-column>
        <el-table-column prop="contactPhone" header-align="center" align="center" label="联系电话" min-width="90">
        </el-table-column>
        <el-table-column prop="registerCity" header-align="center" align="center" label="注册城市" min-width="90"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="address" header-align="center" align="center" label="现居住地址" min-width="90"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="cardNo" header-align="center" align="center" label="身份证号码" min-width="130">
        </el-table-column>
        <el-table-column prop="proofType" header-align="center" align="center" width="70"
                         :formatter="dictConvert" label="准驾类型">
        </el-table-column>
        <el-table-column prop="isHaveVehicle" header-align="center" align="center" width="80" :formatter="yesNo"
                         label="是否自带车">
        </el-table-column>
        <el-table-column prop="urgentPhone" header-align="center" align="center" label="紧急联系电话" min-width="90">
        </el-table-column>
        <el-table-column prop="motorcadeName" header-align="center" align="center" label="所属车队" min-width="80">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="身份证正面" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, cardType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="身份证反面" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, otherCardType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="驾驶证" width="50">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, driverProofType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="从业资格证" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, qualificationType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="行驶证" width="50">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, vehicleDrivingType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="车辆图片" width="60">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, vehicleType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="交强险" width="50">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, vehicleForceType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="商业险" width="50">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, vehicleBusinessType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="车辆营运证" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, vehicleTransportType)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" header-align="center" align="center" width="50" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="50" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="examine(scope.row)">审核
              </el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle(true, scope.row.id)">详情
              </el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle(false, scope.row.id)">修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <detail v-if="detailVisible" ref="detail"></detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import AddOrUpdate from './details/register-save'
import detail from './details/driver-detail'
import viewImage from '@/views/commonPop/viewImage'

export default {
  data () {
    return {
      dataForm: {
        name: null,
        contactPhone: null,
        cardNo: null,
        status: null
      },
      cardType: 5000,
      otherCardType: 5010,
      driverProofType: 5001,
      qualificationType: 5011,
      vehicleDrivingType: 3000,
      vehicleType: 3001,
      vehicleTransportType: 3002,
      vehicleForceType: 9040,
      vehicleBusinessType: 9041,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  components: {
    AddOrUpdate,
    detail,
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    // 查看照片
    viewPhoto (row, type) {
      let typeList = [type]
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 审核
    examine (row) {
      this.$confirm('你正在执行【审核驾驶员注册】操作， 是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/driver/audit'),
          method: 'post',
          data: {
            id: row.id
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driver/findListByAudit'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status,
          'cardNo': this.dataForm.cardNo,
          'contactPhone': this.dataForm.contactPhone
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/driver/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
