<template>
  <div class="incident-main">
    <div class="info">
      <el-form :inline="true" :model="dataForm" size="small" label-width="100px">
        <h3 class="title">违章信息</h3>
        <div class="row">
          <div class="col">
            <el-form-item label="违章车牌号" prop="vehiclePlate">
              <el-select class="item-choose" v-model="dataForm.vehiclePlate" :disabled="isView" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in vehicleList"
                  :key="item.vehiclePlate"
                  :label="item.vehiclePlate"
                  :value="item.vehiclePlate"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col">
<!--            <el-form-item label="违章驾驶员" prop="driverName">-->
<!--              <el-input v-model="dataForm.driverName" placeholder="违章驾驶员" :disabled="isView"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="违章驾驶员" prop="driverName">
              <el-select class="item-choose" v-model="dataForm.driverName" :disabled="isView" filterable clearable placeholder="请选择">
                <el-option
                  v-for="(item, index) in vehicleList"
                  :key="index"
                  :value="item.bindDriverName"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="违章时间" prop="violationTime">
              <el-date-picker v-model="dataForm.violationTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col col-2">
            <el-form-item label="违章地点" prop="violationPlace">
              <el-input v-model="dataForm.violationPlace" placeholder="违章地点" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col max-col">
            <el-form-item label="违章描述" prop="violationDesc">
              <el-input v-model="dataForm.violationDesc" placeholder="违章描述" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="违章次数" prop="violationNumber">
              <el-input v-model="dataForm.violationNumber" placeholder="违章次数" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="违章罚款(元)" prop="payMoney">
              <el-input v-model="dataForm.payMoney" placeholder="违章罚款（元）" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="扣除分数" prop="removeScore">
              <el-input v-model="dataForm.removeScore" placeholder="扣分" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="处理时间" prop="successTime">
              <el-date-picker v-model="dataForm.successTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="处理人" prop="handleUser">
              <el-input v-model="dataForm.handleUser" placeholder="处理人" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col max-col">
            <el-form-item label="违章处理说明" prop="handleExplain">
              <el-input v-model="dataForm.handleExplain" placeholder="违章处理说明" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="submit" v-if="!isView">保存</el-button>
          <el-button type="danger" @click="gotoBack">关闭</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataForm: {
        id: null,
        vehiclePlate: null,
        driverName: null,
        violationTime: null,
        violationPlace: null,
        violationDesc: null,
        violationNumber: null,
        payMoney: null,
        removeScore: null,
        successTime: null,
        handleUser: null,
        handleExplain: null
      },
      todo: '',
      row: {},
      vehicleList: [],
      templateDataForm: {
        templateList: [{}]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  activated () {
    this.getVehicle()
  },
  mounted () {
    if (this.$route.params.todo) {
      this.todo = this.$route.params.todo
    }
    if (this.$route.params.row) {
      this.row = this.$route.params.row
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/violationInfo/getDetail/' + this.row.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = {
          id: data.id,
          vehiclePlate: data.vehiclePlate,
          driverName: data.driverName,
          violationTime: data.violationTime,
          violationPlace: data.violationPlace,
          violationDesc: data.violationDesc,
          violationNumber: data.violationNumber,
          payMoney: data.payMoney,
          removeScore: data.removeScore,
          successTime: data.successTime,
          handleUser: data.handleUser,
          handleExplain: data.handleExplain
        }
      })
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    gotoBack () {
      let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
      this.$store.commit('common/updateMainTabs', val)
      this.$router.push({ name: 'certificates-violationManagement' })
    },
    submit () {
      let formData = {
        id: this.dataForm.id,
        vehiclePlate: this.dataForm.vehiclePlate,
        driverName: this.dataForm.driverName,
        violationTime: this.dataForm.violationTime,
        violationPlace: this.dataForm.violationPlace,
        violationDesc: this.dataForm.violationDesc,
        violationNumber: this.dataForm.violationNumber,
        payMoney: this.dataForm.payMoney,
        removeScore: this.dataForm.removeScore,
        successTime: this.dataForm.successTime,
        handleUser: this.dataForm.handleUser,
        handleExplain: this.dataForm.handleExplain
      }
      let methodName = formData.id ? 'update' : 'add'
      let method = formData.id ? 'post' : 'put'
      this.$http({
        url: this.$http.adornUrl(`/violationInfo/` + methodName),
        method: method,
        data: this.$http.adornData(formData)
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000
          })
        } else {
          this.$message.error('操作失败')
        }
        this.gotoBack()
      })
    }
  }
}
</script>

<style lang="less">
.incident-main {
  .row {
    display: flex;
    .col {
      display: inline-block;
      flex: 1;
    }
    .el-upload {
      float: left;
    }
    .el-upload-list {
      display: inline-block;
      float: left;
      width: 80%;
      .el-upload-list__item {
        height: auto !important;
        margin: 0px !important;
        margin-left: 10px !important;
        float: left;
        width: auto !important;
      }
    }
    .col-2 {
      flex: 2;
      .el-form-item {
        width: 100%
      }
      .el-form-item__content {
        width: 68%;
      }
    }
    .max-col {
      .el-form-item {
        width: 100%;
        .el-input {
          width: 100%;
        }
      }
      .el-form-item__content {
        width: 88%;
      }
    }
  }
  .el-input {
    width: 120%;
  }

  .el-select .el-input {
    width: 112%;
  }
  .el-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer {
    text-align: right;
  }
  .content {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 86%;
    }
    .el-upload.el-upload--picture-card {
      width: 70px;
      height: 70px;
      line-height: 70px;
      margin:0
    }
    .el-upload-list__item {
      width: 70px !important;
      height: 70px !important;
      margin: 0px 8px 0 0 !important;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
  }
  .el-form-item {
    margin-bottom: 8px
  }
}
</style>
