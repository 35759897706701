<template>
  <div>
    <aside class="site-sidebar" :class="'site-sidebar--' + sidebarLayoutSkin">
      <div class="site-sidebar__inner">
        <el-menu :default-active="menuActiveName || 'home'" :collapse="sidebarFold" :collapseTransition="false" :unique-opened="true"
          class="site-sidebar__menu">
          <el-menu-item index="home" @click="$router.push({ name: 'home' })">
            <icon-svg name="shouye" class="site-sidebar__menu-icon"></icon-svg>
            <span slot="title">首页</span>
          </el-menu-item>
          <sub-menu v-for="menu in menuList" :key="menu.id" :menu="menu" :dynamicMenuRoutes="dynamicMenuRoutes">
          </sub-menu>
        </el-menu>
      </div>
    </aside>
    <update-notes-pop ref="updateNotes"></update-notes-pop>
  </div>
</template>

<script>
import SubMenu from './main-sidebar-sub-menu'
import { isURL } from '@/utils/validate'
import updateNotesPop from '@/views/commonPop/updateNotesPop'
export default {
  data () {
    return {
      dynamicMenuRoutes: [],
      loading: true,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false
    }
  },
  components: {
    SubMenu,
    updateNotesPop
  },
  computed: {
    sidebarLayoutSkin: {
      get () { return this.$store.state.common.sidebarLayoutSkin }
    },
    sidebarFold: {
      get () { return this.$store.state.common.sidebarFold }
    },
    menuList: {
      get () { return this.$store.state.common.menuList },
      set (val) { this.$store.commit('common/updateMenuList', val) }
    },
    menuActiveName: {
      get () { return this.$store.state.common.menuActiveName },
      set (val) { this.$store.commit('common/updateMenuActiveName', val) }
    },
    mainTabs: {
      get () { return this.$store.state.common.mainTabs },
      set (val) { this.$store.commit('common/updateMainTabs', val) }
    },
    mainTabsActiveName: {
      get () { return this.$store.state.common.mainTabsActiveName },
      set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
    },
    visible () {
      return this.$store.state.common.memorandum && (window.localStorage.getItem('notes') == 'true')
    }
  },
  watch: {
    $route: 'routeHandle'
  },
  created () {
    this.menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]')
    this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes') || '[]')
    this.routeHandle(this.$route)
  },
  mounted () {
    if (!this.newAuth('early:warning:find') && !this.newAuth('insurance:management:find')) {
      return
    }

    // 弹出消息窗口
    if (window.localStorage.getItem('notes') == 'true') {
      this.$refs.updateNotes.init()
      // notes
      window.localStorage.setItem('notes', false)

    }

    //this.$http({
    //  url: this.$http.adornUrl('/sysNotice/count'),
    //  method: 'get',
    //  params: this.$http.adornParams()
    //}).then(({ data }) => {
    //  if (data > 0) {
    //    this.$store.commit('common/updateMemorandum', true)
    //  }
    //})
    //this.getDataList()
  },
  methods: {
    // 路由操作
    routeHandle (route) {
      if (route.meta.isTab) {
        // tab选中, 不存在先添加
        let tab = this.mainTabs.filter(item => item.name === route.name)[0]
        if (!tab) {
          if (route.meta.isDynamic) {
            route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
            if (!route) {
              return console.error('未能找到可用标签页!')
            }
          }
          tab = {
            id: route.meta.id || route.name,
            name: route.name,
            title: route.meta.title,
            type: isURL(route.meta.iframeUrl) ? 'iframe' : 'module',
            iframeUrl: route.meta.iframeUrl || '',
            params: route.params,
            query: route.query
          }
          this.mainTabs = this.mainTabs.concat(tab)
        }
        this.menuActiveName = tab.id + ''
        this.mainTabsActiveName = tab.name
      }
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    //optProcessed (row) {
    //  this.$refs.updateNotes.init()
    //  this.close()
      // this.$confirm(`确定对该条数据进行【已读】操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$http({
      //     url: this.$http.adornUrl(`/sysNotice/updateStatus/${row.id}`),
      //     method: 'post'
      //   }).then(({ data }) => {
      //     if(data.code == 100000) {
      //       this.$message.success('标记成功')
      //       this.getDataList()
      //     }
      //   })
      // })
    //},
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 获取数据列表
    //getDataList () {
      //if (window.localStorage.getItem('notes') == 'true') {
      //  this.$http({
      //    url: this.$http.adornUrl('/sysNotice/findList'),
      //    method: 'get',
      //    params: this.$http.adornParams({
      //      status: 2,
      //      'page': this.pageIndex,
      //      'limit': this.pageSize
      //    })
      //  }).then(({ data }) => {
      //    if (data.total) {
      //      this.dataList = data.list
      //      this.totalPage = data.total
      //    } else {
      //      this.dataList = []
      //      this.totalPage = 0
      //    }
      //  })
      //}
    //},
    close () {
      this.$store.commit('common/updateMemorandum', false)
      // notes
      window.localStorage.setItem('notes', false)
    }
  }
}
</script>
