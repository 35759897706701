<template>
  <div class="vehicle-main-detail popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】车辆维修管理' : (todo == 'edit' ? '【修改】车辆维修管理' : '【查看】车辆维修管理')"
      width="80%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="100px">
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="车牌号" prop="cardObject">
              <el-select class="item-choose" v-model="dataForm.cardObject" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择" @change="selectObject">
                <el-option
                  v-for="item in dictTypeMap.card_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送修时间" prop="createCardTime">
              <el-date-picker v-model="dataForm.createCardTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isView">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划结束时间" prop="createCardTime">
              <el-date-picker v-model="dataForm.createCardTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isView">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="维修厂" prop="name">
              <el-input v-model="dataForm.name" placeholder="维修厂" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维修类型" prop="name">
              <el-input v-model="dataForm.name" placeholder="维修类型" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
         <el-col :span="8">
            <el-form-item label="维修项目" prop="cardType">
              <el-select class="item-choose" v-model="dataForm.cardType" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in cardTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="送修人" prop="cardNo">
              <el-input v-model="dataForm.cardNo" placeholder="证件编码" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维修金额(元)" prop="archiveNo">
              <el-input v-model="dataForm.archiveNo" placeholder="档案编号" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="维修原因" prop="nativeCity">
              <el-input v-model="dataForm.archiveNo" placeholder="维修原因" type="textarea" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="附件">
              <al-upload :fileData="cardFileList" :fileUrl="uploadUrl" :isView="isView" :format="'img'" :type="'IdCard'" :limit="2" :businessType="8000" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input :rows="1" :disabled="isView" v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      cardTypeList: [],
      dataForm: {
        id: null,
        name: null,
        cardObject: null,
        cardType: null,
        cardNo: null,
        archiveNo: null,
        createCardTime: null,
        createCardCost: 0,
        cardBeginTime: null,
        cardEndTime: null,
        remark: null,
        enclosureInfoList: []
      },
      motorcadeList: [],
      uploadUrl: '',
      accessToken: '',
      cardFileList: [],
      proofFileList: [],
      todo: '',
      dataRule: {
        cardObject: this._Valid.init(['null']),
        name: this._Valid.init(['null']),
        cardType: this._Valid.init(['null']),
        createCardCost: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 证件类型根据证件对象带出
    selectObject () {
      this.$http({
        url: this.$http.adornUrl('/cardType/findAllByObject'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.cardTypeList = data.list
      }).then(() => {
      })
    },
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.cardFileList = []
      this.proofFileList = []
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        this.cardFileList = data.enclosureInfoList
      }
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      if (id) {
        this.$http({
          url: this.$http.adornUrl(`/cardWarn/detail/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.id = data.id
            this.dataForm.name = data.name
            this.dataForm.cardObject = data.cardObject
            this.dataForm.cardType = data.cardType
            this.dataForm.cardNo = data.cardNo
            this.dataForm.archiveNo = data.archiveNo
            this.dataForm.createCardTime = data.createCardTime
            this.dataForm.createCardCost = data.createCardCost
            this.dataForm.cardBeginTime = data.cardBeginTime
            this.dataForm.cardEndTime = data.cardEndTime
            this.dataForm.remark = data.remark
            this.selectObject()
            this.initFileList(data)
            this.visible = true
          }
        })
      } else {
        this.dataForm.name = null
        this.dataForm.cardObject = null
        this.dataForm.cardType = null
        this.dataForm.cardNo = null
        this.dataForm.archiveNo = null
        this.dataForm.createCardTime = null
        this.dataForm.createCardCost = null
        this.dataForm.cardBeginTime = null
        this.dataForm.cardEndTime = null
        this.dataForm.remark = null
        this.visible = true
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          if(this.todo == 'add') {
            this.dataForm.status = 1
          }
          this.$http({
            url: this.$http.adornUrl(`/cardWarn/` + methodName),
            method: method,
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    getFileData (data) {
      this.cardFileList = data.fileList
      this.dataForm.enclosureInfoList = data.fileList
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>
<style lang="less">
.vehicle-main-detail {
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%
  }
}
</style>
