<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机号码">
          <el-input v-model="dataForm.driverPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="加油日期">
          <el-date-picker
            v-model="dataForm.addTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            :clearable="false"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDataList"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :height="tableHeight" ref="table"
                @row-click="rowClick"
                :summary-method="getSummaries"
                show-summary
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column prop="vehiclePlate" label="车牌号" width="90" align="center">
        </el-table-column>
        <el-table-column prop="vehicleType" label="车型" width="90" align="center">
        </el-table-column>
        <el-table-column prop="currentDistance" label="车辆总实跑(km)" width="110" align="center">
        </el-table-column>
        <el-table-column prop="pumpElementFee" label="泵素费" width="110" align="center">
        </el-table-column>
        <el-table-column prop="standardPumpElement" label="平台标准泵素" width="110" align="center">
        </el-table-column>
        <el-table-column prop="pumpElementKg" label="实际泵素(kg)" width="90" align="center">
        </el-table-column>
        <el-table-column prop="differencePumpElement" label="节省/超标" width="90" align="center">
        </el-table-column>
        <el-table-column prop="driverName" label="司机名称" width="100" align="center">
        </el-table-column>
        <el-table-column prop="driverPhone" label="司机电话" width="100" align="center">
        </el-table-column>
        <el-table-column prop="pumpElementRemark" label="备注" min-width="120" :show-overflow-tooltip="true" header-align="center"
                         align="left">
        </el-table-column>
        <el-table-column width="80" label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="addOrUpdateHandle(scope.row)" size="small">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dataForm: {
        vehiclePlate: null,
        addTime: null,
        driverPhone: null,
        driverName: null
      },
      tableHeight: '70vh',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false
    }
  },
  created () {
    // 默认查询当前月
    let start = new Date()
    start.setDate(1)
    let startDate = this.dateFormatter(start, 'yyyy-MM-dd')

    start.setMonth(start.getMonth() + 1)
    let end = new Date(start.getTime() - (1000 * 60 * 60 * 24))
    this.dataForm.addTime = [startDate, this.dateFormatter(end, 'yyyy-MM-dd')]
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['table'].doLayout()
    })
  },
  activated () {
    this.getDataList()
  },
  methods: {
    /**
     *自定义合计
     */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          // 加油量，加油金额
          if (column.property && (column.property.indexOf('pumpElementFee') > -1 ||
            column.property.indexOf('pumpElementKg') > -1 ||
            column.property.indexOf('currentDistance') > -1)) {
            return this.getValue(item[column.property])
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (row) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '',
        row.currentDistance,
        row.pumpElementFee,
        '',
        row.pumpElementKg,
        '', '', '', '', '', '', ''
      ]
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        td.align = 'center'
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    downLoad () {
      let addTime = this.dataForm.addTime
      let timeParam = {}
      if (addTime && addTime.length === 2) {
        timeParam.refuelBeginDate = addTime[0]
        timeParam.refuelEndDate = addTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/export/pumpSumTableExport`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'page': 1, // 导出全部
          'limit': this.totalPage,
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'driverPhone': this.dataForm.driverPhone,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '泵素汇总表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let addTime = this.dataForm.addTime
      let timeParam = {}
      if (addTime && addTime.length === 2) {
        timeParam.refuelBeginDate = addTime[0]
        timeParam.refuelEndDate = addTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/refuelRegister/pumpSumTableList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'driverPhone': this.dataForm.driverPhone,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          // this.getAllSummaries(data.totalRow)
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
        this.tableHeight = '72vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    addOrUpdateHandle (row) {
      let param = {}
      param.vehiclePlate = row.vehiclePlate
      param.driverPhone = this.dataForm.driverPhone
      param.driverName = this.dataForm.driverName
      param.refuelDate = this.dataForm.addTime
      param.sumTable = true
      this.$router.push({
        path: '/transport-pumpElement',
        query: param
      })
    }
  }
}
</script>
