// 年应收统计表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.businessName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="年份" prop="takeGoodsDate">
          <el-date-picker
            style="width: 100%"
            v-model="dataForm.takeGoodsDate"
            value-format="yyyy"
            type="year"
            :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户阶段" prop="stage">
          <el-select class="item-choose" v-model="dataForm.stage" filterable clearable placeholder="请选择" style="width: 100%">
            <el-option label="待跟进" :value="1"></el-option>
            <el-option label="跟进中" :value="2"></el-option>
            <el-option label="成交" :value="3"></el-option>
            <el-option label="流失" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :summary-method="getSummaries"
                show-summary
                :height="tableHeight"
                border size="small"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" fixed="left">
        </el-table-column>
        <el-table-column prop="businessName" label="客户名称" :show-overflow-tooltip="true" min-width="100" fixed="left">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" min-width="100"
                         align="center"
                         v-for="monthValue in goodsMonth"
                         :label="monthValue + '月'" :key="monthValue" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
          <el-table-column label="应收" :show-overflow-tooltip="true" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row, monthValue - 1)" style="color:blue;cursor:pointer">{{getValue(scope.row.summaryList[monthValue-1].totalMoney)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="未收" :show-overflow-tooltip="true" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row, monthValue - 1, 2)" style="color:blue;cursor:pointer">{{getValue(scope.row.summaryList[monthValue-1].notPayMoney)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="已收" :show-overflow-tooltip="true" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row, monthValue - 1, 2)" style="color:blue;cursor:pointer">{{getValue(scope.row.summaryList[monthValue-1].payMoney)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="逾期" :show-overflow-tooltip="true" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row, monthValue - 1, 2)" style="color:blue;cursor:pointer">{{getValue(scope.row.summaryList[monthValue-1].overdueMoney)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="预收" :show-overflow-tooltip="true" v-bind:class-name="monthValue%2==0?'blue-cell':'yellow-cell'">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row, monthValue - 1, 2)" style="color:blue;cursor:pointer">{{getValue(scope.row.summaryList[monthValue-1].receivedFees)}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column :show-overflow-tooltip="true"
                         min-width="100"
                         align="center"
                         label="合计">
          <el-table-column label="应收" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ getValue(scope.row.sumTotalMoney) }}
            </template>
          </el-table-column>
          <el-table-column label="未收" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ getValue(scope.row.sumTotalNoPayMoney) }}
            </template>
          </el-table-column>
          <el-table-column label="已收" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ getValue(scope.row.sumTotalPaidMoney) }}
            </template>
          </el-table-column>
          <el-table-column label="预收" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ getValue(scope.row.sumReceivedFees) }}
            </template>
          </el-table-column>
        </el-table-column>

      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <fee-detail ref="fee"></fee-detail>
  </div>
</template>

<script>
import feeDetail from './details/fee-detail.vue'
import { getValue } from '../../../utils'
export default {
  components: {
    feeDetail
  },
  data () {
    return {
      dataForm: {
        businessName: null,
        takeGoodsDate: null,
        stage: null
      },
      tableHeight: '65vh',
      dataList: [],
      goodsMonth: 12,
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  created () {
    this.dataForm.takeGoodsDate = new Date()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    // window.setTimeout(() => {
    //   this.tableHeight = '67vh'
    // }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {

    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    showDetail (row, index, writeStatus) {
      let month = row.summaryList[index].goodsMonth
      let year = row.takeGoodsDate
      let businessId = row.businessId
      let beginDate = new Date(year, month - 1, 1)
      let endDate = new Date(new Date(year, month, 1).getTime() - 1000 * 60 * 60 * 24)
      let param = {
        beginDate: beginDate,
        endDate: endDate,
        businessId: businessId,
        writeStatus: writeStatus,
        type: 1
      }
      this.$refs.fee.init(param, 'view')
    },
    getSummaries (param) {
      const { columns, data } = param
      let sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        sums = ['合计', '']
        for (let i = 0; i < this.goodsMonth * 5; i++) {
          sums.push(0)
        }
        data.forEach((valItem, valIdx) => {
          for (let i = 1; i <= this.goodsMonth; i++) {
            let startIndex = (i - 1) * 5
            sums[startIndex + 2] = getValue(this.numFilter(sums[startIndex + 2] + valItem.summaryList[i - 1].totalMoney))
            sums[startIndex + 3] = getValue(this.numFilter(sums[startIndex + 3] + valItem.summaryList[i - 1].notPayMoney))
            sums[startIndex + 4] = getValue(this.numFilter(sums[startIndex + 4] + valItem.summaryList[i - 1].payMoney))
            sums[startIndex + 5] = getValue(this.numFilter(sums[startIndex + 5] + valItem.summaryList[i - 1].overdueMoney))
            sums[startIndex + 6] = getValue(this.numFilter(sums[startIndex + 6] + valItem.summaryList[i - 1].receivedFees))
          }
        })
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      delete totalSummary[0]
      delete totalSummary[1]
      // 合计参数，按顺序返回
      let param = totalSummary
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody') && document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody') && document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let year = null
      if (typeof this.dataForm.takeGoodsDate === 'object') {
        year = this.dataForm.takeGoodsDate.getFullYear()
      } else {
        year = this.dataForm.takeGoodsDate
      }
      this.$http({
        url: this.$http.adornUrl('/costSummary/yearReceiptSummary'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'businessName': this.dataForm.businessName,
          'takeGoodsDate': year,
          'stage': this.dataForm.stage
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.goodsMonth = data.goodsMonth
          this.getAllSummaries(data.summaryTotal || {})
          this.tableHeight = '70vh'
        } else {
          this.dataList = []
          this.totalPage = 0
          this.goodsMonth = 12
        }
        this.dataListLoading = false
      })
    },
    downLoad () {
      /**
        1，应收汇总表
        2，应付汇总表
        3，年应收统计表
        4，年应付统计表
        5，客户年利润统计表
        6，车辆年利润统计表
        costSummary/export/{businessType}
       */
      let year = null
      if (typeof this.dataForm.takeGoodsDate === 'object') {
        year = this.dataForm.takeGoodsDate.getFullYear()
      } else {
        year = this.dataForm.takeGoodsDate
      }
      this.$http({
        // url: this.$http.adornUrl(`/costSummary/export/3`),
        url: this.$http.adornUrl(`/export/annualReceivables`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'businessName': this.dataForm.businessName,
          'takeGoodsDate': year,
          'stage': this.dataForm.stage
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '年应收统计表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
  span.searchUpload {
    display: inline-block;
    margin-left: 10px;
  }
</style>
