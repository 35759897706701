<template>
  <div class="container">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="套餐编号">
          <el-input v-model="dataForm.no" placeholder="套餐编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="套餐名字">
          <el-input v-model="dataForm.name" placeholder="套餐名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="套餐状态">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
            placeholder="套餐状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTenantsList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('request:package:find')" @click="editOrAddTenants({}, 'info')"
            type="success">添加租户套餐</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox tenants-list">
      <el-table ref="tableRef" :data="tablelist" border size="mini" v-loading="tableLoading"  height="72vh"
        element-loading-text="正在加载中">
        <el-table-column label="套餐编号" :show-overflow-tooltip="true" prop="no" />
        <el-table-column label="套餐名字" :show-overflow-tooltip="true" prop="name" />
        <el-table-column label="内置套餐" :show-overflow-tooltip="true" prop="supperTenantPackage " width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.supperTenantPackage"  type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
           </template>
        </el-table-column>
        <el-table-column label="最大管理账号数量" :show-overflow-tooltip="true" prop="maxAdminAccount" />
        <el-table-column label="备注" :show-overflow-tooltip="true" prop="remark" />
        <el-table-column label="修改时间" :show-overflow-tooltip="true" prop="updateTime" />
        <el-table-column label="创建时间" :show-overflow-tooltip="true" prop="createTime" />
        <el-table-column fixed="right" width="80" label="套餐状态" align="center" prop="createTime">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" active-value="0"
              size="mini" :disabled="!isAuth('request:package:status') || scope.row.supperTenantPackage === 1"
              @change="(val) => handleSwitchChange(val, scope.row)" inactive-value="1">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" prop="createTime">
          <template slot-scope="scope">
            <el-link type="primary" class="link" v-if="isAuth('request:package:distributionMenu')" @click="assignTenantsMenu(scope.row)">分配套餐菜单</el-link>
            <el-link type="success"  class="link"  v-if="isAuth('request:package:update')" @click="editOrAddTenants(scope.row, 'info')">编辑</el-link>
            <el-link type="danger"  class="link"   v-if="isAuth('request:package:deleted') && !scope.row.supperTenantPackage" @click="deleteTenants(scope.row)" >删除</el-link>
            <!-- <el-button v-if="isAuth('request:package:update')" @click="editOrAddTenants(scope.row, 'info')" type="text"
              size="mini">编辑</el-button>
            <el-button v-if="isAuth('request:package:deleted') && !scope.row.supperTenantPackage" @click="deleteTenants(scope.row)" type="text"
              size="mini">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :page-sizes="[20, 50, 100]"
        :page-size="params.pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <TenantsPopup ref="tenantsPopup" @cancel="getTenantsList" />
  </div>
</template>

<script>
import TenantsPopup from './components/tenants-popup.vue'
export default {
  components: { TenantsPopup },
  data () {
    return {
      params: {
        pageNum: 1,
        pageSize: 20
      },
      totalPage: 0,
      tableLoading: false,
      dataForm: {
        status: null
      },
      tablelist: []
    }
  },
  created () {
    this.getTenantsList()
  },
  methods: {
    // 查询租户套餐列表
    getTenantsList () {
      this.tableLoading = true
      this.$http({
        url: this.$http.adornUrl('/tenant/package/page'),
        method: 'get',
        params: this.$http.adornParams({ ...this.params, ...this.dataForm })
      }).then((response) => {
        if (response) {
          this.tablelist = response.data.list
          this.totalPage = response.data.total
        }
        setTimeout(() => {
          this.tableLoading = false
        }, 300)
      })
    },
    // 修改/新增租户套餐
    editOrAddTenants (row = {}, mode = 'info') {
      this.$refs.tenantsPopup.openDialog(row, mode)
    },
    // 删除租户套餐
    deleteTenants (row) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/tenant/package/${row.id}`),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message.success('删除成功')
            this.getTenantsList()
          }
        })
      }).catch(() => { })
    },
    // 分配租户套餐菜单
    assignTenantsMenu (row) {
      this.$refs.tenantsPopup.openDialog(row, 'menu')
    },
    // 变更租户套餐状态
    handleSwitchChange (val, row) {
      this.$http({
        url: this.$http.adornUrl(`/tenant/package/status`),
        method: 'get',
        params: this.$http.adornParams({
          id: row.id,
          status: val
        })
      }).then(({ data }) => {
        if (data) {
          this.$message.success('已变更')
          this.getTenantsList()
        }
      }).catch(() => { this.getTenantsList() })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.params.pageSize = val
      this.params.pageNum = 1
      this.getTenantsList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.params.pageNum = val
      this.getTenantsList()
    }
  }
}
</script>

<!-- <style lang="scss" scoped>
.container {}
</style> -->
