<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <!-- <img :src="logo" class="logo" alt=""> -->
        <div class="login-main">
          <div class="login-box">
            <h3 class="login-title">易车科技物流TMS</h3>
            <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()">
              <!-- <el-form-item>
              <el-radio-group v-model="dataForm.type">
                <el-radio :label="0">平台登录</el-radio>
                <el-radio :label="1">租户登录</el-radio>
              </el-radio-group>
            </el-form-item> -->
              <el-form-item prop="tenantId">
                <el-input v-model="dataForm.tenantId" placeholder="公司代码" prefix-icon="el-icon-cpu" autocomplete="on" name="account"></el-input>
              </el-form-item>
              <el-form-item prop="userName">
                <el-input v-model="dataForm.userName" placeholder="账号" prefix-icon="el-icon-user" autocomplete="on" name="account"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="dataForm.password" show-password type="password" placeholder="密码" prefix-icon="el-icon-lock" auto-complete="new-password"></el-input>
              </el-form-item>
              <!-- <el-form-item prop="captcha">
              <el-row :gutter="20">
                <el-col :span="14">
                  <el-input v-model="dataForm.captcha" placeholder="验证码">
                  </el-input>
                </el-col>
                <el-col :span="10" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha()" alt="">
                </el-col>
              </el-row>
            </el-form-item> -->
              <el-form-item>
                <el-button  v-no-more-click round class="login-btn-submit" type="primary" @click="dataFormSubmit()">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div id="app">
      <router-view />
      <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: white">
        <p>
          网站备案号：
          <a class="text-color" style="color: white" href="https://beian.miit.gov.cn"
            target="_blank">粤ICP备2021146415号-1</a>。
          <br>
          © 2021-2023 深圳市易车科技物流有限公司 版权所有
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getUUID } from '@/utils'
import Encrypt from '@/utils/encrypt'
// import logo from '@/../static/img/logo.png'
export default {
  data () {
    return {
      // logo: logo,
      dataForm: {
        userName: '',
        password: '',
        tenantId: null
        // uuid: '',
        // captcha: ''
      },
      showPop: false,
      dataRule: {
        userName: [
          { required: true, message: '账号不可为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不可为空', trigger: 'blur' }
        ],
        tenantId: [
          { required: true, message: '公司代码不可为空', trigger: 'blur' }
        ]
        // captcha: [
        //   { required: true, message: '验证码不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  created () {
    if (sessionStorage.getItem('Tenant-Id')) {
      this.dataForm.tenantId = JSON.parse(JSON.stringify(sessionStorage.getItem('Tenant-Id')))
    }
  },
  methods: {
    // 提交表单
    dataFormSubmit () {
      let that = this
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          sessionStorage.removeItem('Tenant-Id')
          if (that.dataForm.tenantId) {
            sessionStorage.setItem('Tenant-Id', that.dataForm.tenantId)
          }
          that.$nextTick(() => {
            that.$http({
              url: that.$http.adornUrl('/auth/pcLogin'),
              method: 'post',
              data: that.$http.adornData({
                'account': that.dataForm.userName,
                'password': Encrypt.RSAEncrypt(that.dataForm.password)
                // 'uuid': this.dataForm.uuid,
                // 'captcha': this.dataForm.captcha
              })
            }).then(({ data }) => {
              that.$cookie.set('TmsToken', data)
              that.$router.replace({ name: 'home' })
              // 初次存储时设置notes为true， 如果notes为false就不需要弹框
              window.localStorage.setItem('notes', true)
            })
          })
        }
      })
    },
    // 获取验证码
    getCaptcha () {
      this.dataForm.uuid = getUUID()
      this.captchaPath = this.$http.adornUrl(`/captcha.jpg?uuid=${this.dataForm.uuid}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-input__inner {
  border: none !important;
  border-bottom: 2px solid #eee !important;
  line-height: 50px !important;
  height: 50px !important;
  padding-left: 38px !important;
  border-radius: 0 !important;
}
::v-deep .is-error {
  .el-input__inner {
    border-color: #f56c6c !important;
  }
  .el-form-item__error{
    font-size: 14px;
    line-height: 25px;
  }
}
::v-deep .el-input__icon{
    font-weight: bold;
    font-size: 25px;
    color: #a0aec0;
  }
  ::v-deep.el-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; /* 设置白色背景填充 */
  -webkit-text-fill-color: black; /* 设置文本颜色为黑色 */
}
::v-deep.el-input input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset; /* 焦点状态下同样背景填充 */
  -webkit-text-fill-color: black; /* 保持文本颜色 */
}
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgba(38, 50, 56, .6);
  overflow: hidden;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/img/login_bg.png);
    background-size: cover;
  }

  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }

  .site-content {
    min-height: 100%;
    .logo{
      width: auto;
      height: 50px;
      position: absolute;
      right: 20px;
    }
  }

  .brand-info {
    margin: 220px 100px 0 90px;
    color: #fff;
  }

  .brand-info__text {
    margin: 0 0 22px 0;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .brand-info__intro {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.58;
    opacity: .6;
  }

  .login-main {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    // padding: 150px 60px 180px;
    width: 858px;
    height: 460px;
    border-radius: 40px 5px 40px;
    background: url(../../assets/img/box.png) no-repeat #fff;
    background-size: auto 100%;
    padding: 20px 35px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    .login-box {
      float: right;
      margin: 0 auto;
      // padding-top: 15px;
      width: 320px;
      height: auto;
      position: relative;
    }
  }

  .login-title {
    float: left;
    width: 100%;
    line-height: 10px;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    font-family: monospace;
  }

  .login-captcha {
    overflow: hidden;

    >img {
      width: 100%;
      cursor: pointer;
    }
  }

  .login-btn-submit {
    width: 100%;
    margin-top: 25px;
    padding: 15px 30px !important;
    border-radius: 50px;
  }
}
</style>
