<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="公司全称">
          <el-input v-model="dataForm.fullName" placeholder="公司全称" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="dataForm.contactName" placeholder="联系人" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
                v-for="item in dictTypeMap.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('', 'add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"  :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  height="72vh" border size="small" v-loading="dataListLoading" style="width: 100%;">
        <!-- <el-table-column v-for="(row, index) in tableRow" :show-overflow-tooltip="true" :width="row.width ? row.width : ''" :key="index" :label="row.label" :prop="row.prop" align="center"></el-table-column> -->
        <af-table-column label="公司编号" prop="companyNo"></af-table-column>
        <af-table-column label="公司全称" prop="fullName"></af-table-column>
        <af-table-column label="公司简称" prop="simpleName"></af-table-column>
        <af-table-column label="企业信用代码" prop="creditCode"></af-table-column>
        <af-table-column label="联系人名称" prop="contactName"></af-table-column>
        <af-table-column label="联系电话" prop="contactPhone"></af-table-column>
        <af-table-column label="地址" prop="address"></af-table-column>
        <af-table-column prop="status" width="40" label="状态" fixed="right">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id, 'view')">详情</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id, 'edit')">修改</el-button>
              <!-- <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button> -->
              <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="disableOrEnable(scope.row)">{{scope.row.status === 2 ? '启用' : '禁用'}}</el-button>
              <!-- <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle(false, scope.row.id)">修改</el-button> -->
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <!-- <detail v-if="detailVisible" ref="detail"></detail> -->
  </div>
</template>

<script>
import AddOrUpdate from './details/company-add-or-update'
// import detail from './details/company-detail'
export default {
  data () {
    return {
      dataForm: {
        fullName: null,
        contactName: null,
        contactPhone: null,
        status: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      getIndex: -1,
      tableRow: [{
        label: '公司编号',
        prop: 'companyNo',
        width: 100
      }, {
        label: '公司全称',
        prop: 'fullName',
        width: 250
      }, {
        label: '公司简称',
        prop: 'simpleName',
        width: 150
      }, {
        label: '企业信用代码',
        prop: 'creditCode',
        width: 200
      }, {
        label: '联系人名称',
        prop: 'contactName',
        width: 100
      }, {
        label: '联系电话',
        prop: 'contactPhone',
        width: 100
      }, {
        label: '地址',
        prop: 'address'
      }]
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) { 
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/company/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'fullName': this.dataForm.fullName,
          'status': this.dataForm.status,
          'contactName': this.dataForm.contactName,
          'contactPhone': this.dataForm.contactPhone
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 查看 / 新增 / 修改
    addOrUpdateHandle (id, todo) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, todo)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
