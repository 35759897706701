// 工资表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="年份" prop="year">
          <el-date-picker
            style="width: 100%"
            v-model="dataForm.year"
            value-format="yyyy"
            type="year"
            :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="createWage()" type="success">创建工资表</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox wages-table">
      <el-table :data="dataList"
                :summary-method="getSummaries"
                show-summary
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :height="tableHeight"
                border size="small" v-loading="dataListLoading"
                style="width: 100%;" class="table-input">
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column prop="yearMonth" label="工资年月" width="70" align="center">
        </el-table-column>
        <el-table-column prop="jobNumber" label="在职人数" width="60" align="center">
        </el-table-column>
        <el-table-column prop="shouldWageFee" label="应发工资" align="center">
        </el-table-column>
        <el-table-column prop="totalDeductionFee" label="应扣合计" align="center">
        </el-table-column>
        <el-table-column prop="actualWageFee" label="实发工资" align="center">
        </el-table-column>
        <el-table-column prop="totalDeduction" label="预支应扣合计" align="center">
        </el-table-column>
        <el-table-column prop="finalWageFee" label="最终实发" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="工资表创建时间" width="130" align="center">
        </el-table-column>
        <el-table-column prop="createName" label="创建人" align="center" width="80">
        </el-table-column>
        <el-table-column prop="status" label="发放状态" align="center" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" size="small" type="success">全部已发放</el-tag>
            <el-tag v-if="scope.row.status == 2" size="small" type="danger">全部未发放</el-tag>
            <el-tag v-if="scope.row.status == 3" size="small" type="danger">部分未发放</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="160" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toUpdatePage(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="batchRelease(scope.row)">全部发放</el-button>
            <el-button type="text" size="small" @click="downLoad(scope.row)">导出明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <create-wage-popup ref="createWage" @refreshDataList="getDataList"></create-wage-popup>
  </div>
</template>

<script>

import createWagePopup from './detail/create-wage-popup'

export default {
  data () {
    return {
      dataForm: {
        year: null
      },
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      detailVisible: false,
    }
  },
  components: {
    createWagePopup
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    this.resetHeight()
  },
  methods: {
    setDefaultDate () {
      this.dataForm.year = new Date().getFullYear() + ''
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    resetHeight () {
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      window.setTimeout(() => {
        this.tableHeight = '67vh'
      }, 1000)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // this.resetHeight()
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let list = 'shouldWageFee,jobNumber,totalDeductionFee,totalDeductionFee,actualWageFee,totalDeduction,finalWageFee'
        const values = data.map(item => {
          if (column.property && list.indexOf(column.property) !== -1) {
            return this.getValue(Number(item[column.property]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getValue (value) {
      if (value == 0) {
        return ''
      } else {
        return value
      }
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    downLoad (row) {
      this.$http({
        url: this.$http.adornUrl(`/driverWage/export`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'mainId': row.id,
          'yearMonth': row.yearMonth
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '工资表明细.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    toUpdatePage (row) {
      this.$router.push({
        name: 'wages',
        params: {
          row: row
        }
      })
    },
    // 创建工资表
    createWage () {
      this.$refs.createWage.init(this.dataForm.year)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driverWage/findMainList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'year': this.dataForm.year
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 批量发放
    batchRelease (row) {
      if (row.status === 1) {
        this.$message.error('该工资表已经全部发放了')
        return
      }
      this.$confirm('确定对【' + row.yearMonth + '】的工资表进行【全部发放】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/driverWage/updateSendByAll'),
          method: 'post',
          data: { id: row.id, yearMonth: row.yearMonth }
        }).then(({ data }) => {
          if (data) {
            this.$message.success('发放成功')
            this.getDataList()
          } else {
            this.$message.success('发放失败')
          }
        })
      }).catch(() => {})
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
  span.searchUpload {
    display: inline-block;
    margin-left: 10px;
  }
  .wages-table {
    td .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .el-input__inner {
      border-radius: 0 !important;
      border:0 !important;
      padding:0 5px !important;
      height: 24px !important;
      line-height: 24px !important
    }
  }
</style>
