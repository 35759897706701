<template>
  <div class="order-examine-popup">
    <el-dialog title="创建工资表" :close-on-click-modal="false" :visible.sync="visible" :width="'30%'" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="选择年月" prop="yearMonth" :rules="[{ required: true, message: '请选择年月', trigger: 'change' }]">
              <el-date-picker
                v-model="dataForm.yearMonth"
                align="right"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择年月">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        year: null,
        yearMonth: null
      }
    }
  },
  methods: {
    init (year) {
      this.visible = true
      this.dataForm.year = year
    },
    confirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/driverWage/createWage`),
            method: 'POST',
            data: {
              year: this.dataForm.year,
              yearMonth: this.dataForm.yearMonth
            }
          }).then(({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
