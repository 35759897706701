<template>
  <div class="incident-main">
    <div class="info">
      <el-form :inline="true" :model="dataForm" size="small" label-width="100px">
        <h3 class="title">事故信息</h3>
        <div class="row">
          <div class="col">
            <el-form-item label="事故车牌号" prop="vehiclePlate">
              <el-select class="item-choose" v-model="dataForm.vehiclePlate" :disabled="isView" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in vehicleList"
                  :key="item.vehiclePlate"
                  :label="item.vehiclePlate"
                  :value="item.vehiclePlate"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col">
<!--            <el-form-item label="事故驾驶员" prop="driverName">-->
<!--              <el-input v-model="dataForm.driverName" placeholder="事故驾驶员" :disabled="isView"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="事故驾驶员" prop="driverName">
              <el-select class="item-choose" v-model="dataForm.driverName" :disabled="isView" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in driverList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="事故时间" prop="accidentTime">
              <el-date-picker v-model="dataForm.accidentTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col col-2">
            <el-form-item label="事故地点" prop="accidentPlace">
              <el-input v-model="dataForm.accidentPlace" placeholder="事故地点" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
            <div class="col max-col">
              <el-form-item label="事故描述" prop="accidentDesc">
                <el-input v-model="dataForm.accidentDesc" placeholder="事故描述" :disabled="isView"></el-input>
              </el-form-item>
            </div>
        </div>
        <div class="col">
          <el-form-item label="事故次数" prop="accidentNumber">
            <el-input v-model="dataForm.accidentNumber" placeholder="事故次数" :disabled="isView"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <div class="col max-col">
            <el-form-item label="事故照片">
              <al-upload :fileData="dataForm.incidentInfoList" :fileUrl="$http.adornUrl('/upload/img')" :isView="isView" :format="'img'" :type="'incidentPho'" :limit="6" :businessType="9001" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </div>
        </div>
        <h3 class="title">事故处理</h3>
        <div class="row">
          <div class="col">
            <el-form-item label="责任方" prop="dutyName">
<!--              <el-input v-model="dataForm.dutyName" placeholder="责任方" :disabled="isView"></el-input>-->
              <el-select class="item-choose" v-model="dataForm.dutyName" size="mini" filterable
                         clearable placeholder="请选择" :disabled="isView">
                <el-option v-for="item in dictTypeMap.responsible_party" :key="item.id" :label="item.name"
                           :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="事故处理结果" prop="handleResult">
<!--              <el-input v-model="dataForm.handleResult" placeholder="事故处理结果" :disabled="isView"></el-input> -->
              <el-select class="item-choose" v-model="dataForm.handleResult" size="mini" filterable
                         clearable placeholder="请选择" :disabled="isView">
                <el-option v-for="item in dictTypeMap.accident_results" :key="item.id" :label="item.name"
                           :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col max-col">
            <el-form-item label="事故处理说明" prop="handleExplain">
              <el-input v-model="dataForm.handleExplain" placeholder="事故处理说明" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="处理完成时间" prop="successTime">
              <el-date-picker v-model="dataForm.successTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="处理人" prop="handleUser">
              <el-input v-model="dataForm.handleUser" placeholder="处理人" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="赔付金额(元)" prop="payMoney">
              <el-input v-model="dataForm.payMoney" placeholder="赔付金额（元）" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="损失金额" prop="lossMoney">
              <el-input v-model="dataForm.lossMoney" placeholder="损失金额" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="维修金额" prop="maintainMoney">
              <el-input v-model="dataForm.maintainMoney" placeholder="维修金额" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col  max-col">
            <el-form-item label="相关附件">
              <al-upload :fileData="dataForm.relevantInfoList" :fileUrl="$http.adornUrl('/upload/img')" :isView="isView" :format="'img'" :type="'relevant'" :limit="6" :businessType="9002" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="submit" v-if="!isView">保存</el-button>
          <el-button type="danger" @click="gotoBack">关闭</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataForm: {
        id: null,
        vehiclePlate: null,
        driverName: null,
        accidentTime: null,
        accidentPlace: null,
        accidentDesc: null,
        dutyName: null,
        handleResult: null,
        accidentNumber: null,
        handleExplain: null,
        successTime: null,
        handleUser: null,
        payMoney: null,
        lossMoney: null,
        maintainMoney: null,
        incidentInfoList: [],
        relevantInfoList: []
      },
      todo: '',
      row: {},
      vehicleList: [],
      driverList: [],
      templateDataForm: {
        templateList: [{}]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  activated () {
    this.getVehicle()
    this.getDriver()
  },
  mounted () {
    if (this.$route.params.todo) {
      this.todo = this.$route.params.todo
    }
    if (this.$route.params.row) {
      this.row = this.$route.params.row
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/vehicleAccident/getDetail/' + this.row.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let enclosureInfoList = data.enclosureInfoList
        let incidentInfoList = []
        let relevantInfoList = []
        if (enclosureInfoList) {
          for (let i = 0; i < enclosureInfoList.length; i++) {
            if (enclosureInfoList[i].businessType === 9001) {
              incidentInfoList.push(enclosureInfoList[i])
            } else {
              relevantInfoList.push(enclosureInfoList[i])
            }
          }
        }
        this.dataForm = {
          id: data.id,
          vehiclePlate: data.vehiclePlate,
          driverName: data.driverName,
          accidentTime: data.accidentTime,
          accidentPlace: data.accidentPlace,
          accidentDesc: data.accidentDesc,
          dutyName: data.dutyName,
          handleResult: data.handleResult,
          handleExplain: data.handleExplain,
          successTime: data.successTime,
          handleUser: data.handleUser,
          payMoney: data.payMoney,
          lossMoney: data.lossMoney,
          maintainMoney: data.maintainMoney,
          accidentNumber: data.accidentNumber,
          incidentInfoList: incidentInfoList,
          relevantInfoList: relevantInfoList
        }
      })
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    getDriver () {
      this.$http({
        url: this.$http.adornUrl('/driver/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let list = []
        if (data) {
          for (let i = 0; i < data.length; i++) {
            list.push(...data[i].children)
          }
        }
        this.driverList = list
      })
    },
    getFileData (data) {
      if (data.type === 'incidentPho') {
        this.dataForm.incidentInfoList = data.fileList
      } else {
        this.dataForm.relevantInfoList = data.fileList
      }
    },
    gotoBack () {
      let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
      this.$store.commit('common/updateMainTabs', val)
      this.$router.push({ name: 'certificates-IncidentManagement' })
    },
    submit () {
      let enclosureInfoList = []
      let incidentInfoList = this.dataForm.incidentInfoList
      for (let i = 0; i < incidentInfoList.length; i++) {
        enclosureInfoList.push(incidentInfoList[i])
      }
      let relevantInfoList = this.dataForm.relevantInfoList
      for (let i = 0; i < relevantInfoList.length; i++) {
        enclosureInfoList.push(relevantInfoList[i])
      }
      let formData = {
        id: this.dataForm.id,
        enclosureInfoList: enclosureInfoList,
        vehiclePlate: this.dataForm.vehiclePlate,
        driverName: this.dataForm.driverName,
        accidentTime: this.dataForm.accidentTime,
        accidentPlace: this.dataForm.accidentPlace,
        accidentDesc: this.dataForm.accidentDesc,
        dutyName: this.dataForm.dutyName,
        handleResult: this.dataForm.handleResult,
        handleExplain: this.dataForm.handleExplain,
        successTime: this.dataForm.successTime,
        handleUser: this.dataForm.handleUser,
        payMoney: this.dataForm.payMoney,
        lossMoney: this.dataForm.lossMoney,
        maintainMoney: this.dataForm.maintainMoney,
        accidentNumber: this.dataForm.accidentNumber
      }
      let methodName = formData.id ? 'update' : 'add'
      let method = formData.id ? 'post' : 'put'
      this.$http({
        url: this.$http.adornUrl(`/vehicleAccident/` + methodName),
        method: method,
        data: this.$http.adornData(formData)
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000
          })
        } else {
          this.$message.error('操作失败')
        }
        this.gotoBack()
      })
    }
  }
}
</script>

<style lang="less">
.incident-main {
  .row {
    display: flex;
    .col {
      display: inline-block;
      flex: 1;
    }
    .el-upload {
        float: left;
      }
      .el-upload-list {
        display: inline-block;
        float: left;
        width: 80%;
        .el-upload-list__item {
            height: auto !important;
            margin: 0px !important;
            margin-left: 10px !important;
            float: left;
            width: auto !important;
        }
      }
      .col-2 {
        flex: 2;
        .el-form-item {
          width: 100%
        }
        .el-form-item__content {
          width: 68%;
        }
      }
    .max-col {
      .el-form-item {
        width: 100%;
        .el-input {
          width: 100%;
        }
      }
      .el-form-item__content {
        width: 88%;
      }
    }
  }
  .el-input {
    width: 120%;
  }

  .el-select .el-input {
    width: 112%;
  }
  .el-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer {
    text-align: right;
  }
  .content {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 86%;
    }
    .el-upload.el-upload--picture-card {
      width: 70px;
      height: 70px;
      line-height: 70px;
      margin:0
    }
    .el-upload-list__item {
      width: 70px !important;
      height: 70px !important;
      margin: 0px 8px 0 0 !important;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
  }
  .el-form-item {
    margin-bottom: 8px
  }
}
</style>
