/********** JSON工具类 **************/

/**
 * 克隆对象
 * @returns {any}  新对象
 * @param data
 */
// export function getClone(v) {
//   return JSON.parse(JSON.stringify(v))
// }
export const getClone = (data) => {
  return JSON.parse(JSON.stringify(data))
}

/**
 * 解析JSON
 * @param v 传入JSON对象
 * @returns {any} 新对象
 */
// export function getParseJson(v) {
//   return JSON.parse(v)
// }

/**
 * 获取JSON字符串
 * @param v 传入的对象
 * @returns {string} JSON字符串
 */
// export function getJsonString(v){
//   return JSON.stringify(v)
// }
