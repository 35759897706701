<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="申请人姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="dataForm.contactPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="dataForm.dateRange"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button type="primary" v-if="newAuth('driver:leave:add')"
                     @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
                :data="dataList" border size="small" v-loading="dataListLoading"
                @selection-change="selectionChangeHandle" style="width: 100%;">
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <af-table-column prop="driverName" label="申请人姓名" width="90" align="center">
        </af-table-column>
        <el-table-column prop="contactPhone" label="联系电话" width="100" align="center">
        </el-table-column>
        <af-table-column prop="requestTime" label="申请时间" width="140" align="center">
        </af-table-column>
        <el-table-column prop="leaveType" width="70" :formatter="dictConvert" label="请假类型" align="center">
        </el-table-column>
        <af-table-column prop="leaveStart" label="请假开始时间" width="140" align="center">
        </af-table-column>
        <el-table-column prop="leaveEnd" label="请假截止时间" width="140" align="center">
        </el-table-column>
        <el-table-column prop="leaveDays" label="请假天数" width="60" align="center">
        </el-table-column>
<!--        <el-table-column prop="leaveDeduction" label="请假扣款" width="60" align="center">-->
<!--        </el-table-column>-->
        <af-table-column prop="leaveDesc" label="请假事由">
        </af-table-column>
        <af-table-column prop="status" label="执行状态" width="80"  align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">待生效</el-tag>
            <el-tag v-if="scope.row.status === 1" size="small" type="success">生效中</el-tag>
            <el-tag v-if="scope.row.status === 2" size="small" type="info">已结束</el-tag>
          </template>
        </af-table-column>
        <af-table-column prop="auditStatus" label="审核状态" width="80"  align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.auditStatus === 0" size="small" type="danger">待审核</el-tag>
            <el-tag v-if="scope.row.auditStatus === 1" size="small" type="success">审批通过</el-tag>
            <el-tag v-if="scope.row.auditStatus === 2" size="small" type="warning">审批不通过</el-tag>
            <el-tag v-if="scope.row.auditStatus === 3" size="small" type="info">已撤回</el-tag>
          </template>
        </af-table-column>
        <af-table-column prop="auditName" label="审核人" width="90"  align="center">
        </af-table-column>
        <af-table-column prop="auditTime" label="审核时间" width="140"  align="center">
        </af-table-column>
        <af-table-column width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
            <el-button  v-if="newAuth('driver:leave:update')" type="text" size="small"
                       @click="addOrUpdateHandle('edit', scope.row.id)">修改
            </el-button>
            <el-button  v-if="newAuth('driver:leave:audit') && scope.row.auditStatus == 0"
                        type="text" size="small"
                       @click="addOrUpdateHandle('audit', scope.row.id)">审核
            </el-button>
            <el-button
              size="small"
              type="text"
              plain
              @click="goPrint(scope.row)"
              >打印</el-button
            >
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <leave-request ref="leaveRequest" @refreshDataList="getDataList"></leave-request>
    <div style="display:none;">
      <print :itemInfo="itemRow" ref="print"></print>
    </div>

  </div>
</template>

<script>
import print from '@/components/print/index.vue'// 打印
import LeaveRequest from './details/leave-request'

export default {
  data () {
    return {
      dataForm: {
        driverName: null,
        contactPhone: null,
        nowCity: null,
        motorcadeName: null,
        status: null,
        dateRange: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      bindWxOpenIdVisible: false,
      detailVisible: false,
      idCardTypeList: [5000, 5010],
      proofTypeList: [5001],
      itemRow: {}// 每条数据信息
    }
  },
  components: {
    LeaveRequest,
    print
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    goPrint (row) {
      this.$http({
        url: this.$http.adornUrl(`/driverLeave/findPrintInfo?id=${row.id}`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({ data }) => {
        if (data) {
          this.itemRow = data
          this.$nextTick(() => {
            this.$print(this.$refs.print)
          })
        } else {
        }
      })
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    downLoad () {
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.beginTime = dateRange[0]
        timeParam.endTime = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/export/driverLeaveExport`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'driverName': this.dataForm.driverName,
          'contactPhone': this.dataForm.contactPhone,
          'beginTime': timeParam.beginTime,
          'endTime': timeParam.endTime
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '请假申请表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.beginTime = dateRange[0]
        timeParam.endTime = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl('/driverLeave/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'driverName': this.dataForm.driverName,
          'contactPhone': this.dataForm.contactPhone,
          'beginTime': timeParam.beginTime,
          'endTime': timeParam.endTime
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.leaveRequest.init('view', id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.leaveRequest.init(todo, id)
      })
    }
  }
}
</script>
