<template>
  <div class="mod-user">
    <!-- 搜索栏 -->
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="编号">
          <el-input v-model="dataForm.no" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="dataForm.name" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="dataForm.financialContactPerson" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="dataForm.financialContactPersonPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款方">
          <el-input v-model="dataForm.financialPayee" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否默认状态">
          <el-select class="item-choose" v-model="dataForm.defaultStatus" size="small" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.tmsSysAnnouncementWhetherToReleaseItOnAllPlatformsMap" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对公对私">
          <el-select class="item-choose" v-model="dataForm.financialPublicVersusPrivate" size="small" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.tmsTenantFinancialInformationMap" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button type="success" v-if="newAuth('request:tenant:financial:add')"
            @click="viewOrAddOrUpdateHandle('新增', 'add', null)">新增
          </el-button>
          <el-button @click="() => this.dataForm = {}" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格栏 -->
    <div class="tableBox">
      <el-table :data="dataList" @row-click="rowClick" :row-style="selectedHighlight" height="72vh" size="small"
        v-loading="dataListLoading" border style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="60" />
        <el-table-column prop="name" align="center" label="名称" :show-overflow-tooltip="true" />
        <el-table-column prop="no" align="center" label="编号" :show-overflow-tooltip="true" />
        <el-table-column prop="financialLogoUrl" align="center" label="LOGO章" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-image v-if="scope.row.financialLogoUrl != null" style="width: 30px; height: 30px" fit="cover" :preview-src-list="[domain + scope.row.financialLogoUrl]" :src="domain + scope.row.financialLogoUrl" />
          </template>
        </el-table-column>
        <el-table-column prop="financialChapterUrl" align="center" label="财务章" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-image v-if="scope.row.financialChapterUrl != null" style="width: 30px; height: 30px"  fit="cover" :preview-src-list="[domain + scope.row.financialChapterUrl]" :src="domain + scope.row.financialChapterUrl" />
          </template>
        </el-table-column>
        <el-table-column prop="financialPublicVersusPrivate" align="center" label="对公对私" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.financialPublicVersusPrivate == 0" size="small">对公</el-tag>
            <el-tag v-if="scope.row.financialPublicVersusPrivate == 1" size="small" type="success">对私</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="defaultStatus" align="center" label="默认">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.defaultStatus"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="'1'"
                :inactive-value="'0'"
                @change="setDefaultStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="financialContactPerson" align="center" label="联系人" :show-overflow-tooltip="true" />
        <el-table-column prop="financialContactPersonPhone" align="center" label="联系人电话" :show-overflow-tooltip="true" />
        <!--<el-table-column prop="financialContactPersonAddress" align="center" label="联系人地址" :show-overflow-tooltip="true" />-->
        <el-table-column prop="financialPayee" align="center" label="收款方" :show-overflow-tooltip="true" />
        <el-table-column prop="financialBankOfDeposit" align="center" label="开户银行" :show-overflow-tooltip="true" />
        <el-table-column prop="financialBankOfDepositAddress" align="center" label="开户银行地址" :show-overflow-tooltip="true" />
        <!--<el-table-column prop="financialBankBranch" align="center" label="开户银行支行" :show-overflow-tooltip="true" />-->
        <!--<el-table-column prop="financialOpeningBankAccount" align="center" label="开户银行账号" :show-overflow-tooltip="true" />-->
        <el-table-column prop="createTime" align="center" label="创建时间" width="120" :show-overflow-tooltip="true" />
        <el-table-column prop="updateTime" align="center" label="修改时间" width="120" :show-overflow-tooltip="true" />
        <el-table-column label="操作" align="center" fixed="right" width="120">
          <template slot-scope="scope">
            <el-link type="primary" size="small" @click="viewOrAddOrUpdateHandle('详情', 'view', scope.row)">详情
            </el-link>
            <el-link type="info" v-if="newAuth('request:tenant:financial:update')" size="small"
              @click="viewOrAddOrUpdateHandle('修改', 'edit', scope.row)">修改
            </el-link>
            <el-link type="danger" v-if="newAuth('request:tenant:financial:deleted')" size="small"
              @click="removeEnable(scope.row)">删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 视图/新增/修改 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="true" :visible.sync="viewOrAddOrUpdate.viewOrAddOrUpdateVisible"
      v-loading="viewOrAddOrUpdate.viewOrAddOrUpdateLoading" :title="viewOrAddOrUpdate.viewOrAddOrUpdateTitle">
      <el-row type="flex" class="row-bg">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <!-- LOGO -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialLogoUrl" @getFileData="(e) => getFileData(e, 'financialLogoUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="LOGO"></UploadImage>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light">
            <!-- 营业执照 -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialBusinessLicenseUrl"
                         @getFileData="(e) => getFileData(e, 'financialBusinessLicenseUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="营业执照"></UploadImage>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <!-- 公章 -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialOfficialSealUrl"
                         @getFileData="(e) => getFileData(e, 'financialOfficialSealUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="公章"></UploadImage>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <!-- 财务章 -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialChapterUrl"
                         @getFileData="(e) => getFileData(e, 'financialChapterUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="财务章"></UploadImage>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light">
            <!-- 法人章 -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialCorporateSealUrl"
                         @getFileData="(e) => getFileData(e, 'financialCorporateSealUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="法人章"></UploadImage>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <!-- 个人章 -->
            <UploadImage :fileData="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialPersonalBadgeUrl"
                         @getFileData="(e) => getFileData(e, 'financialPersonalBadgeUrl')"
                         @handlePictureCardPreview="handlePictureCardPreview" title="个人章"></UploadImage>
          </div>
        </el-col>
      </el-row>
      <p />
      <p />

      <el-form ref="formRef" v-loading="viewOrAddOrUpdate.viewOrAddOrUpdateFormLoading"
        :model="viewOrAddOrUpdate.viewOrAddOrUpdateForm" :rules="viewOrAddOrUpdate.viewOrAddOrUpdateFormRules"
        label-width="100px" size="mini" label-position="left">
        <el-form-item label="名称" prop="name">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="no">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.no" placeholder="请输入编号" @input="handleInput"></el-input>
        </el-form-item>
        <el-form-item label="是否默认" prop="defaultStatus" v-if="viewOrAddOrUpdate.viewOrAddOrUpdateType != 'edit'">
          <el-radio-group v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.defaultStatus">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开户类型" prop="financialPublicVersusPrivate">
          <el-radio-group v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialPublicVersusPrivate">
            <el-radio label="0">对公</el-radio>
            <el-radio label="1">对私</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联 系 人" prop="financialContactPerson">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialContactPerson" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="financialContactPersonPhone">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialContactPersonPhone" placeholder="请输入联系人电话"></el-input>
        </el-form-item>
        <!--<el-form-item label="联系地址" prop="financialContactPersonAddress">-->
        <!--  <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialContactPersonAddress" placeholder="请输入联系地址"></el-input>-->
        <!--</el-form-item>-->
        <el-form-item label="收  款  方" prop="financialPayee">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialPayee" placeholder="请输入收款方"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="financialBankOfDeposit">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialBankOfDeposit" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <!--<el-form-item label="开户行支行" prop="financialBankBranch">-->
        <!--  <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialBankBranch" placeholder="请输入开户行支行"></el-input>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="开户行地址" prop="financialBankOfDepositAddress">-->
        <!--  <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialBankOfDepositAddress" placeholder="请输入开户行地址"></el-input>-->
        <!--</el-form-item>-->
        <el-form-item label="开户账号" prop="financialOpeningBankAccount">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialOpeningBankAccount" placeholder="请输入开户账号"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="financialRemark">
          <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.financialRemark" type="textarea" placeholder="请输入备注信息"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="viewOrAddOrUpdate.viewOrAddOrUpdateVisible = false">取消</el-button>
        <el-button size="mini" type="primary" v-if="!viewOrAddOrUpdate.viewOrAddOrUpdateCheck"
          @click="submitDetailForm">确认</el-button>
      </div>
      <big-image ref="bigImg"></big-image>
    </el-dialog>
  </div>
</template>

<script>
import UploadImage from '@/components/uploadImage'
import bigImage from '@/views/commonPop/bigImage'
export default {
  data() {
    return {
      // 域名
      domain: '',
      // 搜索信息
      dataForm: {},
      // 表格
      dataList: [],
      getIndex: -1,
      dataListLoading: false,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      // 视图/新增/修改
      viewOrAddOrUpdate: {
        // 弹窗
        viewOrAddOrUpdateVisible: false,
        viewOrAddOrUpdateLoading: false,
        viewOrAddOrUpdateTitle: '',
        viewOrAddOrUpdateType: '',
        viewOrAddOrUpdateCheck: false,
        // 表单
        viewOrAddOrUpdateForm: {},
        viewOrAddOrUpdateFormLoading: false,
        viewOrAddOrUpdateFormRules: ({
          name: [{ required: true, message: '请输入名称', trigger: 'change' }],
          no: [{ required: true, message: '请输入编号', trigger: 'change' }],
          defaultStatus: [{ required: true, message: '请选择默认状态', trigger: 'change' }]
        })
      },
    }
  },
  components: {
    UploadImage,
    bigImage
  },
  activated() {
    this.$http({
      url: this.$http.adornUrl('/sys/appConfig/get'),
      method: 'get',
      params: this.$http.adornParams({ type: 2 })
    }).then(({ data }) => {
      this.domain = JSON.parse(data.configValue).spaceDomain
    })

    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色
     * @param row 当前行数据
     * @param rowIndex 当前行号
     * @returns {{"background-color": string}}
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick(row) {
      this.getIndex = row.index
    },

    /**
     * 每页大小
     * @param val 当前选中页大小
     */
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    /**
     * 当前页
     * @param val 当前选中的页数
     */
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataForm.page = this.pageIndex
      this.dataForm.pageSize = this.pageSize

      // 执行
      this.$http({
        url: this.$http.adornUrl('/tenant/financial/page'),
        method: 'get',
        params: this.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
      }).finally(() => {
        this.dataListLoading = false
      })
    },

    // 视图/新增/修改
    viewOrAddOrUpdateHandle(title, type, row) {
      // 重置
      this.viewOrAddOrUpdate = this.$options.data().viewOrAddOrUpdate

      // 执行
      this.viewOrAddOrUpdate.viewOrAddOrUpdateVisible = true
      this.viewOrAddOrUpdate.viewOrAddOrUpdateTitle = title
      this.viewOrAddOrUpdate.viewOrAddOrUpdateType = type
      this.viewOrAddOrUpdate.viewOrAddOrUpdateCheck = type === 'view'
      if (type === 'add') {
        this.viewOrAddOrUpdate.viewOrAddOrUpdateForm = this.$options.data().viewOrAddOrUpdate.viewOrAddOrUpdateForm
      } else {
        let rowCopy = JSON.parse(JSON.stringify(row))
        this.viewOrAddOrUpdate.viewOrAddOrUpdateForm = rowCopy
      }
    },

    // 提交
    submitDetailForm() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          this.viewOrAddOrUpdate.viewOrAddOrUpdateLoading = true
          let formCopy = JSON.parse(JSON.stringify(this.viewOrAddOrUpdate.viewOrAddOrUpdateForm))
          this.$http({
            url: this.$http.adornUrl(this.viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' ? `/tenant/financial/update` : `/tenant/financial/add`),
            method: this.viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' ? 'PUT' : 'POST',
            data: this.$http.adornData(formCopy)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.viewOrAddOrUpdate.viewOrAddOrUpdateVisible = false
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          }).finally(() => {
            this.viewOrAddOrUpdate.viewOrAddOrUpdateLoading = false
          })
        }
      })
    },

    // 删除
    removeEnable(row) {
      let title = '删除'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/tenant/financial/' + row.id),
          method: 'DELETE'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },

    // 设置默认值
    setDefaultStatus (row) {
      let title = row.defaultStatus == 0 ? '否' : '设置'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/tenant/financial/status'),
          method: 'GET',
          params: {
            id: row.id,
            status: row.defaultStatus == 0 ? 0 : 1
          }
        }).then(({ data }) => {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
          })
        })
      }).catch(() => {})
          .finally(() => {
            setTimeout(() => {
              this.getDataList()
            },1000)

          })
    },

    handlePictureCardPreview(data) {
      this.$refs.bigImg.init(data.url)
    },

    // 获取附件信息
    getFileData(data, key) {
      this.viewOrAddOrUpdate.viewOrAddOrUpdateForm[key] = data.fileList
      this.$forceUpdate()
    },

    handleInput(value) {
      // 检查是否包含中文字符
      if (/[\u4e00-\u9fa5]/.test(value)) {
        // 如果包含，则将输入框的值重置为之前的值
        this.viewOrAddOrUpdate.viewOrAddOrUpdateForm.no = this.viewOrAddOrUpdate.viewOrAddOrUpdateForm.no.replace(/[\u4e00-\u9fa5]/g, '')
      }
    },
  }
}
</script>
