<template>
  <div>
    <el-dialog title="消息通知" :close-on-click-modal="false" width="58%" :visible.sync="visible" size="mini"
      :append-to-body='true' class="memorandum">
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="待办事项" name="dbsx">
          <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="未处理" name="未处理">
              <untreated ref="untreated"></untreated>
            </el-tab-pane>
            <el-tab-pane label="已处理" name="已处理">
              <processed ref="processed"></processed>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="公告" name="zhgg">
          <announs ref="announs" />
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" type="danger">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import processed from './notesDetail/processed'
import untreated from './notesDetail/untreated'
import announs from './notesDetail/announs'
export default {
  data () {
    return {
      visible: false,
      activeName: '未处理',
      activeTab: 'dbsx'
    }
  },
  components: {
    processed,
    untreated,
    announs
  },
  methods: {
    close () {
      this.visible = false
    },
    init () {
      let that = this
      this.visible = true
      this.activeName = '未处理'
      //setTimeout(function () {
      //  that.$refs.untreated.getDataList()
      //})
    },
    handleClick () {
      if (this.activeTab === 'dbsx') {
        if (this.activeName === '未处理') {
          this.$refs.untreated.getDataList()
        } else {
          this.$refs.processed.getDataList()
        }
      } else {
        this.$refs.announs.getDataList()
      }
    }
  }
}
</script>
<style lang="less"></style>
