<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="申请类型">
          <el-select class="item-choose"
                     v-model="dataForm.requestType"
                     size="small"
                     filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.driver_repair"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请人">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="维修项目">
          <el-input v-model="dataForm.repairName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="维保状态">
          <el-select class="item-choose"
                     v-model="dataForm.repairStatus"
                     size="small"
                     filterable clearable placeholder="请选择">
            <el-option label="待审核" value="0"></el-option>
            <el-option label="已审核" value="1"></el-option>
            <el-option label="已完成" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维修供应商">
          <el-select class="item-choose"
                     v-model="dataForm.repairItem"
                     size="small"
                     filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.repair_factory"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            v-model="dataForm.requestDate"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="维修完成时间">
          <el-date-picker
            v-model="dataForm.successTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>

        </el-form-item>
        <el-form-item>
          <el-button @click="addOrUpdateHandle('add')" type="primary">新增</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh"  @row-click="rowClick"
                :summary-method="getSummaries"
                show-summary
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column prop="requestType" label="申请类型" width="60" align="center">
        </el-table-column>
        <el-table-column prop="requestDate" label="申请日期" width="130" align="center">
        </el-table-column>
        <el-table-column prop="driverName" label="申请人" width="70" align="center">
        </el-table-column>
        <el-table-column prop="vehiclePlate" label="车牌号" width="80" align="center">
        </el-table-column>
        <el-table-column prop="travelMileage" label="行驶里程" width="70" align="center">
        </el-table-column>
<!--        <el-table-column prop="repairName" label="申请项目" width="80" align="center">-->
<!--        </el-table-column>-->
        <el-table-column prop="repairDesc" label="申请说明" min-width="100" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="维修前照片" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, repairBeforeTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="analysis" label="督导意见" min-width="70" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="repairItem" label="维修供应商" min-width="90" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="cashPayment" label="现金支付" width="70" align="center">
        </el-table-column>
        <el-table-column label="现金票据" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, cashPaymentTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="repairCost" label="月结支付" width="70" align="center">
        </el-table-column>
        <el-table-column label="月结票据" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, repairCostTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="repairRemark" label="维保说明" min-width="90" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="维修后照片" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, repairAfterTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="successTime" label="维修完成时间" width="130" align="center">
        </el-table-column>
        <el-table-column label="费用小计" prop="payableAmount" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createName" label="创建人" width="60" align="center">
        </el-table-column>
        <el-table-column prop="writeName" label="核销人" width="60" align="center">
        </el-table-column>
        <el-table-column prop="writeDate" label="核销时间" width="70" align="center">
        </el-table-column>
        <el-table-column prop="repairStatus" label="维保状态" width="60" fixed="right" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.repairStatus === 0" size="small" type="danger">待审核</el-tag>
            <el-tag v-if="scope.row.repairStatus === 1" size="small" type="success">已审核</el-tag>
            <el-tag v-if="scope.row.repairStatus === 2" size="small" type="info">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="付款状态" prop="writeStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.writeStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('driver:repair:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
              <el-button v-if="newAuth('driver:repair:update')" type="text" @click="addOrUpdateHandle('update',scope.row.id)" size="small">修改</el-button>
              <el-button v-if="newAuth('driver:repair:delete')" type="text" @click="deleteInfo(scope.row.id)" size="small">删除</el-button>
              <el-button type="text" size="small"
                         v-if="newAuth('driver:repair:audit') && scope.row.repairStatus === 0"
                         @click="addOrUpdateHandle('audit', scope.row.id)">审核
              </el-button>
              <el-button type="text" size="small"
                         v-if="newAuth('driver:repair:audit') && scope.row.repairStatus === 1"
                         @click="addOrUpdateHandle('back', scope.row.id)">反审核
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <repair-request ref="repairRequest" @refreshDataList="getDataList"></repair-request>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>

    <!--审核弹窗-->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
  </div>
</template>

<script>
import RepairRequest from './details/repair-request'
import viewImage from '@/views/commonPop/viewImage'
import writeOffPopup from './details/write-off-popup'

export default {
  data () {
    return {
      dataForm: {
        requestType: null,
        vehiclePlate: null,
        repairName: null,
        repairStatus: null,
        writeStatus: null,
        repairItem: null,
        successTime: null,
        requestDate: null,
        driverName: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      repairBeforeTypeList: [5007],
      repairAfterTypeList: [5008],
      cashPaymentTypeList: [7009],
      repairCostTypeList: [7010],
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      selectFeeData: []
    }
  },
  components: {
    RepairRequest,
    viewImage,
    writeOffPopup
  },
  created () {
    this.setDefaultDate()
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.requestDate = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(this.dataForm.requestType, writeStatus, ids)
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/driverRepair/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     *自定义合计
     */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          if (column.property && (column.property.indexOf('cashPayment') > -1)) {
            return this.getValue(item[column.property])
          }
          // 维保金额
          if (column.property && (column.property.indexOf('repairCost') > -1)) {
            return this.getValue(item[column.property])
          }
          if (column.property && (column.property.indexOf('payableAmount') > -1)) {
            return this.getValue(item[column.property])
          }
          if (column.property && (column.property.indexOf('paidAmount') > -1)) {
            return this.getValue(item[column.property])
          }
          if (column.property && (column.property.indexOf('notPayableAmount') > -1)) {
            return this.getValue(item[column.property])
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (row) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '', row.cashPayment, '',
        row.repairCost, '',
        '', '', '', row.payableAmount, row.paidAmount, row.notPayableAmount, '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        td.align = 'center'
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    downLoad () {
      let successTime = this.dataForm.successTime
      let timeParam = {}
      if (successTime && successTime.length === 2) {
        timeParam.refuelBeginDate = successTime[0]
        timeParam.refuelEndDate = successTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/export/driverRepairExport`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'requestType': this.dataForm.requestType,
          'driverName': this.dataForm.driverName, // 申请人
          'vehiclePlate': this.dataForm.vehiclePlate,
          'repairName': this.dataForm.repairName,
          'repairStatus': this.dataForm.repairStatus,
          'writeStatus': this.dataForm.writeStatus,
          'repairItem': this.dataForm.repairItem,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '维修登记表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let successTime = this.dataForm.successTime
      let timeParam = {}
      if (successTime && successTime.length === 2) {
        timeParam.refuelBeginDate = successTime[0]
        timeParam.refuelEndDate = successTime[1]
      }
      // 申请日期
      let requestDate = this.dataForm.requestDate
      let timeParam1 = {}
      if (requestDate && requestDate.length === 2) {
        timeParam1.requestBeginDate = requestDate[0]
        timeParam1.requestEndDate = requestDate[1]
      }
      this.$http({
        url: this.$http.adornUrl('/driverRepair/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'requestType': this.dataForm.requestType,
          'driverName': this.dataForm.driverName, // 申请人
          'vehiclePlate': this.dataForm.vehiclePlate,
          'repairName': this.dataForm.repairName,
          'repairStatus': this.dataForm.repairStatus,
          'writeStatus': this.dataForm.writeStatus,
          'repairItem': this.dataForm.repairItem,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate,
          'requestBeginDate': timeParam1.requestBeginDate,
          'requestEndDate': timeParam1.requestEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.totalRow)
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
      this.selectFeeData = val
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.repairRequest.init(todo, id)
      })
    },
    deleteInfo (id) {
      this.$confirm('删除后无法恢复, 确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/driverRepair/delete/` + id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
