<template>
  <el-dialog
    :title="'【查看】公司信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="120px" size="small">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="公司编号" prop="companyNo">
            <el-input v-model="dataForm.companyNo" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业信用代码" prop="creditCode">
            <el-input v-model="dataForm.creditCode" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="公司全称" prop="fullName">
            <el-input v-model="dataForm.fullName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司简称" prop="simpleName">
            <el-input v-model="dataForm.simpleName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="联系人名称" prop="contactName">
            <el-input v-model="dataForm.contactName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人电话" prop="contactPhone">
            <el-input v-model="dataForm.contactPhone" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="18">
          <el-form-item label="营业执照">
            <el-upload
              class="upload-demo"
              :action="''"
              :file-list="licenseList"
              :before-remove="removeFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="联系地址" prop="address">
            <el-input v-model="dataForm.address" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="3" v-model="dataForm.remark" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      licenseList: [],
      dataForm: {
        id: null,
        companyNo: null,
        creditCode: null,
        fullName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        remark: null
      }
    }
  },
  methods: {
    // 文件反显
    initFile (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.licenseList.push({ id: infoList[i].id, name: infoList[i].name, url: infoList[i].url })
        }
      }
    },
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.licenseList = []
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      this.$http({
        url: this.$http.adornUrl(`/company/${this.dataForm.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataForm.companyNo = data.companyNo
          this.dataForm.creditCode = data.creditCode
          this.dataForm.fullName = data.fullName
          this.dataForm.simpleName = data.simpleName
          this.dataForm.address = data.address
          this.dataForm.contactName = data.contactName
          this.dataForm.contactPhone = data.contactPhone
          this.dataForm.remark = data.remark
          this.initFile(data)
        }
      })
    },
    removeFileList () {
      return false
    }
  }
}
</script>
