/*
/ 全局静态变量定义
切勿随意修改数组次序，很多地方已下标方式使用
 */

// 公告状态
export const announsStatus = {
  10: '待发布',
  20: '发布中',
  30: '发布结束',
  40: '发布中止'
}

// 租户类型
export const tenantsType = [
  { label: '组织', value: '0' },
  { label: '代理', value: '1' },
  { label: '个体', value: '2' }
]

// 操作日志 操作方式
export const requestType = [
  { label: '添加', value: '1' },
  { label: '修改', value: '2' },
  { label: '删除', value: '3' }
]
