<template>
  <div class="charge-cost-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】应收费用池信息' : '【编辑】应收费用池信息'"
      :close-on-click-modal="false"
      width="60%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
        <label>（装货图片<span @click="previewByOrderId(loadGoodsTypeList)" style="color: blue;cursor:pointer">预览 </span></label>
        <label>签收单<span @click="previewByOrderId(successTypeList)" style="color: blue;cursor:pointer">预览</span>）</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderInfo.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型">
                <el-select class="item-choose" v-model="dataForm.orderInfo.orderType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车长(米)">
                <el-select class="item-choose" v-model="dataForm.orderInfo.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in vehicleTypeList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车型">
                <el-select class="item-choose" v-model="dataForm.orderInfo.vehicleModelIdList" multiple :disabled="true" style="width: 100%;" collapse-tags>
                  <el-option
                    v-for="item in dictTypeMap.vehicle_model"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-input v-model="dataForm.orderInfo.status" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.orderInfo.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input v-model="dataForm.orderInfo.customerName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="chargeType">
              <el-form-item label="客户业务员">
                <el-input v-model="dataForm.orderInfo.customerBusinessName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.orderInfo.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-for="(item) in dataForm.orderInfo.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号" prop="customerId">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="件数">
                 <el-input v-model="dataForm.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                 <el-input v-model="dataForm.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                 <el-input v-model="dataForm.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="6">
                <el-form-item label="客户单号">
                  <el-input v-model="dataForm.orderInfo.customerSelfNo" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="客户联系人">
                <el-input v-model="dataForm.orderInfo.customerContactName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户联系电话">
                <el-input v-model="dataForm.orderInfo.customerContactPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单时间">
                <el-input v-model="dataForm.orderInfo.commitTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单人">
                <el-input v-model="dataForm.orderInfo.commitName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="订单备注">
                <el-input v-model="dataForm.orderInfo.remark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.orderInfo.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="客服备注">
                <el-input v-model="dataForm.orderInfo.companyRemark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-steps :active="statusActive" style="margin-left: 20px;" finish-status="success">
                <el-step title="派车时间" :description="dispatchTime"></el-step>
                <el-step title="出车时间" :description="outDriveTime"></el-step>
                <el-step title="装货到场时间" :description="arrivedTime"></el-step>
                <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
                <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
                <el-step title="签收时间" :description="successTime"></el-step>
              </el-steps>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>客户费用（应收）</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" :rules="rules" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="金额小计" prop="totalMoney">
                <el-input v-model="dataForm.totalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="dataForm.freightFee" @input="countMoney()" :disabled="isViews" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="dataForm.overtimeFee" @input="countMoney()" :disabled="isViews" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="dataForm.addFee" @input="countMoney()" :disabled="isViews" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="dataForm.weighFee" @input="countMoney()" :disabled="isViews" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="dataForm.loadFee" @input="countMoney()" :disabled="isViews" placeholder="装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="dataForm.entranceFee" @input="countMoney()" :disabled="isViews" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货费" prop="takeFee">
                <el-input v-model="dataForm.takeFee" @input="countMoney()" :disabled="isViews" placeholder="提货费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="dataForm.parkingFee" @input="countMoney()" :disabled="isViews" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="dataForm.highFee" @input="countMoney()" :disabled="isViews" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="dataForm.otherFee" @input="countMoney()" :disabled="isViews" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="dataForm.otherFeeRemark" :disabled="isViews" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="预收费用" prop="receivedFee">
                <el-input v-model="dataForm.receivedFee" placeholder="预收费用"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="预收费用备注" prop="receivedRemark">
                <el-input v-model="dataForm.receivedRemark" :disabled="isViews" placeholder="预收费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="1"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage-fy'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {},
        pieceNum: null,
        weight: null,
        volume: null,
        vehiclePlan: {}
      },
      areaList: [],
      imgTypeList: {
        entranceFee: [7050], // 报关/入仓
        highFee: [7051], // 高速费
        weighFee: [7052], // 过磅费
        loadFee: [7053], // 装卸费
        overtimeFee: [7054], // 押夜超时费
        takeFee: [7055], // 提货费
        addFee: [7056], // 加点费
        parkingFee: [7008],
        otherFee: [7057]// 其他费用
      },
      costInfoData: {},
      chargeType: true,
      todo: 'view',
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        entranceFee: this._Valid.canNullinit(['nullOrPrice']),
        highFee: this._Valid.canNullinit(['nullOrPrice']),
        weighFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        takeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice']),
        receivedFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      successTypeList: [6010],
      loadGoodsTypeList: [6011],
      writeStatus: 2,
      vehicleTypeList: [],
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view' || this.writeStatus === 1
    }
  },
  components: {
    viewImage
  },
  methods: {
    clearOldValue () {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
      this.wageBase = null
    },
    init (type, todo, row) {
      this.visible = true
      this.todo = todo || 'view'
      this.dataForm = row
      this.writeStatus = row.costInfo.writeStatus
      if (!this.dataForm.orderInfo.orderGoods) {
        this.dataForm.orderInfo.orderGoods = {}
      }
      this.costInfoData = JSON.parse(JSON.stringify(row))
      this.$http({
        url: this.$http.adornUrl(`/costInfo/orderCost/${row.costInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.dataForm.pieceNum = data.orderInfo.orderGoods.pieceNum
        this.dataForm.weight = data.orderInfo.orderGoods.weight
        this.dataForm.volume = data.orderInfo.orderGoods.volume
        this.dataForm.orderInfo.takeGoodsDate = data.orderInfo.takeGoodsDate + ' ' + data.orderInfo.takeGoodsTime
        let recordList = data.orderInfo.recordList
        this.dispatchTime = data.orderInfo.dispatchTime
        if (recordList) {
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            if (record.status === 5) {
              this.outDriveTime = record.operationTime
              this.statusActive = 2
            } else if (record.status === 6) {
              this.arrivedTime = record.operationTime
              this.statusActive = 3
            } else if (record.status === 7) {
              this.loadGoodsTime = record.operationTime
              this.statusActive = 4
            } else if (record.status === 15) {
              this.arrivedGiveTime = record.operationTime
              this.statusActive = 5
            }
          }
        }
        if (data.orderInfo.status === 8) {
          this.successTime = data.orderInfo.successTime
          this.statusActive = 6
        }
        this.dataForm.orderInfo.status = this.dictTypeMap.orderStatusMap[data.orderInfo.status].name
      })

      // 获取车型信息
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
      })
    },
    // 图片预览
    preview (typeList) {
      this.$refs.viewPhoto.init(this.costInfoData.costInfo.id, typeList)
    },
    previewByOrderId (typeList) {
      this.$refs.viewPhoto.init(this.dataForm.orderInfo.id, typeList)
    },
    getDefaultValue (value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    countMoney () {
      this.dataForm.totalMoney = this.getDefaultValue(this.dataForm.freightFee, 0) +
        this.getDefaultValue(this.dataForm.entranceFee, 0) +
        this.getDefaultValue(this.dataForm.highFee, 0) +
        this.getDefaultValue(this.dataForm.weighFee, 0) +
        this.getDefaultValue(this.dataForm.loadFee, 0) +
        this.getDefaultValue(this.dataForm.overtimeFee, 0) +
        this.getDefaultValue(this.dataForm.takeFee, 0) +
        this.getDefaultValue(this.dataForm.addFee, 0) +
        this.getDefaultValue(this.dataForm.parkingFee, 0) +
        this.getDefaultValue(this.dataForm.otherFee, 0)
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/costInfo/update/orderCost`),
            method: 'POST',
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'costType': 1,
              'status': this.dataForm.status,
              'freightFee': this.dataForm.freightFee,
              'entranceFee': this.dataForm.entranceFee,
              'highFee': this.dataForm.highFee,
              'weighFee': this.dataForm.weighFee,
              'loadFee': this.dataForm.loadFee,
              'overtimeFee': this.dataForm.overtimeFee,
              'takeFee': this.dataForm.takeFee,
              'parkingFee': this.dataForm.parkingFee,
              'addFee': this.dataForm.addFee,
              'otherFee': this.dataForm.otherFee,
              'otherFeeRemark': this.dataForm.otherFeeRemark,
              'receivedFee': this.dataForm.receivedFee,
              'receivedRemark': this.dataForm.receivedRemark,
              'customerId': this.dataForm.orderInfo.customerId
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="less">
  .charge-cost-pop {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-dialog {
      // margin-top: 20vh !important;
      .el-input__inner {
        height: 24px !important;
        font-size: 12px; /* 输入框的字号 */
      }
      .el-dialog__header {
        padding: 0px 0px 0px;
      }
      .el-form-item__label {
        font-size: 12px; /* label 的字号 */
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 650px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 0px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
    .modularBox {
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      margin-left: 20px;
      margin-right: 20px;
      .el-col {
        padding-right: 0 !important;
      }
    }
    .modularBox1 {
      padding-right: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .modularBox2 {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 40px;
      margin-bottom: 2px;
      .addressBtn {
        width: 100%;
        padding: 9px 0px;
        text-align: center
      }
      .deleteBtn {
        width: 100%;
        height: 40px;
        padding-top: 5px;
        text-align: right;
      }
    }
    .modularBox3 {
      padding: 25px;
      .carBox {
        width: 320px;
        margin-right: 20px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        text-align: center;
        cursor: pointer;
      }
    }
    .modularBox4 {
      padding: 25px;
    }
    .modularLabel {
      margin-bottom: 0px;
      //padding-left: 20px;
      margin-top: 0px;
      span.separate {
        display: inline-block;
        width: 5px;
        margin-right: 10px;
        background: #17B3A3;
        color: transparent;
        height: 20px;
        line-height: 20px;
      }
      label {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .modularLabel2 {
      margin-top: 0px;
      line-height: 30px;
      height: 30px;
      label {
        min-width: 120px;
        float: left;
      }
    }
    .footer {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      text-align: right;
      padding: 25px;
      .explain {
        color: #17B3A3 !important
      }
    }
    .common-pop {
      .el-table__header-wrapper {
        font-size: 8px;
      }
      .el-table__body-wrapper {
        font-size: 8px;
      }
      .cell {
        padding: 0 !important;
      }
      .el-dialog {
        margin-top: 0px !important;
        margin: 0 auto;
        top: 50%;
        transform: translate(-0, -50%);
        .el-dialog__header {
          padding: 10px;
        }
        .el-dialog__body {
          padding: 10px;
        }
        .el-dialog__footer {
          padding: 0px;
          .el-button {
            height: 20px;
            line-height: 0;
          }
        }
      }
    }
    .el-step__title {
      font-size: 14px; /* 调整为你希望的字体大小 */
      line-height: 18px;
    }
  }
  </style>
