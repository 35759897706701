import { render, staticRenderFns } from "./announ-popup.vue?vue&type=template&id=ead344c0&scoped=true"
import script from "./announ-popup.vue?vue&type=script&lang=js"
export * from "./announ-popup.vue?vue&type=script&lang=js"
import style0 from "./announ-popup.vue?vue&type=style&index=0&id=ead344c0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ead344c0",
  null
  
)

export default component.exports