<template>
  <el-dialog
    :title="'【详情】驾驶员信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" size="small" label-width="120px">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="驾驶员姓名" prop="name">
            <el-input v-model="dataForm.name" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号码" prop="cardNo">
            <el-input v-model="dataForm.cardNo" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="所属车队" prop="motorcadeName">
            <el-input v-model="dataForm.motorcadeName" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="籍贯" prop="nativeCity">
            <el-input v-model="dataForm.nativeCity" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="dataForm.contactPhone" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="紧急联系电话" prop="urgentPhone">
            <el-input v-model="dataForm.urgentPhone" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="驾驶证类型" prop="proofType">
            <el-input v-model="dataForm.proofType" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="驾驶证编号" prop="proofNo">
            <el-input v-model="dataForm.proofNo" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="身份证照片" prop="cardFileList">
            <el-upload  style="margin-top: -30px;"
              action="/upload/img"
              :on-preview="handlePreview"
              :before-remove="removeFileList"
              :file-list="cardFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="驾驶证照片" prop="proofFileList">
            <el-upload  style="margin-top: -30px;"
              class="upload-demo"
              action="/upload/img"
              :on-preview="handlePreview"
              :before-remove="removeFileList"
              :file-list="proofFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="居住城市" prop="dwellCity">
            <el-input v-model="dataForm.dwellCity" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="熟悉行政区" prop="familiarArea">
            <el-input v-model="dataForm.familiarArea" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="5" v-model="dataForm.remark" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        motorcadeName: null,
        name: null,
        cardNo: null,
        motorcadeId: null,
        nativeCity: null,
        contactPhone: null,
        urgentPhone: null,
        proofType: null,
        proofNo: null,
        dwellCity: null,
        familiarArea: null,
        address: null,
        remark: null,
        cardFileList: [],
        proofFileList: []
      },
      dialogImageUrl: '',
      dialogVisible: false,
      cardFileList: [],
      proofFileList: [],
      domain: ''
    }
  },
  created() {
    let that = this
    that.$http({
      url: that.$http.adornUrl('/sys/appConfig/get'),
      method: 'get',
      params: that.$http.adornParams({ type: 2 })
    }).then(({ data }) => {
      this.domain = JSON.parse(data.configValue).spaceDomain
    })
  },
  computed: {
    dictInfoMap: {
      get () { return this.$store.state.common.dictInfoMap }
    }
  },
  methods: {
    clearFileList () {
      this.cardFileList = []
      this.proofFileList = []
      this.dataForm.cardFileList = []
      this.dataForm.proofFileList = []
    },
    initFileList (dto) {
      if (dto.cardFileList) {
        this.dataForm.cardFileList = dto.cardFileList
        for (let i = 0; i < dto.cardFileList.length; i++) {
          this.cardFileList.push({ name: dto.cardFileList[i].name, url: this.domain + dto.cardFileList[i].url })
        }
      }
      if (dto.proofFileList) {
        this.dataForm.proofFileList = dto.proofFileList
        for (let i = 0; i < dto.proofFileList.length; i++) {
          this.proofFileList.push({ name: dto.proofFileList[i].name, url: this.domain + dto.proofFileList[i].url })
        }
      }
    },
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.clearFileList()
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/driver/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.name = data.name
            this.dataForm.cardNo = data.cardNo
            this.dataForm.motorcadeName = data.motorcadeName
            this.dataForm.motorcadeId = data.motorcadeId
            this.dataForm.nativeCity = data.nativeCity
            this.dataForm.contactPhone = data.contactPhone
            this.dataForm.urgentPhone = data.urgentPhone
            this.dataForm.proofType = this.dictInfoMap[data.proofType]
            this.dataForm.proofNo = data.proofNo
            this.dataForm.dwellCity = data.dwellCity
            this.dataForm.familiarArea = data.familiarArea
            this.dataForm.address = data.address
            this.dataForm.remark = data.remark
            this.dataForm.status = data.status
            this.initFileList(data)
          }
        })
      }
    },
    // 图片查看
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    removeFileList () {
      return false
    }
  }
}
</script>
