<template>
  <div class="untrated tableBox">
    <el-table height="55vh" :row-class-name="tableRowClassName" :append-to-body='true'
      @row-click="rowClick" :row-style="selectedHighlight" :data="dataList" border size="small"
      v-loading="dataListLoading" style="width: 100%;">
      <el-table-column type="index" label="序号"/>
      <el-table-column prop="title" label="标题" min-width="250"/>
      <el-table-column prop="createName" label="发布人" />
      <el-table-column prop="tenantName" label="发布平台" />
      <!--<el-table-column prop="content" label="内容" :show-overflow-tooltip="true" />-->
      <el-table-column prop="publishTime" label="发布时间" :show-overflow-tooltip="true" />
      <el-table-column label="操作" width="60">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="seeDetails(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNum"
      :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- <el-dialog :title="dialo.title" :visible.sync="drawer" :append-to-body="false">
      <div v-html="dialo.content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="drawer = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="drawer = false" size="mini">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-drawer :title="dialo.title" :visible.sync="drawer" direction="rtl" :append-to-body="true">
      <div class="drawer_wrap">
        <div v-html="dialo.content"></div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: true,
      dataList: [],
      pageNum: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      drawer: false,
      dialo: {
        title: '',
        content: ''
      }
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
      this.seeDetails(row)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageNum = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageNum = val
      this.getDataList()
    },
    // 获取数据列表
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/announcement/getShowAnnouncement'),
        method: 'get',
        params: this.$http.adornParams({
          status: 20,
          'page': this.pageNum,
          'limit': this.pageSize
        })
      }).then(({ data }) => {
        if (data.total) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 1
        }
      })
    },
    // 查看公告详情
    seeDetails (row) {
      this.dialo = {
        title: row.title,
        content: row.content
      }
      this.drawer = true
    }
  }
}
</script>
<style lang="less" scoped>
.drawer_wrap{
  padding: 10px;
}
</style>
