<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="用户名">
          <el-input v-model="dataForm.account" placeholder="模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="dataForm.realName" placeholder="模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select class="item-choose"
                     v-model="dataForm.status"
                     style="width: 100%;"
                     filterable
                     clearable placeholder="请选择">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" :row-class-name="tableRowClassName" @row-click="rowClick"
                :row-style="selectedHighlight" height="72vh" border size="small" v-loading="dataListLoading"
                style="width: 100%;">
        <af-table-column v-for="(row, index) in tableRow" :width="row.width ? row.width : ''" :key="index"
                         :label="row.label" :prop="row.prop" align="center">
          <template slot-scope="scope">
            <span v-if="row.prop == 'userType'">
              <el-tag v-if="scope.row.userType === 2" size="small" type="danger">注册用户</el-tag>
              <el-tag v-else size="small">系统用户</el-tag>
            </span>
            <span v-else>
                {{ scope.row[row.prop] }}
            </span>
          </template>
        </af-table-column>
        <af-table-column prop="createTime" align="center" width="150" label="创建时间">
        </af-table-column>
        <af-table-column prop="status" width="60" fixed="right" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-button v-if="newAuth('sys:user:list')" type="text" size="small"
                       @click="addOrUpdateHandle('view', scope.row.id)">详情
            </el-button>
            <el-button v-if="newAuth('sys:user:update')" type="text" size="small"
                       @click="addOrUpdateHandle('update', scope.row.id)">修改
            </el-button>
            <el-button v-if="newAuth('sys:user:delete') && scope.row.id != 'admin'" type="text" size="small"
                       @click="disableOrEnable(scope.row)">{{ scope.row.status === 1 ? '禁用' : '启用' }}
            </el-button>
            <!--          <el-button v-if="isAuth('sys:user:delete') && scope.row.status === 2" type="text" size="small" @click="disableOrEnable(scope.row)">启用</el-button>-->
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <!-- 详情 -->
    <detail v-if="detailVisible" ref="detail"></detail>
  </div>
</template>

<script>
import AddOrUpdate from './details/user-add-or-update'
import detail from './detail'

export default {
  data () {
    return {
      dataForm: {
        account: null,
        realName: null,
        status: null
      },
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      tableRow: [{
        label: '用户名',
        prop: 'account'
      }, {
        label: '真实姓名',
        prop: 'realName'
      }, {
        label: '用户类型',
        prop: 'userType'
      }, {
        label: '身份证号码',
        prop: 'cardNo'
      }, {
        label: '手机号',
        prop: 'mobilePhone'
      }]
    }
  },
  components: {
    AddOrUpdate,
    detail
  },
  activated () {
    this.getDataList()
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/system/user/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'account': this.dataForm.account,
          'realName': this.dataForm.realName,
          'status': this.dataForm.status
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (oper, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, oper)
      })
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 禁用/启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对账号【' + row.account + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/system/user/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
