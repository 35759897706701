<template>
  <div id="add-order-detail">
    <el-dialog title="【追加订单】" :close-on-click-modal="false" width="80%" :visible.sync="visible">
      <div class="searchBox">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
          <el-form-item label="订单类型" prop="orderType">
            <el-select class="item-choose" v-model="dataForm.orderType"  style="width: 100%;" filterable clearable placeholder="请选择" @change="getDataList">
              <el-option label="请选择" value="" ></el-option>
              <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提货时间">
            <el-date-picker
              v-model="takeGoodsTime"
              type="daterange" align="right" unlink-panels range-separator="-"
              size="small" value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDataList"
              :picker-options="$store.state.common.pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="包装类型">
            <el-select class="item-choose" v-model="dataForm.packType" size="mini" style="width: 100%" filterable clearable placeholder="请选择" @change="getDataList">
              <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model="dataForm.customerName" placeholder="客户名称"  @keyup.enter.native="getDataList" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <el-table :data="dataList" :header-cell-class-name="cellClass" ref="tableRef" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight" border size="mini" max-height="50vh" :span-method="arraySpanMethod"  style="width: 100%;" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="25" align="center"> </el-table-column>
          <el-table-column label="订单号" prop="orderInfo.orderNo" width="105" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" align="left" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户单号"  width="70" :show-overflow-tooltip="true" prop="orderInfo.customerSelfNo"  align="left">
          </el-table-column>
          <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType"  width="60" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column  label="提货地址"  prop="fullAddress" align="left" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="包装类型"  width="80" :show-overflow-tooltip="true" :formatter="manyDictConvert" prop="orderGoods.packType" align="left">
          </el-table-column>
          <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" prop="orderInfo.takeGoodsDate">
            <template slot-scope="scope">
              {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
            </template>
          </el-table-column>
          <el-table-column prop="status" align="center" label="状态" width="70" fixed="right">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
          :page-sizes="[20, 50, 100]" :page-size="dataForm.limit" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        page: 1,
        status: 2,
        limit: 20,
        orderType: null,
        packType: null,
        customerName: null,
        takeGoodsBeginDate: null,
        takeGoodsEndDate: null
      },
      takeGoodsTime: null,
      pageIndex: 1, // 当前页
      totalPage: 0, // 总数
      companyList: [],
      dataList: [],
      getIndex: 1,
      selectListArr: [],
      row: {},
      todo: 'view'
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    cellClass (row) {
      if (row.columnIndex === 0) {
        return 'DisabledSelection'
      }
    },
    /**
     * 点击表格变色end
     */
    init (row, todo) {
      this.todo = todo
      this.visible = true
      // console.log(row)
      this.row = JSON.parse(JSON.stringify(row))
      this.getDataList()
    },
    getDataList () {
      this.dataListLoading = true
      let dataForm = JSON.parse(JSON.stringify(this.dataForm))
      let takeGoodsTime = this.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      dataForm.takeGoodsBeginDate = timeParam.takeGoodsBeginDate
      dataForm.takeGoodsEndDate = timeParam.takeGoodsEndDate
      this.$http({
        url: this.$http.adornUrl('/order/findNoDispatchOrderList'),
        method: 'get',
        params: this.$http.adornParams(dataForm)
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 提交
    submit () {
      if (this.selectListArr.length === 0) {
        this.$message.error('请至少添加一个订单')
      } else {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/planAddOrder`),
          method: 'POST',
          data: this.$http.adornData({
            id: this.row.id,
            orderInfoList: this.selectListArr
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error('加入订单失败')
          }
        })
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.dataForm.limit = val
      this.dataForm.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.dataForm.page = val
      this.getDataList()
    },
    // 多选
    handleSelectionChange (rows) {
      this.selectListArr = []
      rows.forEach(item => {
        this.selectListArr.push({
          id: item.orderInfo.id
        })
      })
    }
  }
}
</script>
<style lang="less">
#add-order-detail {
  .el-dialog {
    top: 50%;
    transform: translate(0, -100%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
