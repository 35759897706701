<template>
    <el-dialog :title="sizeForm.id?'编辑租户套餐':'新增租户套餐'" :visible.sync="dialogVisible" :width="width" top="6vh" modal-append-to-body
        :append-to-body="true">
        <el-form ref="dataForm" :rules="rules" :model="sizeForm" :label-width="mode === 'menu' ? '0' : '110px'" size="mini">
            <el-form-item label="套餐编号" prop="no" v-show="mode === 'info'">
                <el-input v-model="sizeForm.no" type="Number"></el-input>
            </el-form-item>
            <el-form-item label="套餐名称" prop="name" v-show="mode === 'info'">
                <el-input v-model="sizeForm.name"></el-input>
            </el-form-item>
            <el-form-item label="套餐状态" prop="status" v-show="mode === 'info'">
                <el-switch v-model="sizeForm.status" active-color="#13ce66" inactive-color="#ff4949" active-value="0"
                    inactive-value="1" active-text="开启" inactive-text="关闭">
                </el-switch>
            </el-form-item>
            <el-form-item :class="mode === 'menu' ? 'lableNone' : ''" label="菜单授权"  v-show="mode === 'menu' || !sizeForm.id">
                <div class="buttons">
                    <el-button @click="checkAllNodes(true)" size="mini">全选</el-button>
                    <el-button @click="checkAllNodes(false)" size="mini">全不选</el-button>
                    <el-button @click="expandAllNodes"  size="mini">全展开</el-button>
                    <el-button @click="collapseAllNodes" size="mini">全收起</el-button>
                </div>
                <el-tree :data="menuList" :props="menuListTreeProps" :default-expand-all="true" node-key="id" ref="menuListTree" :disabled="mode === 'menu' || !sizeForm.id" show-checkbox >
                </el-tree>
            </el-form-item>
            <el-form-item label="最大管理账号数" prop="maxAdminAccount" v-show="mode === 'info'">
                <el-input v-model="sizeForm.maxAdminAccount" type="Number"></el-input>
            </el-form-item>
            <el-form-item label="备注" v-show="mode === 'info'">
                <el-input type="textarea" v-model="sizeForm.remark"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handleCancel">取消</el-button>
            <el-button size="mini" :loading="btnLoading" type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { treeDataTranslate } from '@/utils'
export default {
  props: {
    width: {
      type: String,
      default: '700px'
    }
  },
  data () {
    return {
      dialogVisible: false,
      menuList: [],
      menuIdList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children',
        value: 'id',
        isLeaf: 'leaf',
        disabled: 'disabled'
      },
      btnLoading: false,
      dialogContent: '12123123', // 弹窗显示的内容
      sizeForm: {
        no: '',
        name: '',
        status: '1',
        maxAdminAccount: 1,
        menuIds: [],
        remark: ''
      },
      rules: {
        no: [
          { required: true, message: '请输入套餐编号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入套餐编号', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择套餐状态', trigger: 'blur' }
        ]
      },
      mode: 'info' // mode: info=====修改信息/ menu=====菜单
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    // 打开弹窗并传入要显示的内容
    openDialog (row, mode) {
      this.dialogVisible = true

      // 重置
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        this.$refs.menuListTree.setCheckedKeys([])
      })


      if (row && row.id) {
        this.details(row.id)
      } else {
        this.sizeForm = {
          no: '',
          name: '',
          status: '1',
          maxAdminAccount: 1,
          menuIds: [],
          remark: ''
        }
      }
      if (mode) this.mode = mode
      this.$forceUpdate()
    },
    handleCancel () {
      // 点击取消按钮触发的事件，同样可以向外emit自定义事件，供父组件监听
      this.$emit('cancel')
      this.sizeForm = {
        no: '',
        name: '',
        status: '1',
        maxAdminAccount: 1,
        menuIds: [],
        remark: ''
      }
      this.$refs.menuListTree.setCheckedKeys([])
      this.dialogVisible = false
    },
    init () {
      this.$http({
        url: this.$http.adornUrl('/system/menu/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data.length >= 1) {
          data.forEach((item, index) => this.menuIdList.push({ id: item.id }))
        }
        this.menuList = treeDataTranslate(data, 'id')
      })
    },
    // 表单提交
    handleConfirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let sizeFormCopy = JSON.parse(JSON.stringify(this.sizeForm))
          // 获取选中节点和半选节点
          let halfCheckedKeys = this.$refs.menuListTree.getHalfCheckedKeys();
          let checkedKeys = this.$refs.menuListTree.getCheckedKeys();
          sizeFormCopy.menuIds = checkedKeys.concat(halfCheckedKeys);

          this.btnLoading = true
          if (this.mode === 'info') {
            this.$http({
              url: this.$http.adornUrl(sizeFormCopy.id ? '/tenant/package/update' : '/tenant/package/add'),
              method: sizeFormCopy.id ? 'put' : 'post',
              data: this.$http.adornParams(sizeFormCopy)
            }).then((response) => {
              this.$message.success('操作成功')
              setTimeout(() => {
                this.btnLoading = false
                this.handleCancel()
              })
            }).catch(() => {
              this.btnLoading = false
            })
          } else {
            this.$http({
              url: this.$http.adornUrl('/tenant/package/distributionMenu/' + sizeFormCopy.id),
              method: 'put',
              params: this.$http.adornParams({
                menuIds: sizeFormCopy.menuIds.join(',')
              })
            }).then((response) => {
              this.$message.success('操作成功')
              setTimeout(() => {
                this.btnLoading = false
                this.handleCancel()
              })
            }).catch(() => {
              this.btnLoading = false
            })
          }
        }
      })
    },
    // 全选节点方法
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.menuListTree.setCheckedNodes(this.menuIdList)
        } else {
          this.sizeForm.menuIds = []
          this.$refs.menuListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    // 全展开节点方法
    expandAllNodes () {
      const tree = this.$refs.menuListTree
      tree.store._getAllNodes().forEach(node => {
        node.expanded = true
      })
    },
    // 全收起节点方法
    collapseAllNodes () {
      const tree = this.$refs.menuListTree
      tree.store._getAllNodes().forEach(node => {
        node.expanded = false
      })
    },
    // 查询详情
    details (id) {
      this.$http({
        url: this.$http.adornUrl(`/tenant/package/${id}`),
        method: 'get'
      }).then(({ data }) => {
        if (data) {
          this.sizeForm = data
          let ids = []
          // 去除半选状态
          data.menuIds.forEach((item, index) => { this.checked(item,this.menuList,ids) })
          this.$refs.menuListTree.setCheckedKeys(ids)
        }
      })
    },
    // 处理半选ID
    checked(id,data,newArr) {
      data.forEach(item => {
        if (item.id === id){
          if (item.children === undefined || item.children.length === 0 ) {
            newArr.push(item.id)
          }
        } else {
          if(item.children !== undefined && item.children.length !== 0 ) {
            this.checked(id,item.children,newArr)
          }
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

::v-deep .el-tree {
    background: #f6f6f6;
    height: 400px;
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;

    /* 整体滚动条样式 */
    &::-webkit-scrollbar {
        width: 8px;
        /* 滚动条宽度 */
        height: 8px;
        /* 滚动条高度，对于水平滚动条需要设置高度 */
    }

    /* 滚动条轨道（背景部分）样式 */
    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        /* 轨道背景颜色 */
        border-radius: 4px;
        /* 轨道圆角 */
    }

    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
        background-color: #666;
        /* 滑块颜色 */
        border-radius: 4px;
        /* 滑块圆角 */
    }

    /* 滚动条滑块悬停时的样式 */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #333;
        /* 悬停时滑块颜色变深 */
    }
}

.lableNone {
    ::v-deep .el-form-item__label {
        display: none;
    }
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
    ::v-deep .el-button--mini{
        padding: 5px 10px;
    }
}
::v-deep .el-dialog__body {
  padding: 20px !important;
}
</style>
