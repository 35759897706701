<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="车型">
          <el-input v-model="dataForm.vehicleTypeName" placeholder="车型" clearable></el-input>
        </el-form-item>
        <el-form-item label="车次号">
          <el-input v-model="dataForm.planNo" placeholder="车次号" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货日期">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            @change="getDataList"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <!--<el-form-item label="状态">-->
          <!--<el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">-->
            <!--<el-option-->
              <!--v-for="item in dictTypeMap.status"-->
              <!--:key="item.id"-->
              <!--:label="item.name"-->
              <!--:value="item.id"-->
            <!--&gt;</el-option>-->
          <!--</el-select>-->
        <!--</el-form-item>-->
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item style="text-align: right; float: right;">
          <div class="operationList">
            <el-button size="small" @click="operationList()" type="success" :disabled="!selectListArr.length">
              批量发布任务
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="64vh"  :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%;" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="25" fixed="left"> </el-table-column>
        <el-table-column  label="车次号" prop="planNo" align="left" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货日期" prop="takeGoodsDate" align="left" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="所属车队" prop="motorcadeName" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="车型" prop="vehicleTypeName" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="车牌" prop="vehiclePlate" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="司机姓名" prop="driverName" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="司机电话" prop="driverPhone" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status" align="left" label="体积满载率">
          <template slot-scope="scope">
            <el-tag size="small" type="danger">{{ scope.row.volumeLoadRate }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column  label="总件数" prop="pieceNum" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="总重量" prop="weight" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="总体积" prop="volume" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货点数" prop="takeNum" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="卸货点数" prop="giveNum" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车次创建时间" prop="createTime" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车次创建人" prop="createId" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="left" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="detail(scope.row, 'view')">详情</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="releaseTask(scope.row)">发布</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="reassign(scope.row, 'reassign')">重新指派</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrder(scope.row, 'reassign')">追加订单</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="removeOrder(scope.row, 'reassign')">剔除订单</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="deleteTask(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <!-- 车次详情 -->
    <all-number-detail ref="detail" @refreshDataList="getDataList"></all-number-detail>
    <intel-scheDetail ref="dispatch" @refreshDataList="refreshData"></intel-scheDetail>
    <remove-order-detail ref="removeOrder" @refreshDataList="getDataList"></remove-order-detail>
    <add-order-detail ref="addOrder" @refreshDataList="getDataList"></add-order-detail>
  </div>
</template>

<script>
// import detail from './company-detail'
import allNumberDetail from './detail/allNumber-detail'
import intelScheDetail from '@/views/commonPop/list-dispatch'
import removeOrderDetail from './detail/removeOrderDetail'
import addOrderDetail from './detail/addOrderDetail'
export default {
  data () {
    return {
      dataForm: {
        vehiclePlate: null,
        vehicleTypeName: null,
        planNo: null,
        takeGoodsTime: null,
        status: 1
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectListArr: [],
      getIndex: -1
    }
  },
  components: {
    // detail
    allNumberDetail,
    intelScheDetail,
    removeOrderDetail,
    addOrderDetail
  },
  created () {
    // this.setDefaultDate()
  },
  activated () {
    // this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    operationList () {
      this.dataListLoading = true
      let ids = []
      this.selectListArr.forEach(item => {
        ids.push(item.id)
      })
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/batchCommitPlan'),
        method: 'post',
        data: this.$http.adornParams({
          ids: ids
        })
      }).then(({ data }) => {
        if (data) {
          this.$message.success('批量发布成功')
        } else {
        }
        this.getDataList()
        this.dataListLoading = false
      })
    },
    refreshData () {
      this.getDataList()
      this.$emit('getTotal')
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    removeOrder (row) {
      this.$refs.removeOrder.init(row)
    },
    addOrder (row) {
      this.$refs.addOrder.init(row)
    },
    // 多选操作
    handleSelectionChange (rows) {
      this.selectListArr = rows
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.beginCreateTime = takeGoodsTime[0]
        timeParam.endCreateTime = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'vehicleTypeName': this.dataForm.vehicleTypeName,
          'beginDate': timeParam.beginCreateTime,
          'endDate': timeParam.endCreateTime,
          'status': this.dataForm.status,
          'planNo': this.dataForm.planNo
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.$emit('getTotal')
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 重新指派
    reassign (row, todo) {
      this.$nextTick(() => {
        this.$refs.dispatch.init(row, todo)
      })
    },
    // 删除车次
    deleteTask (row) {
      this.$confirm('你正在执行【删除】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/${row.id}`),
          method: 'DELETE'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error(data.data.msg)
          }
        })
      }).catch(() => {
      })
    },
    // 发布任务
    releaseTask (row) {
      this.$confirm('你正在执行【任务发布】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/commitPlan/${row.id}`),
          method: 'post'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error(data.data.msg)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>

// <style lang="less">
// .el-select-dropdown.el-popper {
//   top: 173px !important
// }
// </style>
