<template>
  <div class="popup-detail motorcade-detail">
    <el-dialog
      :title="!dataForm.id ? '【新增】车队信息' : '【修改】车队信息'"
      :close-on-click-modal="false"
      width="80%"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="110px" size="small">
        <div style="margin-top:8px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>车队信息</label>
        </div>
        <div class="infoBox">
          <el-row :gutter="50">
            <!-- <el-col :span="8">
              <el-form-item label="车队编号" prop="motorcadeCode">
                <el-input v-model="dataForm.motorcadeCode" readonly="readonly" :disabled="true" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="所属公司" prop="companyId">
                <el-select class="item-choose"
                          v-model="dataForm.companyId"
                          style="width: 100%;"
                          filterable
                          clearable
                           :disabled="isViews"
                          placeholder="请选择">
                  <el-option
                    v-for="item in companyList"
                    :key="item.id"
                    :label="item.simpleName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :span="8">
              <el-form-item label="车队全称" prop="fullName">
                <el-input v-model="dataForm.fullName" placeholder="车队全称" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车队简称" prop="simpleName">
                <el-input v-model="dataForm.simpleName" placeholder="车队简称" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="联系人" prop="contactName">
                <el-input v-model="dataForm.contactName" placeholder="联系人" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="应付加点价" prop="addFee">
                <el-input v-model="dataForm.addFee" placeholder="请输入整数或两位小数" :disabled="isViews" @input="inputTotal('addFee')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="所属类型" prop="motorcadeType">
                <el-select class="item-choose" v-model="dataForm.motorcadeType" filterable clearable placeholder="请选择" style="width: 100%">
                  <el-option v-for="item in dictTypeMap.motorcade_type" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结算周期(天)" prop="settleCycle">
                <el-input v-model="dataForm.settleCycle" placeholder="请输入数字" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="地址" prop="address">
                <el-input v-model="dataForm.address" placeholder="地址" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :rows="1" v-model="dataForm.remark" placeholder="备注" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-form :model="templateDataForm" ref="templateDataForm" label-width="100px" size="small">
        <div style="margin-top:8px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>费用模板</label>
          <el-button style="margin-left: 20px;" round type="primary" size="mini" @click="addTemplateRow()" v-if="todo != 'view'">添加</el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="templateDataForm.templateList" border size="mini" :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="50">
            </el-table-column>
            <el-table-column prop="templateCode" label="模板编号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="templateName" align="center" label="模板名称">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="templateDataForm.templateList[scope.$index].templateId" :disabled="todo == 'view' ? true : false"
                          size="mini" style="width: 100%" @change="((val)=>{showTemplateCode(val, scope.$index)})" filterable clearable placeholder="请选择">
                  <el-option v-for="item in quoteTemplate" :key="item.templateCode" :label="item.templateName" :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="endTime" label="有效期" align="center" width="300">
              <template slot-scope="scope">
                <div>
                  <el-date-picker :disabled="todo == 'view' ? true : false"
                    v-model="templateDataForm.templateList[scope.$index].dateRange"
                    type="daterange" range-separator="-" size="mini" align="center" style="width: 100%"
                    value-format="yyyy-MM-dd"
                    start-placeholder="生效日期"
                    end-placeholder="失效日期">
                  </el-date-picker>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80" v-if="todo !='view'">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.$index)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        motorcadeCode: null,
        companyId: null,
        fullName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        addFee: 0,
        address: null,
        remark: null,
        settleCycle: null,
        motorcadeType: null,
        status: 1
      },
      companyList: [],
      todo: 'view',
      templateDataForm: {
        templateList: []
      },
      quoteTemplate: [],
      dataRule: {
        companyId: this._Valid.init(['null']),
        fullName: this._Valid.init(['null']),
        simpleName: this._Valid.init(['null']),
        contactName: this._Valid.init(['null']),
        contactPhone: this._Valid.init(['null']),
        address: this._Valid.init(['null']),
        motorcadeType: this._Valid.init(['null']),
        addFee: this._Valid.init(['price'])
      }
    }
  },
  computed: {
    isViews () {
      return this.todo === 'view'
    },
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    inputTotal (value) {
      // if (parseFloat(this.dataForm.addFee)) {
      //   if (!(/^\d+(\.\d{1,2})?$|^\.\d+$/.test(this.dataForm.addFee))) {
      //     this.$message({
      //       message: '最多保留两位小数',
      //       type: 'error',
      //       duration: 1500,
      //       onClose: () => {
      //         this.dataForm.addFee = 0
      //       }
      //     })
      //   }
      // }
    },
    init (id, todo) {
      this.dataForm.id = id
      this.todo = todo
      this.templateDataForm.templateList = []
      this.$http({
        url: this.$http.adornUrl('/priceTemplate/findAll'),
        method: 'get',
        params: this.$http.adornParams({
          'templateType': 2
        })
      }).then(({data}) => {
        this.quoteTemplate = data
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/findListBySelect'),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          this.companyList = data
        }).then(() => {
          this.visible = true
          this.$nextTick(() => {
            this.$refs['dataForm'].resetFields()
          })
        }).then(() => {
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/motorcade/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data) {
                this.dataForm.motorcadeCode = data.motorcadeCode
                this.dataForm.companyId = data.companyId
                this.dataForm.fullName = data.fullName
                this.dataForm.simpleName = data.simpleName
                this.dataForm.contactName = data.contactName
                this.dataForm.contactPhone = data.contactPhone
                this.dataForm.addFee = data.addFee
                this.dataForm.address = data.address
                this.dataForm.remark = data.remark
                this.dataForm.settleCycle = data.settleCycle
                this.dataForm.motorcadeType = data.motorcadeType
                this.initTemplate(data)
              }
            })
          }
        })
      })
    },
    // 初始化费用模板数据
    initTemplate (data) {
      let templateList = data.templateList
      if (templateList) {
        for (let i = 0; i < templateList.length; i++) {
          let dateRange = []
          dateRange[0] = templateList[i].beginTime
          dateRange[1] = templateList[i].endTime
          this.templateDataForm.templateList.push({
            templateId: templateList[i].templateId,
            dateRange: dateRange
          })
          this.showTemplateCode(templateList[i].templateId, i)
        }
      }
    },
    // 封装费用模板表单
    pickTemplateForm () {
      let templateList = this.templateDataForm.templateList
      if (templateList.length === 0) {
        return true
      }
      for (let i = 0; i < templateList.length; i++) {
        let element = templateList[i]
        if (element.dateRange && element.dateRange.length === 2) {
          element.beginTime = element.dateRange[0]
          element.endTime = element.dateRange[1]
        } else {
          delete element.beginTime
          delete element.endTime
        }
        if (!element.templateId) {
          this.$message.error('费用模板中，第【' + (i + 1) + '】行未选择模板。')
          return false
        }
        if (!element.beginTime || !element.endTime) {
          this.$message.error('费用模板中，第【' + (i + 1) + '】行有效期错误。')
          return false
        }
      }
      return true
    },
    deleteRow (index) {
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.templateDataForm.templateList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    addTemplateRow () {
      this.templateDataForm.templateList.push({
        dateRange: null
      })
    },
    showTemplateCode (templateId, index) {
      if (!templateId) {
        this.templateDataForm.templateList[index].templateCode = null
        return
      }
      for (let i = 0; i < this.quoteTemplate.length; i++) {
        if (this.quoteTemplate[i].id === templateId) {
          this.templateDataForm.templateList[index].templateCode = this.quoteTemplate[i].templateCode
          break
        }
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (!this.pickTemplateForm()) {
            return
          }
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/motorcade/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'companyId': this.dataForm.companyId,
              'fullName': this.dataForm.fullName,
              'simpleName': this.dataForm.simpleName,
              'contactName': this.dataForm.contactName,
              'contactPhone': this.dataForm.contactPhone,
              'addFee': this.dataForm.addFee,
              'address': this.dataForm.address,
              'remark': this.dataForm.remark,
              'settleCycle': this.dataForm.settleCycle,
              'templateList': this.templateDataForm.templateList,
              'status': this.dataForm.status,
              'motorcadeType': this.dataForm.motorcadeType
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.motorcade-detail {
  .infoBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
  }
  .el-dialog {
    margin-top: 0 !important;
    top: 50% !important;
    transform: translate(0, -50%);
  }
}
</style>
