<template>
  <el-dialog :title="sizeForm.id?'编辑公告':'添加公告'" :visible.sync="dialogVisible" v-if="dialogVisible" :width="width" top="6vh" modal-append-to-body
    :append-to-body="true">
    <el-form ref="dataForm" :rules="rules" :model="sizeForm" label-width="120px" size="small">

      <!-- 公告标题 -->
      <el-row>
        <el-form-item label="公告标题" prop="title">
          <el-input v-model="sizeForm.title"></el-input>
        </el-form-item>
      </el-row>

      <!-- 是否全平台发布/通知入口 -->
      <el-row :gutter="24">
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item label="是否全平台发布" prop="whetherToReleaseItOnAllPlatforms" v-if="isDefaultTenantId">
              <el-select v-model="sizeForm.whetherToReleaseItOnAllPlatforms" size="small" filterable clearable placeholder="请选择">
                <el-option v-for="item in dictTypeMap.tmsSysAnnouncementWhetherToReleaseItOnAllPlatformsMap" :key="item.id" :label="item.name" :value="item.id.toString()"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-form-item label="通知入口" prop="notificationEntry">
              <el-select v-model="sizeForm.notificationEntry" size="small" multiple  filterable clearable placeholder="请选择">
                <el-option v-for="item in dictTypeMap.tmsSysAnnouncementNotificationTerminalMap" :key="item.id" :label="item.name" :value="item.id.toString()"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

      <!-- 公告内容 -->
      <el-row>
        <el-form-item label="公告内容" prop="content">
          <Tinymce v-model="sizeForm.content" :height="400" style="width: 100%" ></Tinymce>
        </el-form-item>
      </el-row>

      <!-- 发布时间/结束时间 -->
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-form-item label="发布时间" prop="publishTime">
              <el-date-picker v-model="sizeForm.publishTime" type="datetime" placeholder="选择日期时间" style="width: 100%;"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker v-model="sizeForm.endTime" type="datetime" placeholder="选择日期时间" style="width: 100%;"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="handleCancel">取消</el-button>
      <el-button size="mini" :loading="btnLoading" type="primary" @click="handleConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import * as myJsonClone from "@/utils/myJsonClone";
export default {
  components: { Tinymce },
  props: {
    width: {
      type: String,
      default: '1000px'
    }
  },
  data () {
    return {
      dialogVisible: false,
      btnLoading: false,
      isDefaultTenantId: (sessionStorage.getItem('Tenant-Id') || 10000) == 10000,
      sizeForm: {
        content: '',
        whetherToReleaseItOnAllPlatforms: 1,
      },
      rules: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入公告内容', trigger: 'blur' }
        ],
        publishTime: [
          { required: true, message: '请选择发布时间', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择发布时间', trigger: 'blur' }
        ],
        whetherToReleaseItOnAllPlatforms: [
          { required: true, message: '请选择是否全平台发布', trigger: 'blur' }
        ],
        notificationEntry: [
          { required: true, message: '请选择通知入口', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
  },
  computed: {
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    handleCheckChange (data) {
      this.sizeForm.menuIds.push(data.id)
    },
    // 打开弹窗并传入要显示的内容
    openDialog (row) {
      // 重置
      this.sizeForm = this.$options.data().sizeForm
      if (row && row.id) {
        let cloneRow = myJsonClone.getClone(row);
        this.sizeForm = cloneRow
        if (cloneRow.notificationEntry != null && cloneRow.notificationEntry.split(',').length > 0) {
          this.sizeForm.notificationEntry = cloneRow.notificationEntry.split(',');
        } else {
          this.sizeForm.notificationEntry = []
        }
        this.sizeForm.content = this.formatRichText(cloneRow.content || null)
        this.$forceUpdate()
      } else {
        this.sizeForm = {
          content: ''
        }
      }
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },
    handleCancel () {
      // 点击取消按钮触发的事件，同样可以向外emit自定义事件，供父组件监听
      this.$emit('cancel')
      this.sizeForm = {
        no: '',
        name: '',
        status: '1',
        maxAdminAccount: 0,
        menuIds: [],
        remark: ''
      }
      this.dialogVisible = false
    },
    // 过滤详情内容
    formatRichText (html) {
      let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '')
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '')
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '')
        return match
      })
      newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/max-max-width:[^;]+;/gi, 'max-width:100%;')
        return match
      })
      newContent = newContent.replace(/<br[^>]*\/>/gi, '')
      newContent = newContent.replace(
        /\<img/gi,
        '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"'
      )
      return newContent
    },
    // 表单提交
    handleConfirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let cloneSizeForm = myJsonClone.getClone(this.sizeForm);
          if (cloneSizeForm.notificationEntry != null && cloneSizeForm.notificationEntry.length > 0) {
            cloneSizeForm.notificationEntry = cloneSizeForm.notificationEntry.join(',');
          }
          this.btnLoading = true
          this.$http({
            url: this.$http.adornUrl(cloneSizeForm.id ? '/announcement/update' : '/announcement/create'),
            method: cloneSizeForm.id ? 'post' : 'put',
            data: this.$http.adornParams({
              ...cloneSizeForm,
              content: this.formatRichText(cloneSizeForm.content)
            })
          }).then((response) => {
            this.$message.success('操作成功')
            setTimeout(() => {
              this.btnLoading = false
              this.handleCancel()
            })
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-dialog__body {
  padding: 20px !important;
}

.lableNone {
  ::v-deep .el-form-item__label {
    display: none;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ::v-deep .el-button--mini {
    padding: 5px 10px;
  }
}
</style>
