<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="字典编码">
          <el-input v-model="dataForm.code" placeholder="字典编码" clearable></el-input>
        </el-form-item>
        <el-form-item label="字典名称">
          <el-input v-model="dataForm.name" placeholder="字典名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-select class="item-choose" v-model="dataForm.typeCode" size="small" filterable clearable placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
            ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
                v-for="item in dictTypeMap.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  height="72vh" v-loading="dataListLoading" style="width: 100%;">
        <af-table-column v-for="(row, index) in tableRow" :key="index" :label="row.label" :prop="row.prop">
        </af-table-column>
        <el-table-column prop="status" fixed="right" width="60" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="100" label="操作">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
            <!-- <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">禁用</el-button> -->
            <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './details/dict-add-or-update'
export default {
  data () {
    return {

      dataForm: {
        account: ''
      },
      tableRow: [{
        label: '所属分类',
        prop: 'typeName'
      }, {
        label: '字典编码',
        prop: 'code'
      }, {
        label: '字典名称',
        prop: 'name'
      }, {
        label: '备注',
        prop: 'remark'
      }],
      typeList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    this.getDataList()
    this.getDictType()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    getDictType () {
      this.$http({
        url: this.$http.adornUrl('/dict/findTypeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.typeList = data
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      if(this.dataForm.typeCode === 'repair_factory'){
        this.tableRow = [{
          label: '所属分类',
          prop: 'typeName'
        }, {
          label: '字典编码',
          prop: 'code'
        }, {
          label: '字典名称',
          prop: 'name'
        }, {
          label: '联系人',
          prop: 'linkman'
        }, {
          label: '联系电话',
          prop: 'phone'
        }, {
          label: '备注',
          prop: 'remark'
        }]
      }else{
        this.tableRow=[{
          label: '所属分类',
          prop: 'typeName'
        }, {
          label: '字典编码',
          prop: 'code'
        }, {
          label: '字典名称',
          prop: 'name'
        }, {
          label: '备注',
          prop: 'remark'
        }]
      }
      this.$http({
        url: this.$http.adornUrl('/dict/findInfoList/page'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'typeCode': this.dataForm.typeCode,
          'status': this.dataForm.status,
          'code': this.dataForm.code,
          'name': this.dataForm.name
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dict/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
