import * as constants from '@/utils/constants.js'

// 租户类型 过滤器
export function filterTenantsType (value) {
  return constants.tenantsType.filter(item => value === item.value)[0].label
}

// 公共过滤器
export function filterEmpty (val) {
  let _result = '-'
  if (!val) {
    return _result
  }
  _result = val
  return _result
}
