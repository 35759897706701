import Vue from 'vue'
// import App from '../App'
import { Message } from 'element-ui'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'

// const vm = new Vue({
//   el: '#app',
//   router,
//   components: { App }
// })

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['access_token'] = Vue.cookie.get('TmsToken') // 请求头带上token
  config.headers['Tenant-Id'] = sessionStorage.getItem('Tenant-Id') || 10000
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  let data = response.data
  // 非成功码
  if (data) {
    let code = data.code
    if (code === undefined || code === null) {
      return response
    }
    if (code === 100000) {
      return data
    }
    // 登录问题
    if (code < 101006) {
      // 101001
      // 101002
      // 101003
      // 101004
      // 101005
      // 101006
      Message.error(data.message)
      clearLoginInfo()
      router.push({ name: 'login' })
      return Promise.reject(data.message)
    } else {
      Message.error(data.message)
      return Promise.reject(data.message)
    }
  }
  return response
}, error => {
  var res = error.response
  var data = res.data
  if (res && res.status === 500 && data) {
    var code = data.code
    Message.error(data.message)
    if (code === 101002) {
      // 未登录或会话过期，跳转到登录页
      router.push({ name: 'login' })
    }
  } else {
    return Promise.reject(error.response)
  }
  return Promise.reject(data.message)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // http://47.104.187.223/ydw/tms/v1
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
  return (process.env.NODE_ENV !== 'production' ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
