<template>
  <div class="cancel-order-popup">
    <el-dialog title="【取消】- 订单" :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <div>
        请确认是否继续执行【取消订单】操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      row: {}
    }
  },
  computed: {
    isView () {
      return false
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
    },
    confirm () {
      // console.log('取消订单')
      // this.$http({
      //   url: this.$http.adornUrl(`/vehicleInfo/unBindDriver/${this.row.id}`),
      //   method: 'post'
      // }).then(({ data }) => {
      //   if (data) {
      //     this.$message({
      //       message: '操作成功',
      //       type: 'success',
      //       duration: 1500,
      //       onClose: () => {
      //         this.visible = false
      //         this.$emit('refreshDataList')
      //       }
      //     })
          
      //   } else {
      //     this.$message.error(data.data.msg)
      //   }
      // })
    }
  }
}
</script>
