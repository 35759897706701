<template>
  <div class="business">
    <el-form :inline="true" :model="dataForm" ref="dataForm" :rules="rules" size="small" label-width="120px">
      <el-row :gutter="50">
        <el-col :span="8">
          <el-form-item label="保单号" prop="insureNo">
              <el-input v-model="dataForm.insureNo" placeholder="保单号" :disabled="isView"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="购买日期" prop="purchaseDate">
            <el-date-picker v-model="dataForm.purchaseDate" type="date"
                            value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isView">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="8">
          <el-form-item label="被保险人" prop="insureUser">
              <el-input v-model="dataForm.insureUser" placeholder="被保险人" :disabled="isView"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="保费总计" prop="insureTotal">
              <el-input v-model="dataForm.insureTotal" placeholder="保费总计" :disabled="isView"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="保险有效期" prop="dateRange" class="date-range">
             <el-date-picker
                v-model="dataForm.dateRange"
                :disabled="isView"
                type="daterange" align="right" unlink-panels range-separator="-"
                size="small" value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="$store.state.common.pickerOptions">
              </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="附件">
            <al-upload :fileData="dataForm.enclosureInfoList" :fileUrl="$http.adornUrl('/upload/img')"
                         :isView="isView" :type="'IdCard'" :limit="8" :businessType="9041"
                         @getFileData="getFileData"></al-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="8">
          <el-form-item label="保险公司" prop="insureCompany">
<!--              <el-input v-model="dataForm.insureCompany" placeholder="保险公司" :disabled="isView"></el-input>-->
            <el-autocomplete class="inline-input" v-model="dataForm.insureCompany"
                             :fetch-suggestions="queryNameSearch"
                             :disabled="isView"
                             style="width:100%"
                             clearable
                             placeholder="点击输入框弹出可选项"></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="保险区域" prop="insureArea">
              <el-input v-model="dataForm.insureArea" placeholder="保险区域" :disabled="isView"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <hr>
      <div v-for="item in dataForm.itemList" :key="item.type" :id="item.type">
        <h3 class="title">{{ item.name }}</h3>
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="保险金额">
              <el-input v-model="item.money" placeholder="保险金额" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保费">
              <el-input v-model="item.cost" placeholder="保费" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="不计免赔">
              <el-input v-model="item.deductible" placeholder="不计免赔" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <div class="footer">
          <el-button type="primary" @click="submit" v-if="!isView">保存</el-button>
          <el-button type="danger" @click="gotoBack">关闭</el-button>
        </div>
      </el-row>
    </el-form>
    <big-image ref="bigImg"></big-image>
  </div>
</template>
<script>
import bigImage from '@/views/commonPop/bigImage'
export default {
  components: {
    bigImage
  },
  props: ['todo', 'row'],
  data () {
    return {
      dataForm: {
        dateRange: null,
        insureNo: null,
        insureUser: null,
        insureTotal: null,
        insureCompany: null,
        insureArea: null,
        purchaseDate: this.dateFormatter(new Date(), 'yyyy-MM-dd'),
        vehicleInsure: {},
        enclosureInfoList: [],
        itemList: [
          { type: 1, name: '第三方责任险', sequence: 1, money: null, cost: null, deductible: null },
          { type: 2, name: '强盗险', sequence: 2, money: null, cost: null, deductible: null },
          { type: 3, name: '车上责任险（乘客）', sequence: 3, money: null, cost: null, deductible: null },
          { type: 4, name: '玻璃险', sequence: 4, money: null, cost: null, deductible: null },
          { type: 5, name: '自燃险', sequence: 5, money: null, cost: null, deductible: null },
          { type: 6, name: '特约险', sequence: 6, money: null, cost: null, deductible: null }
        ]
      },
      insuranceCompany: 'insurance_company',
      limit: 8,
      rules: {
        insureTotal: this._Valid.init(['null']),
        insureUser: [{ required: true, message: '被保险人不能为空', trigger: 'blur' }],
        purchaseDate: [{ required: true, message: '请选择购买日期', trigger: 'change' }],
        dateRange: [{ required: true, message: '请选择有效期', trigger: 'change' }]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap },
      set (val) { this.$store.commit('common/updateDictMapByKey', val) }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  mounted () {
    // this.dataForm = this.row
    this.update(this.row)
    this.init(this.row)
  },
  methods: {
    // 获取基础数据字典信息
    getDictInfo () {
      this.$http({
        url: this.$http.adornUrl('/dict/findDictAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let types = {}
        types[this.insuranceCompany] = data[this.insuranceCompany]
        this.dictTypeMap = types
      })
    },
    init (row) {
      if (row && row.id) {
        this.$http({
          url: this.$http.adornUrl(`/businessInsure/detail/${row.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          this.formData(data)
        })
      }
    },
    formData (row) {
      if (row) {
        this.dataForm.id = row.id
        this.dataForm.dateRange = [row.beginTime, row.endTime]
        this.dataForm.insureNo = row.insureNo || null
        this.dataForm.purchaseDate = row.purchaseDate || null
        this.dataForm.insureUser = row.insureUser || null
        this.dataForm.insureTotal = row.insureTotal || null
        this.dataForm.insureCompany = row.insureCompany || null
        this.dataForm.insureArea = row.insureArea || null
        this.dataForm.insureMoney = row.insureMoney || null
        this.dataForm.insureCost = row.insureCost || null
        this.dataForm.deductible = row.deductible || null
        this.dataForm.useTax = row.useTax || null
        this.dataForm.enclosureInfoList = row.enclosureInfoList || []
        if (row.itemList) {
          this.dataForm.itemList = row.itemList
        }
      }
    },
    update (data) {
      if (data) {
        this.dataForm.vehicleInsure.id = data.id
        this.dataForm.vehicleInsure.vehiclePlate = data.vehiclePlate
        this.dataForm.vehicleInsure.insureObject = data.insureObject
      }
    },
    handlePictureCardPreview (data) {
      this.$refs.bigImg.init(data.url)
    },
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    gotoBack () {
      let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
      this.$store.commit('common/updateMainTabs', val)
      this.$router.push({ name: 'certificates-InsuranceManagement' })
    },
    submit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          let dateRange = this.dataForm.dateRange
          if (dateRange && dateRange.length === 2) {
            this.dataForm.beginTime = dateRange[0]
            this.dataForm.endTime = dateRange[1]
          }
          let formData = JSON.parse(JSON.stringify(this.dataForm))
          if (Array.isArray(formData.vehicleInsure.vehiclePlate)) {
            formData.vehicleInsure.vehiclePlate = formData.vehicleInsure.vehiclePlate[0] + '/' + formData.vehicleInsure.vehiclePlate[1]
          }
          this.$http({
            url: this.$http.adornUrl(`/businessInsure/` + methodName),
            method: method,
            data: this.$http.adornData(formData)
          }).then(({ data }) => {
            if (data) {
              this.getDictInfo()
              this.$message({
                message: '保存成功',
                type: 'success',
                duration: 1000
              })
              if (methodName === 'add') {
                this.dataForm.id = data.id
                this.$emit('returnAddData', data.vehicleInsure)
              }
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    queryNameSearch (queryString, cb) {
      let values = []
      let typeMapElement = this.dictTypeMap[this.insuranceCompany]
      for (let key in typeMapElement) {
        values.push({
          value: typeMapElement[key].name
        })
      }
      let results = queryString ? values.filter(this.createFilter(queryString, 'value')) : values
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString, name) {
      return (restaurant) => {
        if (restaurant[name]) {
          return (restaurant[name].indexOf(queryString) === 0)
        }
        return false
      }
    }
  }
}
</script>
<style lang="less">
.business {
  .el-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .title {
    margin: 8px
  }
  .el-form-item {
    margin-bottom: 8px !important;
  }
  .footer {
    text-align: right;
  }
}
</style>
