<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="证件对象">
          <el-select class="item-choose" v-model="dataForm.cardObject" style="width: 100%;" filterable clearable placeholder="请选择" @change="getDataList">
                <el-option label="请选择" value=""></el-option>
                <el-option
                  v-for="item in dictTypeMap.card_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="证件类型">
            <el-input v-model="dataForm.cardTypeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车号名称">
          <el-input v-model="dataForm.name" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择"  @change="getDataList">
            <el-option label="请选择" value=""></el-option>
            <el-option label="正常" value="1"></el-option>
            <el-option label="即将过期" value="2"></el-option>
            <el-option label="已过期" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="办证日期">
          <el-date-picker
            v-model="dataForm.dateRange"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="年审供应商">
          <el-input v-model="dataForm.annualReview" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button size="small" type="success" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" ref="table"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList" border size="small"
                :summary-method="getSummaries"
                show-summary
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column prop="cardObject" label="证件对象"  :formatter="cardType" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="name" label="车号名称" :show-overflow-tooltip="true" min-width="100">
        </el-table-column>
        <el-table-column prop="cardTypeName" label="证件类型" width="70">
        </el-table-column>
        <el-table-column prop="status" label="证件状态" width="70">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 3" size="small" type="danger">已过期</el-tag>
            <el-tag  v-else-if="scope.row.status === 2" size="small" type="warning">即将过期</el-tag>
            <el-tag v-else size="small" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="cardBeginTime" label="证件开始日期">
        </el-table-column>
        <el-table-column prop="cardEndTime" label="证件失效日期">
        </el-table-column>
        <el-table-column prop="cardNo" label="证件编码">
        </el-table-column>
<!--        <el-table-column prop="archiveNo" label="档案编码">-->
<!--        </el-table-column>-->
        <el-table-column prop="annualReview"  label="年审供应商">
        </el-table-column>
        <el-table-column prop="createCardCost" label="办证费用">
        </el-table-column>
        <el-table-column label="附件" prop="" width="100">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row)" style="color: blue;cursor:pointer"  v-if="scope.row.fileNum>0">查 看</span>
            <span v-else>
              无
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
        </el-table-column>
        <el-table-column label="费用小计" prop="payableAmount" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createCardTime" label="办证日期">
        </el-table-column>
        <el-table-column prop="createName" label="创建人">
        </el-table-column>
        <el-table-column prop="writeName" label="核销人" width="60" align="center">
        </el-table-column>
        <el-table-column prop="writeDate" label="核销时间" width="70" align="center">
        </el-table-column>
        <el-table-column label="付款状态" prop="writeStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.writeStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('early:warning:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row)">详情</el-button>
              <el-button v-if="newAuth('early:warning:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row)">修改</el-button>
              <el-button v-if="newAuth('early:warning:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>

    <!--审核弹窗-->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
  </div>
</template>

<script>
import AddOrUpdate from './detail/earlyWarning-detail'
import viewImage from '@/views/commonPop/viewImage'
import writeOffPopup from './detail/write-off-popup'
export default {
  data () {
    return {
      dataForm: {
        name: null,
        cardObject: null,
        cardTypeName: null,
        status: null,
        dateRange: null,
        writeStatus: null,
        annualReview: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      proofTypeList: [8000],
      cardTypeList: [],
      selectFeeData: []
    }
  },
  components: {
    AddOrUpdate,
    // detail,
    viewImage,
    writeOffPopup
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },

  mounted () {
    this.$http({
      url: this.$http.adornUrl('/cardType/findList'),
      method: 'get',
      params: this.$http.adornParams({
        'page': this.pageIndex,
        'limit': this.pageSize
      })
    }).then(({ data }) => {
      this.cardTypeList = data.list
    })
  },

  updated () {
    this.$nextTick(() => {
      this.$refs['table'].doLayout()
    })
  },
  methods: {
    downLoad () {
      this.$http({
        url: this.$http.adornUrl('/cardWarn/export'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status,
          'cardTypeName': this.dataForm.cardTypeName,
          'cardObject': this.dataForm.cardObject,
          'annualReview': this.dataForm.annualReview
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '证件预警管理列表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // setDefaultDate () {
    //   let end = new Date()
    //   let start = new Date()
    //   start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    //   this.dataForm.dateRange = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    // },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(this.dataForm.name, writeStatus, ids)
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/cardWarn/CardWarnBatchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row, todo) {
      this.$refs.viewPhoto.init(row.id, this.proofTypeList)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.repairTimeBegin = dateRange[0]
        timeParam.repairTimeEnd = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl('/cardWarn/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status,
          'repairTimeBegin': timeParam.repairTimeBegin,
          'repairTimeEnd': timeParam.repairTimeEnd,
          'cardTypeName': this.dataForm.cardTypeName,
          'cardObject': this.dataForm.cardObject,
          'writeStatus': this.dataForm.writeStatus,
          'annualReview': this.dataForm.annualReview
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.totalRow || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
      this.selectFeeData = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, row)
      })
    },
    // 删除
    deleteHandle (row) {
      this.$confirm('确定对【' + row.name + '】进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/cardWarn/delete/' + row.id),
          method: 'DELETE'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.id === preId) {
            return ''
          }
          preId = item.id
          if (column.property) {
            if (column.property === 'createCardCost') {
              return this.getValue(Number(item.createCardCost))
            }
            if (column.property === 'payableAmount') {
              return this.getValue(Number(item.payableAmount))
            }
            if (column.property === 'paidAmount') {
              return this.getValue(Number(item.paidAmount))
            }
            if (column.property === 'notPayableAmount') {
              return this.getValue(Number(item.notPayableAmount))
            }
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        totalSummary.createCardCost,
        '',
        '',
        totalSummary.payableAmount,
        totalSummary.paidAmount,
        totalSummary.notPayableAmount, '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    }
  }
}
</script>
