<template>
  <div class="drawer-plane">
    <el-drawer title="导入数据" :visible.sync="drawer" :wrapperClosable="false" :direction="direction" :before-close="handleClose">
      <div class="title">导入步骤</div>
      <div class="export">
        <p>1, 下载标准模板，按示例格式和示例说明填写需要导入的电子表格文件</p>
        <div class="btns">
          <el-button type="primary" @click="download('upload')">下载导入模板</el-button>
          <el-button type="primary" @click="download('org')">下载组织模板</el-button>
        </div>
        <p>2, 点击“导入数据”按钮，选择您要导入的电子表格文件</p>
        <p>3, 导入成功后，页面会提示成功数量，失败数量，并显示相关信息</p>
        <p>4, 如有失败记录，删除电子表格里已经成功的记录，然后修正失败的记录，再次上传导入。</p>
      </div>
      <div class="tips">
        <div class="title">提示：</div>
        <p>导入功能仅支持批量新增</p>
      </div>
      <div class="btns">
          <!-- <el-button type="primary" @click="importData">导入数据</el-button> -->
         <el-upload
            ref="upload"
            :action="$http.adornUrl('/upload/file')"
            :data="{businessType: 6001}"
            :headers="{'access_token': $cookie.get('TmsToken'),'Tenant-Id': uploadTenantId}"
            :limit="limit"
            :on-exceed="limitExceed"
            :file-list="fileList"
            :show-file-list="true"
            :on-error="errorFileList"
            :before-upload="beforeAvatarUpload"
            :on-success="saveFileList">
            <el-button size="mini" type="primary" :loading="loading">导入数据</el-button>
            <div slot="file" slot-scope="{file}">
              <span @click="handleDownload(file)" style="color:blue">
                {{file.name}}
              </span>
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="removeFileList(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
        <div class="footer btns">
          <el-button type="danger" @click="close()">关闭</el-button>
        </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      direction: 'rtl',
      limit: 2,
      loading: false,
      fileList: [],
      uploadTenantId: sessionStorage.getItem('Tenant-Id') || 10000
    }
  },
  methods: {
    handleClose (done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //   })
      //   .catch(_ => {});
    },
    close () {
      this.drawer = false
    },
    init () {
      this.drawer = true
    },
    download (type) {
      if (type == 'upload') {
        window.open('/small/tms/annex/f3b643c08a8b4df6be90e26862336db3.xls')
      } else {
        window.open('/small/tms/annex/f3b643c08a8b4df6be90e26862336db3.xls')
      }
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    limitExceed (data, files) {
      if (files.length === this.limit) {
        this.$message.error(`文件上传限制为${this.limit}个文件`)
      }
    },
    downLoadXls (data, filename) {
      // var blob = new Blob([data], {type: 'application/vnd.ms-excel'})接收的是blob，若接收的是文件流，需要转化一下
      if (typeof window.chrome !== 'undefined') {
        // Chrome version
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = filename
        link.click()
      } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE version
        var blob = new Blob([data], { type: 'application/force-download' });
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Firefox version
        var file = new File([data], filename, { type: 'application/force-download' })
        window.open(URL.createObjectURL(file))
      }
    },
    handleDownload (file) {
      this.$http({
        url: this.$http.adornUrl(`/upload/download/stream/${file.id}`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        var link = document.createElement('a')
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = file.name
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (response.code == 100000) {
      } else {
        file = {}
      }
      this.loading = false
      this.fileList.push({ id: resData.id, name: file.name, status: file.status, uid: file.uid, businessType: resData.businessType })
    },
    beforeAvatarUpload (response, file) {
      this.loading = true
    },
    errorFileList (response, file) {
    },
    // 删除文件信息
    removeFileList (file) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let fileId = file.id
        let fileList = this.fileList
        // 从集合删除
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].id === fileId) {
            this.fileList.splice(i, 1)
            break
          }
        }
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
.drawer-plane {
  .el-drawer__header {
    padding: 0 10px;
    height: 50px;
    margin-bottom: 0;
  }
  .el-drawer__body {
    padding-left: 10px;
    .el-button {
      height: 28px;
      line-height: 0;
      padding: 0 10px;
      border-radius: 0;
    }
    .title {
      height: 26px;
      line-height: 26px;
      font-weight: 600;
    }
    .export {
      font-size: 12px;
    }
    .tips {
      p {
        margin: 8px 0;
      }
    }
    li.el-upload-list__item {
      float: left;
      width: auto !important;
      height: auto !important;
    }
    .footer {
      position: absolute;
      bottom: 0;
      height: 50px;
      border-top: 1px solid #ccc;
      width: 1000%;
      line-height: 50px;
      margin-left: -10px;
      padding-left: 350px;
    }
  }
}
</style>
