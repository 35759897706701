<template>
<div class="common-pop">
  <el-dialog
    :title="!dataForm.id ? '【新增】字典信息' : '【修改】字典信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
      <el-form-item label="字典编码" prop="code">
        <el-input v-model="dataForm.code" placeholder="字典编码"></el-input>
      </el-form-item>
      <el-form-item label="字典名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="字典名称"></el-input>
      </el-form-item>
      <el-form-item label="联系人" v-show="linkmanT" prop="name">
        <el-input v-model="dataForm.linkman" placeholder="联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" v-show="phoneT" prop="name">
        <el-input v-model="dataForm.phone" placeholder="联系电话"></el-input>
      </el-form-item>
      <el-form-item label="所属分类" prop="typeId">
        <el-select class="item-choose" @change="checkChange"  v-model="dataForm.typeId" :disabled="false" size="small" filterable clearable placeholder="请选择">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序号" prop="sort">
        <el-input-number v-model="dataForm.sort" controls-position="right" :min="0" label="排序号"></el-input-number>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" :rows="5" v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        typeList: [],
        linkmanT:false,
        phoneT:false,
        dataForm: {
          id: null,
          code: null,
          name: null,
          linkman: null,
          phone: null,
          typeId: null,
          sort: 0,
          remark: null,
          status: 1
        },
        dataRule: {
          code: [
            { required: true, message: '字典编码不能为空', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '字典名称不能为空', trigger: 'blur' }
          ],
          typeId: [
            { required: true, message: '请选择所属类别', trigger: 'change' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.$http({
          url: this.$http.adornUrl('/dict/findTypeList'),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          this.typeList = data
        }).then(() => {
          this.visible = true
          this.$nextTick(() => {
            this.$refs['dataForm'].resetFields()
          })
        }).then(() => {
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/dict/findInfo/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if(data.typeId==="bd706c1d2f8411ebacfd00163e128711"){
                this.linkmanT=true
                this.phoneT=true
                if (data) {
                  this.dataForm.code = data.code
                  this.dataForm.name = data.name
                  this.dataForm.linkman = data.linkman
                  this.dataForm.phone = data.phone
                  this.dataForm.typeId = data.typeId
                  this.dataForm.sort = data.sort
                  this.dataForm.remark = data.remark
                  this.dataForm.status = data.status
                }
              }else{
                this.linkmanT=false
                this.phoneT=false
                if (data) {
                  this.dataForm.code = data.code
                  this.dataForm.name = data.name
                  this.dataForm.typeId = data.typeId
                  this.dataForm.sort = data.sort
                  this.dataForm.remark = data.remark
                  this.dataForm.status = data.status
                }
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            let methodName = !this.dataForm.id ? 'addInfo' : 'updateInfo'
            let method = !this.dataForm.id ? 'put' : 'post'
            this.$http({
              url: this.$http.adornUrl(`/dict/` + methodName),
              method: method,
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'code': this.dataForm.code,
                'name': this.dataForm.name,
                'linkman': this.dataForm.linkman,
                'phone': this.dataForm.phone,
                'typeId': this.dataForm.typeId,
                'sort': this.dataForm.sort,
                'remark': this.dataForm.remark,
                'status': this.dataForm.status
              })
            }).then(({data}) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      },
      checkChange(val){
        if(val==="bd706c1d2f8411ebacfd00163e128711"){
          this.phoneT=true
          this.linkmanT=true
        }else{
          this.phoneT=false
          this.linkmanT=false
        }
      }
    }
  }
</script>
