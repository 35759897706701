<template>
  <div class="order-examine-popup">
    <el-dialog title="订单审核" :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="审核状态" prop="status">
               <el-radio v-model="dataForm.status" label=2>审核通过</el-radio>
                <el-radio v-model="dataForm.status" label=10>审核不通过（订单退回）</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="审核说明" prop="reason">
              <el-input v-model="dataForm.reason" type="textarea" rows="5" placeholder="审核说明限200字"  maxlength="200" show-word-limit ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        reason: '',
        status: '',
        ids: ''
      },
      row: {},
      dataRule: {
        reason: [
          { required: true, message: '审核说明不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '审核状态不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
      this.dataForm.reason = null
      this.dataForm.status = null
      this.dataForm.ids = null
      this.resetForm()
    },
    resetForm () {
      // this.$refs['dataForm'].resetFields();
    },
    confirm () {
      let ids = []
      this.row.forEach(item => {
        ids.push(item.id)
      })
      this.dataForm.ids = ids
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/waybill/waybillAudit`),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })

            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>
