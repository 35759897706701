<template>
  <div class="order-create-body createSpecial-body">
    <el-form size="small" label-width="100px">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>【订单运费】不可见设置</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="10">
            <el-form-item label="运费费用">
              <el-radio-group v-model="orderFreightConfig.showRange" @change="updateConfig(orderFreightConfig)">
                <el-radio :label="1">全部不可见</el-radio>
                <el-radio :label="2">自定义不可见</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14" v-if="orderFreightConfig.showRange === 2">
            <label style="font-size: 14px">选择不可见对象，已选择：</label>
            <label style="font-size: 14px; color:red;">{{ orderFreightConfig.showMember }}</label>
            <label style="font-size: 14px; margin-right: 10px"> 人</label>
            <el-button size="mini" @click="showSelectTree(orderFreightConfig.id)">选择</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-dialog title="选择"
               :visible.sync="selectDriverLoading"
               width="500px">
      <div>
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          clearable>
        </el-input>
        <el-tree
          class="filter-tree"
          :data="driverTreeList"
          :props="defaultProps"
          node-key="id"
          :filter-node-method="filterNode"
          :default-checked-keys="currentConfig.driverIdList"
          :show-checkbox="true"
          ref="tree">
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectDriverLoading = false">取 消</el-button>
        <el-button type="primary" @click="saveDriverConfig">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      filterText: null,
      props: { multiple: true, value: 'id', label: 'name' },
      driverTreeList: [],
      /**
       * 订单运费配置
       */
      orderFreightConfig: {
        id: '',
        showRange: 1,
        showMember: 0
      },
      /**
       * 当前正在配置的信息
       */
      currentConfig: {
        id: '',
        driverIdList: []
      },
      orderFreightConfigCode: 'ORDER_FREIGHT',
      selectDriverLoading: false,
      dataForm: {}
    }
  },
  components: {},
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  activated () {
    this.getDriverTree()
  },
  created () {
    this.getOrderFreightConfig()
  },
  methods: {
    updateConfig (config) {
      this.$http({
        url: this.$http.adornUrl('/appletConfig/updateConfig'),
        method: 'POST',
        data: config
      }).then(({ data }) => {
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    getDriverTree () {
      this.$http({
        url: this.$http.adornUrl('/driver/findAllTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.driverTreeList = data
      })
    },
    showSelectTree (configId) {
      this.currentConfig.id = configId
      this.$http({
        url: this.$http.adornUrl('/appletConfig/getConfigDriverIdList'),
        method: 'get',
        params: this.$http.adornParams({ configId: configId })
      }).then(({ data }) => {
        this.currentConfig.driverIdList = data
      })
      this.selectDriverLoading = true
    },
    /**
     * 获取订单运费配置
     */
    getOrderFreightConfig () {
      this.$http({
        url: this.$http.adornUrl('/appletConfig/getInfoByConfigCode'),
        method: 'get',
        params: this.$http.adornParams({ configCode: this.orderFreightConfigCode })
      }).then(({ data }) => {
        this.orderFreightConfig = data
      })
    },
    /**
     * 获取选中节点
     */
    getCheckedNodes () {
      let checkedNodes = this.$refs.tree.getCheckedNodes()
      if (checkedNodes.length === 0) {
        this.$message.error('自定义至少选择一个')
        return false
      }
      let driverIdList = []
      for (let i = 0; i < checkedNodes.length; i++) {
        let checkedNode = checkedNodes[i]
        if (checkedNode.children) {
          continue
        }
        driverIdList.push(checkedNode.id)
      }
      if (driverIdList.length === 0) {
        this.$message.error('自定义至少选择一个')
        return false
      }
      this.currentConfig.driverIdList = driverIdList
      return true
    },
    /**
     * 保存配置
     */
    saveDriverConfig () {
      if (!this.getCheckedNodes()) {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/appletConfig/saveConfig'),
        method: 'POST',
        data: this.currentConfig
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.selectDriverLoading = false
              // 清空选择
              this.currentConfig.driverIdList = []
              this.getOrderFreightConfig()
            }
          })
        } else {
          this.$message.error(data.message)
        }
      })
    }
  }
}
</script>
