<template>
  <div class="declar-popup popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】报关方式' : (todo == 'edit' ? '【修改】报关方式' : '【查看】报关方式')"
      :close-on-click-modal="false" width="60%" :visible.sync="visible">
      <div class="boxSize">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
          <p class="title">
            <span class="separate">|</span>基本信息
          </p>
          <div class="infoBox infoBox1">
            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="dataForm.name" :disabled="isViews" placeholder="名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="排序号" prop="sequence">
                  <el-input-number v-model="dataForm.sequence" :disabled="isViews" :min="0"   placeholder="请输入排序号"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
<!--            <el-row :gutter="50">-->
<!--              <el-col :span="12">-->
<!--                  <el-form-item label="是否报关" prop="show">-->
<!--                    <el-select class="item-choose" v-model="dataForm.show" :disabled="isViews"  style="width: 100%;" filterable clearable placeholder="请选择">-->
<!--                      <el-option label="是" :value=1 ></el-option>-->
<!--                      <el-option label="否" :value=2 ></el-option>-->
<!--                    </el-select>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->
            <el-row :gutter="50">
              <el-col :span="24">
<!--                <el-form-item label="地址" prop="address">-->
<!--                  <el-input v-model="dataForm.address" :disabled="isViews" placeholder="地址"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="地址" prop="address">

                <el-autocomplete style="width: 100%"
                                 :loading="isLoading"
                                 popper-class="my-autocomplete"
                                 v-model="dataForm.address"
                                 :fetch-suggestions="querySearch"
                                 placeholder="地址"
                                 clearable :disabled="isViews"
                                 @select="handleSelect($event)">
                  <i
                    class="el-icon-location-outline el-input__icon"
                    slot="suffix"
                    @click="handleIconClick">
                  </i>
                  <template slot-scope="{ item }">
                    <div class="name">{{ item.title }}</div>
                    <span class="addr">{{ item.address }}</span>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
                <el-col :span="24">
                  <el-form-item label="备注" prop="remark">
                    <el-input v-model="dataForm.remark" :disabled="isViews" placeholder="请输入备注"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div>
          <p class="title">
            <span class="separate">|</span>联系人信息
          </p>
          <div class="contacts">
            <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="150px" size="small">
              <el-row v-for="(item, index) in dataForm.entranceContactList" :key="index" :gutter="50">
                <el-col :span="10">
                  <el-form-item label="联系人" prop="contactName">
                    <el-input v-model="item.contactName" :disabled="isViews" placeholder="联系人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-input v-model="item.contactPhone" :disabled="isViews" placeholder="可输入手机号码或固定电话"></el-input>
                </el-col>
                <el-col :span="2" style="text-align: center;">
                  <span class="deleteBtn" v-if="todo != 'view' && dataForm.entranceContactList.length != 1" @click="deleteContact(index)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="add" @click="addContact(index)" v-if="todo != 'view' && index == dataForm.entranceContactList.length-1">
                      <i class="el-icon-circle-plus-outline"></i>
                    </span>
                </el-col>
              </el-row>
            </el-form>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit(todo)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        name: null,
        address: null,
        show: null,
        sequence: null,
        remark: null,
        longitude: null,
        latitude: null,
        entranceContactList: [{
          contactName: null,
          contactPhone: null
        }]
      },
      isLoading: false, // 详细地址输入不显示加载框
      dataRule: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        sequence: [
          { required: true, message: '是否显示地址不能为空', trigger: 'change' }
        ]
      },
      todo: '' // 当前弹框类型， add:新增 ； edit：编辑 ； view：查看
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view'
    }
  },
  methods: {
    querySearch (queryString, cb) {
      // this.restaurants 调用接口获取
      if (queryString !== '') {
        this.isLoading = true
        this.restaurants = []
        this.$http({
          url: this.$http.adornUrl(`/order/txAddressByKeyword/` + queryString),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          console.log('querySearch', JSON.parse(data))
          let dataJson = JSON.parse(data)
          if (dataJson.status === 0) {
            let addressDate = dataJson.data
            addressDate.forEach((item) => {
              this.restaurants.push({
                address: item.address,
                title: item.title,
                province: item.province,
                city: item.city,
                district: item.district,
                location: item.location
              })
            })
          }
        }).then(() => {
          var restaurants = this.restaurants
          // 调用 callback 返回建议列表的数据
          cb(restaurants)
          this.isLoading = false
        })
      }
    },
    createFilterAddress (queryString) {
      return (restaurant) => {
        return (restaurant.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (item) {
      this.dataForm.address = item.address
      this.dataForm.longitude = item.location.lng
      this.dataForm.latitude = item.location.lat
    },
    handleIconClick (ev) {
      console.log('handleIconClick', ev)
    },
    // 添加联系人
    addContact (index) {
      this.dataForm.entranceContactList.push({
        contactName: null,
        contactPhone: null
      })
    },
    // 删除
    deleteContact (index) {
      if (this.dataForm.entranceContactList.length === 1) {
        this.$message.error('至少要有一条联系人信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.dataForm.entranceContactList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    init (todo, row) {
      if (todo === 'add') {
        this.dataForm.name = ''
        this.dataForm.address = ''
        this.dataForm.show = ''
        this.dataForm.sequence = ''
        this.dataForm.remark = ''
        this.dataForm.longitude = ''
        this.dataForm.latitude = ''
        this.dataForm.entranceContactList = [{
          contactName: '',
          contactPhone: ''
        }]
      } else {
        // this.dataForm = row
        this.$http({
          url: this.$http.adornUrl(`/entranceType/${row.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            // console.log(data)
            this.dataForm = data
            if (!this.dataForm.entranceContactList.length) {
              this.dataForm.entranceContactList = [{
                contactName: '',
                contactPhone: ''
              }]
            }
          } else {
            this.$message.error('操作失败')
          }
        })
      }
      this.todo = todo
      this.visible = true
    },
    // 表单校验
    checkValid () {
      for (let i = 0; i < this.dataForm.entranceContactList.length; i++) {
        if (!this.dataForm.entranceContactList[i].contactName) {
          this.$message.error('请完善客户联系人客户名称')
          return false
        } else if (!this.dataForm.entranceContactList[i].contactPhone) {
          this.$message.error('请完善客户联系人客户电话')
          return false
        }
      }
      return true
    },
    // 表单提交
    dataFormSubmit (todo) {
      if (!this.checkValid()) {
        return false
      } else if (todo === 'view') {
        this.visible = false
      } else {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            let type = this.todo === 'add' ? 'add' : 'update'
            let method = this.todo === 'add' ? 'put' : 'post'
            this.$http({
              url: this.$http.adornUrl(`/entranceType/${type}`),
              method: method,
              data: this.$http.adornData(this.dataForm)
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less">
.declar-popup {
  .el-input-number--small {
    width: 130px;
    line-height: 24px;
    height: 23px;
  }
  .infoBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px
  }
  .infoBox1 {
    max-height: 280px;
    overflow: auto;
    padding-right: 25px;
    padding-top: 20px;
    .el-form-item {
      margin-bottom: 4px;
    }
  }
  .contacts {
      display: block;
      margin: 10px 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      position: relative;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      .el-row {
        width: 100%;
        .el-col {
            padding: 0 !important;
            height: 32px;
            line-height: 32px;
        }
        .add, .deleteBtn {
          // position: absolute;
          font-size: 20px;
          color: #409EFF;
          // top: 5px;
          cursor: pointer;
        }
        .el-form-item {
          margin-bottom: 0
        }
        // .add {
        //   right: -15px;
        // }
        .deleteBtn {
          // right: 5px;
          color: red
        }
      }
    }
    .el-dialog {
      margin-top: 15vh !important;
      .el-input__inner {
        height: 26px !important;
      }
      .el-dialog__header {
        padding: 10px 20px 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 500px;
      overflow: auto;
      .title {
          margin: 0;
          padding-left: 21px;
          font-size: 18px;
          font-weight: bold;
      }
    }
    .el-dialog__footer {
      padding: 10px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
  .infoBox2 {
    padding: 25px;
    td {
      padding: 2px 0
    }
  }
}
</style>
