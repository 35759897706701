// 更全面的中文Unicode编码范围参考，涵盖更多生僻字及特殊中文符号等情况
const CHINESE_REGEX = /[\u2E80-\u9FFF]/

export default {
  inserted (el) {
    // 存储原始的keydown和paste事件监听器，用于后续移除等操作（比如组件更新时）
    const originalKeydownListener = el._originalKeydownListener || null
    const originalPasteListener = el._originalPasteListener || null

    // 定义keydown事件监听器
    const keydownListener = function (e) {
      const keyCode = e.keyCode || e.which
      const charCode = String.fromCharCode(keyCode)
      const isChinese = CHINESE_REGEX.test(charCode)
      if (isChinese) {
        e.preventDefault()
      }
    }

    // 定义paste事件监听器
    const pasteListener = function (e) {
      const clipboardData = e.clipboardData || window.clipboardData
      const pastedText = clipboardData.getData('text')
      const hasChinese = CHINESE_REGEX.test(pastedText)
      if (hasChinese) {
        e.preventDefault()
      }
    }

    // 先移除可能之前添加过的相同事件监听器（避免重复添加导致多次触发等问题）
    if (originalKeydownListener) {
      el.removeEventListener('keydown', originalKeydownListener)
    }
    if (originalPasteListener) {
      el.removeEventListener('paste', originalPasteListener)
    }

    // 添加keydown和paste事件监听器
    el.addEventListener('keydown', keydownListener)
    el._originalKeydownListener = keydownListener
    el.addEventListener('paste', pasteListener)
    el._originalPasteListener = pasteListener
  },
  unbind (el) {
    // 当指令解绑时（比如组件销毁），移除添加的事件监听器，防止内存泄漏等问题
    const originalKeydownListener = el._originalKeydownListener
    const originalPasteListener = el._originalPasteListener
    if (originalKeydownListener) {
      el.removeEventListener('keydown', originalKeydownListener)
    }
    if (originalPasteListener) {
      el.removeEventListener('paste', originalPasteListener)
    }
  }
}
