// 货物运输特别说明
<template>
  <div class="explain-popup">
    <el-dialog
      title="报价说明"
      :visible.sync="explainPriceVisible"
      width="50%"
      center>
      <div>
          <p>1、专车车型地址不变的情况下，订单按此价格计费。</p>
          <p>2、拼车体积地址不变的情况下，订单按此价格计费。</p>
          <p>3、4.2米以上车型都可免费提供尾板。</p>
          <p>4、不含入仓费等代垫费，代垫费用实报实销。</p>
          <p>5、拼车价格，超重货物按照1:500计算。</p>
          <p>6、机场收货和送货，保税区提货各车型加收100元/车次</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="explainPriceVisible = false">已 阅</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="货物运输特别说明"
      :visible.sync="transportVisible"
      width="70%"
      center>
      <div>
        <strong>
          <p>禁止入仓货物：《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品；</p>
          <p>目前，我司仓库不接收的货物品名如下（包括但不限于以下品名）：</p>
        </strong>
        <p>1.  爆炸品：炸药、雷管、火药、子弹、烟花、鞭炮等</p>
        <p>2.  易燃液体：打火机、打火机油、空气清新剂、摩丝(发胶、头发定型剂)、液化气，灭火器</p>
        <p>3.  易燃液体：油漆、酒精、白酒、胶黏剂、粘合剂、丙酮、汽油、柴油、天那水、胶水、 粘合剂、油墨、定型水、指甲油、香水</p>
        <p>4.  易燃固体：火柴、硫磺、明胶、硝基、磷、镁、锂电池、乒乓球</p>
        <p>5.  毒性物质：砷、尼古丁、氰化物、氰化钾、杀虫剂、农药</p>
        <p>6.  腐蚀品：硫酸、盐酸、氢氧化钠、氢氧化钾、水银、双氧水、电镀用溶液、蓄电池、清洗液、清洁水</p>
        <p>7.  杂类：安全气囊、充电宝（移动电源）</p>
        <p></p>
        <strong>
          <p>非危险品声明</p>
        </strong>
        <p style="text-indent: 25px">现申明本进仓单所对应货物，非易燃易爆易腐蚀、非有毒有害物质、非氧化剂、非麻醉品、精神性药品、无放射性、不可用于制造化学武器、不属于《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品，申报属实，且货物在仓储及装卸过程中，如发生因货物本身原因导致的安全隐患或事故，我方承担全部责任。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="transportVisible = false">我已阅读并同意</el-button>
        <el-button  @click="transportVisible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      explainPriceVisible: false,
      transportVisible: false
    }
  },
  methods: {
    init(flag) {
      if( flag == 'price') {
        this.explainPriceVisible = true
      } else if( flag == 'transport') {
        this.transportVisible = true
      }
    }
  }
}
</script>

<style lang="less">
.explain-popup {
  .el-dialog__body {
    padding: 0 20px !important;
    max-height: 500px
  }
  .el-dialog__title {
    font-weight: 800;
  }
}
</style>
