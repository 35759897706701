<template>
  <div id="wine_print_wrap">
    <div class="wine_print_content-box">
      <div class="wine_print_content">
        <div class="print-title">员工请假申请单</div>
        <div class="print-item flex">
          <div class="print-item-div flex">
            <div class="item-label border">申请人姓名</div>
            <div class="item-text border">{{itemInfo.driverName}}</div>
          </div>
          <div class="print-item-div flex">
            <div class="item-label border">岗位</div>
            <div class="item-text border">驾驶员</div>
          </div>
        </div>
        <div class="print-item flex">
          <div class="print-item-div flex">
            <div class="item-label">请假类型</div>
            <div class="item-text">{{itemInfo.leaveTypeName}}</div>
          </div>
        </div>
        <div class="print-item flex">
          <div class="print-item-div flex">
            <div class="item-label">请假起止日期</div>
            <div class="item-text">{{itemInfo.leaveTimeDesc}}</div>
          </div>
        </div>
        <div class="print-item flex setheight">
          <div class="print-item-div flex">
            <div class="item-label">请假事由</div>
            <div class="item-text">{{itemInfo.leaveDesc}}</div>
          </div>
        </div>
        <div class="print-item flex">
          <div class="print-item-div flex">
            <div class="item-label">部门经理签字</div>
            <div class="item-text"></div>
          </div>
        </div>
        <div class="print-item flex">
          <div class="print-item-div flex">
            <div class="item-label">总经理签字</div>
            <div class="item-text">{{itemInfo.auditName}}</div>
          </div>
        </div>
        <div class="print-item flex setheight1">
          <div class="print-item-div flex">
            <div class="item-label">请假说明</div>
            <div class="item-text" style="white-space: pre-wrap;">{{ itemInfo.leaveConfigDesc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'print',
  components: {},
  props: {
    itemInfo: {
      type: Object,
      default: {}
    },
  },
  filters: {},
  data() {
    return {
      itemRow:{},
      itemList:[],
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.pxExcept(@name, @mm) {
  @{name}: @mm * (210/2480);
}
.flex{
  display: flex;
  align-items: center;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
}
body{
  -webkit-print-color-adjust: exact;
  background: #fff;
}
#wine_print_wrap{
  .pxExcept(width,2480mm);
  margin: 0 auto;
  background: #fff;
}
.wine_print_content{
  .pxExcept(padding-left,90mm);
  .pxExcept(padding-right,90mm);
  .pxExcept(padding-top,80mm);
  .pxExcept(height,3507.42857142mm);
  margin: 0 auto;
  overflow: hidden;
}
.print-title{
  text-align: center;
  .pxExcept(font-size,60mm);
  .pxExcept(margin-bottom,60mm);
}
.print-item .print-item-div:nth-of-type(2n) .item-label{
  border-left: none;
}
.print-item-div{
  flex: 1;
  flex-shrink: 0;
}

.item-label{
  display: flex;
  align-items: center;
  justify-content: center;
  .pxExcept(width,420mm);
  .pxExcept(padding-top,40mm);
  .pxExcept(padding-bottom,40mm);
  text-align: center;
  background: #f1f1f1;
  flex-shrink: 0;
  border: 1px solid #666;
  border-top: none;
  .pxExcept(min-height,138mm);
}
.item-text{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .pxExcept(padding-top,40mm);
  .pxExcept(padding-bottom,40mm);
  .pxExcept(padding-left,40mm);
  .pxExcept(padding-right,40mm);
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #666;
  border-right: 1px solid #666;
  .pxExcept(min-height,138mm);
}
.print-item .border{
  border-top: 1px solid #666;
}
.setheight{
  .item-label{
    .pxExcept(min-height,240mm);
  }
  .item-text{
    .pxExcept(min-height,240mm);
  }
}
.setheight1{
  .item-label{
    .pxExcept(min-height,440mm);
  }
  .item-text{
    .pxExcept(min-height,440mm);
    justify-content: flex-start;
    text-align: left;
  }
}
</style>
