<template>
  <div class="waybill-body">
    <el-tabs type="border-card" @tab-click="selectTab" v-model="tabActive">
      <el-tab-pane :label="'全部(' + count.total + ')'" name="全部">
        <all-table ref="all" @getTotal="getTotal"></all-table>
      </el-tab-pane>
      <el-tab-pane :label="'分拨中心(' + count.allocatedCount + ')'" name="分拨中心">
        <allocated-center ref="allocatedCenter" @getTotal="getTotal"></allocated-center>
      </el-tab-pane>
      <el-tab-pane :label="'指派中(' + count.dispatchCount + ')'" name="指派中">
        <scheduling-table ref="scheduleding" @getTotal="getTotal"></scheduling-table>
      </el-tab-pane>
      <el-tab-pane :label="'报价中(' + count.offerCount + ')'" name="报价中">
        <offer-center ref="offerCenter" @getTotal="getTotal"></offer-center>
      </el-tab-pane>
      <el-tab-pane :label="'抢单中(' + count.grabCount + ')'" name="抢单中">
        <grab-center ref="grabCenter" @getTotal="getTotal"></grab-center>
      </el-tab-pane>
      <el-tab-pane :label="'已调度(' + count.yesDispatchCount + ')'" name="已调度">
          <scheduled-table ref="scheduled" @getTotal="getTotal"></scheduled-table>
      </el-tab-pane>
      <el-tab-pane :label="'已拒单(' + count.refuseCount + ')'" name="已拒单">
          <refuse-order ref="refuseOrder" @getTotal="getTotal"></refuse-order>
      </el-tab-pane>
      <el-tab-pane :label="'已出车(' + count.outDriveCount + ')'" name="已出车">
          <alreadyReleased-table ref="alreadyReleased" @getTotal="getTotal"></alreadyReleased-table>
      </el-tab-pane>
      <el-tab-pane :label="'已到达(' + count.arrivedCount + ')'" name="已到达">
        <arrived-table ref="arrived" @getTotal="getTotal"></arrived-table>
      </el-tab-pane>
      <el-tab-pane :label="'已装货(' + count.goodsCount + ')'" name="已装货">
        <loaded-table ref="loaded" @getTotal="getTotal"></loaded-table>
      </el-tab-pane>
      <el-tab-pane :label="'卸货到场(' + count.arrivedGiveCount + ')'" name="卸货到场">
        <arrived-give ref="arrivedGive" @getTotal="getTotal"></arrived-give>
      </el-tab-pane>
      <el-tab-pane :label="'已签收(' + count.successCount + ')'" name="已签收">
        <already-singed ref="already" @getTotal="getTotal"></already-singed>
      </el-tab-pane>
      <el-tab-pane :label="'订单退回(' + count.returnCount + ')'" name="订单退回">
        <order-return ref="orderReturn" @getTotal="getTotal"></order-return>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹窗 -->
  </div>
</template>

<script>
import allTable from './tableList/all-table.vue'
import schedulingTable from './tableList/scheduling-table.vue'
import scheduledTable from './tableList/scheduled-table.vue'
import alreadyReleasedTable from './tableList/alreadyReleased-table.vue'
import arrivedTable from './tableList/arrived-table.vue'
import loadedTable from './tableList/loaded-table.vue'
import alreadySinged from './tableList/alreadySigned-table.vue'
import orderReturn from './tableList/orderReturn-table.vue'
import allocatedCenter from './tableList/allocated-center-table.vue'
import offerCenter from './tableList/offer-center-table.vue'
import grabCenter from './tableList/grab-center-table.vue'
import refuseOrder from './tableList/refuseOrder-table'
import arrivedGive from './tableList/arrivedGive-table'
import { newAuth } from '@/utils'
import qs from 'qs'

export default {
  data () {
    return {
      dataForm: {
        customerIdList: []
      },
      tabActive: '分拨中心',
      count: {
        allocatedCount: 0,
        offerCount: 0,
        refuseCount: 0,
        grabCount: 0,
        arrivedCount: 0,
        dispatchCount: 0,
        goodsCount: 0,
        outDriveCount: 0,
        successCount: 0,
        yesDispatchCount: 0,
        total: 0,
        arrivedGiveCount: 0,
        returnCount: 0
      }
    }
  },
  components: {
    allTable,
    schedulingTable,
    scheduledTable,
    alreadyReleasedTable,
    arrivedTable,
    loadedTable,
    alreadySinged,
    orderReturn,
    allocatedCenter,
    offerCenter,
    grabCenter,
    refuseOrder,
    arrivedGive
  },
  activated () {
    if (!newAuth('order:manager:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    this.getTotal()
    // this.selectTab()
    // this.selectTab({ label: '调度中' })
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    // 选择标签
    selectTab (flag) {
      if (flag.label.indexOf('全部') > -1) {
        this.$store.commit('common/updateMenusSelectLi', '')
        this.$refs.all.getDataList()
      } else if (flag.label.indexOf('分拨中心') > -1) {
        this.$refs.allocatedCenter.getDataList()
      } else if (flag.label.indexOf('报价中') > -1) {
        this.$refs.offerCenter.getDataList()
      } else if (flag.label.indexOf('抢单中') > -1) {
        this.$refs.grabCenter.getDataList()
      } else if (flag.label.indexOf('指派中') > -1) {
        this.$refs.scheduleding.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '指派中')
      } else if (flag.label.indexOf('已调度') > -1) {
        this.$refs.scheduled.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '已调度')
      } else if (flag.label.indexOf('已拒单') > -1) {
        this.$refs.refuseOrder.getDataList()
      } else if (flag.label.indexOf('已出车') > -1) {
        this.$refs.alreadyReleased.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '已出车')
      } else if (flag.label.indexOf('已到达') > -1) {
        this.$refs.arrived.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '已到达')
      } else if (flag.label.indexOf('已装货') > -1) {
        this.$refs.loaded.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '已装货')
      } else if (flag.label.indexOf('卸货到场') > -1) {
        this.$refs.arrivedGive.getDataList()
      } else if (flag.label.indexOf('已签收') > -1) {
        this.$refs.already.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '已签收')
      } else if (flag.label.indexOf('订单退回') > -1) {
        this.$refs.orderReturn.getDataList()
        this.$store.commit('common/updateMenusSelectLi', '订单退回')
      }
    },
    getTotal () {
      let param = {
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(this.dataForm.customerIdList == null || this.dataForm.customerIdList == undefined ? '/order/count/listTitle' : '/order/count/listTitle?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.count = data
        this.dataListLoading = false
      })
    }
  },
  watch: {
    '$store.state.common.wayBillType': {
      handler: function (val, oldVal) {
        if (val) {
          this.selectTab({ label: val })
          this.tabActive = val
        } else {
          this.selectTab({ label: '全部' })
          this.tabActive = '全部'
        }
      },
      deep: true
    }
    // $route: {
    //   handler: function (val, oldVal) {
    //     if (val.params.type) {
    //       this.selectTab({ label: val.params.type })
    //       this.tabActive = val.params.type
    //     } else {
    //       this.selectTab({ label: '全部' })
    //       this.tabActive = '全部'
    //     }
    //   },
    //   deep: true
    // }
  }
}
</script>

<style lang="less">
#pane-operPlatform-waybillManager {
  .el-tabs--border-card>.el-tabs__content {
    padding: 5px;
  }
}
</style>
