<template>
  <el-dialog
    :title="'【详情】车辆信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm"ref="dataForm" size="small" label-width="120px">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="车牌号" prop="vehiclePlate">
            <el-input v-model="dataForm.vehiclePlate" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属车队" prop="motorcadeName">
            <el-input v-model="dataForm.motorcadeName" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="车型" prop="vehicleType">
            <el-input v-model="dataForm.vehicleType" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="能源类型" prop="energyType">
            <el-input v-model="dataForm.energyType" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="行驶证编号" prop="drivingCode">
            <el-input v-model="dataForm.drivingCode" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="车辆照片" prop="vehicleFileList">
            <el-upload  style="margin-top: -30px;"
              action="/upload/img"
              :on-preview="handlePreview"
              :before-remove="removeFileList"
              :file-list="vehicleFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="行驶证照片" prop="drivingFileList">
            <el-upload  style="margin-top: -30px;"
              class="upload-demo"
              action="/upload/img"
              :on-preview="handlePreview"
              :before-remove="removeFileList"
              :file-list="drivingFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="3" v-model="dataForm.remark" readonly="readonly" disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        vehiclePlate: null,
        motorcadeName: null,
        vehicleType: null,
        energyType: null,
        drivingCode: null,
        vehicleFileList: [],
        drivingFileList: [],
        remark: null,
        status: 1
      },
      dialogImageUrl: '',
      dialogVisible: false,
      vehicleFileList: [],
      drivingFileList: []
    }
  },
  computed: {
    dictInfoMap: {
      get () { return this.$store.state.common.dictInfoMap }
    }
  },
  methods: {
    clearFileList () {
      this.vehicleFileList = []
      this.drivingFileList = []
      this.dataForm.vehicleFileList = []
      this.dataForm.drivingFileList = []
    },
    initFileList (dto) {
      if (dto.vehicleFileList) {
        this.dataForm.vehicleFileList = dto.vehicleFileList
        for (let i = 0; i < dto.vehicleFileList.length; i++) {
          this.vehicleFileList.push({name: dto.vehicleFileList[i].name, url: dto.vehicleFileList[i].url})
        }
      }
      if (dto.drivingFileList) {
        this.dataForm.drivingFileList = dto.drivingFileList
        for (let i = 0; i < dto.drivingFileList.length; i++) {
          this.drivingFileList.push({name: dto.drivingFileList[i].name, url: dto.drivingFileList[i].url})
        }
      }
    },
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.clearFileList()
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/vehicleInfo/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.vehiclePlate = data.vehiclePlate
            this.dataForm.motorcadeName = data.motorcadeName
            this.dataForm.vehicleType = this.dictInfoMap[data.vehicleType]
            this.dataForm.energyType = this.dictInfoMap[data.energyType]
            this.dataForm.drivingCode = data.drivingCode
            this.dataForm.remark = data.remark
            this.initFileList(data)
          }
        })
      }
    },
    // 图片查看
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    removeFileList () {
      return false
    }
  }
}
</script>
