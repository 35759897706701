// 车辆利润统计表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="供应商">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="月份" prop="takeGoodsDate">
          <el-date-picker
            v-model="dataForm.takeGoodsDate"
            type="monthrange"
            align="right"
            unlink-panels
            value-format="yyyy-MM"
            :clearable="false"
            range-separator="-"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                show-summary
                :height="this.tableHeight"
                border size="small"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column type="index" label="序号" width="40" fixed="left">
        </el-table-column>
        <el-table-column prop="motorcadeName" label="供应商" width="90" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column prop="vehiclePlate" label="车牌号" width="75" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column prop="yearMonth" label="年月" width="60" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column prop="vehicleProfitMonth" label="利润" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="freightFee" label="应收费用" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="payFreightFee" label="应付费用" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="refuelFee" label="加油费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="passingFee" label="过路费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="pumpElementFee" label="泵素费" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column prop="stayFee" label="住宿费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="extraFee" label="加班费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column prop="royaltyFee" label="司机工资提成" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column prop="salesmanFee" label="业务员提成费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="salesmanManagerFee" label="业务经理提成费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="serviceFee" label="客服提成费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="managerFee" label="客服经理提成费" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column prop="saleRoyaltyFee" label="业务部提成费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="careRoyaltyFee" label="客服部提成费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="maintainFee" label="客户维护费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="supplierCost" label="供应商费用" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="taxesFee" label="客户税费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createCardFee" label="车辆办证费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="insureMoney" label="车辆保险费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="repairMoney" label="车辆维修费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="lossMoney" label="事故损失费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="affiliationFee" label="挂靠费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="socialFee" label="社保费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="monthParkingFee" label="停车费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="ureaFee" label="尿素费" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="otherFee" label="其他费" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick (picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick (picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dataForm: {
        vehiclePlate: null,
        motorcadeName: null,
        takeGoodsDate: null
      },
      showTotal: false,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      tableHeight: '65vh',
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  created () {
    let searchDate = this.dateFormatter(new Date(), 'yyyy-MM')
    this.dataForm.takeGoodsDate = [searchDate, searchDate]
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costSummary/vehicleProfitList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'motorcadeName': this.dataForm.motorcadeName,
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.profitCount || {})
          this.tableHeight = '70vh'
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        if (!this.showTotal) {
          // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
          this.tableHeight = '67vh'
          this.showTotal = true
        }
        this.dataListLoading = false
      })
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsDate
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/costSummary/export/vehicleProfit`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'vehiclePlate': this.dataForm.vehiclePlate,
          'motorcadeName': this.dataForm.motorcadeName,
          'beginDate': timeParam.takeGoodsBeginDate,
          'endDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '车辆利润统计表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    getValue (value) {
      if (value === undefined || value === null) {
        return ''
      }
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (profitCount) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '',
        this.getValue(profitCount.vehicleProfitMonth),
        this.getValue(profitCount.freightFee),
        this.getValue(profitCount.payFreightFee),
        this.getValue(profitCount.refuelFee),
        this.getValue(profitCount.passingFee),
        this.getValue(profitCount.pumpElementFee),
        this.getValue(profitCount.royaltyFee),
        // this.getValue(profitCount.salesmanFee),
        // this.getValue(profitCount.salesmanManagerFee),
        // this.getValue(profitCount.serviceFee),
        // this.getValue(profitCount.managerFee),
        this.getValue(profitCount.saleRoyaltyFee),
        this.getValue(profitCount.careRoyaltyFee),
        this.getValue(profitCount.maintainFee),
        this.getValue(profitCount.supplierCost),
        this.getValue(profitCount.taxesFee),
        this.getValue(profitCount.createCardFee),
        this.getValue(profitCount.insureMoney),
        this.getValue(profitCount.repairMoney),
        this.getValue(profitCount.lossMoney),
        this.getValue(profitCount.affiliationFee),
        this.getValue(profitCount.socialFee),
        this.getValue(profitCount.monthParkingFee),
        this.getValue(profitCount.ureaFee),
        this.getValue(profitCount.otherFee)
      ]
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
  span.searchUpload {
    display: inline-block;
    margin-left: 10px;
  }
</style>
