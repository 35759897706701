<template>
  <div class="order-examine-popup">
    <el-dialog title="【解绑】- 车辆与驾驶员解绑" :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <div>
        请确认是否继续执行【解绑】操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      row: {}
    }
  },
  computed: {
    isView () {
      return false
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
    },
    confirm () {
      this.$http({
        url: this.$http.adornUrl(`/vehicleInfo/unBindDriver/${this.row.id}`),
        method: 'POST'
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error('操作失败，请刷新后再试')
        }
      })
    }
  }
}
</script>
