<template>
  <div class="order-create-body">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单基本信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="客户名称" prop="customerId">
              <el-select class="item-choose" v-model="dataForm.customerId" :disabled="readonly || isCustomer" filterable
                @change="changeCustomer(false)" clearable placeholder="请选择">
                <el-option v-for="item in customerList" :key="item.id" :label="item.simpleName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户联系人" prop="customerContactName">
              <!-- <el-input v-model="dataForm.customerContactName" :disabled="readonly" placeholder="客户联系人" maxlength="40" show-word-limit></el-input> -->
              <el-autocomplete class="inline-input" :disabled="readonly" v-model="dataForm.customerContactName"
                :fetch-suggestions="queryNameSearch" value-key="contactName" placeholder="客户联系人" style="width:100%"
                clearable @select="nameSelect"></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系电话" prop="customerContactPhone">
              <el-input v-model="dataForm.customerContactPhone" :disabled="readonly" placeholder="客户联系人电话"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="报关方式" prop="entranceType">
              <el-select class="item-choose" v-model="dataForm.entranceType" :disabled="readonly" filterable clearable
                placeholder="请选择" @clear="changeType" popper-class="auto-weight" @change="changeType">
                <el-option v-for="item in dictTypeMap.entrance_type" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户单号" prop="customerSelfNo">
              <el-input v-model="dataForm.customerSelfNo" :disabled="readonly" placeholder="客户单号" maxlength="40"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="进仓单号" prop="inWarehouseNo">
              <el-input v-model="dataForm.inWarehouseNo" :disabled="readonly" placeholder="进仓单号" maxlength="40"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="提货日期" prop="takeGoodsDate">-->
          <!--              <el-date-picker v-model="dataForm.takeGoodsDate" :disabled="readonly" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">-->
          <!--              </el-date-picker>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="提货时间" prop="takeGoodsTime">-->
          <!--              <el-time-picker v-model="dataForm.takeGoodsTime" :disabled="readonly" format="HH:mm" value-format="HH:mm" placeholder="选择时间">-->
          <!--              </el-time-picker>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="卸货时间" prop="abortWarehouseTime">-->
          <!--              <el-date-picker-->
          <!--                v-model="dataForm.abortWarehouseTime"-->
          <!--                :disabled="readonly"-->
          <!--                type="datetime"-->
          <!--                default-time="12:00:00"-->
          <!--                format="yyyy-MM-dd HH:mm"-->
          <!--                value-format="yyyy-MM-dd HH:mm"-->
          <!--                placeholder="选择日期时间">-->
          <!--              </el-date-picker>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
        <el-row :gutter="50" v-if="readonly">
          <el-col :span="19">
            <el-steps :active="statusActive" align-center finish-status="success">
              <el-step title="派车时间" :description="dispatchTime"></el-step>
              <el-step title="出车时间" :description="outDriveTime"></el-step>
              <el-step title="装货到场时间" :description="arrivedTime"></el-step>
              <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
              <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
              <el-step title="签收时间" :description="successTime"></el-step>
            </el-steps>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>提卸/货信息</label>
        运输里程：<span style="color: red">{{ mileage }} </span>KM
      </div>
      <div class="modularBox modularBox2">
        <div v-for="(item, index) in addressInfoList" :key="item.id">
          <!--提卸货地址基本信息-->
          <el-row :gutter="50">
            <el-col :span="24">
              <el-row>
                <el-form-item :rules="[{ required: true }]">
                  <el-col :span="1" style="margin-left:-84px">
                    <el-button type="primary" :disabled="readonly" @click="selectAddress(1, index)">提货地</el-button>
                  </el-col>
                  <el-col :span="3">
                    <el-cascader v-model="item.cityList" :disabled="readonly" :options="$store.state.areaList"
                      ref="cascaderTake" clearable filterable @change="calculation(1, index)" change-on-select
                      :props="{ checkStrictly: true, value: 'name', label: 'name' }"
                      placeholder="选择省-市-区"></el-cascader>
                  </el-col>
                  <el-col :span="5">
                    <!--                    <el-input v-model="item.address" :disabled="readonly" placeholder="详细地址" maxlength="200"></el-input>-->
                    <el-autocomplete style="width: 100%" :disabled="readonly" :loading="isLoading"
                      popper-class="my-autocomplete" v-model="item.address" :fetch-suggestions="querySearch"
                      placeholder="详细地址" @select="handleSelect($event, index, 1)">
                      <i class="el-icon-location-outline el-input__icon" slot="suffix" @click="handleIconClick">
                      </i>
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.title }}</div>
                        <span class="addr">{{ item.address }}</span>
                      </template>
                    </el-autocomplete>
                  </el-col>
                  <el-col :span="3">
                    <el-autocomplete class="inline-input" v-model="item.factoryName"
                      :fetch-suggestions="filterTakeAddress" :disabled="readonly" value-key="simpleName"
                      style="width:100%" clearable popper-class="auto-weight" placeholder="装货工厂"
                      @select="getAddress($event, index, 1)">
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.simpleName }} - {{ item.contactName }} - {{ item.contactPhone }} - {{
                          item.fullAddress }}</div>
                      </template>
                    </el-autocomplete>
                  </el-col>
                  <el-col :span="3">
                    <el-input v-model="item.contactName" :disabled="readonly" placeholder="装货联系人"
                      maxlength="40"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <el-input v-model="item.contactPhone" :disabled="readonly" placeholder="联系电话"
                      maxlength="40"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <el-form-item label="" prop="takeGoodsDate">
                      <el-date-picker v-model="dataForm.takeGoodsDate" type="date" value-format="yyyy-MM-dd"
                        placeholder="装货日期" :disabled="readonly" style="width: 110%;">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <el-form-item label="" prop="takeGoodsTime" style="margin-left: 12px">
                      <el-time-picker v-model="dataForm.takeGoodsTime" format="HH:mm" value-format="HH:mm"
                        placeholder="装货时间" :disabled="readonly" style="width: 100%;">
                      </el-time-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <el-input v-model="item.route" :disabled="readonly" placeholder="*起点" maxlength="40"></el-input>
                  </el-col>
                  <i class="el-icon-delete address-delete"
                    v-if="addressInfoList.length != 1 && dataForm.orderType == 1 && !readonly && isAddAddress"
                    @click="deleteAddressInfoRow(index)"></i>
                  <span class="addAddressRow" @click="insertAddressInfoRow"
                    v-if="addressInfoList.length == (index + 1) && dataForm.orderType == 1 && !readonly && isAddAddress"><i
                      class="el-icon-circle-plus-outline"></i></span>
                </el-form-item>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="50" v-if="showEntranceAddress">
          <el-col :span="24">
            <el-row :gutter="50">
              <el-col :span="24">
                <el-row>
                  <el-form-item :rules="[{ required: true }]">
                    <el-col :span="1" style="margin-left:-84px">
                      <el-button type="info">报关方</el-button>
                    </el-col>
                    <el-col :span="3">
                      <el-autocomplete class="inline-input" v-model="entranceAddressInfo.contactName"
                        :fetch-suggestions="entranceContactSearch" value-key="contactName" :disabled="readonly"
                        style="width:100%" clearable popper-class="auto-weight" placeholder="联系人"
                        @select="entranceContactSelect"></el-autocomplete>
                    </el-col>
                    <el-col :span="3">
                      <el-input v-model="entranceAddressInfo.contactPhone" placeholder="联系电话" :disabled="readonly"
                        show-word-limit></el-input>
                    </el-col>
                    <el-col :span="16">
                      <!--                      <el-input v-model="entranceAddressInfo.fullAddress" placeholder="详细地址" :disabled="readonly" maxlength="200"-->
                      <!--                                show-word-limit></el-input>-->
                      <el-autocomplete style="width: 100%" :loading="isLoading" popper-class="my-autocomplete"
                        v-model="entranceAddressInfo.fullAddress" :fetch-suggestions="querySearch" placeholder="详细地址"
                        clearable :disabled="readonly" @select="handleSelect($event, 0, 3)">
                        <i class="el-icon-location-outline el-input__icon" slot="suffix" @click="handleIconClick">
                        </i>
                        <template slot-scope="{ item }">
                          <div class="name">{{ item.title }}</div>
                          <span class="addr">{{ item.address }}</span>
                        </template>
                      </el-autocomplete>
                    </el-col>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div v-for="(item, index) in unLoadAddressInfoList" :key="item.id">
          <!--卸货地址基本信息-->
          <el-row :gutter="50">
            <el-col :span="24">
              <el-row>
                <el-form-item :rules="[{ required: true }]">
                  <el-col :span="1" style="margin-left:-84px">
                    <el-button type="danger" :disabled="readonly" @click="selectAddress(2, index)">卸货地</el-button>
                  </el-col>
                  <el-col :span="3">
                    <el-cascader v-model="item.cityList" :disabled="readonly" :options="$store.state.areaList"
                      ref="cascaderTake" clearable filterable @change="calculation(2, index)" change-on-select
                      :props="{ checkStrictly: true, value: 'name', label: 'name' }"
                      placeholder="选择省-市-区"></el-cascader>
                  </el-col>
                  <el-col :span="5">
                    <!--                    <el-input v-model="item.address" :disabled="readonly" placeholder="详细地址" maxlength="200"></el-input>-->
                    <el-autocomplete :loading="isLoading" :disabled="readonly" style="width: 100%"
                      popper-class="my-autocomplete" v-model="item.address" :fetch-suggestions="querySearch"
                      placeholder="详细地址" @select="handleSelect($event, index, 2)">
                      <i class="el-icon-location-outline el-input__icon" slot="suffix" @click="handleIconClick">
                      </i>
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.title }}</div>
                        <span class="addr">{{ item.address }}</span>
                      </template>
                    </el-autocomplete>
                  </el-col>
                  <el-col :span="3">
                    <el-autocomplete class="inline-input" v-model="item.factoryName"
                      :fetch-suggestions="filterGiveAddress" :disabled="readonly" value-key="simpleName"
                      style="width:100%" clearable popper-class="auto-weight" placeholder="选择卸货仓库"
                      @select="getAddress($event, index, 2)">
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.simpleName }} - {{ item.contactName }} - {{ item.contactPhone }} - {{
                          item.fullAddress }}</div>
                      </template>
                    </el-autocomplete>
                  </el-col>
                  <el-col :span="3">
                    <el-input v-model="item.contactName" :disabled="readonly" placeholder="卸货联系人"
                      maxlength="40"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <el-input v-model="item.contactPhone" :disabled="readonly" placeholder="联系电话"
                      maxlength="40"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="" prop="abortWarehouseTime">
                      <el-date-picker style="width: 100%" :disabled="readonly" v-model="dataForm.abortWarehouseTime"
                        type="datetime" default-time="12:00:00" format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm" placeholder="选择卸货时间">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <el-input v-model="item.route" :disabled="readonly" placeholder="*终点" maxlength="40"></el-input>
                  </el-col>
                  <i class="el-icon-delete address-delete"
                    v-if="unLoadAddressInfoList.length != 1 && dataForm.orderType == 1 && !readonly && isAddAddress"
                    @click="deleteUnLoadAddressInfoRow(index)"></i>
                  <span class="addAddressRow" @click="insertUnLoadAddressInfoRow"
                    v-if="unLoadAddressInfoList.length == (index + 1) && dataForm.orderType == 1 && !readonly && isAddAddress"><i
                      class="el-icon-circle-plus-outline"></i></span>
                </el-form-item>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!--        <el-row :gutter="50">-->
        <!--          <el-col :span="24">-->
        <!--            <el-row :gutter="50">-->
        <!--              <el-col :span="24">-->
        <!--                <el-row>-->
        <!--                  <el-form-item :rules="[{ required: true }]">-->
        <!--                    <el-col :span="1" style="margin-left:-84px">-->
        <!--                      <el-button type="danger" :disabled="readonly" @click="selectAddress(2, 0)">卸货方</el-button>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="4">-->
        <!--                      <el-select class="item-choose" v-model="giveAddressInfo.factoryName"-->
        <!--                                 filterable-->
        <!--                                 @change="selectGiveFactory"-->
        <!--                                 :disabled="readonly"-->
        <!--                                 popper-class="auto-weight"-->
        <!--                                 clearable-->
        <!--                                 placeholder="选择卸货仓">-->
        <!--                          <el-option v-for="(item) in giveAreaList" :key="item.id" :label="item.simpleName + ' - ' + item.contactName + ' - ' + item.contactPhone + ' - ' + item.fullAddress" :value="item.id">-->
        <!--                        </el-option>-->
        <!--                      </el-select>-->
        <!--&lt;!&ndash;                      <el-input v-model="giveAddressInfo.factoryName" placeholder="点击卸货方选择" :disabled="true"></el-input>&ndash;&gt;-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="6">-->
        <!--                      <el-cascader-->
        <!--                        v-model="giveAddressInfo.cityList"-->
        <!--                        :disabled="readonly || inputGive"-->
        <!--                        :options="$store.state.areaList"-->
        <!--                        ref="cascaderGive"-->
        <!--                        clearable-->
        <!--                        filterable-->
        <!--                        change-on-select-->
        <!--                        @change="calculation(2)"-->
        <!--                        :props="{ checkStrictly: true, value : 'name', label : 'name' }"-->
        <!--                        placeholder="选择省-市-区"></el-cascader>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="6">-->
        <!--                      <el-input v-model="giveAddressInfo.address" :disabled="readonly || inputGive" placeholder="详细地址" maxlength="200"></el-input>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="3">-->
        <!--                      <el-input v-model="giveAddressInfo.contactName" :disabled="readonly || inputGive" placeholder="联系人姓名" maxlength="40"></el-input>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="3">-->
        <!--                      <el-input v-model="giveAddressInfo.contactPhone" :disabled="readonly || inputGive" placeholder="联系电话" maxlength="40"></el-input>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="2">-->
        <!--                      <el-input v-model="giveAddressInfo.route" :disabled="readonly" placeholder="*终点" maxlength="40"></el-input>-->
        <!--                    </el-col>-->
        <!--                  </el-form-item>-->
        <!--                </el-row>-->
        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--提卸货地址的货物信息-->
        <div class="goodsInfoSty">
          <el-table :data="goodsInfoList" border show-summary size="mini"
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }" style="width: 92%">
            <el-table-column label="*货物名称" header-row-class-name="aaaaaaa" align="center" fixed="left" width="150">
              <template slot-scope="scope">
                <el-autocomplete class="inline-input" v-model="goodsInfoList[scope.$index].goodsName"
                  :fetch-suggestions="goodsQueryNameSearch" :disabled="readonly" style="width:100%" clearable
                  size="mini"></el-autocomplete>
                <!--                  <el-input size="mini" v-model="goodsInfoList[scope.$index].goodsName" :disabled="readonly"></el-input>-->
              </template>
            </el-table-column>
            <el-table-column align="center" width="120" label="唛头">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].shippingMark"
                  :disabled="readonly"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="100" prop="length" label="单件长(cm)">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].length" :disabled="readonly"
                  @input="calcVolume(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="width" label="单件宽(cm)">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].width" :disabled="readonly"
                  @input="calcVolume(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="height" label="单件高(cm)">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].height" :disabled="readonly"
                  @input="calcVolume(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="pieceNum" label="件数">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].pieceNum" :disabled="readonly"
                  @input="calcVolume(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="weight" label="重量(kg)">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].weight" :disabled="readonly"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="volume" label="* 体积(m³)">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].volume" :disabled="readonly"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="货物类型" align="center" width="130">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="goodsInfoList[scope.$index].goodsType" :disabled="readonly"
                  size="mini" filterable clearable placeholder="请选择">
                  <el-option v-for="item in dictTypeMap.goods_type" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="* 包装类型" align="center" width="150">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="goodsInfoList[scope.$index].packType" :disabled="readonly"
                  size="mini" filterable clearable placeholder="请选择">
                  <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column lign="center" min-width="150" label="备注">
              <template slot-scope="scope">
                <el-input size="mini" v-model="goodsInfoList[scope.$index].remark" :disabled="readonly"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="70" v-if="!readonly">
              <template slot-scope="scope">
                <i class="el-icon-delete opt-delete" v-if="goodsInfoList.length != 1"
                  @click="deleteGoodsRow(scope.$index, 1)"></i>
                <i class="el-icon-circle-plus-outline" @click="insertGoodsInfoRow(scope.$index, scope.row)"
                  v-if="goodsInfoList.length === (scope.$index + 1)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="modularLabel modularLabel3">
        <label><span class="separate">|</span>车辆信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="4">
            <el-form-item label="订单类型" prop="orderType">
              <el-select class="item-choose" v-model="dataForm.orderType" :disabled="readonly" filterable clearable
                placeholder="请选择">
                <el-option label="专车" :value="1"></el-option>
                <el-option label="拼车" :value="2"></el-option>
                <el-option label="零担" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单车型" prop="vehicleModelIdList">
              <el-select v-model="dataForm.vehicleModelIdList" multiple placeholder="请选择" :disabled="readonly">
                <el-option v-for="item in dictTypeMap.vehicle_model" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单车长(米)" prop="vehicleType" :label-width="'120px'">
              <el-select class="item-choose" v-model="dataForm.vehicleType" filterable clearable placeholder="请选择"
                @change="handleSelectVehicleChange" :disabled="readonly">
                <el-option v-for="(item, idx) in vehicleTypeList" :key="item.id" :label="item.name" :value="item.id"
                  :index="idx">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="是否带尾板" prop="isTailstock">
              <el-select class="item-choose" v-model="dataForm.isTailstock" :disabled="readonly" filterable clearable
                placeholder="请选择">
                <el-option v-for="item in dictTypeMap.yes_no" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="18">
            <el-form-item label="订单备注" prop="remark">
              <el-input type="textarea" :disabled="readonly" v-model="dataForm.remark" placeholder="请输入订单注意问题"
                maxlength="1000" :autosize="{ minRows: 2, maxRows: 4 }" show-word-limit></el-input>
              <el-button v-if="readonly != true" type="text" v-for="remark in remarks" :label="remark" :key="remark"
                @click="handleCommand(remark)"
                style="background-color: #8a979e;padding: 2px 1px;color: white;margin-right: -7px">{{ remark }}</el-button>
              <el-dropdown v-if="readonly != true" @command="handleCommand" style="margin-left: 15px">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in dictTypeMap.order_remark" :command="item.name"
                    :key="item.id">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50" v-if="showCompanyRemark">
          <el-col :span="18">
            <el-form-item label="客服备注" prop="companyRemark">
              <el-input v-model="dataForm.companyRemark" :disabled="readonly" placeholder="客服备注" maxlength="1000"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="4">
            <el-form-item label="订单路线" prop="transportMode">
              <el-select class="item-choose" v-model="dataForm.transportMode" filterable clearable placeholder="请选择"
                :disabled="readonly">
                <el-option v-for="item in dictTypeMap.transport_mode" :key="item.id" :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="操作调度" prop="careAdminIdList">
              <el-select class="item-choose" v-model="dataForm.careAdminIdList" filterable clearable placeholder="请选择"
                :disabled="readonly">
                <el-option v-for="item in userJobMap.CARE_ADMIN" :key="item.id" :label="item.realName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="操作调度" prop="careAdminIdList">-->
            <!--              <el-select class="item-choose" :disabled="readonly"-->
            <!--                         v-model="dataForm.careAdminIdList"-->
            <!--                         filterable-->
            <!--                         multiple-->
            <!--                         clearable-->
            <!--                         placeholder="请选择"-->
            <!--                         style="width: 100%"-->
            <!--                         :multiple-limit="1"-->
            <!--              >-->
            <!--                <el-option-->
            <!--                  v-for="item in  userJobMap.CARE_ADMIN"-->
            <!--                  :key="item.id"-->
            <!--                  :label="item.realName"-->
            <!--                  :value="item.id"-->
            <!--                ></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
          </el-col>
          <el-col :span="4">
            <el-form-item label="操作跟单" prop="careIdList">
              <el-select class="item-choose" v-model="dataForm.careIdList" filterable clearable placeholder="请选择"
                :disabled="readonly">
                <el-option v-for="item in userJobMap.CARE" :key="item.id" :label="item.realName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="操作跟单" prop="careIdList">-->
            <!--              <el-select class="item-choose" :disabled="readonly"-->
            <!--                         v-model="dataForm.careIdList"-->
            <!--                         filterable-->
            <!--                         multiple-->
            <!--                         clearable-->
            <!--                         placeholder="请选择"-->
            <!--                         style="width: 100%"-->
            <!--                         :multiple-limit="1"-->
            <!--              >-->
            <!--                <el-option-->
            <!--                  v-for="item in  userJobMap.CARE"-->
            <!--                  :key="item.id"-->
            <!--                  :label="item.realName"-->
            <!--                  :value="item.id"-->
            <!--                ></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
          </el-col>
        </el-row>
        <div class="vehicle-rule" v-if="vehicleRulesTips" style="margin-left: 20px; margin-top: 10px">
          <label style="font-size: 15px; font-weight: bold">车辆规则说明：</label>
          <p style="margin-left: 20px">{{ vehicleRulesTips }}</p>
        </div>
        <div class="vehicle-tip" v-if="vehicleTips" style="margin-left: 20px;">
          <label style="font-size: 15px; font-weight: bold">温馨提示：</label>
          <p style="margin-left: 20px; padding-bottom: 10px">{{ vehicleTips }}</p>
        </div>
      </div>
      <!--      <div class="modularBox modularBox3">-->
      <!--        <div class="vehicle-layout">-->
      <!--          <div v-for="(item, idx) in vehicleTypeList" :key="idx"-->
      <!--               :disabled="readonly"-->
      <!--               @click="showTips(item, idx)"-->
      <!--               :class = "isactive == idx ? 'carBox activeBox' : 'carBox'">-->
      <!--            <h3>{{ item.name }}</h3>-->
      <!--            <div class="imgs">-->
      <!--              <img :src="'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/'+item.imageUrl" :title="item.name"/>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="vehicle-rule" v-if="vehicleRulesTips">-->
      <!--          <label>车辆规则说明：</label>-->
      <!--          <p>{{ vehicleRulesTips }}</p>-->
      <!--        </div>-->
      <!--        <div class="vehicle-tip" v-if="vehicleTips">-->
      <!--          <label>温馨提示：</label>-->
      <!--          <p>{{ vehicleTips }}</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="modularLabel modularLabel4">
        <label>附件信息</label>
      </div>
      <div style="" class="modularBox modularBox4">
        <al-upload :fileData="dataForm.enclosureInfoList" :isView="todo == 'view'" :fileUrl="enclosureDto.uploadUrl"
          :businessType="enclosureDto.type" :limit="8" @getFileData="getFileData"></al-upload>
        <!-- <el-upload
          list-type="picture-card"
          :action="enclosureDto.uploadUrl"
          :data="{businessType: enclosureDto.type}"
          :headers="{'access_token': enclosureDto.accessToken}"
          :limit="8"
          :on-success="saveFileList"
          :on-remove="removeFileList"
          :before-upload="beforeAvatarUpload"
          :disabled="isViews"
          :file-list="dataForm.enclosureInfoList">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">仅支持上传jpg、png、Excel、pdf格式文件</div>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span class="el-upload-list__item-delete" v-if="!isViews" @click="handleDownload(file)" >
                <i class="el-icon-download"></i>
              </span>
              <span class="el-upload-list__item-delete" v-if="!isViews" @click="removeFileList(file)" >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload> -->
      </div>
      <div class="modularLabel modularLabel4" v-if="dataForm.status == 11">
        <label>订单退回/审核说明</label>
      </div>
      <div class="modularBox modularBox5" v-if="dataForm.status == 11">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="退回/审核说明" prop="reason">
              <el-input type="textarea" :rows="2" :disabled="true" v-model="dataForm.reason"
                placeholder="退回/审核说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel4">
        <label><span class="separate">|</span>注意事项</label>
      </div>
      <div class="modularBox modularBox6">
        <div class="price-word">
          <span>
            <p class="title-word">备注说明：</p>
            <p>货物信息与车型信息无变化，则当前报价为最终运费；</p>
            <p>如订单信息有变动，导致价格变动，客服人员将再派前与您确认！</p>
          </span>
          <span>
            <p class="title-word">报价说明：</p>
            <p>1，专车车型地址不变的情况下，订单按此价格计费。</p>
            <p>2，拼车体积地址不变的情况下，订单按次价格计费。</p>
            <p>3，4.2米以上车型都可免费提供尾板。</p>
            <p>4，不含入仓费等代垫费，代垫费用实报实销。</p>
            <p>5，拼车价格，超重货物按照1:500计算。</p>
            <p>6，机场收获和送货，保税区提货各车型加收100元/车次。</p>
          </span>
        </div>
        <div class="good-word">
          <span>
            <p class="title-word">货物运输特别说明</p>
            <p>禁止入仓货物：《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品；</p>
            <p>目前，我司仓库不接收的货物品名如下（包括但不限于以下品名）：</p>
            <p>1. 爆炸品：炸药、雷管、火药、子弹、烟花、鞭炮等</p>
            <p>2. 易燃液体：打火机、打火机油、空气清新剂、摩丝(发胶、头发定型剂)、液化气，灭火器</p>
            <p>3. 易燃液体：油漆、酒精、白酒、胶黏剂、粘合剂、丙酮、汽油、柴油、天那水、胶水、 粘合剂、油墨、定型水、指甲油、香水</p>
            <p>4. 易燃固体：火柴、硫磺、明胶、硝基、磷、镁、锂电池、乒乓球</p>
            <p>5. 毒性物质：砷、尼古丁、氰化物、氰化钾、杀虫剂、农药</p>
            <p>6. 腐蚀品：硫酸、盐酸、氢氧化钠、氢氧化钾、水银、双氧水、电镀用溶液、蓄电池、清洗液、清洁水</p>
            <p>7. 杂类：安全气囊、充电宝（移动电源）</p>
            <p></p>
            <strong>
              <p style="height: 20px;line-height: 20px;">非危险品声明</p>
            </strong>
            <p>
              现申明本进仓单所对应货物，非易燃易爆易腐蚀、非有毒有害物质、非氧化剂、非麻醉品、精神性药品、无放射性、不可用于制造化学武器、不属于《国际海运危险货物规则》和国家标准《危险货物品名表》所列的危险品，申报属实，且货物在仓储及装卸过程中，如发生因货物本身原因导致的安全隐患或事故，我方承担全部责任。
            </p>
          </span>
        </div>
      </div>
      <div class="footer">
        <div v-if="this.newAuth('show:price')">
          <div class="total-tip" v-if="dataForm.orderType == 1">
            【专车】订单应付:<label>{{ totalPrice + addFee }}</label>元(运费{{ totalPrice }}元+加点价{{ addFee }})
          </div>
          <div class="total-tip" v-else-if="dataForm.orderType == 2">
            【拼车】订单应付:<label>{{ totalPrice }}</label>元
          </div>
          <div class="total-tip" v-else>
            【零担】订单应付:<label>{{ totalPrice }}</label>元
          </div>
        </div>
        <div>
          <!-- <el-link type="info" style="margin-right: 10px" class="explain" @click="explain('price')">价格说明</el-link>
          <el-link type="info" style="margin-right: 15px" class="explain" @click="explain('transport')">货物运输特别声明</el-link>
          <el-checkbox v-model="dataForm.readNadConsent" style="margin-right: 15px" v-if="todo != 'view'">我已阅读并同意</el-checkbox> -->
          <el-button type="primary" style="font-size: 24px;" @click="submitForm(1)" v-if="todo != 'view'">提交</el-button>
          <el-button type="info" style="font-size: 24px;" @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </el-form>
    <!-- 放大图片 -->
    <enlarge-img ref="enlarge"></enlarge-img>
    <address-book-pop ref="addressBook" @getAddress="getAddress"></address-book-pop>
    <!-- 说明 -->
    <explain-pop ref="explainPop"></explain-pop>
  </div>
</template>
<script>
import enlargeImg from '@/views/commonPop/enlargeImg.vue'
import explainPop from '@/views/commonPop/explain-popup.vue'
import addressBookPop from '@/views/commonPop/addressBook-popup.vue'

const remarkOptions = ['需雨布', '需回单', '三不超', '有禁区']

export default {
  components: {
    enlargeImg,
    explainPop,
    addressBookPop
  },
  data() {
    return {
      visible: false,
      totalPrice: 0,
      addFee: 0,
      isCustomer: false,
      dataForm: {
        id: null,
        readNadConsent: false,
        vehicleType: null,
        orderType: 1,
        inWarehouseNo: null,
        isTailstock: '',
        transportMode: null,
        takeGoodsDate: null,
        takeGoodsTime: null,
        customerId: null,
        customerSelfNo: null,
        customerContactName: null,
        customerContactPhone: null,
        customerContactId: null,
        abortWarehouseTime: null,
        enclosureInfoList: [],
        entranceType: '',
        remark: null,
        vehicleModelIdList: [], // 订单车型
        careIdList: null, // 跟单
        careAdminIdList: null // 调度
      },
      takeAreaList: [],
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      giveAreaList: [],
      isShowSumit: true,
      customerList: [],
      addressInfoList: [{

      }],
      unLoadAddressInfoList: [], // 卸货地址信息
      giveUnLoadCityFourList: [[]],
      isactive: -1,
      todo: 'view',
      isAddAddress: true,
      takeType: 1,
      giveType: 2,
      takeCityFourList: [[]],
      giveFactoryNameList: [],
      giveCityFourList: [],
      vehicleTypeList: [],
      vehicleRulesTips: null,
      vehicleTips: null,
      enclosureDto: {
        uploadUrl: '',
        accessToken: '',
        show: false,
        download: false,
        delete: true,
        type: 6001,
        jpegType: 'image/jpeg',
        pngType: 'image/png',
        excel13Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        excel07Type: 'application/vnd.ms-excel',
        pdfType: 'application/pdf'
      },
      entranceAddressInfo: { // 报关地址信息
        factoryName: '',
        contactName: '',
        contactPhone: '',
        fullAddress: '',
        longitude: '',
        latitude: ''
      },
      inputGive: false,
      giveAddressInfo: { // 卸货地址信息
        factoryName: '',
        contactName: '',
        contactPhone: '',
        cityList: '',
        giveCityList: '',
        enCity: '',
        address: ''
      },
      isCustomerRole: false,
      showEntranceAddress: false,
      goodsInfoList: [], // 货物信息
      dialogImageUrl: '',
      dialogVisible: false,
      routeParam: {},
      showCompanyRemark: false,
      readonly: true,
      isLoading: false, // 详细地址输入不显示加载框
      dataRule: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        // inWarehouseNo: [{ required: true, message: '进仓单号不能为空', trigger: 'blur' }],
        takeGoodsDate: [{ required: true, message: '请选择提货日期', trigger: 'change' }],
        takeGoodsTime: [{ required: true, message: '请选择提货时间', trigger: 'change' }],
        abortWarehouseTime: [{ required: true, message: '请选择卸货时间', trigger: 'change' }],
        customerContactName: [{ required: true, message: '客户联系人不能为空', trigger: 'blur' }],
        customerContactPhone: [{ required: true, message: '客户联系人电话不能为空', trigger: 'blur' }],
        entranceType: [{ required: true, message: '报关方式必填', trigger: 'change' }],
        orderType: [{ required: true, message: '订单类型必填', trigger: 'change' }],
        transportMode: [{ required: true, message: '订单路线必选', trigger: 'change' }],
        vehicleModelIdList: [{ required: true, message: '订单车型必填', trigger: 'change' }],
        vehicleType: [{ required: true, message: '订单车长必填', trigger: 'change' }]
      },
      contactNameOption: [],
      entranceContactNameOption: [],
      mileage: 0,
      remarks: remarkOptions,
      userJobMap: new Map()
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    },
    isViews() {
      return this.todo === 'view'
    }
  },
  watch: {
    'dataForm.readNadConsent': {
      handler(newVal, oldVal) {
        this.isShowSumit = !newVal
      }
    }
  },
  activated() {
    if (this.currentUser.roleIdList) {
      this.currentUser.roleIdList.forEach(item => {
        if (item === '49969afff6664c189edff95e7190448a') {
          this.isCustomerRole = true
        }
      })
    }
    this.routeParam = this.$route.params
    this.showCompanyRemark = this.routeParam.listType === 2
    this.todo = this.routeParam.todo
    this.readonly = this.routeParam.todo === 'view'
    this.getCustomerList()
    this.getUserList()
    this.init()
    if (this.routeParam.id) {
      // 获取详情
      this.$http({
        url: this.$http.adornUrl('/order/detail/' + this.routeParam.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let addStatus = [0, 1, 2, 3, 10, 11, 12, 4, 5, 6]
        // 数据结构转换
        this.dataForm = data
        this.isAddAddress = addStatus.indexOf(data.status) > -1
        this.changeType(true)
        this.changeCustomer(true)
        this.totalPrice = data.freightFee
        this.addFee = data.addFee || 0
        let addressList = data.addressList
        this.addressInfoList = addressList
        // this.giveAddressInfo = data.giveAddressInfo
        let unLoadAddressInfoList = data.unLoadAddressInfoList
        this.unLoadAddressInfoList = unLoadAddressInfoList
        // this.inputGive = data.giveAddressInfo.inputGive
        this.inputGive = data.unLoadAddressInfoList[0].inputGive
        if (this.showEntranceAddress) {
          this.entranceAddressInfo = {
            factoryName: data.entranceAddressInfo.factoryName,
            fullAddress: data.entranceAddressInfo.fullAddress,
            contactName: data.entranceAddressInfo.contactName,
            contactPhone: data.entranceAddressInfo.contactPhone,
            addressId: data.entranceAddressInfo.addressId,
            longitude: data.entranceAddressInfo.longitude,
            latitude: data.entranceAddressInfo.latitude
          }
        }
        // this.dataForm.customerId = this.currentUser.customerId
        if (this.dataForm.orderType === 1) {
          if (!this.newAuth('create:special:addAll')) {
            this.isCustomer = true
          }
        } else {
          if (!this.newAuth('create:carpooling:addAll')) {
            this.isCustomer = true
          }
        }
        this.goodsInfoList = data.goodsList
        // 修改时默认选中车型
        this.getVehicleType(data.vehicleType)
        this.clearOldValue(data.recordList)
        this.dispatchTime = data.dispatchTime
        if (data.status === 8) {
          this.successTime = data.successTime
          this.statusActive = 6
        }
        this.getMileage()
      })
      // this.getAreaList()
      // this.initAddressInfoList()
    } else {
      let mainTabs = this.$store.state.common.mainTabs
      this.$store.state.common.mainTabs = mainTabs.filter(item => item.name !== this.$route.name)
      this.$router.go(-1)
    }
  },
  methods: {
    querySearch(queryString, cb) {
      // this.restaurants 调用接口获取
      if (queryString !== '') {
        this.isLoading = true
        this.restaurants = []
        this.$http({
          url: this.$http.adornUrl(`/order/txAddressByKeyword/` + queryString),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          console.log('querySearch', JSON.parse(data))
          let dataJson = JSON.parse(data)
          if (dataJson.status === 0) {
            let addressDate = dataJson.data
            addressDate.forEach((item) => {
              this.restaurants.push({
                address: item.address,
                title: item.title,
                province: item.province,
                city: item.city,
                district: item.district,
                location: item.location
              })
            })
          }
        }).then(() => {
          var restaurants = this.restaurants
          // 调用 callback 返回建议列表的数据
          cb(restaurants)
          this.isLoading = false
        })
      }
    },
    createFilterAddress(queryString) {
      return (restaurant) => {
        return (restaurant.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item, index, flag) {
      console.log('handleSelect', item, index, flag)
      if (item.city === '东莞市' || item.city === '中山市' || item.city === '儋州市' || item.city === '嘉峪关市') {
        // 需要进行逆地址查询
        // 给item.district赋值
        let location = item.location.lat + ',' + item.location.lng
        this.$http({
          url: this.$http.adornUrl(`/order/findAddressByLocation/` + location),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          let dataJson = JSON.parse(data)
          if (dataJson.status === 0) { // 获取成功
            item.district = dataJson.result.address_reference.town.title
            // 将后续操作放入.then()方法的回调函数中
            this.processAddressInfo(item, index, flag)
          }
        }).catch(error => {
          console.error('请求失败:', error)
        })
      } else {
        this.processAddressInfo(item, index, flag)
      }
    },
    processAddressInfo(item, index, flag) {
      let cityList = []
      if (item.province) {
        cityList.push(item.province)
      }
      if (item.city) {
        cityList.push(item.city)
      }
      if (item.district) {
        cityList.push(item.district)
      }
      if (flag === 1) {
        this.addressInfoList[index].factoryName = item.title
        this.addressInfoList[index].address = item.address
        this.addressInfoList[index].cityList = cityList
        this.addressInfoList[index].route = this.getCitySimpleName(cityList)
        this.addressInfoList[index].longitude = item.location.lng
        this.addressInfoList[index].latitude = item.location.lat
      } else if (flag === 2) {
        this.unLoadAddressInfoList[index].factoryName = item.title
        this.unLoadAddressInfoList[index].address = item.address
        this.unLoadAddressInfoList[index].cityList = cityList
        this.unLoadAddressInfoList[index].route = this.getCitySimpleName(cityList)
        this.unLoadAddressInfoList[index].longitude = item.location.lng
        this.unLoadAddressInfoList[index].latitude = item.location.lat
      } else if (flag === 3) {
        this.entranceAddressInfo.fullAddress = item.address
        this.entranceAddressInfo.longitude = item.location.lng
        this.entranceAddressInfo.latitude = item.location.lat
      }
      // 获取里程
      this.getMileage(flag, index)
      console.log('addressInfoList', this.addressInfoList, this.unLoadAddressInfoList)
      // 获取价格
      this.calculation()
    },
    handleIconClick(ev) {
      console.log('handleIconClick', ev)
    },
    // 获取用户信息
    getUserList() {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/customer/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    handleCommand(value) {
      if (this.dataForm.remark == null || this.dataForm.remark == undefined) {
        this.dataForm.remark = value + '，'
      } else {
        this.dataForm.remark = this.dataForm.remark + value + '，'
      }
    },
    /* 计算里程数 */
    getMileage(type, index) {
      // 判断提卸货地址是否都为空
      if (type) {
        if (type === 1) {
          let cityList = this.addressInfoList[index].cityList
          this.addressInfoList[index].route = this.getCitySimpleName(cityList)
        } else if (type === 2) {
          let cityList = this.unLoadAddressInfoList[index].cityList
          this.unLoadAddressInfoList[index].route = this.getCitySimpleName(cityList)
        }
      }
      // 当这些值都有时调用计算方法
      if (this.addressInfoList[0].cityList && this.unLoadAddressInfoList[0].cityList) {
        let addressList = []
        for (let i = 0; i < this.addressInfoList.length; i++) {
          addressList.push({
            'province': this.addressInfoList[i].cityList[0],
            'city': this.addressInfoList[i].cityList[1],
            'area': this.addressInfoList[i].cityList[2],
            'street': this.addressInfoList[i].cityList[3],
            'longitude': this.addressInfoList[i].longitude,
            'latitude': this.addressInfoList[i].latitude,
            'type': 1,
            'cityList': this.addressInfoList[i].cityList
          })
        }
        let unLoadAddressInfoList = []
        for (let i = 0; i < this.unLoadAddressInfoList.length; i++) {
          unLoadAddressInfoList.push({
            'province': this.unLoadAddressInfoList[i].cityList[0],
            'city': this.unLoadAddressInfoList[i].cityList[1],
            'area': this.unLoadAddressInfoList[i].cityList[2],
            'street': this.unLoadAddressInfoList[i].cityList[3],
            'factoryName': this.unLoadAddressInfoList[i].factoryName,
            'longitude': this.unLoadAddressInfoList[i].longitude,
            'latitude': this.unLoadAddressInfoList[i].latitude,
            'type': 2,
            'cityList': this.unLoadAddressInfoList[i].cityList
          })
        }
        let entranceAddressInfo = {}
        if (this.entranceAddressInfo.factoryName !== '一般贸易') {
          entranceAddressInfo = this.entranceAddressInfo
        }
        if (['[]', '{}'].includes(JSON.stringify(entranceAddressInfo))) {
          entranceAddressInfo = null
        }
        let formData = {
          'orderType': this.dataForm.orderType,
          'unLoadAddressInfoList': unLoadAddressInfoList,
          'addressList': addressList,
          'entranceAddressInfo': entranceAddressInfo // 报关
        }
        this.$http({
          url: this.$http.adornUrl(`/order/findMileage`),
          method: 'post',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          this.mileage = data
        })
      }
    },
    handleSelectVehicleChange(value) {
      const index = this.vehicleTypeList.findIndex(item => item.id === value)
      this.showTips(this.vehicleTypeList[index], index)
    },
    clearOldValue(recordList) {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
      if (recordList) {
        for (let i = 0; i < recordList.length; i++) {
          let record = recordList[i]
          if (record.status === 5) {
            this.outDriveTime = record.operationTime
            this.statusActive = 2
          } else if (record.status === 6) {
            this.arrivedTime = record.operationTime
            this.statusActive = 3
          } else if (record.status === 7) {
            this.loadGoodsTime = record.operationTime
            this.statusActive = 4
          } else if (record.status === 15) {
            this.arrivedGiveTime = record.operationTime
            this.statusActive = 5
          }
        }
      }
    },
    // 选择卸货仓
    selectGiveFactory() {
      this.giveAreaList.forEach(item => {
        if (item.id === this.giveAddressInfo.factoryName) {
          this.giveAddressInfo.cityList = this.getCityList(item)
          this.giveAddressInfo.factoryName = item.simpleName
          this.giveAddressInfo.contactName = item.contactName
          this.giveAddressInfo.contactPhone = item.contactPhone
          this.giveAddressInfo.address = item.address
          this.giveAddressInfo.route = this.getCitySimpleName(this.giveAddressInfo.cityList)
        }
      })
      this.calculation()
    },
    findGiveFactoryName(customerId) {
      this.$http({
        url: this.$http.adornUrl(`/priceBase/findGiveFactoryName`),
        method: 'get',
        params: this.$http.adornParams({
          customerId: customerId,
          orderType: this.takeType
        })
      }).then(({ data }) => {
        if (data) {
          this.giveFactoryNameList = data
        } else {
          this.giveFactoryNameList = []
        }
      })
    },
    // 获取附件信息
    getFileData(data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    changeType(init) {
      let entranceType = this.dataForm.entranceType
      this.entranceContactNameOption = []
      if (entranceType) {
        let list = this.dictTypeMap.entrance_type
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === entranceType) {
            this.showEntranceAddress = list[i].show === 1
            this.entranceAddressInfo.fullAddress = list[i].address
            this.entranceAddressInfo.factoryName = list[i].name
            this.entranceAddressInfo.addressId = entranceType
            this.entranceAddressInfo.longitude = list[i].longitude
            this.entranceAddressInfo.latitude = list[i].latitude
            break
          }
        }
        if (this.showEntranceAddress) {
          this.$http({
            url: this.$http.adornUrl(`/entranceType/findContactList/${entranceType}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            // 将值存入联系人、联系人电话下拉搜索框中
            data.forEach(item => {
              this.entranceContactNameOption.push({
                contactName: item.contactName,
                contactPhone: item.contactPhone
              })
            })
            // 选择客户名称时有联系人信息则将第一个联系人信息赋值
            if (!init && this.entranceContactNameOption[0]) {
              this.entranceAddressInfo.contactName = this.entranceContactNameOption[0].contactName
              this.entranceAddressInfo.contactPhone = this.entranceContactNameOption[0].contactPhone
            }
          })
        }
      } else {
        this.showEntranceAddress = false
      }
      this.getMileage()
      this.calculation()
    },
    // 客户名称改变时，客户要带出联系人、联系电话、带出省市区下拉、运费清0
    changeCustomer(init) {
      this.contactNameOption = []
      if (!init) {
        this.dataForm.customerContactName = null
        this.dataForm.customerContactPhone = null
        this.dataForm.customerContactId = null
      }
      // 总计清0
      this.totalPrice = 0
      this.addFee = 0
      this.calculation()
      // let infoList = this.addressInfoList
      // for (let i = 0; i < infoList.length; i++) {
      //   infoList[i].street = null
      //   infoList[i].factoryName = null
      //   infoList[i].contactName = null
      //   infoList[i].contactPhone = null
      //   infoList[i].cityList = null
      //   infoList[i].address = null
      // }
      // this.giveAddressInfo.street = null
      // this.giveAddressInfo.factoryName = null
      // this.giveAddressInfo.contactName = null
      // this.giveAddressInfo.contactPhone = null
      // this.giveAddressInfo.cityList = null
      // this.giveAddressInfo.address = null
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/customer/findContactList/${this.dataForm.customerId}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 将值存入联系人、联系人电话下拉搜索框中
        data.forEach(item => {
          this.contactNameOption.push({
            contactName: item.contactName,
            contactPhone: item.contactPhone,
            contactId: item.id
          })
        })
        // 选择客户名称时有联系人信息则将第一个联系人信息赋值
        if (!init && this.contactNameOption[0]) {
          this.dataForm.customerContactName = this.contactNameOption[0].contactName
          this.dataForm.customerContactPhone = this.contactNameOption[0].contactPhone
          this.dataForm.customerContactId = this.contactNameOption[0].contactId
        }
      })
      this.getTakeGiveAreaList()
      // this.findGiveFactoryName(this.dataForm.customerId)
    },

    queryNameSearch(queryString, cb) {
      var results = queryString ? this.contactNameOption.filter(this.createFilter(queryString, 'contactName')) : this.contactNameOption
      if (!results.length) {
        results = [{ value: '' }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString, name) {
      return (restaurant) => {
        if (restaurant[name]) {
          return (restaurant[name].indexOf(queryString) === 0)
        }
        return false
      }
    },
    // 报关联系人建议信息
    entranceContactSearch(queryString, cb) {
      let results = queryString ? this.entranceContactNameOption.filter(this.createFilter(queryString, 'contactName')) : this.entranceContactNameOption
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    entranceContactSelect(data) {
      this.entranceAddressInfo.contactPhone = data.contactPhone
    },
    goodsQueryNameSearch(queryString, cb) {
      let goodsName = []
      let goodsNameMap = this.dictTypeMap.goods_name
      for (let goodsNameKey in goodsNameMap) {
        goodsName.push({
          value: goodsNameMap[goodsNameKey].name
        })
      }
      let results = queryString ? goodsName.filter(this.createFilter(queryString, 'value')) : goodsName
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    nameSelect(data) {
      this.dataForm.customerContactPhone = data.contactPhone
      this.dataForm.customerContactId = data.id
    },
    phoneSelect(data) {
      // this.dataForm.customerContactName = data.customerContactName
    },
    getDefaultValue(value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    // 计算体积
    calcVolume(index, $index) {
      let volume = this.getDefaultValue(this.addressInfoList[index].goodsInfoList[$index].length, 1) *
        this.getDefaultValue(this.addressInfoList[index].goodsInfoList[$index].width, 1) *
        this.getDefaultValue(this.addressInfoList[index].goodsInfoList[$index].height, 1)
      let pieceNum = this.getDefaultValue(this.addressInfoList[index].goodsInfoList[$index].pieceNum, 1)
      let tmp = (volume / 1000000) * pieceNum
      if (tmp < 0.1) {
        tmp = 0.1
      }
      this.addressInfoList[index].goodsInfoList[$index].volume = tmp.toFixed(1)
    },
    // 地址薄
    selectAddress(flag, index) {
      // flag 1为提货； 2为卸货
      if (!this.dataForm.customerId) {
        this.$message.error('请先选择客户')
      } else {
        this.$refs.addressBook.init(flag, this.dataForm.customerId, index)
      }
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.$refs.enlarge.init(file)
    },
    // *********************************提卸货地址省市区改变**************
    // 卸货地址
    changeGiveAddress(addressType, index, flag) {
      // customerId  客户id
      // addressType 地址类型，1 提货， 2 卸货
      // takeCityList
      let formData = {}
      if (addressType === 1) {
        formData = {
          orderType: this.dataForm.orderType,
          customerId: this.dataForm.customerId,
          addressType: addressType,
          takeCityList: this.addressInfoList[index].cityList
        }
        if (!flag) {
          this.addressInfoList[index].street = ''
        }
      } else {
        formData = {
          orderType: this.dataForm.orderType,
          customerId: this.dataForm.customerId,
          addressType: addressType,
          // takeCityList: this.giveAddressInfo.cityList
          takeCityList: this.unLoadAddressInfoList[index].cityList
        }
        if (!flag) {
          // this.giveAddressInfo.street = ''
          this.unLoadAddressInfoList[index].street = ''
        }
      }
      this.$http({
        url: this.$http.adornUrl(`/priceBase/findCustomerPriceList`),
        method: 'post',
        data: this.$http.adornData(formData)
      }).then(({ data }) => {
        if (addressType === 1) {
          this.takeCityFourList[index].length = 0
          if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
              this.takeCityFourList[index].push(data[i])
            }
          } else {
            this.takeCityFourList[index].push('')
          }
        } else {
          this.giveUnLoadCityFourList[index].length = 0
          if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
              this.giveUnLoadCityFourList[index].push(data[i])
            }
          } else {
            this.giveUnLoadCityFourList[index].push('')
          }
          // this.giveCityFourList = data
        }
      })
    },
    getCitySimpleName(cityList) {
      let city = cityList[1] ? cityList[1] : ''
      let area = cityList[2] ? cityList[2] : ''
      if (city.length > 2 && city.endsWith('市')) {
        city = city.substring(0, city.length - 1)
      }
      if (area.length > 2) {
        area = area.replaceAll('新区', '')
          .replaceAll('区', '')
          .replaceAll('镇', '')
          .replaceAll('县', '')
          .replaceAll('市', '')
      }
      return city + area
    },
    calculation(type, index) {
      if (type) {
        if (type === 1) {
          let cityList = this.addressInfoList[index].cityList
          this.addressInfoList[index].route = this.getCitySimpleName(cityList)
        } else {
          // let cityList = this.giveAddressInfo.cityList
          // this.giveAddressInfo.route = this.getCitySimpleName(cityList)
          let cityList = this.unLoadAddressInfoList[index].cityList
          this.unLoadAddressInfoList[index].route = this.getCitySimpleName(cityList)
        }
      }
      // 当这些值都有时调用计算方法
      if (!!this.dataForm.customerId &&
        !!this.addressInfoList[0].cityList &&
        this.dataForm.vehicleType) {
        let addressList = []
        for (let i = 0; i < this.addressInfoList.length; i++) {
          addressList.push({
            'province': this.addressInfoList[i].cityList[0],
            'city': this.addressInfoList[i].cityList[1],
            'area': this.addressInfoList[i].cityList[2],
            'street': this.addressInfoList[i].cityList[3],
            'longitude': this.addressInfoList[i].longitude,
            'latitude': this.addressInfoList[i].latitude
          })
        }
        let unLoadAddressInfoList = []
        for (let i = 0; i < this.unLoadAddressInfoList.length; i++) {
          unLoadAddressInfoList.push({
            'province': this.unLoadAddressInfoList[i].cityList[0],
            'city': this.unLoadAddressInfoList[i].cityList[1],
            'area': this.unLoadAddressInfoList[i].cityList[2],
            'street': this.unLoadAddressInfoList[i].cityList[3],
            'factoryName': this.unLoadAddressInfoList[i].factoryName,
            'longitude': this.unLoadAddressInfoList[i].longitude,
            'latitude': this.unLoadAddressInfoList[i].latitude
          })
        }
        let formData = {
          'orderType': this.dataForm.orderType,
          'vehicleType': this.dataForm.vehicleType,
          // 'giveAddressInfo': {
          //   'province': this.giveAddressInfo.cityList[0],
          //   'city': this.giveAddressInfo.cityList[1],
          //   'area': this.giveAddressInfo.cityList[2],
          //   'street': this.giveAddressInfo.cityList[3]
          // },
          'unLoadAddressInfoList': unLoadAddressInfoList,
          'addressList': addressList,
          'entranceTypeName': this.entranceAddressInfo.factoryName,
          // 'factoryName': this.giveAddressInfo.factoryName,
          'customerId': this.dataForm.customerId
        }
        this.$http({
          url: this.$http.adornUrl(`/priceTemplateBaseCost/calculationOrderFreightFee`),
          method: 'post',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          if (data && data.price) {
            this.totalPrice = data.price
            this.addFee = (this.addressInfoList.length - 1) * data.addFee || 0
          } else {
            this.totalPrice = 0
            this.addFee = 0
          }
        })
      }
    },
    // 提交
    submitForm() {
      // if (!this.dataForm.enclosureInfoList.length) {
      //   this.$message.error('请上传附件')
      //   return false
      // }
      // this.dataForm.orderType 专车 1； 拼车 2；零担 3
      // 如果有显示费用权限并且费用小于等于0
      if (this.newAuth('show:price') && this.getDefaultValue(this.totalPrice, 0) <= 0) {
        this.$confirm('当前订单运费为0，请确认是否继续创建订单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitApi(status)
        }).catch(() => {
          // 不提交
        })
      } else {
        this.submitApi(status)
      }
    },
    submitApi() {
      this.dataForm.freightFee = this.totalPrice
      this.dataForm.addFee = this.addFee || 0
      this.dataForm.addressInfoList = this.addressInfoList
      this.dataForm.unLoadAddressInfoList = this.unLoadAddressInfoList
      if (this.showEntranceAddress) {
        this.dataForm.entranceAddressInfo = this.entranceAddressInfo
      } else {
        delete this.dataForm.entranceAddressInfo
      }
      this.$refs['dataForm'].validate((valid) => {
        if (!valid || !this.checkAddressInfoList() || !this.checkGoodsInfoList()) {
          return false
        }
        if (this.dataForm.orderType === 1) {
          this.$http({
            url: this.$http.adornUrl('/order/updateSpecialOrder'),
            method: 'post',
            data: this.dataForm
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  // this.$router.go(-1)
                  let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
                  this.$store.commit('common/updateMainTabs', val)
                  // this.$router.push({ name: 'list-inside' })
                  this.$router.go(-1)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        } else {
          this.$http({
            url: this.$http.adornUrl('/order/updateCarpoolingOrder'),
            method: 'post',
            data: this.dataForm
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.$router.go(-1)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    // 添加地址信息
    insertAddressInfoRow() {
      // 需要判断卸货地址是否已经是多个
      if (this.unLoadAddressInfoList.length > 1) {
        this.$message.error('添加多个提货地址须确保只能有一个卸货地址！')
        return false
      }
      this.addressInfoList.push({
        type: this.takeType,
        factoryName: null,
        contactName: null,
        contactPhone: null,
        cityList: null,
        street: null,
        enCity: null,
        address: null
      })
      this.takeCityFourList.push([])
    },
    // 添加卸货地址信息
    insertUnLoadAddressInfoRow() {
      // 需要判断提货地址是否已经是多个
      if (this.addressInfoList.length > 1) {
        this.$message.error('添加多个卸货地址须确保只能有一个提货地址！')
        return false
      }
      this.unLoadAddressInfoList.push({
        type: this.giveType,
        factoryName: null,
        contactName: null,
        contactPhone: null,
        cityList: null,
        street: null,
        enCity: null,
        route: null,
        address: null
      })
      this.giveUnLoadCityFourList.push([])
    },
    // 删除地址信息
    deleteAddressInfoRow(index) {
      if (this.addressInfoList.length === 1) {
        this.$message.error('至少要有一条提货地址信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.addressInfoList.splice(index, 1)
        this.takeCityFourList.splice(index, 1)
        this.calculation()
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    // 删除卸货地址信息
    deleteUnLoadAddressInfoRow(index) {
      if (this.unLoadAddressInfoList.length === 1) {
        this.$message.error('至少要有一条卸货地址信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.unLoadAddressInfoList.splice(index, 1)
        this.giveUnLoadCityFourList.splice(index, 1)
        this.calculation()
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    // 添加货物信息
    insertGoodsInfoRow(index, row) {
      // this.addressInfoList[index].goodsInfoList.push({
      //   id: getUUID(),
      //   goodsName: null
      // })
      this.goodsInfoList.push({
        goodsName: null,
        shippingMark: null,
        length: null,
        width: null,
        height: null,
        pieceNum: null,
        weight: null,
        volume: null,
        goodsType: null,
        remark: null
      })
    },
    // 删除货物信息
    deleteGoodsRow(index, goodsIndex) {
      let goodsInfoList = this.addressInfoList[index].goodsInfoList
      if (goodsInfoList.length <= 1) {
        this.$message.error('至少要有一条货物信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        goodsInfoList.splice(goodsIndex, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    // 初始化地址信息
    initAddressInfoList() {
      this.addressInfoList = []
      this.insertAddressInfoRow()
    },
    // 初始化客户下拉
    getCustomerList() {
      this.$http({
        url: this.$http.adornUrl('/customer/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.customerList = data
      })
    },
    // 获取省市区信息
    // getAreaList () {
    //   this.$http({
    //     url: this.$http.adornUrl(`/areaManager/findAll`),
    //     method: 'get',
    //     params: this.$http.adornParams()
    //   }).then(({data}) => {
    //     this.areaList = data
    //   })
    // },
    // 获取提货方，卸货方
    getTakeGiveAreaList(customerId) {
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/customerAddress/findAll`),
        method: 'get',
        params: this.$http.adornParams({
          customerId: this.dataForm.customerId,
          status: 1
        })
      }).then(({ data }) => {
        this.takeAreaList = []
        this.giveAreaList = []
        if (data) {
          data.forEach(item => {
            if (item.simpleName) {
              if (item.addressType === 1) {
                this.takeAreaList.push(item)
              } else {
                this.giveAreaList.push(item)
              }
            }
          })
        }
      })
    },
    filterTakeAddress(queryString, cb) {
      let results = queryString ? this.takeAreaList.filter(this.createFilter(queryString, 'simpleName')) : this.takeAreaList
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    filterGiveAddress(queryString, cb) {
      let results = queryString ? this.giveAreaList.filter(this.createFilter(queryString, 'simpleName')) : this.giveAreaList
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 获取车型信息
    getVehicleType(id) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
        if (id) {
          data.forEach((item, idx) => {
            if (item.id === id) {
              this.dataForm.vehicleType = item.id
              this.vehicleRulesTips = item.tips
              this.vehicleTips = item.tips
              this.isactive = idx
            }
          })
        }
      })
    },
    // 点击车型显示提示信息
    showTips(item, index) {
      if (!this.isViews) {
        this.dataForm.vehicleType = item.id
        this.vehicleRulesTips = item.tips
        this.vehicleTips = item.tips
        this.isactive = index
        this.calculation()
      }
    },
    checkAddressInfoList() {
      let infoList = this.addressInfoList
      for (let i = 0; i < infoList.length; i++) {
        let address = infoList[i]
        // if (!address.factoryName) {
        //   this.$message.error('请输入提货方名称')
        //   return false
        // }
        // if (!address.contactName) {
        //   this.$message.error('请输入提货联系人姓名')
        //   return false
        // }
        // if (!address.contactPhone) {
        //   this.$message.error('请输入提货联系人电话')
        //   return false
        // }
        if (!address.cityList) {
          this.$message.error('请选择提货省市区')
          return false
        }
        if (!address.address) {
          this.$message.error('请输入提货地址')
          return false
        }
        // if (!address.street) {
        //   this.$message.error('请输入提货点')
        //   return false
        // }
      }
      // if (!this.giveAddressInfo.factoryName) {
      //   this.$message.error('请输入卸货方名称')
      //   return false
      // }
      // if (!this.giveAddressInfo.contactName) {
      //   this.$message.error('请输入卸货联系人姓名')
      //   return false
      // }
      // if (!this.giveAddressInfo.contactPhone) {
      //   this.$message.error('请输入卸货联系人电话')
      //   return false
      // }
      // if (!this.giveAddressInfo.cityList) {
      //   this.$message.error('请选择卸货省市区')
      //   return false
      // }
      // if (!this.giveAddressInfo.address) {
      //   this.$message.error('请输入卸货地址')
      //   return false
      // }
      // if (!this.giveAddressInfo.factoryName) {
      //   this.$message.error('请选择卸货工厂')
      //   return false
      // }

      // this.dataForm.waybillInfoList = infoList
      let unLoadInfoList = this.unLoadAddressInfoList
      for (let i = 0; i < unLoadInfoList.length; i++) {
        let address = unLoadInfoList[i]
        // if (!address.factoryName) {
        //   this.$message.error('请输入提货方名称')
        //   return false
        // }
        // if (!address.contactName) {
        //   this.$message.error('请输入提货联系人姓名')
        //   return false
        // }
        // if (!address.contactPhone) {
        //   this.$message.error('请输入提货联系人电话')
        //   return false
        // }
        if (!address.cityList) {
          this.$message.error('请选择卸货省市区')
          return false
        }
        if (!address.address) {
          this.$message.error('请输入卸货地址')
          return false
        }
        // if (!address.route) {
        //   this.$message.error('请输入终点')
        //   return false
        // }
      }
      return true
    },
    checkGoodsInfoList() {
      let goodsInfoList = this.goodsInfoList
      for (let j = 0; j < goodsInfoList.length; j++) {
        let goods = goodsInfoList[j]
        if (!goods.goodsName) {
          this.$message.error('请输入货物名称')
          return false
        }
        if (!goods.volume) {
          this.$message.error('请输入体积')
          return false
        }
        if (!goods.packType) {
          this.$message.error('请选择包装类型')
          return false
        }
      }
      return true
    },
    // 初始化
    init() {
      this.enclosureDto.uploadUrl = this.$http.adornUrl('/upload/file')
      this.enclosureDto.accessToken = this.$cookie.get('TmsToken')
    },
    // 文件上传成功保存id和类型
    saveFileList(response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        file.url = resData.url
      }
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    //  文件上传之前过滤,仅支持上传jpg、png、Excel、pdf格式文件
    beforeAvatarUpload(file) {
      if (file.type === this.enclosureDto.jpegType ||
        file.type === this.enclosureDto.pngType ||
        file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        return true
      }
      this.$message.error('仅支持上传jpg、png、Excel、pdf格式文件')
      return false
    },
    handleDownload(file) {
      this.$http({
        url: this.$http.adornUrl(`/upload/download/stream/${file.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        window.open(data.url)
      })
    },
    getCityList(data) {
      let cityList = []
      if (data.province) {
        cityList.push(data.province)
      }
      if (data.city) {
        cityList.push(data.city)
      }
      if (data.area) {
        cityList.push(data.area)
      }
      if (data.street) {
        cityList.push(data.street)
      }
      return cityList
    },
    // 从地址薄中得到地址
    getAddress(data, index, flag) {
      let cityList = this.getCityList(data)
      if (flag === 1) {
        this.addressInfoList[index].factoryName = data.simpleName
        this.addressInfoList[index].contactName = data.contactName
        this.addressInfoList[index].contactPhone = data.contactPhone
        this.addressInfoList[index].cityList = cityList
        this.addressInfoList[index].address = data.address
        this.addressInfoList[index].route = this.getCitySimpleName(cityList)
        // this.addressInfoList[index].street = data.street
        // this.addressInfoList[index].enCity = data.enCity
      } else if (flag === 2) {
        // this.giveAddressInfo.factoryName = data.simpleName
        // this.giveAddressInfo.contactName = data.contactName
        // this.giveAddressInfo.contactPhone = data.contactPhone
        // this.giveAddressInfo.cityList = cityList
        // this.giveAddressInfo.address = data.address
        // this.giveAddressInfo.addressId = data.id
        // this.giveAddressInfo.route = this.getCitySimpleName(cityList)
        this.unLoadAddressInfoList[index].factoryName = data.simpleName
        this.unLoadAddressInfoList[index].contactName = data.contactName
        this.unLoadAddressInfoList[index].contactPhone = data.contactPhone
        this.unLoadAddressInfoList[index].cityList = cityList
        this.unLoadAddressInfoList[index].address = data.address
        this.unLoadAddressInfoList[index].route = this.getCitySimpleName(cityList)
        this.inputGive = data.type === 0
        // this.giveAddressInfo.street = data.street
        // this.giveAddressInfo.enCity = data.enCity
        this.unLoadAddressInfoList[index].street = data.street
        this.unLoadAddressInfoList[index].enCity = data.enCity
      }
      this.changeGiveAddress(flag, index, true)
      let self = this
      setTimeout(function () {
        self.calculation()
      })
    },
    // 说明弹框
    explain(flag) {
      this.$refs.explainPop.init(flag)
    },
    // 删除文件信息
    removeFileList(file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
