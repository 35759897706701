<template>
  <div class="mod-role">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item label="岗位名称">
          <el-input v-model="dataForm.name" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
          <el-button v-if="newAuth('sys:job:add')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" @row-click="rowClick" :row-style="selectedHighlight" height="72vh" border
        v-loading="dataListLoading" style="width: 100%;">
        <el-table-column type="index" width="50" align="center" label="序号"></el-table-column>
        <af-table-column v-for="(row, index) in tableRow" :width="row.width ? row.width : ''" :key="index"
          :label="row.label" :prop="row.prop" align="center"></af-table-column>
        <el-table-column label="内置岗位" :show-overflow-tooltip="true" prop="supperJob " width="100" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.supperJob"  type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="标记类型" :show-overflow-tooltip="true" prop="whetherDepartmentOrPosition " width="100" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.whetherDepartmentOrPosition"  type="success">岗位</el-tag>
            <el-tag v-else type="info">部门</el-tag>
          </template>
        </el-table-column>
        <af-table-column prop="status" width="60" fixed="right" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-button v-if="newAuth('sys:job:update') && !scope.row.supperJob" type="text" size="small"
              @click="addOrUpdateHandle(scope.row.id)">修改
            </el-button>
            <el-button v-if="newAuth('sys:job:disable') && scope.row.id != 'admin' && !scope.row.supperJob" type="text" size="small"
              @click="disableOrEnable(scope.row)">{{ scope.row.status === 1 ? '禁用' : '启用' }}
            </el-button>
            <el-button v-if="newAuth('sys:job:delete') && !scope.row.supperJob" type="text" size="small" @click="deleteHandle(scope.row)">删除
            </el-button>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './details/job-add-or-update'

export default {
  data() {
    return {
      dataForm: {
        name: '',
        status: ''
      },
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      tableRow: [{
        label: '岗位名称',
        prop: 'name'
      }, {
        label: '岗位编码',
        prop: 'jobCode'
      }, {
        label: '排序号',
        prop: 'jobSort'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }]
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick(row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/system/job/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(row) {
      this.$confirm('确定删除岗位【' + row.name + '】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/system/job/' + row.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('删除失败')
          }
        })
      }).catch(() => {
      })
    },
    // 禁用/启用
    disableOrEnable(row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对岗位【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/system/job/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
