<template>
  <div class="container">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="租户名称">
          <el-input v-model="dataForm.name" placeholder="租户名称" clearable
            @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="租户编号">
          <el-input v-model="dataForm.no" placeholder="租户编号" clearable
            @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-input v-model="dataForm.contacts" placeholder="负责人" clearable
                    @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="负责人联系电话">
          <el-input v-model="dataForm.contactsPhone" placeholder="负责人联系电话" clearable
                    @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="租户套餐名称">
          <el-input v-model="dataForm.packageName" placeholder="租户套餐名称" clearable
                    @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="租户套餐编号">
          <el-input v-model="dataForm.packageNo" placeholder="租户套餐编号" clearable
                    @keyup.enter.native="getTenantsUsersList"></el-input>
        </el-form-item>
        <el-form-item label="租户状态">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
            placeholder="租户状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户类型">
          <el-select class="item-choose" v-model="dataForm.type" style="width: 100%;" filterable clearable
                     placeholder="租户类型">
            <el-option label="组织" value="0"></el-option>
            <el-option label="代理" value="1"></el-option>
            <el-option label="个体" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTenantsUsersList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('request:package:find')" @click="editOrAddTenants({}, 'info')"
            type="success">添加租户</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox tenants-users-list">
      <el-table ref="tableRef" :data="tablelist" border size="mini" v-loading="tableLoading" height="72vh"
        element-loading-text="正在加载中">
        <el-table-column label="公司编号" :show-overflow-tooltip="true" prop="id" />
        <el-table-column label="租户编号" :show-overflow-tooltip="true" prop="no" />
        <el-table-column label="租户名称" :show-overflow-tooltip="true" prop="name" />
        <el-table-column label="租户类型" :show-overflow-tooltip="true" prop="type">
          <template slot-scope="scope">
            {{ scope.row.type | filterTenantsType }}
          </template>
        </el-table-column>
        <el-table-column label="套餐名称" :show-overflow-tooltip="true" prop="tenantPackageName" />
        <el-table-column label="顶层租户" :show-overflow-tooltip="true" prop="supperTenant " width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.supperTenant"  type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
           </template>
        </el-table-column>
        <el-table-column label="到期时间" :show-overflow-tooltip="true" prop="expirationTime" width="160">
          <template slot-scope="scope">
            <span class="el-icon-time btn" @click="renewal(scope.row)" v-if="!scope.row.supperTenant"></span>
            {{ scope.row.expirationTime }}
          </template>
        </el-table-column>
        <el-table-column label="邮箱" :show-overflow-tooltip="true" prop="mail" />
        <el-table-column label="负责人" :show-overflow-tooltip="true" prop="contacts" />
        <el-table-column label="联系方式" :show-overflow-tooltip="true" prop="contactsPhone" />
        <el-table-column label="传真" :show-overflow-tooltip="true" prop="facsimile" />
        <el-table-column label="经营地址" :show-overflow-tooltip="true" prop="companyAddress" />
        <el-table-column label="简介" :show-overflow-tooltip="true" prop="briefIntroduction" />
        <el-table-column label="备注" :show-overflow-tooltip="true" prop="remark" />
        <!-- <el-table-column label="管理员账号" :show-overflow-tooltip="true" prop="userInfoAccount" />
                <el-table-column label="管理员姓名" :show-overflow-tooltip="true" prop="userInfoRealName" />
                <el-table-column label="管理员手机号" :show-overflow-tooltip="true" prop="userInfoMobilePhone" /> -->
        <el-table-column label="修改时间" :show-overflow-tooltip="true" prop="updateTime" />
        <el-table-column label="创建时间" :show-overflow-tooltip="true" prop="createTime" />
        <el-table-column fixed="right" width="80" label="租户状态" align="center" prop="createTime">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" active-value="0"
              size="mini" :disabled="!isAuth('request:tenant:status') || !!scope.row.supperTenant"
              @change="(val) => handleSwitchChange(val, scope.row)" inactive-value="1">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" prop="createTime" width="200">
          <template slot-scope="scope">
            <el-link type="danger" class="link" v-if="isAuth('request:tenant:resetTheSuperAdministratorPassword')" @click="rePassTenants(scope.row)">重置密码</el-link>
            <el-link type="success" v-if="isAuth('request:package:update')  && !scope.row.supperTenant" @click="editOrAddTenants(scope.row, 'info')">编辑</el-link>
            <el-link type="primary" class="link" v-if="isAuth('request:tenant:changePackage') && !scope.row.supperTenant" @click="assignTenants(scope.row)" >变更套餐</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :page-sizes="[20, 50, 100]"
        :page-size="params.pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <TenantsUserPopup ref="tenantsUserPopup" @cancel="getTenantsUsersList" />
    <el-dialog :title="selectedForm.type ? '套餐变更' : '续期'" :visible.sync="dialogVisible" width="400px">
      <el-select v-if="selectedForm.type" v-model="selectedForm.tmsTenantPackageId" placeholder="请选择租户套餐" filterable
        clearable style="width: 100%;">
        <el-option v-for="(item, index) in packageList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-date-picker v-else v-model="selectedForm.expirationTime" type="datetime" placeholder="请选择到期时间" :append-to-body="false"
        format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"  autocomplete="off">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Encrypt from '@/utils/encrypt'
import TenantsUserPopup from './components/tenants-user-popup.vue'
export default {
  components: { TenantsUserPopup },
  data () {
    return {
      packageList: [],
      params: {
        pageNum: 1,
        pageSize: 20
      },
      totalPage: 0,
      tableLoading: false,
      dataForm: {
        status: null
      },
      tablelist: [],
      dialogVisible: false,
      selectedForm: {
        type: null, // 0 === 续期 1 === 套餐
        expirationTime: null,
        tmsTenantPackageId: null
      }
    }
  },
  created () {
    this.getTenantsUsersList()
    this.getPackageList()
  },
  methods: {
    // 查询租户列表
    getTenantsUsersList () {
      this.tableLoading = true
      this.$http({
        url: this.$http.adornUrl('/tenant/page'),
        method: 'get',
        params: this.$http.adornParams({ ...this.params, ...this.dataForm })
      }).then((response) => {
        if (response) {
          this.tablelist = response.data.list
          this.totalPage = response.data.total
        }
        setTimeout(() => {
          this.tableLoading = false
        }, 300)
      })
    },
    // 续期
    renewal (row) {
      this.selectedForm = {
        id: row.id,
        type: 0,
        expirationTime: row.expirationTime
      }
      this.dialogVisible = true
    },
    cancel () {
      this.dialogVisible = false
      this.selectedForm = { type: null, expirationTime: null }
      this.getTenantsUsersList()
    },
    confirm () {
      if (!this.selectedForm.type && !this.selectedForm.expirationTime) {
        this.$message.success('请选择到期时间！')
        return false
      }
      if (this.selectedForm.type && !this.selectedForm.tmsTenantPackageId) {
        this.$message.success('请选择租户套餐！')
        return false
      }
      const params = this.selectedForm.type ? {
        tmsTenantPackageId: this.selectedForm.tmsTenantPackageId
      } : { expirationTime: this.selectedForm.expirationTime }
      this.$http({
        url: this.$http.adornUrl(this.selectedForm.type ? `/tenant/changePackage/${this.selectedForm.id}` : `/tenant/renewal/${this.selectedForm.id}`),
        method: 'get',
        params: this.$http.adornParams(params)
      }).then(({ data }) => {
        if (data) {
          this.$message.success('操作成功')
          this.cancel()
        }
      })
    },
    assignTenants (row) {
      this.selectedForm = {
        id: row.id,
        type: 1,
        tmsTenantPackageId: row.tmsTenantPackageId
      }
      this.dialogVisible = true
    },
    // 修改/新增租户套餐
    editOrAddTenants (row = {}, mode = 'info') {
      this.$refs.tenantsUserPopup.openDialog(row, mode)
    },
    // 重置超级管理员密码
    rePassTenants (row) {
      this.$prompt('', '重置管理员密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'password',
        inputPattern: /^(?=.*[a-zA-Z]){0,1}(?=.*\d){0,1}(?=.*[^\w]){0,1}.{6,}$/,
        inputErrorMessage: '密码至少为6位数，可包含(数字、字母、特殊符号)'
      }).then(({ value }) => {
        this.$http({
          url: this.$http.adornUrl(`/tenant/changePackage/resetTheSuperAdministratorPassword/${row.id}`),
          method: 'get',
          params: this.$http.adornParams({ password: Encrypt.RSAEncrypt(value) })
        }).then(({ data }) => {
          if (data) {
            this.$message.success('密码已重置！')
            this.getTenantsUsersList()
          }
        })
      })
      this.$nextTick(() => {
        const messageBox = document.querySelector('.el-message-box')
        if (messageBox) {
          const input = messageBox.querySelector('input')
          if (input) {
            // 创建显示密码按钮
            const showPasswordBtn = document.createElement('i')
            showPasswordBtn.classList.add('el-icon-view')
            showPasswordBtn.style.cursor = 'pointer'
            showPasswordBtn.style.position = 'absolute'
            showPasswordBtn.style.top = '12px'
            showPasswordBtn.style.right = '10px'
            showPasswordBtn.onclick = () => {
              input.type = input.type === 'password' ? 'text' : 'password'
            }
            // 将显示密码按钮添加到输入框的父元素中
            const inputParent = input.parentElement
            inputParent.appendChild(showPasswordBtn)
          }
        }
      })
    },
    // 删除租户套餐
    deleteTenants (row) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/tenant/package/${row.id}`),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message.success('删除成功')
            this.getTenantsUsersList()
          }
        })
      }).catch(() => { this.getTenantsUsersList() })
    },
    // 分配租户套餐菜单
    assignTenantsMenu (row) {
      this.$refs.tenantsUserPopup.openDialog(row, 'menu')
    },
    // 变更租户状态
    handleSwitchChange (val, row) {
      this.$http({
        url: this.$http.adornUrl(`/tenant/status`),
        method: 'get',
        params: this.$http.adornParams({
          id: row.id,
          status: val
        })
      }).then(({ data }) => {
        if (data) {
          this.$message.success('已变更')
          this.getTenantsUsersList()
        }
      }).catch(() => { this.getTenantsUsersList() })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.params.pageSize = val
      this.params.pageNum = 1
      this.getTenantsUsersList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.params.pageNum = val
      this.getTenantsUsersList()
    },
    // 获取套餐列表
    getPackageList () {
      this.$http({
        url: this.$http.adornUrl('/tenant/package/getTheTenantListAnonymously'),
        method: 'get',
        data: this.$http.adornParams(this.sizeForm)
      }).then((response) => {
        if (response) {
          this.packageList = response.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  .btn {
    font-size: 16px;
    padding: 2px;
    border-radius: 50%;
    background: #fff;

    &:hover {
      color: #17B3A3;
      cursor: pointer;
    }
  }
}
  ::v-deep .el-message-box{
    .el-icon-view{
    position: absolute;
    top: 12px;
    right: 10px;
    }
  }
</style>
