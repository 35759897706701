<template>
  <el-dialog :title="sizeForm.id ? '编辑租户' : '新增租户'" :visible.sync="dialogVisible" v-if="dialogVisible" :width="width"
    top="6vh" :close-on-click-modal="false" modal-append-to-body :append-to-body="true">
    <el-form ref="formRef" :rules="rules" :model="sizeForm" label-width="90px" size="small"
      :validate-on-rule-change="false">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="first">
          <el-form-item label="租户编号" prop="no">
            <el-input v-model="sizeForm.no" placeholder="请输入租户编号" @input="handleInput"></el-input>
          </el-form-item>
          <el-form-item label="租户名称" prop="name">
            <el-input v-model="sizeForm.name" placeholder="请输入租户名称"></el-input>
          </el-form-item>
          <el-form-item label="租户类型" prop="type">
            <el-select v-model="sizeForm.type" placeholder="请选择租户类型" style="width: 50%;">
              <el-option label="组织" value="0"></el-option>
              <el-option label="代理" value="1"></el-option>
              <el-option label="个体" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="租户套餐" prop="tmsTenantPackageId">
            <el-select v-model="sizeForm.tmsTenantPackageId" placeholder="请选择租户套餐" filterable clearable
              style="width: 50%;" :disabled="!!sizeForm.id">
              <el-option v-for="(item, index) in packageList" :key="index" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="到期时间" prop="expirationTime">
            <el-date-picker v-model="sizeForm.expirationTime" type="datetime" placeholder="选择到期时间"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 50%;" :open="false"
              :append-to-body="false" autocomplete="off" trigger="manual" :editable="false">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="租户状态" prop="status">
            <el-switch v-model="sizeForm.status" active-color="#13ce66" inactive-color="#ff4949" active-value="0"
              inactive-value="1" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="备   注" prop="remark">
            <el-input v-model="sizeForm.remark" type="textarea" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="经营管理" name="second">
          <el-form-item label="负 责  人" prop="contacts">
            <el-input v-model="sizeForm.contacts" placeholder="请输入负责人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactsPhone">
            <el-input v-model="sizeForm.contactsPhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="邮 箱" prop="mail">
            <el-input v-model="sizeForm.mail" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="传 真" prop="facsimile">
            <el-input v-model="sizeForm.facsimile" placeholder="请输入传真"></el-input>
          </el-form-item>
          <el-form-item label="经营地址" prop="companyAddress">
            <el-input v-model="sizeForm.companyAddress" placeholder="请输入经营地址"></el-input>
          </el-form-item>
          <el-form-item label="简   介" prop="briefIntroduction">
            <el-input v-model="sizeForm.briefIntroduction" type="textarea" placeholder="请输入经营者简介"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="账号设置" name="third">
          <el-form-item label="管理账号" prop="userInfoAccount">
            <el-input v-model="sizeForm.userInfoAccount" placeholder="请输入管理员账号" :disabled="!!sizeForm.id"></el-input>
          </el-form-item>
          <template v-if="!sizeForm.id">
            <el-form-item label="管理密码" prop="userInfoPassword">
              <el-input v-model="sizeForm.userInfoPassword" placeholder="请输入管理员密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="reUserInfoPassword">
              <el-input v-model="sizeForm.reUserInfoPassword" placeholder="请确认管理员密码" show-password></el-input>
            </el-form-item>
          </template>
          <el-form-item label="管 理 员" prop="userInfoRealName">
            <el-input v-model="sizeForm.userInfoRealName" placeholder="请输入管理员姓名" :disabled="!!sizeForm.id"></el-input>
          </el-form-item>
          <el-form-item label="手  机  号" prop="userInfoMobilePhone">
            <el-input v-model="sizeForm.userInfoMobilePhone" placeholder="请输入管理员手机号"
              :disabled="!!sizeForm.id"></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="handleCancel">取消</el-button>
      <el-button size="mini" :loading="btnLoading" type="primary" @click="handleConfirm">确定</el-button>
    </span>
    <big-image ref="bigImg"></big-image>
  </el-dialog>
</template>

<script>
import Encrypt from '@/utils/encrypt'
import UploadImage from '@/components/uploadImage'
import bigImage from '@/views/commonPop/bigImage'
import _ from 'lodash'
export default {
  components: {
    UploadImage,
    bigImage
  },
  props: {
    width: {
      type: String,
      default: '700px'
    }
  },
  data() {
    return {
      dialogVisible: false,
      activeName: 'first',
      btnLoading: false,
      packageList: [],
      sizeForm: {
        status: '1',
        financialPublicVersusPrivate: '0'
      },
      rules: {
        no: [
          { required: true, message: '请输入套餐编号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入套餐编号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择套餐类型', trigger: 'blur' }
        ],
        tmsTenantPackageId: [
          { required: true, message: '请选择租户套餐', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择套餐状态', trigger: 'blur' }
        ],
        expirationTime: [
          { required: true, message: '请选择到期时间', trigger: 'change' }
        ],
        userInfoAccount: [
          { required: true, message: '请选择管理员账号', trigger: 'blur' }
        ],
        userInfoPassword: [
          { required: true, message: '请输入管理员密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6位', trigger: 'blur' }
        ],
        reUserInfoPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { validator: this.validateConfirmPassword, message: '两次密码不一致', trigger: 'blur' }
        ],
        userInfoRealName: [
          { required: true, message: '请输入管理员姓名', trigger: 'blur' }
        ],
        userInfoMobilePhone: [
          { required: true, message: '请输入管理员手机号', trigger: 'blur' },
          { validator: this.validateMobile, trigger: 'blur' }
        ],
        contactsPhone: [
          { required: false, message: '请输入联系方式', trigger: 'blur' },
          { validator: this.validateMobile, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {

  },
  methods: {
    // 获取附件信息
    getFileData(data, key) {
      this.sizeForm[key] = data.fileList
      this.$forceUpdate()
    },
    handlePictureCardPreview(data) {
      this.$refs.bigImg.init(data.url)
    },
    handleClick(tab) {
      this.activeName = tab.name
    },
    handleInput(value) {
      // 检查是否包含中文字符
      if (/[\u4e00-\u9fa5]/.test(value)) {
        // 如果包含，则将输入框的值重置为之前的值
        this.sizeForm.no = this.sizeForm.no.replace(/[\u4e00-\u9fa5]/g, '')
      }
    },
    // 确认密码校验
    validateConfirmPassword(rule, value, callback) {
      if (value === this.sizeForm.userInfoPassword) {
        callback()
      } else {
        callback(new Error('两次密码不一致'))
      }
    },
    // 手机号校验
    validateMobile(rule, value, callback) {
      // 如果输入框为空，直接通过验证，因为是非必填项
      if (!value) {
        callback()
        return
      }
      // 使用正则表达式验证手机号格式是否正确
      const phoneRegex = /^1(3[0-9]|4[5-9]|5[0-3,5-9]|6[2,5,6,7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/
      if (phoneRegex.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号格式'))
      }
    },
    handleCheckChange(data) {
      this.sizeForm.menuIds.push(data.id)
    },
    // 打开弹窗并传入要显示的内容
    openDialog(row) {
      this.activeName = 'first'
      if (row && row.id) {
        this.details(row.id)
      } else {
        this.sizeForm = {
          status: '1',
          financialPublicVersusPrivate: '0'
        }
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      }
      this.getPackageList()
    },
    handleCancel() {
      // 点击取消按钮触发的事件，同样可以向外emit自定义事件，供父组件监听
      this.$emit('cancel')
      this.sizeForm = {
        status: '1',
        financialPublicVersusPrivate: '0'
      }
      this.dialogVisible = false
    },
    // 表单提交
    handleConfirm() {
      let that = this
      that.$refs['formRef'].validate((valid, invalidFields) => {
        if (valid) {
          this.btnLoading = true
          let params = _.cloneDeep(that.sizeForm)
          delete params.reUserInfoPassword
          params.userInfoPassword = Encrypt.RSAEncrypt(params.userInfoPassword)
          that.$http({
            url: that.$http.adornUrl(that.sizeForm.id ? '/tenant/update' : '/tenant/add'),
            method: that.sizeForm.id ? 'put' : 'post',
            data: that.$http.adornParams(params)
          }).then((response) => {
            that.$message.success('操作成功')
            setTimeout(() => {
              that.btnLoading = false
              that.handleCancel()
            })
          }).catch(() => {
            that.btnLoading = false
          })
        } else {
          if (Object.keys(invalidFields).length > 0) {
            const field = Object.keys(invalidFields)[0];
            const message = that.rules[field][0].message;
            that.$message({
              message: message,
              type: 'warning'
            });
          }
        }
      })
    },
    // 查询详情
    details(id) {
      this.$http({
        url: this.$http.adornUrl(`/tenant/${id}`),
        method: 'get'
      }).then(({ data }) => {
        if (data) {
          this.sizeForm = data
          this.$nextTick(() => {
            this.dialogVisible = true
          })
        }
      })
    },
    // 获取套餐列表
    getPackageList() {
      this.$http({
        url: this.$http.adornUrl('/tenant/package/getTheTenantListAnonymously'),
        method: 'get',
        data: this.$http.adornParams(this.sizeForm)
      }).then((response) => {
        if (response) {
          this.packageList = response.data
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #EBEEF5;
}

::v-deep .el-tree {
  background: #f6f6f6;
  height: 400px;
  padding: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  /* 整体滚动条样式 */
  &::-webkit-scrollbar {
    width: 8px;
    /* 滚动条宽度 */
    height: 8px;
    /* 滚动条高度，对于水平滚动条需要设置高度 */
  }

  /* 滚动条轨道（背景部分）样式 */
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* 轨道背景颜色 */
    border-radius: 4px;
    /* 轨道圆角 */
  }

  /* 滚动条滑块样式 */
  &::-webkit-scrollbar-thumb {
    background-color: #666;
    /* 滑块颜色 */
    border-radius: 4px;
    /* 滑块圆角 */
  }

  /* 滚动条滑块悬停时的样式 */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
    /* 悬停时滑块颜色变深 */
  }
}

.lableNone {
  ::v-deep .el-form-item__label {
    display: none;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;

  ::v-deep .el-button--mini {
    padding: 5px 10px;
  }
}

::v-deep .el-dialog__body {
  padding: 20px !important;
}

.item-line {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px;
  background: aliceblue;
}
</style>
