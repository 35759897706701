import { render, staticRenderFns } from "./customer-register-save.vue?vue&type=template&id=f4c50752"
import script from "./customer-register-save.vue?vue&type=script&lang=js"
export * from "./customer-register-save.vue?vue&type=script&lang=js"
import style0 from "./customer-register-save.vue?vue&type=style&index=0&id=f4c50752&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports