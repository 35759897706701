<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="所属车队">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车型">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.vehicleType" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in vehicleTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList"  v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="allVehicle()">全部车辆</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <af-table-column prop="vehiclePlate" label="车牌号">
        </af-table-column>
        <el-table-column prop="vehicleType" :formatter = "dictConvert" label="车辆类型">
        </el-table-column>
        <el-table-column prop="energyType" :formatter = "dictConvert" label="能源类型">
        </el-table-column>
        <af-table-column prop="carriageInsideLength" label="车厢内长(cm)">
        </af-table-column>
        <af-table-column prop="carriageInsideWidth" label="车厢内宽(cm)">
        </af-table-column>
        <af-table-column prop="carriageInsideHeight" label="车厢内高(cm)">
        </af-table-column>
        <af-table-column prop="enterDoorWidth" label="进门宽(cm)">
        </af-table-column>
        <af-table-column prop="enterDoorHeight" label="进门高(cm)">
        </af-table-column>
        <af-table-column prop="maxLoad" label="最大载重(kg)">
        </af-table-column>
        <af-table-column prop="vehicleEmptyWeight" label="车辆空重(KG)">
        </af-table-column>
        <af-table-column prop="isTailstock" :formatter="yesNo" label="是否有尾板">
        </af-table-column>
        <af-table-column label="车辆图片" prop="" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </af-table-column>
        <af-table-column prop="motorcadeName" label="所属车队">
        </af-table-column>
        <af-table-column prop="bindDriverName" label="绑定驾驶员">
        </af-table-column>
        <af-table-column prop="status" fixed="right" label="状态" width="40">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="trackView(scope.row)">查看轨迹</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="latestLocationView(scope.row)">查看最新位置</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
          </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <select-time ref="time" @comfirmTime="comfirmTime"></select-time>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
    <iframe-track ref="track"></iframe-track>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage'
import selectTime from './details/select-time'
import iframeTrack from '@/views/commonPop/iframe-track'
export default {
  data () {
    return {
      dataForm: {
        motorcadeName: null,
        vehiclePlate: null,
        vehicleType: null,
        status: null
      },
      fontRate: {
        OTHER_RATE: 1.5 // 除汉字和数字以外的字符的比率
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      imageTypeList: [3000, 3001],
      vehicleTypeList: [],
    }
  },
  components: {
    viewImage,
    selectTime,
    iframeTrack
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看轨迹
    trackView (row) {
      this.$refs.time.init(row)
    },
    comfirmTime (data) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleInfo/getToMapTrajectoryUrl`),
        method: 'get',
        params: this.$http.adornParams(data)
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    // 查看最新位置
    latestLocationView (row) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleInfo/getToMapNewLocationUrl/${row.vehiclePlate}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    // 全部车辆
    allVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/getToMapNewLocationUrl'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.id, this.imageTypeList)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findListAll'),
        method: 'get',
        params: this.$http.adornParams({
          'status': 1,
          'isGps': 1
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.vehiclePlate + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/vehicleInfo/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  },
  created() {
    // 获取车型信息
    this.$http({
      url: this.$http.adornUrl(`/vehicleType/findAll`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      this.vehicleTypeList = data
    })
  }
}
</script>
