<template>
  <div class="order-examine-popup">
    <el-dialog title="【追加到车次】" :close-on-click-modal="false" :visible.sync="visible" width="30%" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="140px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="追加到目标车次" prop="id" :rules="[{ required: true, message: '请选择车次号', trigger: 'blur' }]">
              <el-select class="item-choose" v-model="dataForm.id" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option v-for="item in dataList" :key="item.id" :label="item.planNo" :value="item.id" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null
      },
      row: {},
      dataList: []
    }
  },
  methods: {
    queryNameSearch (queryString, cb) {
      var results = queryString ? this.carNumberOption.filter(this.createFilter(queryString)) : this.carNumberOption
      if (!results.length) {
        results = [{ value: '' }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString, name) {
      return (carNmberList) => {
        return (carNmberList.carNumber.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    nameSelect (data) {
    },
    submit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/vehiclePlan/planAddOrder`),
            method: 'POST',
            data: this.$http.adornData({
              orderInfoList: [
                { id: this.row.orderInfo.id }
              ],
              oldPlanId: this.row.orderInfo.planId,
              id: this.dataForm.id
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '追加成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    init (row) {
      this.visible = true
      this.row = row
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/getPlanIdList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataList = data
        } else {
          this.dataList = []
        }
        this.dataListLoading = false
      })
    }
  }
}
</script>
