<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="计算方式名称">
          <el-input v-model="dataForm.calculationName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  height="72vh" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <af-table-column prop="calculationCode" label="计算方式代码">
        </af-table-column>
        <af-table-column prop="calculationName" label="计算方式名称">
        </af-table-column>
        <el-table-column prop="orderType" width="80" :formatter="orderType" label="订单类型">
        </el-table-column>
        <af-table-column prop="describe" label="描述">
        </af-table-column>
        <af-table-column prop="remark" label="备注">
        </af-table-column>
        <af-table-column prop="status" width="60" label="状态" fixed="right">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row.id)">修改</el-button>
            <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <detail v-if="detailVisible" ref="detail"></detail>
  </div>
</template>

<script>
import AddOrUpdate from './details/price-calculation-save'
import detail from './details/info-detail'
export default {
  data () {
    return {
      dataForm: {
        calculationName: null,
        orderType: null,
        calculationCode: null,
        status: null
      },
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  components: {
    AddOrUpdate,
    detail
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) { 
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/priceCalculation/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'calculationCode': this.dataForm.calculationCode,
          'orderType': this.dataForm.orderType,
          'calculationName': this.dataForm.calculationName
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.calculationName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/priceCalculation/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
