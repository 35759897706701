// 应付统计表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车队名称">
          <el-input v-model="dataForm.simpleName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.businessName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货日期">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :height="tableHeight"
                border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle" style="width: 100%;" class="table-input">
        <el-table-column type="index" label="序号">
        </el-table-column>
        <el-table-column prop="simpleName" label="车队名称" :show-overflow-tooltip="true" min-width="90">
        </el-table-column>
        <el-table-column prop="businessName" label="车牌号" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderNum" label="订单数" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="auditNum" label="已出账单订单数" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="totalMoney" label="应付金额" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="payMoney" label="已付金额" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="notPayMoney" label="未付金额" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="invoice" label="已开票金额" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="isEditMoney === scope.$index">
              <el-input v-model="scope.row.invoice" onkeyup="value=value.replace(/[^0-9.-]/g, '')"></el-input>
            </span>
            <span v-else>
              {{ scope.row.invoice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="notInvoice" label="未开票金额" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="isEditMoney === scope.$index">
              <el-input v-model="scope.row.notInvoice" onkeyup="value=value.replace(/[^0-9.-]/g, '')"></el-input>
            </span>
            <span v-else>
              {{ scope.row.notInvoice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="90">
          <template slot-scope="scope">
            <el-button v-if="newAuth('price:receivable:update')" type="text" size="small"
                       @click="editMoney(scope.row, scope.$index)">{{ isEditMoney == scope.$index ? '保 存' : '修改金额' }}
            </el-button>
            <el-button v-if="newAuth('price:receivable:find')" type="text" size="small"
                       @click="history(scope.row)">历史记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <payable-history ref="history"></payable-history>
  </div>
</template>

<script>
import payableHistory from './details/payableHistory'

export default {
  data () {
    return {
      dataForm: {
        businessName: null,
        takeGoodsTime: null,
        simpleName: null
      },
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      detailVisible: false,
      isEditMoney: -1,
      initEditMoney: {}
    }
  },
  components: {
    payableHistory
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    // window.setTimeout(() => {
    //   this.tableHeight = '67vh'
    // }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date(end.getFullYear(), 0, 1, 0, 0, 0)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.id === preId) {
            return ''
          }
          preId = item.id
          let totalRow = 'orderNum,auditNum,totalMoney,payMoney,notPayMoney,invoice,notInvoice'
          if (column.property && totalRow.indexOf(column.property) > -1) {
            return this.getValue(Number(item[column.property]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '',
        totalSummary.orderNum,
        totalSummary.auditNum,
        totalSummary.totalMoney,
        totalSummary.payMoney,
        totalSummary.notPayMoney,
        totalSummary.invoice,
        totalSummary.notInvoice,
        '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    editMoney (row, index) {
      if (this.isEditMoney === -1) {
        // 保存修改钱的数据
        this.initEditMoney.invoice = row.invoice
        this.initEditMoney.notInvoice = row.notInvoice
        this.initEditMoney.index = index
        this.isEditMoney = index
      } else if (this.isEditMoney !== -1 && this.isEditMoney !== index) {
        // 上一条数据
        let self = this
        let beforeData = this.dataList[this.initEditMoney.index]
        this.$confirm(`当前有未保存的数据，是否保存?`, '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          showClose: false,
          type: 'warning'
        }).then(() => {
          if (!(beforeData.invoice === self.initEditMoney.invoice && beforeData.notInvoice === self.initEditMoney.notInvoice)) {
            self.editMoneyApi(beforeData)
          }
          self.isEditMoney = -1
        }).catch(action => {
          if (action === 'cancel') {
            self.dataList[self.initEditMoney.index].invoice = self.initEditMoney.invoice
            self.dataList[self.initEditMoney.index].notInvoice = self.initEditMoney.notInvoice
            self.isEditMoney = -1
          }
        })
      } else {
        // 当前修改数据有改变时，就修改，没改变时不调用接口
        if (!(this.initEditMoney.invoice === row.invoice && this.initEditMoney.notInvoice === row.notInvoice)) {
          row.invoice = parseFloat(row.invoice)
          row.notInvoice = parseFloat(row.notInvoice)
          this.editMoneyApi(row)
        }
        this.isEditMoney = -1
      }
    },
    // 历史记录
    history (row) {
      /**
       * 1 = 应收 ，2 = 应付
       */
      this.$refs.history.init(row, 1)
    },
    editMoneyApi (row) {
      /**
       * businessType： 1 = 应收 ，2 = 应付
       * /costSummary/updateMoney/{businessType}
       */
      this.$http({
        url: this.$http.adornUrl(`/costSummary/updateMoney/2`),
        method: 'post',
        data: this.$http.adornData(row)
      }).then(() => {
        this.$message.success('修改成功')
        // this.getDataList()
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        this.dataForm.beginDate = takeGoodsTime[0]
        this.dataForm.endDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costSummary/paySummary'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'businessName': this.dataForm.businessName,
          'simpleName': this.dataForm.simpleName,
          'beginDate': this.dataForm.beginDate,
          'endDate': this.dataForm.endDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.summaryTotal || {})
          this.tableHeight = '70vh'
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    downLoad () {
      /**
       1，应收汇总表
       2，应付汇总表
       3，年应收统计表
       4，年应付统计表
       5，客户年利润统计表
       6，车辆年利润统计表
       costSummary/export/{businessType}
       */
      let takeGoodsTime = this.dataForm.takeGoodsTime
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        this.dataForm.beginDate = takeGoodsTime[0]
        this.dataForm.endDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/costSummary/export/2`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '应付汇总表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
  display: inline-block;
  margin-left: 10px;
}
</style>
