<template>
  <div class="common-pop">
    <el-dialog
      :title="!dataForm.id ? '【新增】用户信息' : '【修改】用户信息'"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="用户名" prop="account">
              <el-input v-model="dataForm.account" placeholder="登录帐号" :disabled="oper"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="dataForm.realName" placeholder="真实姓名" :disabled="oper"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="身份证号码" prop="cardNo">
              <el-input v-model="dataForm.cardNo" placeholder="身份证号码" :disabled="oper"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobilePhone">
              <el-input v-model="dataForm.mobilePhone" placeholder="手机号" :disabled="oper"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--      <el-row :gutter="50">-->
        <!--       -->
        <!--        <el-col :span="12">-->
        <!--          <el-form-item label="免密支付" prop="startPay">-->
        <!--            <el-select class="item-choose" v-model="dataForm.startPay" placeholder="请选择">-->
        <!--              <el-option v-for="item in dictTypeMap.yes_no" :key="item.id" :label="item.name" :value="item.id">-->
        <!--              </el-option>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <!--      </el-row>-->

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="所属公司" prop="companyId">
              <el-select class="item-choose" v-model="dataForm.companyId"
                         @change="getMotorcadeList(dataForm.companyId)"
                         :disabled="oper"
                         style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in companyList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属客户" prop="customerIdList">
              <el-select class="item-choose" v-model="dataForm.customerIdList"
                         :disabled="oper"
                         style="width: 100%;"
                         multiple filterable clearable placeholder="请选择">
                <el-option v-for="item in customerList" :key="item.id"
                           :label="item.customerCode + '-' + item.simpleName" :value="item.id">
                  <span>{{ item.customerCode + '-' + item.simpleName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="所属车队" prop="motorcadeId">
              <el-select class="item-choose" v-model="dataForm.motorcadeId"
                         :disabled="oper"
                         style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in motorcadeList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付密码" prop="payPassword">
              <el-input v-model="dataForm.payPassword" type="password" placeholder="不输入则不修改支付密码"
                        :disabled="oper"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="角色" size="mini" prop="roleIdList">
          <el-checkbox-group v-model="dataForm.roleIdList">
            <el-checkbox :disabled="oper" v-for="role in roleList" :key="role.id" :label="role.id">{{
                role.roleName
              }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="部门" size="mini" prop="deptIdList">
          <el-checkbox-group v-model="dataForm.jobIdList">
            <el-checkbox :disabled="oper" v-for="job in jobList" v-if="job.whetherDepartmentOrPosition == 0" :key="job.id" :label="job.id">{{
                job.name
              }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="岗位" size="mini" prop="jobIdList">
          <el-checkbox-group v-model="dataForm.jobIdList">
            <el-checkbox :disabled="oper" v-for="job in jobList" v-if="job.whetherDepartmentOrPosition == 1" :key="job.id" :label="job.id">{{
                job.name
              }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态" size="mini" prop="status">
          <el-radio-group v-model="dataForm.status" :disabled="oper">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" v-if="!oper" @click="dataFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import { isMobile } from '@/utils/validate'
import md5 from 'js-md5'

export default {
  data () {
    const validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      roleList: [],
      jobList: [],
      companyList: [],
      motorcadeList: [],
      customerList: [],
      oper: false,
      dataForm: {
        id: null,
        account: null,
        realName: null,
        cardNo: null,
        mobilePhone: null,
        companyId: null,
        motorcadeId: null,
        customerId: null,
        payPassword: null,
        startPay: 2,
        roleIdList: [],
        jobIdList: [],
        customerIdList: [],
        status: 1
      },
      dataRule: {
        account: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' }
        ],
        cardNo: [
          { required: true, message: '身份证号码不能为空', trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属车队', trigger: 'change' }
        ],
        mobilePhone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        roleIdList: [
          { type: 'array', required: true, message: '请至少选择一个角色', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    getMotorcadeList (companyId) {
      this.motorcadeList = []
      if (!companyId || companyId == null || companyId == undefined) {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams({
          companyId: companyId
        })
      }).then(({ data }) => {
        this.motorcadeList = data
      })
    },
    // 初始化客户下拉
    getCustomerList () {
      this.$http({
        url: this.$http.adornUrl('/customer/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        console.log('提交')
        this.customerList = data
      })
    },
    init (id, oper) {
      this.dataForm.id = id
      this.oper = oper === 'view'
      this.getCustomerList()
      this.$http({
        url: this.$http.adornUrl('/system/user/userPageInit'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.roleList = data.roleList
        this.jobList = data.jobList
        this.companyList = data.companyList
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/system/user/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            this.dataForm.account = data.account
            this.dataForm.realName = data.realName
            this.dataForm.cardNo = data.cardNo
            this.dataForm.mobilePhone = data.mobilePhone
            this.dataForm.companyId = data.companyId
            this.dataForm.motorcadeId = data.motorcadeId
            this.dataForm.customerId = data.customerId
            this.dataForm.roleIdList = data.roleIdList
            this.dataForm.jobIdList = data.jobIdList
            this.dataForm.customerIdList = data.customerIdList
            this.dataForm.status = data.status
            this.dataForm.startPay = data.startPay
            this.getMotorcadeList(data.companyId)
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          if (this.dataForm.payPassword) {
            this.dataForm.payPassword = md5.hex(this.dataForm.payPassword)
          }
          this.$http({
            url: this.$http.adornUrl(`/system/user/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'account': this.dataForm.account,
              'realName': this.dataForm.realName,
              'cardNo': this.dataForm.cardNo,
              'mobilePhone': this.dataForm.mobilePhone,
              'companyId': this.dataForm.companyId,
              'motorcadeId': this.dataForm.motorcadeId,
              'customerId': this.dataForm.customerId,
              'status': this.dataForm.status,
              'payPassword': this.dataForm.payPassword,
              'startPay': this.dataForm.startPay,
              'roleIdList': this.dataForm.roleIdList,
              'jobIdList': this.dataForm.jobIdList,
              'customerIdList': this.dataForm.customerIdList
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
