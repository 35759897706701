<template>
  <div class="boxBox">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
<!--        <el-form-item label="客户单号">-->
<!--          <el-input v-model="dataForm.customerSelfNo" placeholder="支持模糊查询" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            @change="getDataList()"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option label="全部" value="" ></el-option>
            <el-option v-for="item in dictTypeMap.orderStatusMap" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" v-if="newAuth('order:customer:findAll')">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:customer:export')" @click="downLoad">导出</el-button>
          <el-button size="small" type="warning" v-if="newAuth('order:customer:entranceOut')" @click="entranceOut">已报出</el-button>
          <el-button size="small" type="danger" v-if="newAuth('order:customer:entranceIn')" @click="entranceIn">已报入</el-button>
          <!--<el-button size="small" type="success" v-if="newAuth('order:customer:export')" @click="importFile">导入</el-button>-->
          <!--<span class="searchUpload">-->
            <!--<al-upload :fileData="enclosureInfoList" :notShow="false" :fileUrl="$http.adornUrl('/order/orderImport')" @getFileData="getFileData"></al-upload>-->
          <!--</span>-->
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :row-class-name="tableRowClassName" ref="tableRef"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small" height="72vh"
                :span-method="arraySpanMethod"
                @selection-change="selectionChangeHandle"
                v-loading="dataListLoading" style="width: 100%;">
        <el-table-column type="selection" width="40" fixed="left" align="center"></el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系人" prop="orderInfo.customerContactName" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货时间" prop="orderInfo.takeGoodsDate" width="105" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" align="center" :formatter="orderType" prop="orderInfo.orderType" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="所需车型" align="center" :formatter="dictConvertVehicle" prop="orderInfo.vehicleType" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机车牌" prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" align="center" :formatter="entranceType" prop="orderInfo.entranceType" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货方" prop="factoryName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系人" prop="contactName" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货电话" prop="contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货联系人" prop="giveAddressInfo.contactName"  width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货电话" prop="giveAddressInfo.contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物尺寸(长/宽/高)" prop="orderGoods.length" width="110"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
          </template>
        </el-table-column>
        <el-table-column label="包装类型" :formatter="manyDictConvert" prop="orderGoods.packType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="异常备注" prop="orderInfo.groupExplain" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车辆空重(kg)" prop="vehiclePlan.vehicleEmptyWeight" width="95" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="身份证号" prop="vehiclePlan.driverCard" width="130" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderInfo.isTailstock" :formatter="yesNo" label="是否尾板" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系电话" prop="orderInfo.customerContactPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="装货图片" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, loadGoodsTypeList)" style="color: blue;cursor:pointer" v-if="scope.row.orderInfo.showLoadImage">查看</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="签收图片" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, successTypeList)" style="color: blue;cursor:pointer" v-if="scope.row.orderInfo.showSuccessImage">查看</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单人" prop="orderInfo.commitName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="派车时间" prop="orderInfo.dispatchTime" align="left" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="出车时间" prop="outDriveTimeOsr" align="left" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color: red" v-if="isWithinFiveMinutes(scope.row.outDriveTimeOsr, scope.row.arrivedTimeOsr)">{{ scope.row.outDriveTimeOsr }}</span>
            <span v-else>{{ scope.row.outDriveTimeOsr }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="装货到场时间" prop="arrivedTimeOsr" align="center" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color: red" v-if="isWithinFiveMinutes(scope.row.arrivedTimeOsr, scope.row.loadGoodsTimeOsr) || isWithinFiveMinutes(scope.row.outDriveTimeOsr, scope.row.arrivedTimeOsr)">{{ scope.row.arrivedTimeOsr }}</span>
            <span v-else>{{ scope.row.arrivedTimeOsr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="装货离场时间" prop="loadGoodsTimeOsr" align="center" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color: red" v-if="isWithinFiveMinutes(scope.row.loadGoodsTimeOsr, scope.row.arrivedGiveTimeOsr) || isWithinFiveMinutes(scope.row.arrivedTimeOsr, scope.row.loadGoodsTimeOsr)">{{ scope.row.loadGoodsTimeOsr }}</span>
            <span v-else>{{ scope.row.loadGoodsTimeOsr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="卸货到场时间" prop="arrivedGiveTimeOsr" align="center" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color: red" v-if="isWithinFiveMinutes(scope.row.arrivedGiveTimeOsr, scope.row.orderInfo.successTime) || isWithinFiveMinutes(scope.row.loadGoodsTimeOsr, scope.row.arrivedGiveTimeOsr)">{{ scope.row.arrivedGiveTimeOsr }}</span>
            <span v-else>{{ scope.row.arrivedGiveTimeOsr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="签收时间" prop="orderInfo.successTime" align="center" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color: red" v-if="isWithinFiveMinutes(scope.row.orderInfo.successTime, scope.row.arrivedGiveTimeOsr)">{{ scope.row.orderInfo.successTime }}</span>
            <span v-else>{{ scope.row.orderInfo.successTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" fixed="right" width="58">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning" effect="plain">调度中</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="primary">已调度</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info" effect="dark">异常结束</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger" effect="dark">审核拒绝</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger" effect="plain">订单退回</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status == 4" size="small" color="#409eff00" plain>待出车</el-tag>
            <el-tag v-else-if="scope.row.status == 5" size="small" type="success" effect="plain">已出车</el-tag>
            <el-tag v-else-if="scope.row.status == 6" size="small" type="success" color="#d8ff7bdb">已到达</el-tag>
            <el-tag v-else-if="scope.row.status == 7" size="small" type="success" color="#ffff78">已装货</el-tag>
            <el-tag v-else-if="scope.row.status == 15" size="small" type="success" effect="dark">卸货到场</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="报出状态" prop="orderInfo.entranceOutStatus" width="60" fixed="right" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.entranceOutStatus == 1" size="small" type="success">已报出</el-tag>
            <el-tag v-else size="small">无</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="报出时间" prop="orderInfo.clickOutTime" width="85"  fixed="right" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报入状态" prop="orderInfo.entranceInStatus" width="60" fixed="right" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.entranceInStatus == 1" size="small" type="success">已报入</el-tag>
            <el-tag v-else size="small">无</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="报入时间" prop="orderInfo.clickInTime" width="70"  fixed="right" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column fixed="right" label="操作" prop="operate" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('order:customer:find')" type="text" size="small" @click="addOrUpdateHandle(scope.row, 'view')">详情</el-button>
              <el-button v-if="newAuth('order:customer:cost')" type="text" size="small" @click="customerCostDetail('cost', 'view', scope.row)">费用信息</el-button>
              <el-button v-if="newAuth('order:customer:copy')" type="text" size="small" @click="copyInfo(scope.row)">复制</el-button>
              <el-button v-if="showButton(scope.row, 'commit', 'order:customer:commit')" type="text" size="small" @click="updateStatus(scope.row, '提交', 1)">提交</el-button>
              <el-button v-if="showButton(scope.row, 'update', 'order:customer:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row, 'edit')">修改</el-button>
              <el-button v-if="showButton(scope.row, 'cancel', 'order:customer:cancel')" type="text" size="small" @click="updateStatus(scope.row, '取消', 12)">取消订单</el-button>
              <el-button v-if="newAuth('order:customer:find')" type="text" size="small" @click="trackView(scope.row)">轨迹查看</el-button>
              <el-button v-if="newAuth('order:customer:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <cancel-order-pop ref="cancel"></cancel-order-pop>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
    <import-drawer ref="drawer"></import-drawer>
    <!-- 查看轨迹 -->
    <iframe-track ref="track"></iframe-track>
    <customer-cost ref="customerCost"></customer-cost>
  </div>
</template>

<script>
import cancelOrderPop from './customerPop/cancelOrderPop'
import viewImage from '@/views/commonPop/viewImage'
import iframeTrack from '@/views/commonPop/iframe-track'
import importDrawer from './customerPop/importDrawer'
import customerCost from './customerPop/customerCostPop'
import qs from 'qs'
export default {
  components: {
    cancelOrderPop,
    viewImage,
    importDrawer,
    iframeTrack,
    customerCost
  },
  data () {
    return {
      dataForm: {
        inWarehouseNo: null,
        customerSelfNo: null,
        customerId: null,
        customerName: null,
        customerContactName: null,
        takeGoodsTime: [],
        status: null,
        vehiclePlate: null,
        customerIds: []
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      successTypeList: [6010],
      loadGoodsTypeList: [6011],
      dataListLoading: false,
      dataListSelections: [],
      spanArr: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      enclosureInfoList: [],
      listType: 1
    }
  },
  created () {
    this.setDefaultDate()
  },
  activated () {
    if (!this.newAuth('order:customer:findAll')) { // 不查看所有的时候，判断所属客户
      this.dataForm.customerId = this.currentUser.customerId
      this.dataForm.customerIds = this.currentUser.customerIdList
    }
    this.getDataList()
  },
  computed: {
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 已报出
    entranceOut () {
      if (this.dataListSelections.length === 0) {
        this.$message.error('请选择要报出的订单')
        return
      }
      const ids = this.dataListSelections.flatMap(item => item.orderInfo.id)
      this.$http({
        url: this.$http.adornUrl('/order/entranceOut'),
        method: 'post',
        data: ids
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 已报入
    entranceIn () {
      if (this.dataListSelections.length === 0) {
        this.$message.error('请选择要报入的订单')
        return
      }
      const ids = this.dataListSelections.flatMap(item => item.orderInfo.id)
      this.$http({
        url: this.$http.adornUrl('/order/entranceIn'),
        method: 'post',
        data: ids
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.orderInfo.id, typeList)
    },
    // 轨迹查看
    trackView (row) {
      this.$http({
        url: this.$http.adornUrl(`/order/getToMapTrajectoryUrl/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    importFile () {
      this.$refs.drawer.init()
    },
    customerCostDetail (type, todo, row) {
      this.$refs.customerCost.init(type, todo, row, 1)
    },
    isWithinFiveMinutes (time1, time2) {
      const one = new Date(time1).getTime()
      const two = new Date(time2).getTime()
      const fiveMinutes = 3 * 60 * 1000
      return Math.abs(one - two) < fiveMinutes
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/order/orderExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': 1,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerId': this.dataForm.customerId,
          'customerName': this.dataForm.customerName,
          'status': this.dataForm.status,
          'customerSelfNo': this.dataForm.customerSelfNo,
          'customerContactName': this.dataForm.customerContactName,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单列表(客户).xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取附件信息
    getFileData (data) {
      if (data) {
        this.$message({
          message: '导入成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.getDataList()
          }
        })
      } else {
        this.$message.error('操作失败')
      }
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerId': this.dataForm.customerId,
        'customerName': this.dataForm.customerName,
        'status': this.dataForm.status,
        'customerSelfNo': this.dataForm.customerSelfNo,
        'customerContactName': this.dataForm.customerContactName,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerIdList': this.dataForm.customerIds
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findCustomerList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 复制数据
    copyInfo (row) {
      this.$router.push({
        path: '/order-create-special',
        query: {
          id: row.orderInfo.id
        }
      })
    },
    // 取消订单
    cancelOrder () {
      this.$refs.cancel.init()
    },
    // 新增 / 修改
    addOrUpdateHandle (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          listType: this.listType,
          todo: todo
        }
      })
    },
    // 修改状态
    updateStatus (row, title, status) {
      this.$confirm('确定对【' + row.orderInfo.orderNo + '】进行【' + title + '】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/updateStatusById'),
          method: 'post',
          data: {
            id: row.orderInfo.id,
            status: status
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (row) {
      this.$confirm(`确定对【${row.orderInfo.orderNo}】进行【删除】操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/delete/' + row.orderInfo.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less">
div#pane-order-list-customer {
  // 状态为已取消时置灰
  .el-table .cancel-row {
    background: #f6f6f6 !important;
  }
  .el-table .cancel-row:hover>td {
    background: #e6e6e6 !important;
  }
  .el-table .cell {
      white-space: nowrap;
  }
}
</style>
