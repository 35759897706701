<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="订单类型">
          <el-select class="item-choose"  @change="getDataList" v-model="dataForm.orderType" size="small" filterable clearable
                      placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="item in dictTypeMap.orderType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车型" prop="vehicleType">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.vehicleType" style="width: 100%;" filterable clearable
                      placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="item in vehicleTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose"  @change="getDataList" v-model="dataForm.status" size="small" filterable clearable
                      placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板名称">
          <el-input v-model="dataForm.templateName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货省" prop="takeProvince">
          <el-input v-model="dataForm.takeProvince" placeholder="提货省" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货市" prop="takeCity">
          <el-input v-model="dataForm.takeCity" placeholder="提货市" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货区" prop="takeArea">
          <el-input v-model="dataForm.takeArea" placeholder="提货区" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货地" prop="takeAddress">
          <el-input v-model="dataForm.takeAddress" placeholder="提货地" clearable></el-input>
        </el-form-item>
        <el-form-item label="卸货地" prop="giveAddress">
          <el-input v-model="dataForm.giveAddress" placeholder="卸货地" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="info">查询</el-button>
          <el-button v-if="newAuth('price:receivable:add')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button v-if="newAuth('price:receivable:enable')" type="primary" @click="deleteHandle()">批量删除</el-button>
          <span class="searchUpload" v-if="newAuth('price:receivable:import')">
            <al-upload :fileData="enclosureInfoList" :notShow="false" :fileUrl="$http.adornUrl('/priceBase/import')" @getFileData="getFileData"></al-upload>
          </span>

        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                height="67vh"
                border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="selection" width="25" fixed="left" align="center"></el-table-column>
        <el-table-column type="index" label="序号">
        </el-table-column>
        <el-table-column prop="templateName" label="报价模板" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="takeProvince" label="提货省份" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="takeCity" label="提货市" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="takeArea" label="提货区" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="takeAddress" label="提货地">
        </el-table-column>
        <el-table-column prop="giveProvince" label="卸货省份" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="giveCity" label="卸货市" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="giveArea" label="卸货区" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="giveAddress" label="卸货地" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="90" :show-overflow-tooltip="true" prop="vehicleType" :formatter = "dictConvert" label="车型">
        </el-table-column>
        <el-table-column prop="price" label="价格" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderType" :formatter="orderType" label="订单类型" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <af-table-column prop="status" label="状态" width="40">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" size="small" type="danger">启用</el-tag>
            <el-tag v-else size="small" type="success">禁用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" label="操作" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('price:receivable:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
              <el-button v-if="newAuth('price:receivable:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row.id)">修改</el-button>
              <el-button v-if="newAuth('price:receivable:enable')" type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
              <el-button v-if="newAuth('price:receivable:enable')" type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <detail v-if="detailVisible" ref="detail"></detail>
  </div>
</template>

<script>
import AddOrUpdate from './details/base-receipt-save'
import detail from './details/info-detail'
export default {
  data () {
    return {
      dataForm: {
        templateCode: null,
        templateName: null,
        orderType: null,
        takeProvince: null,
        takeArea: null,
        takeCity: null,
        takeAddress: null,
        giveAddress: null,
        vehicleType: null,
        baseType: 1,
        status: 1
      },
      uploadUrl: '',
      accessToken: '',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      enclosureInfoList: [],
      vehicleTypeList: [],
    }
  },
  components: {
    AddOrUpdate,
    detail
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 获取附件信息
    getFileData (data) {
      if (data) {
        this.$message({
          message: '导入成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.getDataList()
          }
        })
      } else {
        this.$message.error('操作失败')
      }
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/priceBase/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'takeProvince': this.dataForm.takeProvince,
          'takeCity': this.dataForm.takeCity,
          'takeArea': this.dataForm.takeArea,
          'baseType': this.dataForm.baseType,
          'orderType': this.dataForm.orderType,
          'takeAddress': this.dataForm.takeAddress,
          'giveAddress': this.dataForm.giveAddress,
          'vehicleType': this.dataForm.vehicleType,
          'templateCode': this.dataForm.templateCode,
          'templateName': this.dataForm.templateName
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/priceBase/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定进行【${id ? '删除' : '批量删除'}】操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/priceBase/delete'),
          method: 'delete',
          data: this.$http.adornData({ ids: ids }, false)
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    }
  },
  created() {
    // 获取车型信息
    this.$http({
      url: this.$http.adornUrl(`/vehicleType/findAll`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      this.vehicleTypeList = data
    })
  }
}
</script>
<style lang="less">
span.searchUpload {
    display: inline-block;
    margin-left: 10px;
}
</style>
