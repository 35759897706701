<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="车型">
          <el-input v-model="dataForm.vehicleTypeName" placeholder="车型" clearable></el-input>
        </el-form-item>
        <el-form-item label="车次号">
          <el-input v-model="dataForm.planNo" placeholder="车次号" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货日期">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            @change="getDataList"
            unlink-panels
            range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in dictTypeMap.planStatus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" @click="downLoad">导出</el-button>
        </el-form-item>
        <!-- <el-form-item style="text-align: right; float: right;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('attention', 'edit')" type="success" :disabled="!selectListArr.length">批量发布任务
            </el-button>
            <el-button size="small" @click="operatopnList('order')" type="danger" :disabled="!selectListArr.length">批量取消车次
            </el-button>
            <el-button size="small" @click="operatopnList('orderReturn')" type="warning" :disabled="!selectListArr.length">显示车牌
            </el-button>
          </div>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="64vh"  :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%;" @selection-change="handleSelectionChange">
        <el-table-column type="index" width="25" fixed="left" align="center"> </el-table-column>
        <!-- <el-table-column v-for="(row, index) in tableRow"  :width="row.width ? row.width : ''" :key="index" :label="row.label" :prop="row.prop" align="center"></el-table-column> -->
        <el-table-column  label="车次号" prop="planNo" width="110" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货日期" width="70" prop="takeGoodsDate" align="left" :show-overflow-tooltip="true">
        </el-table-column>
        <af-table-column  label="所属车队" prop="motorcadeName" align="left" :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="车型" prop="vehicleTypeName"  :show-overflow-tooltip="true">
        </af-table-column>
        <el-table-column  label="车牌" prop="vehiclePlate" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <af-table-column  label="司机姓名" prop="driverName"  :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="司机电话" prop="driverPhone" width="90"  align="left" :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column prop="status" label="体积满载率" width="70" >
          <template slot-scope="scope">
            <el-tag size="small" type="danger">{{ scope.row.volumeLoadRate }}%</el-tag>
          </template>
        </af-table-column>
        <af-table-column  label="总件数" prop="pieceNum"  :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="总重量" prop="weight"  :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="总体积" prop="volume"  :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="提货点数" prop="takeNum"  width="80" :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="卸货点数" prop="giveNum"  width="80" :show-overflow-tooltip="true">
        </af-table-column>
        <af-table-column  label="车次创建人" prop="createId" align="left" :show-overflow-tooltip="true">
        </af-table-column>
        <el-table-column prop="status" align="center" label="状态" width="50" fixed="right">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small">已取消</el-tag>
            <el-tag v-if="scope.row.status === 1" size="danger">未发布</el-tag>
            <el-tag v-if="scope.row.status === 2" size="small">已发布</el-tag>
            <el-tag v-if="scope.row.status === 4" size="small">已接单</el-tag>
            <el-tag v-if="scope.row.status === 5" size="small">进行中</el-tag>
            <el-tag v-if="scope.row.status === 8" size="small">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" min-width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="detail(scope.row, 'view')">详情</el-button>
              <el-button v-if="isAuth('sys:user:update') && scope.row.status === 0 || scope.row.status === 1" type="text" size="small" @click="releaseTask(scope.row)">发布</el-button>
              <el-button v-if="isAuth('sys:user:update') && scope.row.status <= 2" type="text" size="small" @click="reassign(scope.row, 'reassign')">重新指派</el-button>
              <el-button v-if="isAuth('sys:user:update') && scope.row.status === 2 || scope.row.status === 3" type="text" size="small" @click="cancelTask(scope.row)">取消</el-button>
              <el-button v-if="isAuth('sys:user:update') && scope.row.status === 0 || scope.row.status === 1" type="text" size="small" @click="deleteTask(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 所有车次弹框 -->
    <all-number-detail ref="detail" @refreshDataList="getDataList"></all-number-detail>
    <!-- 弹窗 -->
    <!-- <intel-scheDetail ref="detail"></intel-scheDetail> -->
    <intel-scheDetail ref="dispatch" @refreshDataList="refreshData"></intel-scheDetail>
  </div>
</template>

<script>
import allNumberDetail from './detail/allNumber-detail'
import intelScheDetail from '@/views/commonPop/list-dispatch'
export default {
  data () {
    return {
      dataForm: {
        status: null,
        vehiclePlate: null,
        vehicleTypeName: null,
        takeGoodsTime: null,
        planNo: null
      },
      dataList: [],
      hackReset: false,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      getIndex: -1,
      selectListArr: []
    }
  },
  components: {
    allNumberDetail,
    intelScheDetail
  },
  created () {
    // this.setDefaultDate()
  },
  activated () {
    // this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    refreshData () {
      this.getDataList()
      this.$emit('getTotal')
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 多选操作
    handleSelectionChange (rows) {
      this.selectListArr = rows
    },
    // 重新指派
    reassign (row, todo) {
      this.$nextTick(() => {
        this.$refs.dispatch.init(row, todo)
      })
    },
    // 发布任务
    releaseTask (row) {
      this.$confirm('你正在执行【任务发布】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/commitPlan/${row.id}`),
          method: 'post'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error(data.data.msg)
          }
        })
      }).catch(() => {
      })
    },
    // 取消车次
    cancelTask (row) {
      this.$confirm('你正在执行【取消发布】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/cancelPlan/${row.id}`),
          method: 'post'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error(data.data.msg)
          }
        })
      }).catch(() => {
      })
    },
    // 删除车次
    deleteTask (row) {
      this.$confirm('你正在执行【删除】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/${row.id}`),
          method: 'DELETE'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error(data.data.msg)
          }
        })
      }).catch(() => {
      })
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.beginCreateTime = takeGoodsTime[0]
        timeParam.endCreateTime = takeGoodsTime[1]
      }
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/export'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'vehiclePlate': this.dataForm.vehiclePlate,
          'vehicleTypeName': this.dataForm.vehicleTypeName,
          'status': this.dataForm.status,
          'planNo': this.dataForm.planNo,
          'beginDate': timeParam.beginCreateTime,
          'endDate': timeParam.endCreateTime
        })
      }).then(({ data }) => {
        this.dataListLoading = false
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '车次列表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.beginCreateTime = takeGoodsTime[0]
        timeParam.endCreateTime = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'vehicleTypeName': this.dataForm.vehicleTypeName,
          'status': this.dataForm.status,
          'planNo': this.dataForm.planNo,
          'beginDate': timeParam.beginCreateTime,
          'endDate': timeParam.endCreateTime
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.$emit('getTotal')
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    }
  }
}
</script>

// <style lang="less">
// .el-select-dropdown.el-popper {
//   top: 173px !important
// }
// </style>
