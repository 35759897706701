import { JSEncrypt } from 'jsencrypt'

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyhhxMSSxQSCLg7UQWNawyj-fYkfa936lIffB4HdwEwM4UQ5Al_toWBWvfBjn74zn6jlMAb6zDd6UGfdX8tcki9sIEAJ1gNPPGJI7fAGdBo_6KHnJnhm2VRzmwrD6zEtLh-Qry4vqa9zUaCOku4i64ntuYQ4oqMbEoVza3k0EIepYAqgQO9rT_Ufz9Q-h5tPuHFFJ4ysqQZ_GZKbOaJ1eC5GwAo6VTwcSJT3EuDfTdbJJeliD1eZRtymaj2pmeAv6HhgnmqVuUwKlzWu6K4J4a3ZbPparQQaTXhO2KYNR3ywe5bI_21n0sNH4jpAnvfTs0WokIcEWm4ysDRgmgffJ3QIDAQAB'

// 加密方法
export default {
  RSAEncrypt (string) {
    // 实例化jsEncrypt对象
    const jse = new JSEncrypt() // 设置公钥
    jse.setPublicKey(publicKey)
    return jse.encrypt(string)
  },
  // 解密方法
  RSADecrypt (string) {
    const jse = new JSEncrypt() // 私钥
    jse.setPrivateKey(publicKey)
    return jse.decrypt(string)
  }
}
