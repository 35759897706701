<template>
  <div class="mod-home">
  </div>
</template>

<script>
export default {
}
</script>

<style>
  .mod-home {
    line-height: 1.5;
  }
</style>
