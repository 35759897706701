<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="企业名称">
          <el-input v-model="dataForm.fullName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="企业信用代码">
          <el-input v-model="dataForm.creditCode" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="dataForm.numberCard" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            v-model="dataForm.applyTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDataList"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="认证类型">
          <el-select class="item-choose" v-model="dataForm.realType" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.realType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="状态">-->
<!--          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">-->
<!--            <el-option-->
<!--              v-for="item in dictTypeMap.status"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table
        :data="dataList"
        border
        size="small"
        height="72vh"
        :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
        <el-table-column prop="fullName" header-align="center" align="left" label="企业名称" min-width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="creditCode" header-align="center" align="center" label="企业信用代码" min-width="100">
        </el-table-column>
        <el-table-column prop="contactName" header-align="center" align="center" label="申请人" min-width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="contactPhone" header-align="center" align="center" label="联系电话" min-width="70">
        </el-table-column>
        <el-table-column prop="realType" header-align="center" align="center" width="70"
                         :formatter="dictConvert" label="认证类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.realType === 1" size="small" type="primary">个人认证</el-tag>
            <el-tag v-if="scope.row.realType === 2" size="small" type="warning">企业认证</el-tag>
            <el-tag v-if="scope.row.realType === 3" size="small" type="info">加入企业</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="numberCard" header-align="center" align="center" label="身份证号码" min-width="100">
        </el-table-column>
        <el-table-column prop="address" header-align="center" align="center" label="地址" min-width="90"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="email" header-align="center" align="center" label="个人邮箱" min-width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="receiveEmail" header-align="center" align="center" width="80" :formatter="yesNo"
                         label="是否接收邮件">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.receiveEmail === 2" size="small" type="danger">否</el-tag>
            <el-tag v-else size="small" type="success">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="申请时间" prop="applyTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="身份证正面" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, customerCard)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="身份证反面" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, customerCardOver)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="营业执照" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, customerLicense)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" header-align="center" align="center" width="50" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="50" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="examine(scope.row)">审核
              </el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle(true, scope.row.id)">详情
              </el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle(false, scope.row.id)">修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗修改 -->
    <detail v-if="detailVisible" ref="detail"></detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage'
import AddOrUpdate from './details/customer-register-save'
import detail from './details/customer-contact-detail'

export default {
  data () {
    return {
      dataForm: {
        fullName: null,
        numberCard: null,
        status: null,
        applyTime: null,
        creditCode: null,
        realType: null
      },
      customerLicense: 2000,
      customerCard: 2001,
      customerCardOver: 2002,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false
    }
  },
  components: {
    AddOrUpdate,
    detail,
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  created () {
    this.setDefaultDate()
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.applyTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 查看照片
    viewPhoto (row, type) {
      let typeList = [type]
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 审核
    examine (row) {
      this.$confirm('你正在执行【审核客户认证】操作， 是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/customer/contact/audit'),
          method: 'post',
          data: {
            id: row.id,
            realType: row.realType,
            creditCode: row.creditCode
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let applyTime = this.dataForm.applyTime
      let timeParam = {}
      if (applyTime && applyTime.length === 2) {
        timeParam.applyBeginDate = applyTime[0]
        timeParam.applyEndDate = applyTime[1]
      }
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/customer/contact/findListByAudit'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'fullName': this.dataForm.fullName,
          'creditCode': this.dataForm.creditCode,
          'realType': this.dataForm.realType,
          'status': this.dataForm.status,
          'numberCard': this.dataForm.numberCard,
          'applyBeginDate': timeParam.applyBeginDate,
          'applyEndDate': timeParam.applyEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    }
  }
}
</script>
