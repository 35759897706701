<template>
  <div class="mod-user">
    <!-- 搜索栏 -->
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="编号">
          <el-input v-model="dataForm.configNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="dataForm.configName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.tmsStatus" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台类型">
          <el-select class="item-choose" v-model="dataForm.platformType" size="small" filterable clearable
            placeholder="请选择">
            <el-option v-for="item in dictTypeMap.tmsPlatformType" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button type="success" v-if="newAuth('request:platform:gps:find')"
            @click="viewOrAddOrUpdateHandle('新增', 'add', null)">新增
          </el-button>
          <el-button @click="() => this.dataForm = {}" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格栏 -->
    <div class="tableBox">
      <el-table :data="dataList" @row-click="rowClick" :row-style="selectedHighlight" height="72vh" size="small"
        v-loading="dataListLoading" border style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="60" />
        <el-table-column prop="configName" align="center" label="名称" :show-overflow-tooltip="true" />
        <el-table-column prop="configNo" align="center" label="编号" :show-overflow-tooltip="true" />
        <el-table-column prop="platformType" align="center" label="平台类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.platformType == 0" size="small">易流云</el-tag>
            <el-tag v-else size="small" type="success">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" :show-overflow-tooltip="true" />
        <el-table-column prop="updateTime" align="center" label="更新时间" :show-overflow-tooltip="true" />
        <el-table-column prop="remark" type="remark" align="center" label="备注" :show-overflow-tooltip="true" />
        <el-table-column label="操作" align="center" fixed="right" width="200">
          <template slot-scope="scope">
            <el-link type="primary" size="small" @click="viewOrAddOrUpdateHandle('详情', 'view', scope.row)">详情
            </el-link>
            <el-link type="info" v-if="newAuth('request:platform:gps:update')" size="small"
              @click="viewOrAddOrUpdateHandle('修改', 'edit', scope.row)">修改
            </el-link>
            <el-link type="warning" v-if="newAuth('request:platform:gps:status')" size="small"
              @click="disableOrEnable(scope.row)">{{ scope.row.status == 0 ? '禁用' : '启用' }}
            </el-link>
            <el-link type="danger" v-if="newAuth('request:platform:gps:deleted')" size="small"
              @click="removeEnable(scope.row)">删除
            </el-link>
            <el-link v-if="newAuth('request:platform:gps:pageBaseThirdPartyGpsVO')" size="small" @click="viewVehicle(scope.row)">查看车辆
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 视图/新增/修改 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="viewOrAddOrUpdate.viewOrAddOrUpdateVisible"
      v-loading="viewOrAddOrUpdate.viewOrAddOrUpdateLoading" :title="viewOrAddOrUpdate.viewOrAddOrUpdateTitle">
      <el-form ref="formRef" v-loading="viewOrAddOrUpdate.viewOrAddOrUpdateFormLoading"
        :model="viewOrAddOrUpdate.viewOrAddOrUpdateForm" :rules="viewOrAddOrUpdate.viewOrAddOrUpdateFormRules"
        label-width="100px" size="mini" label-position="left">
        <!-- 平台类型/状态 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台类型" prop="platformType">
              <el-select class="item-choose" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.platformType"
                :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' || viewOrAddOrUpdate.viewOrAddOrUpdateType === 'view'"
                size="small" filterable clearable placeholder="请选择">
                <el-option v-for="item in dictTypeMap.tmsPlatformType" :key="item.id" :label="item.name"
                  :value="item.id.toString()"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-select class="item-choose" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.status"
                :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' || viewOrAddOrUpdate.viewOrAddOrUpdateType === 'view'"
                size="small" filterable clearable placeholder="请选择">
                <el-option v-for="item in dictTypeMap.tmsStatus" :key="item.id" :label="item.name"
                  :value="item.id.toString()"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 名称/编号 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="configName">
              <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configName"
                :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="configNo">
              <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configNo"
                :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- ------------------------------  易流云 ------------------------------ -->
        <div
          v-if="viewOrAddOrUpdate.viewOrAddOrUpdateForm.platformType != null && viewOrAddOrUpdate.viewOrAddOrUpdateForm.platformType == 0">
          <!-- 公钥/私钥 -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公钥">
                <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.publicKey"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="私钥">
                <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.privateKey"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 车辆轨迹URL/车辆最新位置信息URL -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="车辆轨迹URL">
                <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.vehicleTrackUrl"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
                <el-link type="info">示例: https://api.e6yun.com/public/v3/StatisticsReport/Call</el-link>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车辆最新位置信息URL">
                <el-input size="small"
                  v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.vehicleLatestLocationUrl"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
                <el-link type="info">示例: https://api.e6yun.com/public/v3/Inface/Call</el-link>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 以地图模式获取车辆的最新位置信息URL/地图模式获取车辆轨迹URL -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="以地图模式获取车辆的最新位置信息URL">
                <el-input size="small"
                  v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.vehicleLatestLocationMapUrl"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
                <el-link type="info">示例: https://api.e6yun.com/public/v3/MapServices/Orientation.aspx?</el-link>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地图模式获取车辆轨迹URL">
                <el-input size="small"
                  v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.vehicleLatestLocationMapModeUrl"
                  :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" />
                <el-link type="info">示例: https://api.e6yun.com/public/v3/MapServices/PlayTrack.aspx?</el-link>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 获取用户名下车辆基础信息URL -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="获取用户名下车辆基础信息URL">
                <el-input size="small" v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.configValue.allVehicleInfoUrl" :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck"/>
                <el-link type="info">示例: https://api.e6yun.com/public/v4/BASIC/api/vehicle/getAllVehicleInfo</el-link>
              </el-form-item>
            </el-col>
            <el-col :span="12">
            </el-col>
          </el-row>
        </div>

        <!-- 备注 -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="viewOrAddOrUpdate.viewOrAddOrUpdateForm.remark"
                :disabled="viewOrAddOrUpdate.viewOrAddOrUpdateCheck" type="textarea" rows="10" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="viewOrAddOrUpdate.viewOrAddOrUpdateVisible = false">取消</el-button>
        <el-button size="mini" type="primary" v-if="!viewOrAddOrUpdate.viewOrAddOrUpdateCheck"
          @click="submitDetailForm">确认</el-button>
      </div>
    </el-dialog>

    <!--  查看车辆 -->
    <el-dialog :close-on-click-modal="false"
               :visible.sync="viewVehicleDialog.viewVehicleDialogVisible"
               v-loading="viewVehicleDialog.viewVehicleDialogLoading"
               :title="viewVehicleDialog.viewVehicleDialogTitle">
      <!-- 表格栏 -->
      <div class="tableBox">
        <el-table :data="viewVehicleDialog.dataList"
                  size="small"
                  v-loading="viewVehicleDialog.dataListLoading"
                  border
                  height="50vh"
                  style="width: 100%;">
          <el-table-column type="index" align="center" label="序号" width="60" />
          <el-table-column prop="vehicleNo" align="center" label="真实车牌" :show-overflow-tooltip="true" />
          <el-table-column prop="regName" align="center" label="系统车牌" :show-overflow-tooltip="true" />
          <el-table-column prop="customName" align="center" label="自定义车牌" :show-overflow-tooltip="true" />
          <el-table-column prop="customName" align="center" label="是否已加入系统" width="120">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.whetherYouHaveJoinedTheSystem == 0" size="small" type="danger">否</el-tag>
              <el-tag v-else size="small" type="success">是</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="monitorCorpName" align="center" label="车辆监控公司全称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="monitorCorpSimName" align="center" label="车辆监控公司简称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="corpSimName" align="center" label="车辆所属公司简称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="corpName" align="center" label="车辆所属公司全称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="vehicleColorName" align="center" label="车牌颜色名称" :show-overflow-tooltip="true" />
          <el-table-column prop="vehiclePropertyName" align="center" label="车辆属性名称" :show-overflow-tooltip="true" />
          <el-table-column prop="vehicleStatusName" align="center" label="车辆状态名称" :show-overflow-tooltip="true" />
          <el-table-column prop="deviceTypeName" align="center" label="设备类型名称" :show-overflow-tooltip="true" />
          <el-table-column prop="temperatureChannel" align="center" label="温控线路" :show-overflow-tooltip="true" />
          <el-table-column prop="humidityChannel" align="center" label="湿度线路" :show-overflow-tooltip="true" />
          <el-table-column prop="orgNames" align="center" label="部门名称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="modifiedTime" align="center" label="基础资料最大修改时间" width="120" />
          <el-table-column prop="commids" align="center" label="中心识别码" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="dataTerminalName" align="center" label="终端名称" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="equipId" align="center" label="设备id" width="120" :show-overflow-tooltip="true" />
          <el-table-column prop="equipCode" align="center" label="设备编号" width="120" :show-overflow-tooltip="true" />
          <el-table-column label="操作" align="center" fixed="right" width="100">
            <template slot-scope="scope">
              <el-link type="success" size="small" :disabled="scope.row.whetherYouHaveJoinedTheSystem == 1 || viewVehicleDialog.row.status == 1" @click="joinThisSystem(scope.row)">加入系统
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="sizeChangeHandleV2"
            @current-change="currentChangeHandleV2"
            :current-page="viewVehicleDialog.pageIndex"
            :page-sizes="[20, 50, 100]"
            :page-size="viewVehicleDialog.pageSize"
            :total="viewVehicleDialog.totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>

      <!-- 运力管理-车辆运力 新增/修改/查看 -->
      <vehicle-add-or-update v-if="viewVehicleDialog.vehicleAddOrUpdateVisible" ref="vehicleAddOrUpdate"></vehicle-add-or-update>
    </el-dialog>
  </div>
</template>

<script>
import vehicleAddOrUpdate from '../transport/details/vehicle-add-or-update'
export default {
  data() {
    return {
      // 搜索信息
      dataForm: {},
      // 表格
      dataList: [],
      getIndex: -1,
      dataListLoading: false,
      columns: [
        { label: '名称', name: 'configName' },
        { label: '编号', name: 'configNo' },
        { label: '平台类型', name: 'platformType' },
        { label: '状态', name: 'status' },
        { label: '创建时间', name: 'createTime' },
        { label: '更新时间', name: 'updateTime' },
        { label: '备注', name: 'remark' },
      ],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      // 视图/新增/修改
      viewOrAddOrUpdate: {
        // 弹窗
        viewOrAddOrUpdateVisible: false,
        viewOrAddOrUpdateLoading: false,
        viewOrAddOrUpdateTitle: '',
        viewOrAddOrUpdateType: '',
        viewOrAddOrUpdateCheck: false,
        // 表单
        viewOrAddOrUpdateForm: {
          configValue: {}
        },
        viewOrAddOrUpdateFormLoading: false,
        viewOrAddOrUpdateFormRules: ({
          configName: [{ required: true, message: '请输入名称', trigger: 'change' }],
          configNo: [{ required: true, message: '请输入编号', trigger: 'change' }],
          platformType: [{ required: true, message: '请选择平台类型', trigger: 'change' }],
          status: [{ required: true, message: '请选择状态', trigger: 'change' }]
        })
      },
      // 查看车辆
      viewVehicleDialog: {
        dataForm: {},
        dataList: [],
        getIndex: -1,
        dataListLoading: false,
        pageIndex: 1,
        pageSize: 20,
        totalPage: 0,
        row: {},
        vehicleAddOrUpdateVisible: false,

        // 弹窗
        viewVehicleDialogVisible: false,
        viewVehicleDialogLoading: false,
        viewVehicleDialogTitle: '',


      },
    }
  },
  components: {
    vehicleAddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色
     * @param row 当前行数据
     * @param rowIndex 当前行号
     * @returns {{"background-color": string}}
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick(row) {
      this.getIndex = row.index
    },

    /**
     * 每页大小
     * @param val 当前选中页大小
     */
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    /**
     * 当前页
     * @param val 当前选中的页数
     */
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataForm.page = this.pageIndex
      this.dataForm.pageSize = this.pageSize

      // 执行
      this.$http({
        url: this.$http.adornUrl('/platform/gps/page'),
        method: 'get',
        params: this.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
      }).finally(() => {
        this.dataListLoading = false
      })
    },

    // 视图/新增/修改
    viewOrAddOrUpdateHandle(title, type, row) {
      // 重置
      this.viewOrAddOrUpdate = this.$options.data().viewOrAddOrUpdate

      // 执行
      this.viewOrAddOrUpdate.viewOrAddOrUpdateVisible = true
      this.viewOrAddOrUpdate.viewOrAddOrUpdateTitle = title
      this.viewOrAddOrUpdate.viewOrAddOrUpdateType = type
      this.viewOrAddOrUpdate.viewOrAddOrUpdateCheck = type === 'view'
      if (type === 'add') {
        this.viewOrAddOrUpdate.viewOrAddOrUpdateForm = this.$options.data().viewOrAddOrUpdate.viewOrAddOrUpdateForm
      } else {
        let rowCopy = JSON.parse(JSON.stringify(row))
        rowCopy.configValue = rowCopy.configValue ? JSON.parse(rowCopy.configValue) : {}
        this.viewOrAddOrUpdate.viewOrAddOrUpdateForm = rowCopy
      }
    },

    // 提交
    submitDetailForm() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          this.viewOrAddOrUpdate.viewOrAddOrUpdateLoading = true
          let formCopy = JSON.parse(JSON.stringify(this.viewOrAddOrUpdate.viewOrAddOrUpdateForm))
          formCopy.configValue = JSON.stringify(formCopy.configValue)
          this.$http({
            url: this.$http.adornUrl(this.viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' ? `/platform/gps/update` : `/platform/gps/add`),
            method: this.viewOrAddOrUpdate.viewOrAddOrUpdateType === 'edit' ? 'PUT' : 'POST',
            data: this.$http.adornData(formCopy)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.viewOrAddOrUpdate.viewOrAddOrUpdateVisible = false
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          }).finally(() => {
            this.viewOrAddOrUpdate.viewOrAddOrUpdateLoading = false
          })
        }
      })
    },

    // 禁用或启用
    disableOrEnable(row) {
      let title = row.status == 0 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.configName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/platform/gps/status'),
          method: 'GET',
          params: {
            id: row.id,
            status: row.status == 1 ? 0 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },

    // 删除
    removeEnable(row) {
      let title = '删除'
      this.$confirm('确定对【' + row.configName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/platform/gps/' + row.id),
          method: 'DELETE'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },

    // 查看车辆
    viewVehicle (row) {
      // 初始化
      this.viewVehicleDialog = this.$options.data().viewVehicleDialog
      this.viewVehicleDialog.viewVehicleDialogTitle = '查看车辆'
      this.viewVehicleDialog.viewVehicleDialogLoading = true
      this.viewVehicleDialog.row = row

      // 打开
      this.viewVehicleDialog.viewVehicleDialogVisible = true


      // 请求参数
      this.viewVehicleDialog.dataForm.id = row.id

      // 请求数据
      this.getDataListV2()

      // 还原
      this.viewVehicleDialog.viewVehicleDialogLoading = false
    },

    /**
     * 每页大小
     * @param val 当前选中页大小
     */
    sizeChangeHandleV2 (val) {
      this.viewVehicleDialog.pageSize = val
      this.viewVehicleDialog.pageIndex = 1
      this.getDataListV2()
    },

    /**
     * 当前页
     * @param val 当前选中的页数
     */
    currentChangeHandleV2 (val) {
      this.viewVehicleDialog.pageIndex = val
      this.getDataListV2()
    },


    // 获取车辆数据列表
    getDataListV2 () {
      this.viewVehicleDialog.dataListLoading = true
      this.viewVehicleDialog.dataForm.page = this.viewVehicleDialog.pageIndex
      this.viewVehicleDialog.dataForm.pageSize = this.viewVehicleDialog.pageSize

      // 执行
      this.$http({
        url: this.$http.adornUrl('/platform/gps/pageBaseThirdPartyGpsVO'),
        method: 'get',
        params: this.$http.adornParams(this.viewVehicleDialog.dataForm)
      }).then(({ data }) => {
        if (data) {
          this.viewVehicleDialog.dataList = data.data
          this.viewVehicleDialog.totalPage = data.totalRecords
        } else {
          this.viewVehicleDialog.dataList = []
          this.viewVehicleDialog.totalPage = 0
        }
      }).finally(() => {
        this.viewVehicleDialog.dataListLoading = false
      })
    },

    // 加入本系统
    joinThisSystem (row) {
      // 路由过去后有遮盖,无法操作
      //this.$router.push({ name: 'transport-vehicle' })
      this.viewVehicleDialog.vehicleAddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.vehicleAddOrUpdate.init('add', null,row.vehicleNo,this.viewVehicleDialog.row.id)
      })

    }
  }
}
</script>
