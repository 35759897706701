<template>
  <el-dialog
    :title="todo ? '【详情】认证信息' : '【修改】认证信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <div class="modularLabel modularLabel1">
        <label>基本信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="12">
<!--            <el-form-item label="企业名称" prop="fullName">-->
<!--              <el-input v-model="dataForm.fullName" placeholder="企业名称" :disabled="todo"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="企业名称" prop="customerId">
              <el-select class="item-choose" v-model="dataForm.customerId" :disabled="todo" style="width: 100%;"
                         @change="changeCreditCode(false)"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.fullName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="企业信用代码" prop="creditCode">-->
<!--              <el-input v-model="dataForm.creditCode" placeholder="企业信用代码" :disabled="todo"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="企业信用代码" prop="creditCode">
              <el-input v-model="dataForm.creditCode" placeholder="企业信用代码" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="申请人" prop="contactName">
              <el-input v-model="dataForm.contactName" placeholder="客户联系人姓名" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" placeholder="客户联系人电话" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="认证类型" prop="realType">
              <el-select class="item-choose" v-model="dataForm.realType" size="small" filterable clearable placeholder="请选择" :disabled="todo">
                <el-option
                  v-for="item in dictTypeMap.realType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号码" prop="numberCard">
              <el-input v-model="dataForm.numberCard" placeholder="身份证号码" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="地址" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="个人邮箱" prop="email">
              <el-input v-model="dataForm.email" placeholder="个人邮箱" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否接受邮件" prop="receiveEmail">
              <el-select class="item-choose" v-model="dataForm.receiveEmail" :disabled="todo" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel1">
        <label>图片信息</label>
      </div>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="身份证正面">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: customerCard}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(customerCard)" :src="getPhotoType(customerCard)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证反面">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: customerCardOver}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(customerCardOver)" :src="getPhotoType(customerCardOver)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="企业营业执照">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: customerLicense}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(customerLicense)" :src="getPhotoType(customerLicense)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!todo">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isPhone, isMobile, isCard } from '@/utils/validate'
export default {
  data () {
    const validateMobile = (rule, value, callback) => {
      if (!isMobile(value) && !isPhone(value)) {
        callback(new Error('格式错误，可输入手机号码或固定电话'))
      } else {
        callback()
      }
    }
    const validateCard = (rule, value, callback) => {
      if (!isCard(value)) {
        callback(new Error('身份证号码格式错误'))
      } else {
        callback()
      }
    }
    return {
      todo: true,
      visible: false,
      customerLicense: 2000,
      customerCard: 2001,
      customerCardOver: 2002,
      dataForm: {
        customerId: null,
        creditCode: null
      },
      areaList: [],
      uploadUrl: '',
      accessToken: '',
      isShow: true,
      customerList: [],
      dataRule: {
        customerId: [
          { required: true, message: '请选择客户所绑定企业信息', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, message: '企业信用代码不能为空', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '企业申请人不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        numberCard: [
          { required: true, message: '身份证号码不能为空', trigger: 'blur' },
          { validator: validateCard, trigger: 'blur' }
        ],
        realType: [
          { required: true, message: '请选择客户认证类型', trigger: 'change' }
        ]
      },
      domain: ''
    }
  },
  created() {
    let that = this
    that.$http({
      url: that.$http.adornUrl('/sys/appConfig/get'),
      method: 'get',
      params: that.$http.adornParams({ type: 2 })
    }).then(({ data }) => {
      this.domain = JSON.parse(data.configValue).spaceDomain
    })
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.dataForm.enclosureInfoList = []
    },
    initFileList (data) {
      if (data.enclosureInfoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
      }
    },
    // 获取省市区信息
    getAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/register/findCityList`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.areaList = data
      })
    },
    getPhotoType (type) {
      let enclosureInfoList = this.dataForm.enclosureInfoList
      if (!enclosureInfoList) {
        return ''
      }
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === type) {
          return this.domain + enclosureInfoList[i].url
        }
      }
      return ''
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      this.$http({
        url: this.$http.adornUrl('/app/customer/findListCustomer'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.customerList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/customer/contact/getByAudit/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            this.dataForm = data
            this.initFileList(data)
          })
        }
      })
      // this.$http({
      //   url: this.$http.adornUrl(`/customer/contact/getByAudit/${this.dataForm.id}`),
      //   method: 'get',
      //   params: this.$http.adornParams()
      // }).then(({ data }) => {
      //   this.dataForm = data
      //   this.initFileList(data)
      // }).then(() => {
      //   this.visible = true
      //   this.$nextTick(() => {
      //     this.$refs['dataForm'].resetFields()
      //   })
      // })
    },
    // 表单提交
    dataFormSubmit () {
      console.log(this.dataForm)
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/customer/contact/updateAudit`),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 选择企业同时变更企业信用代码和企业营业执照
    changeCreditCode (init) {
      if (!init) {
        this.dataForm.creditCode = null
      }
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/app/customer/findListCustomer`),
        method: 'get',
        params: this.$http.adornParams({
          id: this.dataForm.customerId
        })
      }).then(({ data }) => {
        console.log(data)
        // 将值存入联系人、联系人电话下拉搜索框中
        // 选择客户名称时有联系人信息则将第一个联系人信息赋值
        if (!init && data[0]) {
          this.dataForm.creditCode = data[0].creditCode
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      let enclosureInfoList = this.dataForm.enclosureInfoList
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === resData.businessType) {
          enclosureInfoList.splice(i, 1)
          break
        }
      }
      enclosureInfoList.push({ id: resData.id, businessType: resData.businessType, url: resData.url })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>

<style lang="less">
.modularLabel {
  margin-bottom: 20px;

  label {
    font-size: 18px;
    font-weight: bold;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
