<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="保险对象">
          <el-select class="item-choose" v-model="dataForm.insureObject" style="width: 100%;"
                     filterable clearable placeholder="请选择"
                     @change="getDataList">
              <el-option
                v-for="item in dictTypeMap.insure_object"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="车牌号">
            <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号"></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="dataForm.beginTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保险状态">
          <el-select class="item-choose" v-model="dataForm.status"
                     @change="getDataList" size="small"
                     filterable clearable placeholder="请选择">
            <el-option label="正常" value="1"></el-option>
            <el-option label="部分正常" value="2"></el-option>
            <el-option label="已过期" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="车队名称">-->
<!--            <el-input v-model="dataForm.motorcadeName" placeholder="车队名称"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('vehicleInsure:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('vehicleInsure:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" ref="tableRef"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small"
                :summary-method="getSummaries"
                show-summary
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column prop="vehicleInsure.insureObject" label="保险对象" :formatter="dictConvert" width="60"></el-table-column>
        <el-table-column prop="vehicleInsure.vehiclePlate" label="车牌号" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="vehicleInsure.status" label="保险状态" :show-overflow-tooltip="true" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.vehicleInsure.status === 3" size="small" type="danger">已过期</el-tag>
            <el-tag v-else-if="scope.row.vehicleInsure.status === 2" size="small" type="warning">部分正常</el-tag>
            <el-tag v-else size="small" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleInsure.allCost" label="全部保费(￥)" width="80"></el-table-column>
        <el-table-column prop="vehicleInsure.validInsure" label="有效保单" width="60"></el-table-column>
        <el-table-column prop="vehicleInsure.createName" label="创建人" :show-overflow-tooltip="true" width="70"></el-table-column>
        <el-table-column prop="typeName" label="保单类型" width="90"></el-table-column>
        <el-table-column prop="status" label="保单状态" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 3" size="small" type="danger">已过期</el-tag>
            <el-tag v-else-if="scope.row.status === 2" size="small" type="warning">即将过期</el-tag>
            <el-tag v-else-if="scope.row.status === 1" size="small" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="beginTime" label="开始日期" width="80"></el-table-column>
        <el-table-column prop="endTime" label="到期日期" width="80"></el-table-column>
        <el-table-column prop="insureTotal" label="保费总计(￥)" :show-overflow-tooltip="true" width="80"></el-table-column>
        <el-table-column prop="insureNo" label="保单号" :show-overflow-tooltip="true" width="110"></el-table-column>
        <el-table-column prop="insureUser" label="被保险人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="insureCompany" label="保险公司" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="费用小计" prop="payableAmount" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="vehicleInsure.writeName" label="核销人" width="60" align="center">
        </el-table-column>
        <el-table-column prop="vehicleInsure.writeDate" label="核销时间" width="70" align="center">
        </el-table-column>
        <el-table-column label="付款状态" prop="vehicleInsure.writeStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.vehicleInsure.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.vehicleInsure.writeStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operate" fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('insurance:management:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.vehicleInsure)">详情</el-button>
              <el-button v-if="newAuth('insurance:management:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row.vehicleInsure)">修改</el-button>
              <el-button v-if="newAuth('insurance:management:delete')" type="text" size="small" @click="disableOrEnable(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

    <!--审核弹窗-->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
  </div>
</template>

<script>
import AddOrUpdate from './detail/cardType-detail'
import writeOffPopup from './detail/write-off-popups'
export default {
  data () {
    return {
      dataForm: {
        insureObject: null,
        vehiclePlate: null,
        status: null,
        writeStatus: null,
        beginTime: []
      },
      dataList: [],
      spanArr: [],
      noSpanPropertyArray: ['vehicleInsure.allCost'],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: []
    }
  },
  components: {
    AddOrUpdate,
    writeOffPopup
  },
  activated () {
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['tableRef'].doLayout()
    })
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.beginTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.writeStatus !== writeStatus) {
          ids.push(item.vehicleInsure.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(this.dataForm.insureObject, writeStatus, ids)
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.writeStatus !== writeStatus) {
          ids.push(item.vehicleInsure.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/vehicleInsure/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.id === preId) {
            return ''
          }
          preId = item.id
          if (column.property) {
            if (column.property === 'insureTotal') {
              return this.getValue(Number(item.insureTotal))
            }
            if (column.property === 'payableAmount') {
              return this.getValue(Number(item.payableAmount))
            }
            if (column.property === 'paidAmount') {
              return this.getValue(Number(item.paidAmount))
            }
            if (column.property === 'notPayableAmount') {
              return this.getValue(Number(item.notPayableAmount))
            }
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        totalSummary.insureTotal,
        '',
        '',
        '',
        totalSummary.payableAmount,
        totalSummary.paidAmount,
        totalSummary.notPayableAmount, '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let beginTime = this.dataForm.beginTime
      let timeParam = {}
      if (beginTime && beginTime.length === 2) {
        timeParam.beginTime = beginTime[0]
        timeParam.endTime = beginTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehicleInsure/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'beginTime': timeParam.beginTime,
          'endTime': timeParam.endTime,
          'insureObject': this.dataForm.insureObject,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'writeStatus': this.dataForm.writeStatus
        })
      }).then(({ data }) => {
        if (data) {
          //const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          //this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.totalRow || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
      this.selectFeeData = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.$router.push({
        name: 'insurancePage',
        params: {
          row: row,
          todo: todo
        }
      })
      // this.addOrUpdateVisible = true
      // this.$nextTick(() => {
      //   this.$refs.addOrUpdate.init(todo, id)
      // })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let tips = '确定对【' + row.vehicleInsure.vehiclePlate + '】进行删除操作?'
      let url = `/vehicleInsure/delete/${row.vehicleInsure.id}`
      if (row.typeName) {
        tips = '确定对【' + row.vehicleInsure.vehiclePlate + '】的【' + row.typeName + '】进行删除操作?'
        if (row.typeName === '交强险') {
          url = `/forceInsure/delete/${row.id}`
        } else if (row.typeName === '商业险') {
          url = `/businessInsure/delete/${row.id}`
        } else if (row.typeName === '承运人责任险') {
          url = `/carrierInsure/delete/${row.id}`
        }
      }
      this.$confirm(tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(url),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
