<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
<!--        <el-form-item label="客户代码">-->
<!--          <el-input v-model="dataForm.customerCode" placeholder="客户代码" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="企业信用代码">
          <el-input v-model="dataForm.creditCode" placeholder="企业信用代码" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户全称">
          <el-input v-model="dataForm.fullName" placeholder="客户全称" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户阶段" prop="stage">
          <el-select @change="getDataList" class="item-choose" v-model="dataForm.stage" filterable clearable placeholder="请选择">
            <el-option label="待跟进" :value="1"></el-option>
            <el-option label="跟进中" :value="2"></el-option>
            <el-option label="成交" :value="3"></el-option>
            <el-option label="流失" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跟进人">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.followId" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in userJobMap.SALE_DEP"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
                v-for="item in dictTypeMap.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对接人" prop="salesType">
          <el-select class="item-choose" v-model="dataForm.salesType" filterable clearable placeholder="请选择">
            <el-option label="业务员" :value="1"></el-option>
            <el-option label="业务经理" :value="2"></el-option>
            <el-option label="客服员" :value="3"></el-option>
            <el-option label="客服经理" :value="4"></el-option>
          </el-select>
          <el-form-item label="">
            <el-input v-model="dataForm.salesName" placeholder="对接人名称" clearable></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="newAuth('customer:manager:add')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button type="success" @click="addMarketTrack('add')">营销跟踪</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  border size="small" v-loading="dataListLoading" style="width: 100%;">
        <!-- <el-table-column v-for="(row, index) in tableRow" :show-overflow-tooltip="true" :width="row.width ? row.width : ''" :key="index" :label="row.label" :prop="row.prop" align="center"></el-table-column> -->
<!--        <el-table-column  prop="customerCode" label="客户代码" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column  prop="creditCode" label="企业信用代码" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="fullName" label="客户全称" width="150" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column  prop="simpleName" label="客户简称" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column  prop="address" label="地址" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="industry" label="行业" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="business" label="主营业务" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="grade" label="客户等级" width="80" :formatter="dictConvert" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="stage" label="客户阶段" width="80" :formatter="stageTypeConvent" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="followName" label="跟进人" width="80" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column  prop="saleName" label="业务员" width="70" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column  prop="saleAdminName" label="业务经理" width="70" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column prop="settleCycle" label="结算周期(天)" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="mailCount" label="邮件跟踪(次)" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="trackCount" label="营销跟踪(次)" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  prop="templateName" label="关联报价模板" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status" width="40" fixed="right" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('customer:manager:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
              <el-button v-if="newAuth('customer:manager:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row.id)">修改</el-button>
              <el-button v-if="newAuth('customer:manager:enable')" type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
               <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗, 新增营销跟踪 -->
    <market-track v-if="addMarketTrackVisible" ref="marketTrack"></market-track>
    <!-- 弹窗详情 -->
    <detail v-if="detailVisible" ref="detail"></detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import AddOrUpdate from './details/info-save'
import detail from './details/info-detail'
import viewImage from '@/views/commonPop/viewImage'
import qs from 'qs'
import marketTrack from './details/market-track'
export default {
  data () {
    return {
      dataForm: {
        customerCode: null,
        customerId: null,
        fullName: null,
        contactName: null,
        contactPhone: null,
        status: 1,
        customerIdList: [],
        followId: null,
        stage: null,
        creditCode: null,
        salesName: null,
        salesType: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      addMarketTrackVisible: false,
      detailVisible: false,
      stageTypeMap: null,
      userJobMap: new Map()
    }
  },
  components: {
    AddOrUpdate,
    detail,
    viewImage,
    marketTrack
  },
  activated () {
    this.stageTypeMap = new Map()
    this.stageTypeMap.set(1, '待跟进')
    this.stageTypeMap.set(2, '跟进中')
    this.stageTypeMap.set(3, '成交')
    this.stageTypeMap.set(4, '流失')
    this.getDataList()
    this.getUserList()
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.id)
    },
    // 获取用户信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/customer/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      if (!this.newAuth('customer:manager:findAll')) {
        this.dataForm.customerId = this.currentUser.customerId
        this.dataForm.customerIdList = this.currentUser.customerIdList
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'id': this.dataForm.customerId,
        'customerCode': this.dataForm.customerCode,
        'fullName': this.dataForm.fullName,
        'status': this.dataForm.status,
        'contactName': this.dataForm.contactName,
        'contactPhone': this.dataForm.contactPhone,
        'ids': this.dataForm.customerIdList,
        'followId': this.dataForm.followId,
        'stage': this.dataForm.stage,
        'creditCode': this.dataForm.creditCode,
        'salesType': this.dataForm.salesType,
        'salesName': this.dataForm.salesName
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/customer/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    stageTypeConvent (row, column, cellValue, index) {
      return this.stageTypeMap.get(cellValue)
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 新增营销跟踪
    addMarketTrack (todo) {
      this.addMarketTrackVisible = true
      this.$nextTick(() => {
        this.$refs.marketTrack.init(todo)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/customer/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
