<template>
  <div id="navbar-detail">
    <el-dialog :title="'【' + titleName + '】( '+ orderNum +' )'" :close-on-click-modal="false" :width="dataForm.status!=1?'40%':'82%'" :visible.sync="visible" :modal-append-to-body='false'>
      <div class="searchBox">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="所属车队">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车型">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.vehicleType" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in vehicleTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      </div>
      <div class="tableBox" v-if="dataForm.status == 1">
        <el-table height="55vh"
                  ref="tableRef"
                  :row-class-name="tableRowClassName"
                  @row-click="rowClick"
                  :row-style="selectedHighlight"
                  :data="dataList"
                  border size="small"
                  v-loading="dataListLoading"
                  @selection-change="selectionChangeHandle"
                  style="width: 100%;">
          <el-table-column prop="vehiclePlate" label="车牌号" width="85">
          </el-table-column>
          <el-table-column prop="motorcadeName" label="所属车队" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="currentCity" label="当前定位" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="driverName" label="绑定驾驶员" width="85" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="vehicleTypeName" label="车辆类型" width="90" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="customerName" label="客户名称" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="route" label="起点" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="entranceType" :formatter="entranceType" :show-overflow-tooltip="true" label="报关方式" width="80">
          </el-table-column>
          <el-table-column prop="giveRoute" label="终点" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="takeGoodsDate" label="提货日期" width="135">
          </el-table-column>
          <el-table-column prop="abortWarehouseTime" label="卸货日期" width="135">
          </el-table-column>
          <el-table-column prop="vehicleType" :formatter="dictConvert" label="订单车型" width="90" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="pieceNum" label="件数" width="70">
          </el-table-column>
          <el-table-column prop="weight" label="重量" width="70">
          </el-table-column>
          <el-table-column prop="volume" label="体积" width="70">
          </el-table-column>
          <el-table-column label="车辆图片" prop="" align="center" width="70">
            <template slot-scope="scope">
              <span @click="viewPhoto(scope.row.vehicleId, [3000, 3001, 3002, 3004, 9040, 9041])" style="color: blue;cursor:pointer">查 看</span>
            </template>
          </el-table-column>
          <el-table-column label="驾驶员图片" prop="" align="center" width="90">
            <template slot-scope="scope">
              <span @click="viewPhoto(scope.row.driverId, [5000, 5010, 5001])" style="color: blue;cursor:pointer">查 看</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <div class="tableBox" v-if="dataForm.status != 1">
        <el-table height="55vh"
                  ref="tableRef"
                  :row-class-name="tableRowClassName"
                  @row-click="rowClick"
                  :row-style="selectedHighlight"
                  :data="dataList" border size="small"
                  v-loading="dataListLoading"
                  @selection-change="selectionChangeHandle"
                  style="width: 100%;">
          <el-table-column prop="vehiclePlate" label="车牌号" width="85">
          </el-table-column>
          <el-table-column prop="motorcadeName" label="所属车队" width="150" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="currentCity" label="当前定位" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="driverName" label="绑定驾驶员" width="85" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="vehicleType" :formatter="dictConvert" label="车辆类型" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="vehicleEmptyWeight" label="车辆空重(KG)" width="70">
          </el-table-column>
          <el-table-column label="车辆图片" prop="" align="center" width="70">
            <template slot-scope="scope">
              <span @click="viewPhoto(scope.row.vehicleId, [3000, 3001, 3002, 3004, 9040, 9041])" style="color: blue;cursor:pointer">查 看</span>
            </template>
          </el-table-column>
          <el-table-column label="驾驶员图片" prop="" align="center" width="90">
            <template slot-scope="scope">
              <span @click="viewPhoto(scope.row.driverId, [5000, 5010, 5001])" style="color: blue;cursor:pointer">查 看</span>
            </template>
          </el-table-column>
        </el-table>
          <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submit">确定</el-button> -->
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage'
import store from '@/store'
export default {
  data () {
    return {
      dataForm: {
        motorcadeName: null,
        vehiclePlate: null,
        vehicleType: null,
        status: null
      },
      visible: false,
      fontRate: {
        OTHER_RATE: 1.5 // 除汉字和数字以外的字符的比率
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      imageTypeList: [3000, 3001],
      titleName: '',
      orderNum: null,
      spanArr: [],
      noSpanPropertyArray: ['route', 'giveRoute'],
      vehicleTypeList: [],
    }
  },
  components: {
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (id, imgList) {
      this.$refs.viewPhoto.init(id, imgList)
    },
    // 绑定驾驶员
    bindDriver (row) {
      this.$refs.bind.init(row)
    },
    // 解绑驾驶员
    untyingDriver (row) {
      this.$refs.untying.init(row)
    },
    init (typeName, orderNum) {
      this.visible = true
      this.titleName = typeName
      this.orderNum = orderNum
      if (typeName === 'out') {
        this.titleName = '已出车'
        this.dataForm.status = 1
      } else if (typeName === 'notOut') {
        this.dataForm.status = 2
        this.titleName = '未出车'
      } else {
        this.titleName = '总车辆'
        this.dataForm.status = 0
      }

      // 获取车型信息
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
      })

      this.getDataList()
    },
    // 获取数据列表
    getDataList () {
      let that = this
      that.dataListLoading = true
      that.$http({
        url: that.$http.adornUrl('/order/findListToHome'),
        method: 'get',
        params: that.$http.adornParams({
          'page': that.pageIndex,
          'limit': that.pageSize,
          'motorcadeName': that.dataForm.motorcadeName,
          'status': that.dataForm.status,
          'vehiclePlate': that.dataForm.vehiclePlate,
          'vehicleType': that.dataForm.vehicleType
        })
      }).then(({ data }) => {
        if (data) {
          //const columns = that.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          //that.getSpanArr(data.list, columns)
          that.dataList = data.list
          that.totalPage = data.total
        } else {
          that.dataList = []
          that.totalPage = 0
        }
        that.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.vehiclePlate + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/vehicleInfo/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    },
  }
}
</script>
<style lang="less">
#navbar-detail {
  .el-dialog {
    top: 50%;
    margin-top: 0 !important;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
