<template>
    <a :href="baseUrl + url + id + '?access_token=' + $cookie.get('TmsToken')" target="_Blank">
      {{ name }}
    </a>
</template>
<script>
export default {
  data () {
    return {
      baseUrl: window.SITE_CONFIG.baseUrl
    }
  },
  name: 'aLink',
  props: ['id', 'name', 'url']
}
</script>
