<!-- 单图片上传 -->
<template>
    <div class="cardUpload">
        <el-upload :action="fileUrl" ref="upload" :data="{ businessType: businessType }" class="avatar-uploader"
            :headers="{ 'access_token': $cookie.get('TmsToken') }" :show-file-list="false" :on-exceed="limitExceed"
            multiple :on-error="errorFileList" :before-upload="beforeAvatarUpload"
            accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg" :on-success="saveFileList">
            <div class="avatar el-upload-list--picture-card" v-if="fileData">
                <img class="el-upload-list__item-thumbnai avatar" :src="domain + fileData">
                <!-- <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-preview" @click="handlePictureCardPreview(domain + fileData)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="removeFileList(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span> -->
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span v-if="title">{{ title }}</span>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import Http from '@/utils/httpRequest'
import { stringify } from 'qs';
export default {
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            domain: ''
        }
    },
    name: 'upload-images',
    props: {
        fileData: {
            type: String,
            default: ''
        },
        fileUrl: {
            type: String,
            default: Http.adornUrl('/upload/img')
        },
        businessType: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        }
    },
    created() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            let that = this
            that.$http({
                url: that.$http.adornUrl('/sys/appConfig/get'),
                method: 'get',
                params: that.$http.adornParams({ type: 2 })
            }).then(({ data }) => {
                this.domain = JSON.parse(data.configValue).spaceDomain
            })
        },
        limitExceed(data, files) {
            if (files.length === this.limit) {
                this.$message.error(`文件上传限制为${this.limit}个文件`)
            }
        },
        downLoadXls(data, filename) {
            // var blob = new Blob([data], {type: 'application/vnd.ms-excel'})接收的是blob，若接收的是文件流，需要转化一下
            if (typeof window.chrome !== 'undefined') {
                // Chrome version
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = filename
                link.click()
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE version
                var blob = new Blob([data], { type: 'application/force-download' })
                window.navigator.msSaveBlob(blob, filename)
            } else {
                // Firefox version
                var file = new File([data], filename, { type: 'application/force-download' })
                window.open(URL.createObjectURL(file))
            }
        },
        handlePictureCardPreview(file) {
            this.dialogVisible = true;
            this.dialogImageUrl = file;
        },
        // 文件上传成功保存id和类型
        saveFileList(response, file) {
            if (response.code !== 100000) {
              // 若上传失败，移除上传失败的数据
              const uid = file.uid
              const idx = this.$refs.upload.uploadFiles.findIndex(item => item.uid === uid)
              this.$refs.upload.uploadFiles.splice(idx, 1)
              this.$message.error(response.message)
                this.$message.error(response.message)
              return;
            }
            let resData = response.data
            file.id = resData.id
            if (response.code === 100000) {
            } else {
                file = {}
            }
            this.$emit('getFileData', {
                fileList: resData.url
            })
        },
        beforeAvatarUpload(response, file) {
            if (this.format === 'img') {
                if (response.type === this.$store.state.common.enclosureDto.jpegType ||
                    response.type === this.$store.state.common.enclosureDto.pngType) {
                    return true
                }
                this.$message.error('仅支持上传图片格式文件')
                return false
            }
        },
        errorFileList(response, file) {
        },
        // 删除文件信息
        removeFileList(file) {
            this.$confirm('确定进行【删除】操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let fileId = file.id
                this.$emit('getFileData', {
                    fileList: ''
                })
            }).catch(() => { })
        }
    }
}
</script>
<style lang="less" scoped>
.cardUpload {
    float: left;
    text-align: center;
    /* .el-upload-list--picture-card .el-upload-list__item-actions span+span {
        margin-left: 0
    } */
}

.avatar-uploader {
    ::v-deep .el-upload {
        border: 1px dashed #d9d9d9 !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
            border-color: #409EFF !important;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 90px;
            height: 90px;
            line-height: 90px;
            text-align: center;
        }
    }

    .avatar {
        width: 90px;
        height: 90px;
        display: block;
    }
}
</style>