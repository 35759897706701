<template>
  <div class="fast-add-car common-pop">
    <el-dialog title="快速添加人车" :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="car-info">
        <span class="labelTile">第一步：填写车辆信息</span>
        <span class="title">
          <i class="idx">1</i>车辆信息
        </span>
        <div class="car-body">
          <el-form :model="dataForm" ref="carRule" :rules="carRule" size="small" label-width="130px">
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="车牌号" prop="vehiclePlate">
                  <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="车辆类型" prop="vehicleType">
                  <el-select class="item-choose" v-model="dataForm.vehicleType" style="width: 100%;"
                             filterable clearable placeholder="请选择"
                             @change="changeVehicleType(dataForm.vehicleType)">
                    <el-option
                      v-for="item in vehicleTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属车队" prop="motorcadeId">
                    <el-select class="item-choose" v-model="dataForm.motorcadeId" style="width: 100%;" filterable clearable placeholder="请选择">
                      <el-option
                        v-for="item in motorcadeList"
                        :key="item.id"
                        :label="item.simpleName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="能源类型" prop="energyType">
                  <el-select class="item-choose" v-model="dataForm.energyType" style="width: 100%;" filterable clearable placeholder="请选择">
                    <el-option
                      v-for="item in dictTypeMap.energy_type"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                 <el-form-item label="最大载重(kg)" prop="maxLoad">
                    <el-input v-model="dataForm.maxLoad" placeholder="最多两位小数"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="8">
                  <el-form-item label="车辆空重(KG)" prop="vehicleEmptyWeight">
                    <el-input v-model="dataForm.vehicleEmptyWeight" placeholder="车辆空重(KG)" :disabled="isView"></el-input>
                  </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="进门高(cm)" prop="enterDoorHeight">
                  <el-input v-model="dataForm.enterDoorHeight" placeholder="请输入" :disabled="isView"></el-input>
                </el-form-item>
              </el-col>
            <el-col :span="8">
              <el-form-item label="进门宽(cm)" prop="enterDoorWidth">
                <el-input v-model="dataForm.enterDoorWidth" placeholder="请输入" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否有尾板" prop="isTailstock">
                <el-select class="item-choose" v-model="dataForm.isTailstock" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择">
                  <el-option
                    v-for="item in dictTypeMap.yes_no"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="24">
                <el-form-item label="车厢内长/宽/高" prop="carriageInsideHeight">
                  <el-col :span="8">
                    <el-input v-model="dataForm.carriageInsideLength" placeholder="最长(cm)" :disabled="isView"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="dataForm.carriageInsideWidth" placeholder="最宽(cm)" :disabled="isView"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="dataForm.carriageInsideHeight" placeholder="最高(cm)" :disabled="isView"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="driver-info">
        <span class="labelTile">第二步：填写司机信息</span>
        <span class="title">
          <i class="idx">2</i>费用信息
        </span>
        <div class="driver-body">
          <el-form :model="dataForm" ref="driverForm" :rules="driverRule" size="small" label-width="130px">
            <el-row :gutter="50">
              <el-col :span="8">
                  <el-form-item label="驾驶员姓名" prop="bindDriverInfo.name">
                    <el-input v-model="dataForm.bindDriverInfo.name" placeholder="驾驶员姓名" :disabled="isView"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="8">
                 <el-form-item label="联系电话" prop="bindDriverInfo.contactPhone">
                    <el-input v-model="dataForm.bindDriverInfo.contactPhone" placeholder="可输入手机号码或固定电话" :disabled="isView"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属车队" prop="bindDriverInfo.motorcadeId">
                    <el-select class="item-choose" v-model="dataForm.bindDriverInfo.motorcadeId" style="width: 100%;"
                               filterable clearable placeholder="请选择">
                      <el-option
                        v-for="item in motorcadeList"
                        :key="item.id"
                        :label="item.simpleName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                 <el-form-item label="身份证号码" prop="bindDriverInfo.cardNo">
                  <el-input v-model="dataForm.bindDriverInfo.cardNo" placeholder="身份证号码" :disabled="isView"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="准驾类型" prop="bindDriverInfo.proofType">
                  <el-select class="item-choose" v-model="dataForm.bindDriverInfo.proofType" :disabled="isView" style="width: 100%;"
                             filterable clearable placeholder="请选择">
                    <el-option
                      v-for="item in dictTypeMap.drive_type"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="remark-info">
        <span class="remark">备注说明</span>
        <p class="content">请尽快前往“车辆运力”和“驾驶员运力”菜单中完善当前人，驾驶员及车辆信息</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      todo: '',
      motorcadeList: [],
      dataForm: {
        vehiclePlate: '', // 车牌
        vehicleType: '', // 车型
        motorcadeId: '', // 车队
        energyType: '', // 能源类型
        maxLoad: '', // 最大载重
        vehicleEmptyWeight: '', // 车辆空重
        carriageInsideHeight: '', // 车厢进门高
        carriageInsideWidth: '', // 车厢进门宽
        enterDoorHeight: '', // 进门高
        enterDoorWidth: '', // 进门宽
        carriageInsideLength: '', // 车厢
        isTailstock: 2, // 是否有尾板
        bindDriverInfo: {
          name: '', // 司机姓名
          contactPhone: '', // 司机手机号
          motorcadeId: '', // 司机信息所属车队
          cardNo: '', // 身份证号码
          proofType: '' // 准驾车型
        }
      },
      carRule: {
        vehiclePlate: this._Valid.init(['null']),
        motorcadeId: this._Valid.init(['null']),
        energyType: this._Valid.init(['null']),
        vehicleType: this._Valid.init(['null']),
        maxLoad: this._Valid.init(['null']),
        vehicleEmptyWeight: this._Valid.init(['null'])
      },
      driverRule: {
        'bindDriverInfo.name': this._Valid.init(['null']),
        'bindDriverInfo.contactPhone': this._Valid.init(['null']),
        'bindDriverInfo.motorcadeId': this._Valid.init(['null']),
        'bindDriverInfo.cardNo': this._Valid.init(['null'])
      },
      vehicleTypeList: [],
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo == 'view'
    }
  },
  methods: {
    init (row) {
      this.dialogVisible = true
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.motorcadeList = data
      })
      this.dataForm.motorcadeId = row.motorcadeId
      this.dataForm.bindDriverInfo.motorcadeId = row.motorcadeId
      this.dataForm.vehicleType = row.vehicleTypeId
      this.changeVehicleType(row.vehicleTypeId)

      // 获取车型信息
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
      })
    },
    // 选择车型时自动带出车载重\长\宽\高
    changeVehicleType (id) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataForm.maxLoad = data.maxLoad
          this.dataForm.carriageInsideLength = data.carriageLength
          this.dataForm.carriageInsideWidth = data.carriageWidth
          this.dataForm.carriageInsideHeight = data.carriageHeight
        }
      })
    },
    submit () {
      this.$refs['carRule'].validate((validCar) => {
        this.$refs['driverForm'].validate((validDriver) => {
          if (validCar && validDriver) {
            this.$http({
              url: this.$http.adornUrl(`/vehicleInfo/fastCreate`),
              method: 'POST',
              data: this.$http.adornData(this.dataForm)
            }).then(({data}) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.dialogVisible = false
                    this.$emit('refreshDispatch', this.dataForm)
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
.fast-add-car {
  .el-dialog {
    // margin-top: 3vh !important;
    .el-dialog__header {
      padding: 10px;
      font-weight: 500;
    }
    .el-dialog__body {
      padding: 10px;
    }
    .remark-info {
        margin: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        padding: 10px;
        .remark {
            display: block;
            font-weight: 800;
        }
        .content {
            margin: 5px 0;
            text-indent: 2rem;
        }
    }
    .labelTile {
      background: #fff;
      padding: 0 10px;
      position: absolute;
      height: 38px;
      line-height: 38px;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #17B3A3;
      border-radius: 5px;
      z-index: 1;
    }
    .driver-info,.car-info {
        margin-top: 30px;
        padding-right: 20px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        .el-row {
          margin: 0 !important;
          .el-col {
            padding: 0 !important;
          }
        }
      .el-form-item {
          margin-bottom: 10px;
      }
    }
    .title {
      height: 38px;
      line-height: 38px;
      padding-left: 15px;
      margin-top: 20px;
      i.idx {
        display: inline-block;
        background: #17B3A3;
        height: 20px;
        line-height: 20px;
        width: 20px;
        text-align: center;
        color: #fff;
        margin-right: 6px;
      }
    }
  }
}
</style>
