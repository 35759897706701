<template>
  <div class="reassign-popup">
    <el-dialog title="重新指派" :close-on-click-modal="false" :visible.sync="visible" :width="dialogWidth" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="指派方式：" prop="status">
              <el-radio v-model="dataForm.status" :label=1 @change="assMethod">创建新车次</el-radio>
              <el-radio v-model="dataForm.status" :label=2 @change="assMethod">追加到已有车次</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50" v-if="haveData">
          <div class="reassing-table">
            <el-table :data="dataList" border size="small" max-height="400" style="width: 100%;">
              <el-table-column label="选择" width="55" fixed="left">
                <template slot-scope="scope">
                  <el-radio v-model="radioData"  :label="scope.$index" @change.native="getSelectData(scope.row)" class="hideLabel"></el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="status" align="center" label="状态" width="90" fixed="left">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 0" size="warning">已取消</el-tag>
                  <el-tag v-if="scope.row.status === 1" size="danger">未发布</el-tag>
                  <el-tag v-if="scope.row.status === 2" size="small">已发布</el-tag>
                  <el-tag v-if="scope.row.status === 3" size="danger">已接单</el-tag>
                  <el-tag v-if="scope.row.status === 4" size="info">进行中</el-tag>
                </template>
              </el-table-column>
              <el-table-column width="140" label="车次号" prop="planNo" align="left">
              </el-table-column>
              <el-table-column label="提货日期" prop="takeGoodsDate" align="left" width="130" >
              </el-table-column>
              <el-table-column  label="指派车型" prop="vehicleTypeName" width="80" align="left">
              </el-table-column>
              <el-table-column label="提货点数" prop="giveNum" align="left">
              </el-table-column>
              <el-table-column label="卸货点数" prop="takeNum" align="left">
              </el-table-column>
              <el-table-column label="总件数" width="100" prop="pieceNum" align="left">
              </el-table-column>
              <el-table-column  label="总重量" prop="weight" align="left">
              </el-table-column>
              <el-table-column  label="总体积" prop="volume" align="left" width="100">
              </el-table-column>
              <el-table-column  label="体积满载率" prop="volumeLoadRate" align="left" width="100">
                <template slot-scope="scope">
                  <el-tag size="small" type="danger">{{ scope.row.volumeLoadRate }}%</el-tag>
                </template>
              </el-table-column>
              <el-table-column  label="车次创建日期"  prop="createTime" align="left" width="200">
              </el-table-column>
            </el-table>
          </div>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定指派</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dialogWidth: '450px',
      haveData: false,
      selectData: [],
      radioData: [],
      dataForm: {
        status: null
      },
      dataList: [],
      row: {},
      dataRule: {
        status: [
          { required: true, message: '指派方式不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
      this.dataForm.status = null
      this.radioData = []
      this.haveData = false
      this.dialogWidth = '450px'
    },
    // 指派方式
    assMethod () {
      // /vehiclePlan/planAddWaybill
      if (this.dataForm.status === 1) {
        this.dialogWidth = '450px'
        this.haveData = false
      } else {
        this.dialogWidth = '80vw'
        this.haveData = true
        this.$http({
          url: this.$http.adornUrl(`/vehiclePlan/findPlanListByVehiclePlate`),
          method: 'GET',
          params: this.$http.adornParams({
            'vehiclePlate': this.row.vehiclePlate,
            'takeGoodsDate': this.row.takeGoodsDate
          })
        }).then(({ data }) => {
          this.dataList = data
        })
      }
    },
    // 选择
    getSelectData (row) {
      this.selectData = row
    },
    submit () {
      // /vehiclePlan/planAddWaybill    加入已有车次
      // /vehiclePlan/findPlanListByVehiclePlate 查询未完成的车次列表(选择已有车次)
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.status === 1) {
            this.row.status = 2
            this.$http({
              url: this.$http.adornUrl(`/vehiclePlan/createPlan`),
              method: 'PUT',
              data: this.$http.adornData(this.row)
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '重新指派成功',
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                    this.$emit('closeReassign')
                  }
                })
              }
            })
          } else {
            if (this.selectData.id === this.row.oldPlanId) {
              this.$message.error('重新指派的车次不能是同一个车次')
              return
            }
            this.$http({
              url: this.$http.adornUrl(`/vehiclePlan/planAddOrder`),
              method: 'POST',
              data: this.$http.adornData({
                orderInfoList: this.row.orderInfoList,
                oldPlanId: this.row.oldPlanId,
                id: this.selectData.id
              })
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '重新指派成功',
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                    this.$emit('closeReassign')
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.reassign-popup {
  .hideLabel {
    .el-radio__label {
      display: none
    }
  }
  .el-dialog__header {
    padding: 10px 20px 10px;
  }
  .el-dialog__body {
    padding: 0px 25px 0px;
  }
  .el-radio+.el-radio {
    margin-left: 0px;
  }
  .reassing-table {
    padding: 0 20px;
    td {
      padding: 3px 0 !important
    }
  }
}
</style>
