<template>
  <div class="processed tableBox">
    <el-table height="55vh" :row-class-name="tableRowClassName" :append-to-body="true"
              @row-click="rowClick" :row-style="selectedHighlight"
              :data="dataList" border size="small" v-loading="dataListLoading" >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="noticeContent" label="标题">
      </el-table-column>
      <el-table-column prop="createName" label="发布人">
        <template slot-scope="scope">
          系统通知
        </template>
      </el-table-column>
      <el-table-column prop="noticeTime" label="提醒时间">
      </el-table-column>
      <el-table-column prop="handleName" label="处理人" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="handleTime" label="处理时间">
      </el-table-column>
<!--      <el-table-column prop="status" label="当前状态" width="70" :show-overflow-tooltip="true">-->
<!--        <template slot-scope="scope">-->
<!--          <el-tag v-if="scope.row.status === 2" size="small" type="danger">未读</el-tag>-->
<!--          <el-tag v-else size="small" type="success">已读</el-tag>-->
<!--        </template>-->
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: true,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false
    }
  },
  mounted () {
    // this.getDataList()
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 获取数据列表
    getDataList () {
      // status == 1 已处理
      this.$http({
        url: this.$http.adornUrl('/sysNotice/findList'),
        method: 'get',
        params: this.$http.adornParams({
          status: 1,
          'page': this.pageIndex,
          'limit': this.pageSize
        })
      }).then(({ data }) => {
        if(data.total) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 1
        }
      })
    }
  }
}
</script>
