<template>
  <div class="common-pop">
    <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
        <el-form-item label="岗位名称" prop="name">
          <el-input v-model="dataForm.name" placeholder="岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="岗位编码" prop="jobCode">
          <el-select class="item-choose" v-model="dataForm.jobCode" size="small" filterable clearable placeholder="请选择岗位" :disabled="this.dataForm.id != null">
            <el-option v-for="item in jobCodeArray" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标记类型" prop="whetherDepartmenOtrPosition">
          <el-select class="item-choose" v-model="dataForm.whetherDepartmentOrPosition" size="small" filterable clearable placeholder="请选择标记类型">
            <el-option v-for="item in whetherDepartmentOrPositionArray" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序号" prop="jobSort">
          <el-input-number v-model="dataForm.jobSort" controls-position="right" :min="0" label="排序号"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        name: null,
        jobCode: null,
        jobSort: 1
      },
      dataRule: {
        name: [
          { required: true, message: '岗位名称不能为空', trigger: 'blur' }
        ],
        jobCode: [
          { required: true, message: '岗位编码不能为空', trigger: 'blur' }
        ],
        whetherDepartmentOrPositionArray: [
          { required: true, message: '标记类型不能为空', trigger: 'blur' }
        ]
      },

      // 临时方案,后期升级
      jobCodeArray: [
        {name: '业务员', value: 'SALE'},
        {name: '业务员经理', value: 'SALE_ADMIN'},
        {name: '客服员', value: 'CARE'},
        {name: '客服经理', value: 'CARE_ADMIN'},
        {name: '业务部', value: 'SALE_DEP'},
        {name: '客服部', value: 'CARE_DEP'},
      ],
      whetherDepartmentOrPositionArray: [
        {name: '岗位', value: 1},
        {name: '部门', value: 0},
      ]
    }
  },
  methods: {
    init (id) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      this.dataForm.id = id
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/system/job/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          this.dataForm.name = data.name
          this.dataForm.jobCode = data.jobCode
          this.dataForm.jobSort = data.jobSort
          this.dataForm.whetherDepartmentOrPosition = data.whetherDepartmentOrPosition
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = this.dataForm.id ? 'update' : 'add'
          let method = this.dataForm.id ? 'post' : 'put'
          this.$http({
            url: this.$http.adornUrl(`/system/job/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'name': this.dataForm.name,
              'jobCode': this.dataForm.jobCode,
              'jobSort': this.dataForm.jobSort,
              'whetherDepartmentOrPosition': this.dataForm.whetherDepartmentOrPosition,
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.message)
            }
          })
        }
      })
    }
  }
}
</script>
