<template>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
    <div class="site-navbar__header">
      <h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
        <a class="site-navbar__brand-lg" href="javascript:;">
        {{  currentUser.tenantName || '瑞通达智慧TMS' }}
         </a>
        <a class="site-navbar__brand-mini" href="javascript:;">
          <icon-svg name="tms_menu"></icon-svg>
        </a>
      </h1>
    </div>
    <div class="site-navbar__body clearfix">
      <el-menu class="site-navbar__menu" mode="horizontal" :unique-opened="true" :collapse-transition="true">
        <el-menu-item class="site-navbar__switch" index="0" @click="sidebarFold = !sidebarFold">
          <icon-svg name="zhedie" :class="sidebarFold ? 'showCollapse' : ''"></icon-svg>
        </el-menu-item>
      </el-menu>
      <div class="menuUls" v-if="newAuth('home:show:count')">
        <label :class="this.$store.state.common.menusSelectLi == 'all' ? 'selectedLable label-left' : 'label-left'" @click="showDetail('all', dataForm.totalNum)">总车辆({{dataForm.totalNum}})</label>
        <label :class="this.$store.state.common.menusSelectLi == 'out' ? 'selectedLable label-left' : 'label-left'" @click="showDetail('out' , dataForm.orderNum)">已出车({{dataForm.orderNum}})</label>
        <label :class="this.$store.state.common.menusSelectLi == 'notOut' ? 'selectedLable label-left' : 'label-left'" @click="showDetail('notOut' , dataForm.noOutNum)">未出车({{dataForm.noOutNum}})</label>
        <label class="label-left">今日订单({{dataForm.todayCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '新委派' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('新委派')">新委派({{dataForm.auditCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '调度中' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('调度中')">调度中({{dataForm.dispatchCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '已调度' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('已调度')">已调度({{dataForm.yesDispatchCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '已出车' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('已出车')">已出车({{dataForm.outDriveCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '已到达' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('已到达')">已到达({{dataForm.arrivedCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '已装货' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('已装货')">已装货({{dataForm.goodsCount}})</label>
        <label :class="this.$store.state.common.menusSelectLi == '已签收' ? 'selectedLable label-left' : 'label-left'" @click="gotoPage('已签收')">已签收({{dataForm.successCount}})</label>
         <!-- <span class="label-left">
            <el-button type="text" style="color: red" @click="viewDate('即将到期')">即将到期({{dataForm.soonCount}})</el-button>
            <el-button type="text" style="color: red" @click="viewDate('已到期')">已到期({{dataForm.expireCount}})</el-button>
        </span> -->
<!--        <span :class="rotate ? 'refresh-count-rotate refresh-count' : 'refresh-count'" @click="countTitle('refresh')">-->
<!--          <i class="el-icon-refresh"></i>-->
<!--        </span>-->
        <span class="label-left">
           <el-tooltip :content="'主题颜色: ' + sidebarLayoutSkin === 'light' ? '深色' : sidebarLayoutSkin === 'dark' ? '浅色' : '深色'" placement="top">
            <el-switch
              v-model="sidebarLayoutSkin"
              active-color="#dcdfe6"
              inactive-color="#263238"
              active-value="dark"
              inactive-value="light">
            </el-switch>
          </el-tooltip>
        </span>
        <div class="turn"></div>
      </div>
      <div style="line-height: 40px;">
        <el-menu
          class="site-navbar__menu site-navbar__menu--right"
          mode="horizontal">
          <el-menu-item class="site-navbar__avatar" index="3">
            <span class="tongzhi" @click="updateNotes" v-if="newAuth('early:warning:find') || newAuth('insurance:management:find')">
              <icon-svg name="tongzhi2" class="site-sidebar__menu-icon"></icon-svg>
              <span class="text">{{ notesCount > 0 ? notesCount : 0 }}</span>
            </span>
            <el-dropdown :show-timeout="0" placement="bottom" class="dropUser">
              <span class="el-dropdown-link">
                {{ currentUser.realName }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 弹窗, 修改密码 -->
    <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
    <!-- 显示详情弹框 -->
    <navbar-detail ref="detail"></navbar-detail>
    <view-date ref="viewDate"></view-date>
    <update-notes-pop ref="updateNotes"></update-notes-pop>
  </nav>
</template>

<script>
import UpdatePassword from './main-navbar-update-password'
import navbarDetail from '@/views/commonPop/navbarDetail'
import viewDate from '@/views/commonPop/viewDate'
import updateNotesPop from '@/views/commonPop/updateNotesPop'
import { clearLoginInfo } from '@/utils'
export default {
  data () {
    return {
      updatePassowrdVisible: false,
      rotate: false,
      notesCount: 0,
      timer: null,
      dataForm: {
        totalNum: 0,
        orderNum: 0,
        noOutNum: 0,
        todayCount: 0,
        auditCount: 0,
        dispatchCount: 0,
        yesDispatchCount: 0,
        outDriveCount: 0,
        arrivedCount: 0,
        goodsCount: 0,
        successCount: 0,
        soonCount: 0,
        expireCount: 0
      }
    }
  },
  components: {
    UpdatePassword,
    navbarDetail,
    viewDate,
    updateNotesPop
  },
  computed: {
    navbarLayoutType: {
      get () { return this.$store.state.common.navbarLayoutType }
    },
    sidebarFold: {
      get () { return this.$store.state.common.sidebarFold },
      set (val) { this.$store.commit('common/updateSidebarFold', val) }
    },
    mainTabs: {
      get () { return this.$store.state.common.mainTabs },
      set (val) { this.$store.commit('common/updateMainTabs', val) }
    },
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    },
    sidebarLayoutSkin: {
      get () { return this.$store.state.common.sidebarLayoutSkin },
      set (val) { this.$store.commit('common/updateSidebarLayoutSkin', val) }
    }
  },
  created () {
    if (!this.newAuth('home:show:count')) {
      return
    }
    this.countTitle()
    if (this.timer === null || this.timer === undefined) {
      this.timer = setInterval(() => {
        this.countTitle()
      }, 30 * 1000)
    }
  },
  beforeDestroy () {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  },
  mounted () {
    this.$http({
      url: this.$http.adornUrl('/sysNotice/count'),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      if (data > 0) {
        this.notesCount = data
      } else {
        this.notesCount = 0
      }
    })
  },
  watch: {
    '$store.state.common.notesCount': {
      handler: function (val, oldVal) {
        this.notesCount = val
      },
      deep: true
    }
  },
  methods: {
    countTitle () {
      this.rotate = true
      this.$http({
        url: this.$http.adornUrl('/order/homeTitleCount/'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm.totalNum = data.vehicleInfo.total
        this.dataForm.orderNum = data.vehicleInfo.orderNum
        this.dataForm.noOutNum = data.vehicleInfo.noOutNum
        this.dataForm.todayCount = data.orderInfo.total
        this.dataForm.auditCount = data.orderInfo.auditCount
        this.dataForm.dispatchCount = data.orderInfo.dispatchCount
        this.dataForm.yesDispatchCount = data.orderInfo.yesDispatchCount
        this.dataForm.outDriveCount = data.orderInfo.outDriveCount
        this.dataForm.arrivedCount = data.orderInfo.arrivedCount
        this.dataForm.goodsCount = data.orderInfo.goodsCount
        this.dataForm.successCount = data.orderInfo.successCount
        this.dataForm.soonCount = data.cardWarn.soonCount
        this.dataForm.expireCount = data.cardWarn.expireCount
        this.rotate = false
      })
    },
    // 修改密码
    updatePasswordHandle () {
      this.updatePassowrdVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init()
      })
    },
    updateNotes () {
      this.$refs.updateNotes.init()
    },
    isShowCount () {
      return !this.currentUser.customerId
    },
    viewDate (name) {
      this.$refs.viewDate.init(name)
    },
    gotoPage (type) {
      this.$store.commit('common/updateMenusSelectLi', type)
      if (type === '新委派') {
        this.$store.commit('common/updateAudited', type)
        this.$router.push({
          name: 'order-list-inside'
        })
      } else {
        this.$store.commit('common/updateAudited', '')
      }
      if (type === '调度中' || type === '已调度' || type === '已出车' || type === '已到达' || type === '已装货' || type === '已签收') {
        this.$store.commit('common/updateWayBillType', type)
        this.$router.push({
          name: 'operPlatform-waybillManager'
        })
      } else {
        this.$store.commit('common/updateWayBillType', '')
      }
    },
    showDetail (name, orderNum) {
      this.$store.commit('common/', name)
      this.$refs.detail.init(name, orderNum)
    },
    // 退出
    logoutHandle () {
      this.$confirm(`确定进行[退出]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/auth/logout'),
          method: 'delete',
          data: this.$http.adornData()
        }).then(({ data }) => {
          clearLoginInfo()
          this.$router.push({ name: 'login' })
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
.showCollapse {
    transform:rotate(90deg)
}
.el-dropdown-link {
    cursor: pointer;
    color: #45c2b5;
    font-weight: 600;
}
.tongzhi {
  // position: absolute;
  // right: 6px;
  // top: 1px;
  position: relative;
  float: left;
  padding-top: 2px;
  .text {
    position: absolute;
    right: -3px;
    top: 0px;
    display: inline-block;
    color: red;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
  }
}
.menuUls {
  height: 40px;
  line-height: 35px;
  /*width: 65vw;*/
  left: 20px;
  position: absolute;
  //display: flex;  .refresh-count {
    margin-left: 28px;
    font-size: 14px;
    margin-top: 2px;
    color: #17b3a3;
    cursor: pointer;
  }
  .label-left {
    margin-left: 8px;
    color: red;
    cursor: pointer;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .selectedLable {
      color:#17b3a3
  }
  .refresh-count {
    display: inline-block;
    margin-left: 10px;
  }
  .refresh-count-rotate{
      transform-origin: 50% 50%;
      animation:turn 1s linear infinite;
    }
  @keyframes turn{
      0%{-webkit-transform:rotate(0deg);}
      25%{-webkit-transform:rotate(90deg);}
      50%{-webkit-transform:rotate(180deg);}
      75%{-webkit-transform:rotate(270deg);}
      100%{-webkit-transform:rotate(360deg);}
    }
// .el-popper {
//     margin-top: -8px !important;
// }
</style>
